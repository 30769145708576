/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CorporationPublisherInvoiceGrouped } from '../AfClApiModels';
/**
 * CorporationInvoicesApi - axios parameter creator
 * @export
 */
export const CorporationInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Invoice
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {number} invoiceId Numeric ID of the Invoice to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationDownloadInvoice: async (corporationId: number, invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('corporationDownloadInvoice', 'corporationId', corporationId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('corporationDownloadInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/v1/corporations/{corporationId}/invoices/{invoiceId}/download`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoices Grouped by month
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {number} [publisherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetInvoicesGrouped: async (corporationId: number, from: string, to: string, publisherId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('corporationGetInvoicesGrouped', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('corporationGetInvoicesGrouped', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('corporationGetInvoicesGrouped', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/invoices/grouped`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorporationInvoicesApi - functional programming interface
 * @export
 */
export const CorporationInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorporationInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download Invoice
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {number} invoiceId Numeric ID of the Invoice to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corporationDownloadInvoice(corporationId: number, invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corporationDownloadInvoice(corporationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoices Grouped by month
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {number} [publisherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corporationGetInvoicesGrouped(corporationId: number, from: string, to: string, publisherId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CorporationPublisherInvoiceGrouped>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corporationGetInvoicesGrouped(corporationId, from, to, publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorporationInvoicesApi - factory interface
 * @export
 */
export const CorporationInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorporationInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Download Invoice
         * @param {CorporationInvoicesApiCorporationDownloadInvoiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationDownloadInvoice(requestParameters: CorporationInvoicesApiCorporationDownloadInvoiceRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.corporationDownloadInvoice(requestParameters.corporationId, requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoices Grouped by month
         * @param {CorporationInvoicesApiCorporationGetInvoicesGroupedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetInvoicesGrouped(requestParameters: CorporationInvoicesApiCorporationGetInvoicesGroupedRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CorporationPublisherInvoiceGrouped>> {
            return localVarFp.corporationGetInvoicesGrouped(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for corporationDownloadInvoice operation in CorporationInvoicesApi.
 * @export
 * @interface CorporationInvoicesApiCorporationDownloadInvoiceRequest
 */
export interface CorporationInvoicesApiCorporationDownloadInvoiceRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationInvoicesApiCorporationDownloadInvoice
     */
    readonly corporationId: number

    /**
     * Numeric ID of the Invoice to find
     * @type {number}
     * @memberof CorporationInvoicesApiCorporationDownloadInvoice
     */
    readonly invoiceId: number
}

/**
 * Request parameters for corporationGetInvoicesGrouped operation in CorporationInvoicesApi.
 * @export
 * @interface CorporationInvoicesApiCorporationGetInvoicesGroupedRequest
 */
export interface CorporationInvoicesApiCorporationGetInvoicesGroupedRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationInvoicesApiCorporationGetInvoicesGrouped
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationInvoicesApiCorporationGetInvoicesGrouped
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationInvoicesApiCorporationGetInvoicesGrouped
     */
    readonly to: string

    /**
     * 
     * @type {number}
     * @memberof CorporationInvoicesApiCorporationGetInvoicesGrouped
     */
    readonly publisherId?: number
}

/**
 * CorporationInvoicesApi - object-oriented interface
 * @export
 * @class CorporationInvoicesApi
 * @extends {BaseAPI}
 */
export class CorporationInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Download Invoice
     * @param {CorporationInvoicesApiCorporationDownloadInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationInvoicesApi
     */
    public corporationDownloadInvoice(requestParameters: CorporationInvoicesApiCorporationDownloadInvoiceRequest, options?: AxiosRequestConfig) {
        return CorporationInvoicesApiFp(this.configuration).corporationDownloadInvoice(requestParameters.corporationId, requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoices Grouped by month
     * @param {CorporationInvoicesApiCorporationGetInvoicesGroupedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationInvoicesApi
     */
    public corporationGetInvoicesGrouped(requestParameters: CorporationInvoicesApiCorporationGetInvoicesGroupedRequest, options?: AxiosRequestConfig) {
        return CorporationInvoicesApiFp(this.configuration).corporationGetInvoicesGrouped(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }
}
