<template>
    <BaseButton
        class="ml-auto block"
        :loading="isFetching"
        @click="addKey"
    >
        Add key
    </BaseButton>
    <BaseTable
        :items="keys"
        :headers="tableHeaders"
        :is-loading="isFetching"
        class="mt-10"
    >
        <template #row="{ headers, item }">
            <BaseTableRow v-bind="{ headers, item }">
                <template #start_at>
                    {{ item.start_at ? defaultDate(item.start_at as unknown as string) : 'No info' }}
                </template>
                <template #end_at>
                    {{ item.end_at ? defaultDate(item.end_at as unknown as string) : 'No info' }}
                </template>
                <template #custom_actions>
                    <BaseButton @click="updateKey(item)">
                        Edit key
                    </BaseButton>
                </template>
            </BaseTableRow>
        </template>
    </BaseTable>
    <KeysCampaignModal
        v-model="keyCampaignModal"
        :selected-key
    />
</template>
<script setup lang="ts">
import BaseTable from '@/Components/Core/Table/BaseTable.vue';
import BaseTableRow from '@/Components/Core/Table/BaseTableRow.vue';
import { TableHeader } from '@/Models/Components/BaseTableContract';
import { useCampaignKeys } from '@/Services/ApiHooks/Shared/UseCampaignKeys';
import BaseButton from '@/Components/Core/BaseButton.vue';
import { ref } from 'vue';
import KeysCampaignModal from '@/Components/Pages/Admin/Keys/KeysCampaignModal.vue';
import { CampaignKey } from '@/stub';
import defaultDate from '@/Utils/Filters/DefaultDate.filter';

const { data: keys, isFetching } = useCampaignKeys();
const tableHeaders: TableHeader<CampaignKey>[] = [
    {
        label: 'Campaign name',
        key: 'name',
        forceAlign: 'start'
    },
    {
        label: 'Start date',
        key: 'start_at',
        forceAlign: 'end'
    },
    {
        label: 'End date',
        key: 'end_at',
        forceAlign: 'end'
    },
    {
        label: '',
        key: 'custom_actions',
        forceAlign: 'end',
    },
];

const keyCampaignModal = ref(false);
const selectedKey = ref<CampaignKey>();
const updateKey = (item: CampaignKey) => {
    keyCampaignModal.value = true;
    selectedKey.value = item;
};
const addKey = () => {
    selectedKey.value = undefined;
    keyCampaignModal.value = true;
};
</script>
@/Services/ApiHooks/Shared/UseCampaignKeys
