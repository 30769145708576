/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiGetVoonixAccounts200Response } from '../AfClApiModels';
/**
 * BiVoonixAccountsApi - axios parameter creator
 * @export
 */
export const BiVoonixAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Voonix Accounts
         * @param {string} [login] 
         * @param {'MANUAL' | 'AUTO' | 'UNMAPPED'} [status] 
         * @param {number} [accountId] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetVoonixAccounts: async (login?: string, status?: 'MANUAL' | 'AUTO' | 'UNMAPPED', accountId?: number, affiliateAccountIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/voonix-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (affiliateAccountIds) {
                localVarQueryParameter['affiliate_account_ids[]'] = affiliateAccountIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiVoonixAccountsApi - functional programming interface
 * @export
 */
export const BiVoonixAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiVoonixAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Voonix Accounts
         * @param {string} [login] 
         * @param {'MANUAL' | 'AUTO' | 'UNMAPPED'} [status] 
         * @param {number} [accountId] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biGetVoonixAccounts(login?: string, status?: 'MANUAL' | 'AUTO' | 'UNMAPPED', accountId?: number, affiliateAccountIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiGetVoonixAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biGetVoonixAccounts(login, status, accountId, affiliateAccountIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiVoonixAccountsApi - factory interface
 * @export
 */
export const BiVoonixAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiVoonixAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Voonix Accounts
         * @param {BiVoonixAccountsApiBiGetVoonixAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetVoonixAccounts(requestParameters: BiVoonixAccountsApiBiGetVoonixAccountsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BiGetVoonixAccounts200Response> {
            return localVarFp.biGetVoonixAccounts(requestParameters.login, requestParameters.status, requestParameters.accountId, requestParameters.affiliateAccountIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for biGetVoonixAccounts operation in BiVoonixAccountsApi.
 * @export
 * @interface BiVoonixAccountsApiBiGetVoonixAccountsRequest
 */
export interface BiVoonixAccountsApiBiGetVoonixAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof BiVoonixAccountsApiBiGetVoonixAccounts
     */
    readonly login?: string

    /**
     * 
     * @type {'MANUAL' | 'AUTO' | 'UNMAPPED'}
     * @memberof BiVoonixAccountsApiBiGetVoonixAccounts
     */
    readonly status?: 'MANUAL' | 'AUTO' | 'UNMAPPED'

    /**
     * 
     * @type {number}
     * @memberof BiVoonixAccountsApiBiGetVoonixAccounts
     */
    readonly accountId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof BiVoonixAccountsApiBiGetVoonixAccounts
     */
    readonly affiliateAccountIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof BiVoonixAccountsApiBiGetVoonixAccounts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BiVoonixAccountsApiBiGetVoonixAccounts
     */
    readonly perPage?: number
}

/**
 * BiVoonixAccountsApi - object-oriented interface
 * @export
 * @class BiVoonixAccountsApi
 * @extends {BaseAPI}
 */
export class BiVoonixAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Get Voonix Accounts
     * @param {BiVoonixAccountsApiBiGetVoonixAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiVoonixAccountsApi
     */
    public biGetVoonixAccounts(requestParameters: BiVoonixAccountsApiBiGetVoonixAccountsRequest = {}, options?: AxiosRequestConfig) {
        return BiVoonixAccountsApiFp(this.configuration).biGetVoonixAccounts(requestParameters.login, requestParameters.status, requestParameters.accountId, requestParameters.affiliateAccountIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
