/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPublisherAccessTokens200Response } from '../AfClApiModels';
// @ts-ignore
import { PublisherApiAccessTokenResource } from '../AfClApiModels';
// @ts-ignore
import { StorePublisherApiTokenRequest } from '../AfClApiModels';
/**
 * PublisherAccessTokensApi - axios parameter creator
 * @export
 */
export const PublisherAccessTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Publisher Access Token
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {StorePublisherApiTokenRequest} [storePublisherApiTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherAccessToken: async (publisherId: number, storePublisherApiTokenRequest?: StorePublisherApiTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createPublisherAccessToken', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/api-center/tokens`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storePublisherApiTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Publisher Access Token
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} tokenId Numeric ID of the Token to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherAccessToken: async (publisherId: number, tokenId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('deletePublisherAccessToken', 'publisherId', publisherId)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('deletePublisherAccessToken', 'tokenId', tokenId)
            const localVarPath = `/v1/publishers/{publisherId}/api-center/tokens/{tokenId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publisher Access Tokens
         * @param {number} publisherId Numeric ID of the Publisher
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherAccessTokens: async (publisherId: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherAccessTokens', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/api-center/tokens`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherAccessTokensApi - functional programming interface
 * @export
 */
export const PublisherAccessTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherAccessTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Publisher Access Token
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {StorePublisherApiTokenRequest} [storePublisherApiTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisherAccessToken(publisherId: number, storePublisherApiTokenRequest?: StorePublisherApiTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherApiAccessTokenResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisherAccessToken(publisherId, storePublisherApiTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Publisher Access Token
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} tokenId Numeric ID of the Token to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePublisherAccessToken(publisherId: number, tokenId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePublisherAccessToken(publisherId, tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publisher Access Tokens
         * @param {number} publisherId Numeric ID of the Publisher
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherAccessTokens(publisherId: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublisherAccessTokens200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherAccessTokens(publisherId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherAccessTokensApi - factory interface
 * @export
 */
export const PublisherAccessTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherAccessTokensApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Publisher Access Token
         * @param {PublisherAccessTokensApiCreatePublisherAccessTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherAccessToken(requestParameters: PublisherAccessTokensApiCreatePublisherAccessTokenRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherApiAccessTokenResource> {
            return localVarFp.createPublisherAccessToken(requestParameters.publisherId, requestParameters.storePublisherApiTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Publisher Access Token
         * @param {PublisherAccessTokensApiDeletePublisherAccessTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherAccessToken(requestParameters: PublisherAccessTokensApiDeletePublisherAccessTokenRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePublisherAccessToken(requestParameters.publisherId, requestParameters.tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publisher Access Tokens
         * @param {PublisherAccessTokensApiGetPublisherAccessTokensRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherAccessTokens(requestParameters: PublisherAccessTokensApiGetPublisherAccessTokensRequest, options?: AxiosRequestConfig): AxiosPromise<GetPublisherAccessTokens200Response> {
            return localVarFp.getPublisherAccessTokens(requestParameters.publisherId, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPublisherAccessToken operation in PublisherAccessTokensApi.
 * @export
 * @interface PublisherAccessTokensApiCreatePublisherAccessTokenRequest
 */
export interface PublisherAccessTokensApiCreatePublisherAccessTokenRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherAccessTokensApiCreatePublisherAccessToken
     */
    readonly publisherId: number

    /**
     * 
     * @type {StorePublisherApiTokenRequest}
     * @memberof PublisherAccessTokensApiCreatePublisherAccessToken
     */
    readonly storePublisherApiTokenRequest?: StorePublisherApiTokenRequest
}

/**
 * Request parameters for deletePublisherAccessToken operation in PublisherAccessTokensApi.
 * @export
 * @interface PublisherAccessTokensApiDeletePublisherAccessTokenRequest
 */
export interface PublisherAccessTokensApiDeletePublisherAccessTokenRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherAccessTokensApiDeletePublisherAccessToken
     */
    readonly publisherId: number

    /**
     * Numeric ID of the Token to delete
     * @type {number}
     * @memberof PublisherAccessTokensApiDeletePublisherAccessToken
     */
    readonly tokenId: number
}

/**
 * Request parameters for getPublisherAccessTokens operation in PublisherAccessTokensApi.
 * @export
 * @interface PublisherAccessTokensApiGetPublisherAccessTokensRequest
 */
export interface PublisherAccessTokensApiGetPublisherAccessTokensRequest {
    /**
     * Numeric ID of the Publisher
     * @type {number}
     * @memberof PublisherAccessTokensApiGetPublisherAccessTokens
     */
    readonly publisherId: number

    /**
     * 
     * @type {number}
     * @memberof PublisherAccessTokensApiGetPublisherAccessTokens
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherAccessTokensApiGetPublisherAccessTokens
     */
    readonly perPage?: number
}

/**
 * PublisherAccessTokensApi - object-oriented interface
 * @export
 * @class PublisherAccessTokensApi
 * @extends {BaseAPI}
 */
export class PublisherAccessTokensApi extends BaseAPI {
    /**
     * 
     * @summary Create Publisher Access Token
     * @param {PublisherAccessTokensApiCreatePublisherAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherAccessTokensApi
     */
    public createPublisherAccessToken(requestParameters: PublisherAccessTokensApiCreatePublisherAccessTokenRequest, options?: AxiosRequestConfig) {
        return PublisherAccessTokensApiFp(this.configuration).createPublisherAccessToken(requestParameters.publisherId, requestParameters.storePublisherApiTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Publisher Access Token
     * @param {PublisherAccessTokensApiDeletePublisherAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherAccessTokensApi
     */
    public deletePublisherAccessToken(requestParameters: PublisherAccessTokensApiDeletePublisherAccessTokenRequest, options?: AxiosRequestConfig) {
        return PublisherAccessTokensApiFp(this.configuration).deletePublisherAccessToken(requestParameters.publisherId, requestParameters.tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publisher Access Tokens
     * @param {PublisherAccessTokensApiGetPublisherAccessTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherAccessTokensApi
     */
    public getPublisherAccessTokens(requestParameters: PublisherAccessTokensApiGetPublisherAccessTokensRequest, options?: AxiosRequestConfig) {
        return PublisherAccessTokensApiFp(this.configuration).getPublisherAccessTokens(requestParameters.publisherId, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
