/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetAdvertiserReport200Response } from '../AfClApiModels';
// @ts-ignore
import { GetAdvertiserReportTotals200Response } from '../AfClApiModels';
/**
 * AdvertiserReportsApi - axios parameter creator
 * @export
 */
export const AdvertiserReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export advertiser report data to csv file
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {string} from data format Y-m-d
         * @param {string} to data format Y-m-d
         * @param {string} groupBy program or site_group
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAdvertiserReport: async (advertiserId: number, from: string, to: string, groupBy: string, brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('exportAdvertiserReport', 'advertiserId', advertiserId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportAdvertiserReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportAdvertiserReport', 'to', to)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('exportAdvertiserReport', 'groupBy', groupBy)
            const localVarPath = `/v1/advertisers/{advertiserId}/reports/export`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get advertiser report data
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {string} from data format Y-m-d
         * @param {string} to data format Y-m-d
         * @param {string} groupBy program or site_group
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserReport: async (advertiserId: number, from: string, to: string, groupBy: string, brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('getAdvertiserReport', 'advertiserId', advertiserId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAdvertiserReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAdvertiserReport', 'to', to)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('getAdvertiserReport', 'groupBy', groupBy)
            const localVarPath = `/v1/advertisers/{advertiserId}/reports`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get advertiser report total data
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {string} from data format Y-m-d
         * @param {string} to data format Y-m-d
         * @param {string} [previousFrom] 
         * @param {string} [previousTo] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserReportTotals: async (advertiserId: number, from: string, to: string, previousFrom?: string, previousTo?: string, brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('getAdvertiserReportTotals', 'advertiserId', advertiserId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAdvertiserReportTotals', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAdvertiserReportTotals', 'to', to)
            const localVarPath = `/v1/advertisers/{advertiserId}/reports/totals`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (previousFrom !== undefined) {
                localVarQueryParameter['previous_from'] = previousFrom;
            }

            if (previousTo !== undefined) {
                localVarQueryParameter['previous_to'] = previousTo;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertiserReportsApi - functional programming interface
 * @export
 */
export const AdvertiserReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertiserReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export advertiser report data to csv file
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {string} from data format Y-m-d
         * @param {string} to data format Y-m-d
         * @param {string} groupBy program or site_group
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAdvertiserReport(advertiserId: number, from: string, to: string, groupBy: string, brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAdvertiserReport(advertiserId, from, to, groupBy, brandIds, regionIds, verticalIds, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get advertiser report data
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {string} from data format Y-m-d
         * @param {string} to data format Y-m-d
         * @param {string} groupBy program or site_group
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertiserReport(advertiserId: number, from: string, to: string, groupBy: string, brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvertiserReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertiserReport(advertiserId, from, to, groupBy, brandIds, regionIds, verticalIds, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get advertiser report total data
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {string} from data format Y-m-d
         * @param {string} to data format Y-m-d
         * @param {string} [previousFrom] 
         * @param {string} [previousTo] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertiserReportTotals(advertiserId: number, from: string, to: string, previousFrom?: string, previousTo?: string, brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvertiserReportTotals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertiserReportTotals(advertiserId, from, to, previousFrom, previousTo, brandIds, regionIds, verticalIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertiserReportsApi - factory interface
 * @export
 */
export const AdvertiserReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertiserReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Export advertiser report data to csv file
         * @param {AdvertiserReportsApiExportAdvertiserReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAdvertiserReport(requestParameters: AdvertiserReportsApiExportAdvertiserReportRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.exportAdvertiserReport(requestParameters.advertiserId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get advertiser report data
         * @param {AdvertiserReportsApiGetAdvertiserReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserReport(requestParameters: AdvertiserReportsApiGetAdvertiserReportRequest, options?: AxiosRequestConfig): AxiosPromise<GetAdvertiserReport200Response> {
            return localVarFp.getAdvertiserReport(requestParameters.advertiserId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get advertiser report total data
         * @param {AdvertiserReportsApiGetAdvertiserReportTotalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserReportTotals(requestParameters: AdvertiserReportsApiGetAdvertiserReportTotalsRequest, options?: AxiosRequestConfig): AxiosPromise<GetAdvertiserReportTotals200Response> {
            return localVarFp.getAdvertiserReportTotals(requestParameters.advertiserId, requestParameters.from, requestParameters.to, requestParameters.previousFrom, requestParameters.previousTo, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for exportAdvertiserReport operation in AdvertiserReportsApi.
 * @export
 * @interface AdvertiserReportsApiExportAdvertiserReportRequest
 */
export interface AdvertiserReportsApiExportAdvertiserReportRequest {
    /**
     * Numeric ID of the Advertiser
     * @type {number}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly advertiserId: number

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly from: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly to: string

    /**
     * program or site_group
     * @type {string}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly groupBy: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertiserReportsApiExportAdvertiserReport
     */
    readonly perPage?: number
}

/**
 * Request parameters for getAdvertiserReport operation in AdvertiserReportsApi.
 * @export
 * @interface AdvertiserReportsApiGetAdvertiserReportRequest
 */
export interface AdvertiserReportsApiGetAdvertiserReportRequest {
    /**
     * Numeric ID of the Advertiser
     * @type {number}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly advertiserId: number

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly from: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly to: string

    /**
     * program or site_group
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly groupBy: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertiserReportsApiGetAdvertiserReport
     */
    readonly perPage?: number
}

/**
 * Request parameters for getAdvertiserReportTotals operation in AdvertiserReportsApi.
 * @export
 * @interface AdvertiserReportsApiGetAdvertiserReportTotalsRequest
 */
export interface AdvertiserReportsApiGetAdvertiserReportTotalsRequest {
    /**
     * Numeric ID of the Advertiser
     * @type {number}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly advertiserId: number

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly from: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly previousFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly previousTo?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserReportsApiGetAdvertiserReportTotals
     */
    readonly verticalIds?: Array<number>
}

/**
 * AdvertiserReportsApi - object-oriented interface
 * @export
 * @class AdvertiserReportsApi
 * @extends {BaseAPI}
 */
export class AdvertiserReportsApi extends BaseAPI {
    /**
     * 
     * @summary Export advertiser report data to csv file
     * @param {AdvertiserReportsApiExportAdvertiserReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserReportsApi
     */
    public exportAdvertiserReport(requestParameters: AdvertiserReportsApiExportAdvertiserReportRequest, options?: AxiosRequestConfig) {
        return AdvertiserReportsApiFp(this.configuration).exportAdvertiserReport(requestParameters.advertiserId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get advertiser report data
     * @param {AdvertiserReportsApiGetAdvertiserReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserReportsApi
     */
    public getAdvertiserReport(requestParameters: AdvertiserReportsApiGetAdvertiserReportRequest, options?: AxiosRequestConfig) {
        return AdvertiserReportsApiFp(this.configuration).getAdvertiserReport(requestParameters.advertiserId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get advertiser report total data
     * @param {AdvertiserReportsApiGetAdvertiserReportTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserReportsApi
     */
    public getAdvertiserReportTotals(requestParameters: AdvertiserReportsApiGetAdvertiserReportTotalsRequest, options?: AxiosRequestConfig) {
        return AdvertiserReportsApiFp(this.configuration).getAdvertiserReportTotals(requestParameters.advertiserId, requestParameters.from, requestParameters.to, requestParameters.previousFrom, requestParameters.previousTo, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, options).then((request) => request(this.axios, this.basePath));
    }
}
