/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminStoreVerticalRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateVerticalRequest } from '../AfClApiModels';
// @ts-ignore
import { GetVertical200Response } from '../AfClApiModels';
// @ts-ignore
import { Vertical } from '../AfClApiModels';
/**
 * AdminVerticalsApi - axios parameter creator
 * @export
 */
export const AdminVerticalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Vertical
         * @param {AdminStoreVerticalRequest} [adminStoreVerticalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVertical: async (adminStoreVerticalRequest?: AdminStoreVerticalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/verticals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreVerticalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Vertical
         * @param {number} verticalId Numeric ID of the Vertical to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVertical: async (verticalId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verticalId' is not null or undefined
            assertParamExists('deleteVertical', 'verticalId', verticalId)
            const localVarPath = `/v1/admin/verticals/{verticalId}`
                .replace(`{${"verticalId"}}`, encodeURIComponent(String(verticalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Verticals
         * @param {string} [name] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVertical: async (name?: string, status?: 'ACTIVE' | 'INACTIVE', page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/verticals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Vertical
         * @param {number} verticalId Numeric ID of the Vertical to update
         * @param {AdminUpdateVerticalRequest} [adminUpdateVerticalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVertical: async (verticalId: number, adminUpdateVerticalRequest?: AdminUpdateVerticalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verticalId' is not null or undefined
            assertParamExists('updateVertical', 'verticalId', verticalId)
            const localVarPath = `/v1/admin/verticals/{verticalId}`
                .replace(`{${"verticalId"}}`, encodeURIComponent(String(verticalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVerticalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVerticalsApi - functional programming interface
 * @export
 */
export const AdminVerticalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVerticalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Vertical
         * @param {AdminStoreVerticalRequest} [adminStoreVerticalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVertical(adminStoreVerticalRequest?: AdminStoreVerticalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vertical>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVertical(adminStoreVerticalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Vertical
         * @param {number} verticalId Numeric ID of the Vertical to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVertical(verticalId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVertical(verticalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Verticals
         * @param {string} [name] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVertical(name?: string, status?: 'ACTIVE' | 'INACTIVE', page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVertical200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVertical(name, status, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Vertical
         * @param {number} verticalId Numeric ID of the Vertical to update
         * @param {AdminUpdateVerticalRequest} [adminUpdateVerticalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVertical(verticalId: number, adminUpdateVerticalRequest?: AdminUpdateVerticalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vertical>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVertical(verticalId, adminUpdateVerticalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminVerticalsApi - factory interface
 * @export
 */
export const AdminVerticalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVerticalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Vertical
         * @param {AdminVerticalsApiCreateVerticalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVertical(requestParameters: AdminVerticalsApiCreateVerticalRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Vertical> {
            return localVarFp.createVertical(requestParameters.adminStoreVerticalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Vertical
         * @param {AdminVerticalsApiDeleteVerticalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVertical(requestParameters: AdminVerticalsApiDeleteVerticalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteVertical(requestParameters.verticalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Verticals
         * @param {AdminVerticalsApiGetVerticalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVertical(requestParameters: AdminVerticalsApiGetVerticalRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetVertical200Response> {
            return localVarFp.getVertical(requestParameters.name, requestParameters.status, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Vertical
         * @param {AdminVerticalsApiUpdateVerticalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVertical(requestParameters: AdminVerticalsApiUpdateVerticalRequest, options?: AxiosRequestConfig): AxiosPromise<Vertical> {
            return localVarFp.updateVertical(requestParameters.verticalId, requestParameters.adminUpdateVerticalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createVertical operation in AdminVerticalsApi.
 * @export
 * @interface AdminVerticalsApiCreateVerticalRequest
 */
export interface AdminVerticalsApiCreateVerticalRequest {
    /**
     * 
     * @type {AdminStoreVerticalRequest}
     * @memberof AdminVerticalsApiCreateVertical
     */
    readonly adminStoreVerticalRequest?: AdminStoreVerticalRequest
}

/**
 * Request parameters for deleteVertical operation in AdminVerticalsApi.
 * @export
 * @interface AdminVerticalsApiDeleteVerticalRequest
 */
export interface AdminVerticalsApiDeleteVerticalRequest {
    /**
     * Numeric ID of the Vertical to delete
     * @type {number}
     * @memberof AdminVerticalsApiDeleteVertical
     */
    readonly verticalId: number
}

/**
 * Request parameters for getVertical operation in AdminVerticalsApi.
 * @export
 * @interface AdminVerticalsApiGetVerticalRequest
 */
export interface AdminVerticalsApiGetVerticalRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVerticalsApiGetVertical
     */
    readonly name?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE'}
     * @memberof AdminVerticalsApiGetVertical
     */
    readonly status?: 'ACTIVE' | 'INACTIVE'

    /**
     * 
     * @type {number}
     * @memberof AdminVerticalsApiGetVertical
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminVerticalsApiGetVertical
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateVertical operation in AdminVerticalsApi.
 * @export
 * @interface AdminVerticalsApiUpdateVerticalRequest
 */
export interface AdminVerticalsApiUpdateVerticalRequest {
    /**
     * Numeric ID of the Vertical to update
     * @type {number}
     * @memberof AdminVerticalsApiUpdateVertical
     */
    readonly verticalId: number

    /**
     * 
     * @type {AdminUpdateVerticalRequest}
     * @memberof AdminVerticalsApiUpdateVertical
     */
    readonly adminUpdateVerticalRequest?: AdminUpdateVerticalRequest
}

/**
 * AdminVerticalsApi - object-oriented interface
 * @export
 * @class AdminVerticalsApi
 * @extends {BaseAPI}
 */
export class AdminVerticalsApi extends BaseAPI {
    /**
     * 
     * @summary Create Vertical
     * @param {AdminVerticalsApiCreateVerticalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVerticalsApi
     */
    public createVertical(requestParameters: AdminVerticalsApiCreateVerticalRequest = {}, options?: AxiosRequestConfig) {
        return AdminVerticalsApiFp(this.configuration).createVertical(requestParameters.adminStoreVerticalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Vertical
     * @param {AdminVerticalsApiDeleteVerticalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVerticalsApi
     */
    public deleteVertical(requestParameters: AdminVerticalsApiDeleteVerticalRequest, options?: AxiosRequestConfig) {
        return AdminVerticalsApiFp(this.configuration).deleteVertical(requestParameters.verticalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Verticals
     * @param {AdminVerticalsApiGetVerticalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVerticalsApi
     */
    public getVertical(requestParameters: AdminVerticalsApiGetVerticalRequest = {}, options?: AxiosRequestConfig) {
        return AdminVerticalsApiFp(this.configuration).getVertical(requestParameters.name, requestParameters.status, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Vertical
     * @param {AdminVerticalsApiUpdateVerticalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVerticalsApi
     */
    public updateVertical(requestParameters: AdminVerticalsApiUpdateVerticalRequest, options?: AxiosRequestConfig) {
        return AdminVerticalsApiFp(this.configuration).updateVertical(requestParameters.verticalId, requestParameters.adminUpdateVerticalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
