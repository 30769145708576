/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisers200Response } from '../AfClApiModels';
/**
 * HubAdvertisersApi - axios parameter creator
 * @export
 */
export const HubAdvertisersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Advertisers
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubGetAdvertisers: async (page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/hub/advertisers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HubAdvertisersApi - functional programming interface
 * @export
 */
export const HubAdvertisersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HubAdvertisersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Advertisers
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubGetAdvertisers(page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubGetAdvertisers(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HubAdvertisersApi - factory interface
 * @export
 */
export const HubAdvertisersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HubAdvertisersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Advertisers
         * @param {HubAdvertisersApiHubGetAdvertisersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubGetAdvertisers(requestParameters: HubAdvertisersApiHubGetAdvertisersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisers200Response> {
            return localVarFp.hubGetAdvertisers(requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hubGetAdvertisers operation in HubAdvertisersApi.
 * @export
 * @interface HubAdvertisersApiHubGetAdvertisersRequest
 */
export interface HubAdvertisersApiHubGetAdvertisersRequest {
    /**
     * 
     * @type {number}
     * @memberof HubAdvertisersApiHubGetAdvertisers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof HubAdvertisersApiHubGetAdvertisers
     */
    readonly perPage?: number
}

/**
 * HubAdvertisersApi - object-oriented interface
 * @export
 * @class HubAdvertisersApi
 * @extends {BaseAPI}
 */
export class HubAdvertisersApi extends BaseAPI {
    /**
     * 
     * @summary Get Advertisers
     * @param {HubAdvertisersApiHubGetAdvertisersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubAdvertisersApi
     */
    public hubGetAdvertisers(requestParameters: HubAdvertisersApiHubGetAdvertisersRequest = {}, options?: AxiosRequestConfig) {
        return HubAdvertisersApiFp(this.configuration).hubGetAdvertisers(requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
