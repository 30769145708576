/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiGetPrograms200Response } from '../AfClApiModels';
// @ts-ignore
import { BiViewsProgramResource } from '../AfClApiModels';
/**
 * BiProgramsApi - axios parameter creator
 * @export
 */
export const BiProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Programs
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [programCoordinatorIds] 
         * @param {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>} [dealTypes] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [status] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [labelNames] 
         * @param {string} [sort] Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
         * @param {Array<number>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetPrograms: async (name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, programManagerIds?: Array<number>, programCoordinatorIds?: Array<number>, dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>, page?: number, perPage?: number, status?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, sort?: string, brandIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (advertiserIds) {
                localVarQueryParameter['advertiser_ids[]'] = advertiserIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (programCoordinatorIds) {
                localVarQueryParameter['program_coordinator_ids[]'] = programCoordinatorIds;
            }

            if (dealTypes) {
                localVarQueryParameter['deal_types[]'] = dealTypes;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (labelNames) {
                localVarQueryParameter['label_names[]'] = labelNames;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Programs
         * @param {string} [updatedAt] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biViewsGetPrograms: async (updatedAt?: string, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/views/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = (updatedAt as any instanceof Date) ?
                    (updatedAt as any).toISOString() :
                    updatedAt;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiProgramsApi - functional programming interface
 * @export
 */
export const BiProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Programs
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [programCoordinatorIds] 
         * @param {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>} [dealTypes] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [status] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [labelNames] 
         * @param {string} [sort] Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
         * @param {Array<number>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biGetPrograms(name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, programManagerIds?: Array<number>, programCoordinatorIds?: Array<number>, dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>, page?: number, perPage?: number, status?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, sort?: string, brandIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiGetPrograms200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biGetPrograms(name, regionIds, verticalIds, advertiserIds, programManagerIds, programCoordinatorIds, dealTypes, page, perPage, status, labelNames, sort, brandIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Programs
         * @param {string} [updatedAt] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biViewsGetPrograms(updatedAt?: string, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiViewsProgramResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biViewsGetPrograms(updatedAt, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiProgramsApi - factory interface
 * @export
 */
export const BiProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiProgramsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Programs
         * @param {BiProgramsApiBiGetProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetPrograms(requestParameters: BiProgramsApiBiGetProgramsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BiGetPrograms200Response> {
            return localVarFp.biGetPrograms(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.programManagerIds, requestParameters.programCoordinatorIds, requestParameters.dealTypes, requestParameters.page, requestParameters.perPage, requestParameters.status, requestParameters.labelNames, requestParameters.sort, requestParameters.brandIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Programs
         * @param {BiProgramsApiBiViewsGetProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biViewsGetPrograms(requestParameters: BiProgramsApiBiViewsGetProgramsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<BiViewsProgramResource>> {
            return localVarFp.biViewsGetPrograms(requestParameters.updatedAt, requestParameters.perPage, requestParameters.page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for biGetPrograms operation in BiProgramsApi.
 * @export
 * @interface BiProgramsApiBiGetProgramsRequest
 */
export interface BiProgramsApiBiGetProgramsRequest {
    /**
     * 
     * @type {string}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly name?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly advertiserIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly programCoordinatorIds?: Array<number>

    /**
     * 
     * @type {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>

    /**
     * 
     * @type {number}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly perPage?: number

    /**
     * 
     * @type {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly status?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'

    /**
     * 
     * @type {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>

    /**
     * Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
     * @type {string}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly sort?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof BiProgramsApiBiGetPrograms
     */
    readonly brandIds?: Array<number>
}

/**
 * Request parameters for biViewsGetPrograms operation in BiProgramsApi.
 * @export
 * @interface BiProgramsApiBiViewsGetProgramsRequest
 */
export interface BiProgramsApiBiViewsGetProgramsRequest {
    /**
     * 
     * @type {string}
     * @memberof BiProgramsApiBiViewsGetPrograms
     */
    readonly updatedAt?: string

    /**
     * 
     * @type {number}
     * @memberof BiProgramsApiBiViewsGetPrograms
     */
    readonly perPage?: number

    /**
     * 
     * @type {number}
     * @memberof BiProgramsApiBiViewsGetPrograms
     */
    readonly page?: number
}

/**
 * BiProgramsApi - object-oriented interface
 * @export
 * @class BiProgramsApi
 * @extends {BaseAPI}
 */
export class BiProgramsApi extends BaseAPI {
    /**
     * 
     * @summary Get Programs
     * @param {BiProgramsApiBiGetProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiProgramsApi
     */
    public biGetPrograms(requestParameters: BiProgramsApiBiGetProgramsRequest = {}, options?: AxiosRequestConfig) {
        return BiProgramsApiFp(this.configuration).biGetPrograms(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.programManagerIds, requestParameters.programCoordinatorIds, requestParameters.dealTypes, requestParameters.page, requestParameters.perPage, requestParameters.status, requestParameters.labelNames, requestParameters.sort, requestParameters.brandIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Programs
     * @param {BiProgramsApiBiViewsGetProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiProgramsApi
     */
    public biViewsGetPrograms(requestParameters: BiProgramsApiBiViewsGetProgramsRequest = {}, options?: AxiosRequestConfig) {
        return BiProgramsApiFp(this.configuration).biViewsGetPrograms(requestParameters.updatedAt, requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }
}
