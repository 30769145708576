import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';
import { UserType } from '@/Enums/UserType';
import SetPageTitle from '@/Utils/SetPageTitle';

export const PageTitleHandler = (
    (type: UserType ): MiddlewareFunction => (
        async (context) => {
            const { translate, hasTranslation } = window as Window & typeof globalThis & { 
                translate: { (key: string): string; },
                hasTranslation: { (key: string): boolean; }
            };

            const pageTranslationKey: string = 'pages.' + (context.to?.name?.toString() ?? '') + '.title';
            if(hasTranslation(pageTranslationKey)) {
                SetPageTitle(translate(pageTranslationKey), type);
            }

            return context.next();
        }
    )
);
