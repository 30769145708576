/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Region } from '../AfClApiModels';
/**
 * RegionsApi - axios parameter creator
 * @export
 */
export const RegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Regions
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (all?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Regions
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(all?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(all, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Regions
         * @param {RegionsApiGetRegionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(requestParameters: RegionsApiGetRegionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Region>> {
            return localVarFp.getRegions(requestParameters.all, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRegions operation in RegionsApi.
 * @export
 * @interface RegionsApiGetRegionsRequest
 */
export interface RegionsApiGetRegionsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RegionsApiGetRegions
     */
    readonly all?: boolean
}

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI {
    /**
     * 
     * @summary Get Regions
     * @param {RegionsApiGetRegionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegions(requestParameters: RegionsApiGetRegionsRequest = {}, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegions(requestParameters.all, options).then((request) => request(this.axios, this.basePath));
    }
}
