/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Site } from '../AfClApiModels';
/**
 * AdminPublisherSitesApi - axios parameter creator
 * @export
 */
export const AdminPublisherSitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Publisher Sites
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherSites: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherSites', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/sites`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublisherSitesApi - functional programming interface
 * @export
 */
export const AdminPublisherSitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublisherSitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Sites
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherSites(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Site>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherSites(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublisherSitesApi - factory interface
 * @export
 */
export const AdminPublisherSitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublisherSitesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Sites
         * @param {AdminPublisherSitesApiGetPublisherSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherSites(requestParameters: AdminPublisherSitesApiGetPublisherSitesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Site>> {
            return localVarFp.getPublisherSites(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPublisherSites operation in AdminPublisherSitesApi.
 * @export
 * @interface AdminPublisherSitesApiGetPublisherSitesRequest
 */
export interface AdminPublisherSitesApiGetPublisherSitesRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof AdminPublisherSitesApiGetPublisherSites
     */
    readonly publisherId: number
}

/**
 * AdminPublisherSitesApi - object-oriented interface
 * @export
 * @class AdminPublisherSitesApi
 * @extends {BaseAPI}
 */
export class AdminPublisherSitesApi extends BaseAPI {
    /**
     * 
     * @summary Get Publisher Sites
     * @param {AdminPublisherSitesApiGetPublisherSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherSitesApi
     */
    public getPublisherSites(requestParameters: AdminPublisherSitesApiGetPublisherSitesRequest, options?: AxiosRequestConfig) {
        return AdminPublisherSitesApiFp(this.configuration).getPublisherSites(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }
}
