/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiViewsPublisherResource } from '../AfClApiModels';
// @ts-ignore
import { ShortPublisher } from '../AfClApiModels';
/**
 * BiPublishersApi - axios parameter creator
 * @export
 */
export const BiPublishersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Publishers
         * @param {string} [name] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetPublishers: async (name?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (publisherManagerIds) {
                localVarQueryParameter['publisher_manager_ids[]'] = publisherManagerIds;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publishers
         * @param {string} [updatedAt] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biViewsGetPublishers: async (updatedAt?: string, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/views/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = (updatedAt as any instanceof Date) ?
                    (updatedAt as any).toISOString() :
                    updatedAt;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiPublishersApi - functional programming interface
 * @export
 */
export const BiPublishersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiPublishersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Publishers
         * @param {string} [name] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biGetPublishers(name?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShortPublisher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biGetPublishers(name, publisherType, publisherManagerIds, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publishers
         * @param {string} [updatedAt] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biViewsGetPublishers(updatedAt?: string, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiViewsPublisherResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biViewsGetPublishers(updatedAt, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiPublishersApi - factory interface
 * @export
 */
export const BiPublishersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiPublishersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Publishers
         * @param {BiPublishersApiBiGetPublishersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetPublishers(requestParameters: BiPublishersApiBiGetPublishersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ShortPublisher>> {
            return localVarFp.biGetPublishers(requestParameters.name, requestParameters.publisherType, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publishers
         * @param {BiPublishersApiBiViewsGetPublishersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biViewsGetPublishers(requestParameters: BiPublishersApiBiViewsGetPublishersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<BiViewsPublisherResource>> {
            return localVarFp.biViewsGetPublishers(requestParameters.updatedAt, requestParameters.perPage, requestParameters.page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for biGetPublishers operation in BiPublishersApi.
 * @export
 * @interface BiPublishersApiBiGetPublishersRequest
 */
export interface BiPublishersApiBiGetPublishersRequest {
    /**
     * 
     * @type {string}
     * @memberof BiPublishersApiBiGetPublishers
     */
    readonly name?: string

    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof BiPublishersApiBiGetPublishers
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {Array<number>}
     * @memberof BiPublishersApiBiGetPublishers
     */
    readonly publisherManagerIds?: Array<number>

    /**
     * 
     * @type {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>}
     * @memberof BiPublishersApiBiGetPublishers
     */
    readonly statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>
}

/**
 * Request parameters for biViewsGetPublishers operation in BiPublishersApi.
 * @export
 * @interface BiPublishersApiBiViewsGetPublishersRequest
 */
export interface BiPublishersApiBiViewsGetPublishersRequest {
    /**
     * 
     * @type {string}
     * @memberof BiPublishersApiBiViewsGetPublishers
     */
    readonly updatedAt?: string

    /**
     * 
     * @type {number}
     * @memberof BiPublishersApiBiViewsGetPublishers
     */
    readonly perPage?: number

    /**
     * 
     * @type {number}
     * @memberof BiPublishersApiBiViewsGetPublishers
     */
    readonly page?: number
}

/**
 * BiPublishersApi - object-oriented interface
 * @export
 * @class BiPublishersApi
 * @extends {BaseAPI}
 */
export class BiPublishersApi extends BaseAPI {
    /**
     * 
     * @summary Get Publishers
     * @param {BiPublishersApiBiGetPublishersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiPublishersApi
     */
    public biGetPublishers(requestParameters: BiPublishersApiBiGetPublishersRequest = {}, options?: AxiosRequestConfig) {
        return BiPublishersApiFp(this.configuration).biGetPublishers(requestParameters.name, requestParameters.publisherType, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publishers
     * @param {BiPublishersApiBiViewsGetPublishersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiPublishersApi
     */
    public biViewsGetPublishers(requestParameters: BiPublishersApiBiViewsGetPublishersRequest = {}, options?: AxiosRequestConfig) {
        return BiPublishersApiFp(this.configuration).biViewsGetPublishers(requestParameters.updatedAt, requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }
}
