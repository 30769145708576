import { format } from 'date-fns';

function defaultDate (isoDate: string | Date, includeTime: boolean = false) {
    let date: Date;
    const stringFormat = includeTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';

    if (isoDate instanceof Date) {
        date = isoDate;
    } else {
        date = new Date(isoDate);
    }
    
    return format(date, stringFormat);
}

export default defaultDate;
