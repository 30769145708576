import { customRef, ref } from 'vue';

export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number
): (...args: Params) => void {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: Params) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, timeout);
    };
}

export const useDebouncedRef = (initialValue, timeout) => {
    const state = ref(initialValue);
    return customRef((track, trigger) => ({
        get() {
            track();
            return state.value;
        },
        set: debounce(
            value => {
                state.value = value === '' ? initialValue : value;
                trigger();
            },
            timeout
        ),
    }));
};

