import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { SourceKey } from '@/stub';
import { AfClApi } from '@/Services/AfClApi';

export const useSourceKeyUpdate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (payload: SourceKey) =>
            await AfClApi.adminSourceKeys.updateSourceKey({
                sourceKeyId: payload.id,
                adminUpsertSourceKeyRequest: {
                    name: payload.name
                }
            }),
        onSuccess: async () => {
            onSuccess ? onSuccess() : null;
            await queryClient.invalidateQueries(['source-keys']);
        },
    });
};
