/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminUpsertSourceKeyRequest } from '../AfClApiModels';
// @ts-ignore
import { SourceKey } from '../AfClApiModels';
/**
 * AdminSourceKeysApi - axios parameter creator
 * @export
 */
export const AdminSourceKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Source Key
         * @param {AdminUpsertSourceKeyRequest} [adminUpsertSourceKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSourceKey: async (adminUpsertSourceKeyRequest?: AdminUpsertSourceKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/source-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpsertSourceKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Source Key
         * @param {number} sourceKeyId Numeric ID of the Source Key to update
         * @param {AdminUpsertSourceKeyRequest} [adminUpsertSourceKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSourceKey: async (sourceKeyId: number, adminUpsertSourceKeyRequest?: AdminUpsertSourceKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceKeyId' is not null or undefined
            assertParamExists('updateSourceKey', 'sourceKeyId', sourceKeyId)
            const localVarPath = `/v1/admin/source-keys/{sourceKeyId}`
                .replace(`{${"sourceKeyId"}}`, encodeURIComponent(String(sourceKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpsertSourceKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSourceKeysApi - functional programming interface
 * @export
 */
export const AdminSourceKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSourceKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Source Key
         * @param {AdminUpsertSourceKeyRequest} [adminUpsertSourceKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSourceKey(adminUpsertSourceKeyRequest?: AdminUpsertSourceKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSourceKey(adminUpsertSourceKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Source Key
         * @param {number} sourceKeyId Numeric ID of the Source Key to update
         * @param {AdminUpsertSourceKeyRequest} [adminUpsertSourceKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSourceKey(sourceKeyId: number, adminUpsertSourceKeyRequest?: AdminUpsertSourceKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSourceKey(sourceKeyId, adminUpsertSourceKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSourceKeysApi - factory interface
 * @export
 */
export const AdminSourceKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSourceKeysApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Source Key
         * @param {AdminSourceKeysApiCreateSourceKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSourceKey(requestParameters: AdminSourceKeysApiCreateSourceKeyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SourceKey> {
            return localVarFp.createSourceKey(requestParameters.adminUpsertSourceKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Source Key
         * @param {AdminSourceKeysApiUpdateSourceKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSourceKey(requestParameters: AdminSourceKeysApiUpdateSourceKeyRequest, options?: AxiosRequestConfig): AxiosPromise<SourceKey> {
            return localVarFp.updateSourceKey(requestParameters.sourceKeyId, requestParameters.adminUpsertSourceKeyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSourceKey operation in AdminSourceKeysApi.
 * @export
 * @interface AdminSourceKeysApiCreateSourceKeyRequest
 */
export interface AdminSourceKeysApiCreateSourceKeyRequest {
    /**
     * 
     * @type {AdminUpsertSourceKeyRequest}
     * @memberof AdminSourceKeysApiCreateSourceKey
     */
    readonly adminUpsertSourceKeyRequest?: AdminUpsertSourceKeyRequest
}

/**
 * Request parameters for updateSourceKey operation in AdminSourceKeysApi.
 * @export
 * @interface AdminSourceKeysApiUpdateSourceKeyRequest
 */
export interface AdminSourceKeysApiUpdateSourceKeyRequest {
    /**
     * Numeric ID of the Source Key to update
     * @type {number}
     * @memberof AdminSourceKeysApiUpdateSourceKey
     */
    readonly sourceKeyId: number

    /**
     * 
     * @type {AdminUpsertSourceKeyRequest}
     * @memberof AdminSourceKeysApiUpdateSourceKey
     */
    readonly adminUpsertSourceKeyRequest?: AdminUpsertSourceKeyRequest
}

/**
 * AdminSourceKeysApi - object-oriented interface
 * @export
 * @class AdminSourceKeysApi
 * @extends {BaseAPI}
 */
export class AdminSourceKeysApi extends BaseAPI {
    /**
     * 
     * @summary Create Source Key
     * @param {AdminSourceKeysApiCreateSourceKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSourceKeysApi
     */
    public createSourceKey(requestParameters: AdminSourceKeysApiCreateSourceKeyRequest = {}, options?: AxiosRequestConfig) {
        return AdminSourceKeysApiFp(this.configuration).createSourceKey(requestParameters.adminUpsertSourceKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Source Key
     * @param {AdminSourceKeysApiUpdateSourceKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSourceKeysApi
     */
    public updateSourceKey(requestParameters: AdminSourceKeysApiUpdateSourceKeyRequest, options?: AxiosRequestConfig) {
        return AdminSourceKeysApiFp(this.configuration).updateSourceKey(requestParameters.sourceKeyId, requestParameters.adminUpsertSourceKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
