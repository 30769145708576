<template>
    <div
        class="max-w-full"
        :class="{ 'overflow-auto': mobileScroll}"
    >
        <div :class="mobileScroll ? 'hidden' : 'sm:hidden'">
            <label
                for="tabs"
                class="sr-only"
            >Select a tab</label>
            <select
                id="tabs"
                :model-value
                name="tabs"
                class="block w-full rounded-md border-gray-300 focus:border-yellow-400 focus:ring-yellow-400"
                @change="emits('update:modelValue', ($event.target as HTMLSelectElement)?.value)"
            >
                <option
                    v-for="(tab, index) in tabs"
                    :key="generateKey(tab)"
                    :selected="tab[itemValue] === modelValue"
                    :value="generateKey(tab)"
                >
                    <slot
                        :name="`tab_${index}`"
                        :tab
                        :label="$t(tab[itemText])"
                    >
                        {{ $t(tab[itemText]) }}
                    </slot>
                </option>
            </select>
        </div>
        <div :class="{ 'hidden sm:block': !mobileScroll }">
            <div class="border-b border-gray-200">
                <nav
                    class="-mb-px flex"
                    aria-label="Tabs"
                >
                    <button
                        v-for="(tab, index) in tabs"
                        :key="generateKey(tab)"
                        class="w-full py-1 text-center px-2"
                        :class="btnClass(tab)"
                        :aria-current="tab[itemValue] === modelValue ? 'page' : undefined"
                        @click="emits('update:modelValue', tab[itemValue])"
                    >
                        <slot
                            :name="`tab_${index}`"
                            :tab
                            :label="$t(tab[itemText])"
                        >
                            {{ $t(tab[itemText]) }}
                        </slot>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { OptionPairContract } from '@/Models/Contracts/OptionPairContract';
import { useAuthStore } from '@/Stores/AuthStore';

const props = withDefaults(defineProps<{
    options: OptionPairContract[],
    modelValue: string,
    itemText?: string,
    itemValue?: string,
    dark?: boolean,
    mobileScroll?: boolean,
}>(), {
    itemText: 'text',
    itemValue: 'value',
    dark: false,
    mobileScroll: false
});

const { authUser } = useAuthStore();

const tabs = computed<OptionPairContract[]>(() => {
    return props.options.filter((tab) => {
        if (!tab.permission || authUser?.hasPermission(tab.permission)) {
            return tab;
        }
    });
});

const emits = defineEmits<{(event: 'update:modelValue', value: string )}>();

const generateKey = (option: string | OptionPairContract) => {
    if (typeof option === 'string') {
        return option;
    }
    return option.value;
};

const activeClass = props.dark ?
    'border-yellow-400 border-b-4 text-yellow-500 font-bold'
    : 'border-yellow-400 border-b-4 text-gray-900 font-bold';
const inactiveClass = props.dark ?
    'border-gray-300 border-b-2 text-white hover:text-yellow-400 hover:border-gray-400'
    : 'border-gray-300 border-b-2 text-gray-500 hover:text-gray-700 hover:border-gray-400';

const btnClass = (tab) => {
    return tab[props.itemValue] === props.modelValue ? activeClass : inactiveClass;
};
</script>
