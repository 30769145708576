<template>
    <BaseButton
        class="ml-auto block"
        :loading="isFetching"
        @click="addKey"
    >
        Add key
    </BaseButton>
    <BaseTable
        :items="keys"
        :headers="tableHeaders"
        :is-loading="isFetching"
        class="mt-10"
    >
        <template #row="{ headers, item }">
            <BaseTableRow v-bind="{ headers, item }">
                <template #custom_actions>
                    <BaseButton @click="updateKey(item)">
                        Edit key
                    </BaseButton>
                </template>
            </BaseTableRow>
        </template>
    </BaseTable>
    <KeysSourcesModal
        v-model="keyCampaignModal"
        :selected-key
    />
</template>

<script setup lang="ts">
import BaseTable from '@/Components/Core/Table/BaseTable.vue';
import BaseTableRow from '@/Components/Core/Table/BaseTableRow.vue';
import { TableHeader } from '@/Models/Components/BaseTableContract';
import { useSourceKeys } from '@/Services/ApiHooks/Shared/UseSourceKeys';
import BaseButton from '@/Components/Core/BaseButton.vue';
import { ref } from 'vue';
import KeysSourcesModal from '@/Components/Pages/Admin/Keys/KeysSourcesModal.vue';
import { SourceKey } from '@/stub';

const { data: keys, isFetching } = useSourceKeys();
const tableHeaders: TableHeader<SourceKey>[] = [
    {
        label: 'Name',
        key: 'name',
        forceAlign: 'start'
    },
    {
        label: '',
        key: 'custom_actions',
        forceAlign: 'end',
    },
];

const keyCampaignModal = ref(false);
const selectedKey = ref<SourceKey>();
const updateKey = (item: SourceKey) => {
    keyCampaignModal.value = true;
    selectedKey.value = item;
};

const addKey = () => {
    keyCampaignModal.value = true;
    selectedKey.value = undefined;
};
</script>
@/Services/ApiHooks/Shared/UseSourceKeys
