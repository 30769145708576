/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPaymentSolutions200Response } from '../AfClApiModels';
/**
 * PaymentSolutionsApi - axios parameter creator
 * @export
 */
export const PaymentSolutionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Payment Solutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSolutions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment-solutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentSolutionsApi - functional programming interface
 * @export
 */
export const PaymentSolutionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentSolutionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Payment Solutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentSolutions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentSolutions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentSolutions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentSolutionsApi - factory interface
 * @export
 */
export const PaymentSolutionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentSolutionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Payment Solutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSolutions(options?: AxiosRequestConfig): AxiosPromise<GetPaymentSolutions200Response> {
            return localVarFp.getPaymentSolutions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentSolutionsApi - object-oriented interface
 * @export
 * @class PaymentSolutionsApi
 * @extends {BaseAPI}
 */
export class PaymentSolutionsApi extends BaseAPI {
    /**
     * 
     * @summary Get Payment Solutions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSolutionsApi
     */
    public getPaymentSolutions(options?: AxiosRequestConfig) {
        return PaymentSolutionsApiFp(this.configuration).getPaymentSolutions(options).then((request) => request(this.axios, this.basePath));
    }
}
