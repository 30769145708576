import { useAuthStore } from '@/Stores/AuthStore';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';

export const EnsureIsAdministrator: MiddlewareFunction = async (context) => {
    const authStore = useAuthStore();
    if (!authStore.isACAdministrator) {
        return { name: 'accounts' };
    }
    return context.next();
};
