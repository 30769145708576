/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminUpsertCampaignKeyRequest } from '../AfClApiModels';
// @ts-ignore
import { CampaignKey } from '../AfClApiModels';
/**
 * AdminCampaignKeysApi - axios parameter creator
 * @export
 */
export const AdminCampaignKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Campaign Key
         * @param {AdminUpsertCampaignKeyRequest} [adminUpsertCampaignKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignKey: async (adminUpsertCampaignKeyRequest?: AdminUpsertCampaignKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/campaign-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpsertCampaignKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Campaign Key
         * @param {number} campaignKeyId Numeric ID of the Campaign Key to update
         * @param {AdminUpsertCampaignKeyRequest} [adminUpsertCampaignKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaignKey: async (campaignKeyId: number, adminUpsertCampaignKeyRequest?: AdminUpsertCampaignKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignKeyId' is not null or undefined
            assertParamExists('updateCampaignKey', 'campaignKeyId', campaignKeyId)
            const localVarPath = `/v1/admin/campaign-keys/{campaignKeyId}`
                .replace(`{${"campaignKeyId"}}`, encodeURIComponent(String(campaignKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpsertCampaignKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCampaignKeysApi - functional programming interface
 * @export
 */
export const AdminCampaignKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCampaignKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Campaign Key
         * @param {AdminUpsertCampaignKeyRequest} [adminUpsertCampaignKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaignKey(adminUpsertCampaignKeyRequest?: AdminUpsertCampaignKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaignKey(adminUpsertCampaignKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Campaign Key
         * @param {number} campaignKeyId Numeric ID of the Campaign Key to update
         * @param {AdminUpsertCampaignKeyRequest} [adminUpsertCampaignKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaignKey(campaignKeyId: number, adminUpsertCampaignKeyRequest?: AdminUpsertCampaignKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaignKey(campaignKeyId, adminUpsertCampaignKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCampaignKeysApi - factory interface
 * @export
 */
export const AdminCampaignKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCampaignKeysApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Campaign Key
         * @param {AdminCampaignKeysApiCreateCampaignKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignKey(requestParameters: AdminCampaignKeysApiCreateCampaignKeyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CampaignKey> {
            return localVarFp.createCampaignKey(requestParameters.adminUpsertCampaignKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Campaign Key
         * @param {AdminCampaignKeysApiUpdateCampaignKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaignKey(requestParameters: AdminCampaignKeysApiUpdateCampaignKeyRequest, options?: AxiosRequestConfig): AxiosPromise<CampaignKey> {
            return localVarFp.updateCampaignKey(requestParameters.campaignKeyId, requestParameters.adminUpsertCampaignKeyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCampaignKey operation in AdminCampaignKeysApi.
 * @export
 * @interface AdminCampaignKeysApiCreateCampaignKeyRequest
 */
export interface AdminCampaignKeysApiCreateCampaignKeyRequest {
    /**
     * 
     * @type {AdminUpsertCampaignKeyRequest}
     * @memberof AdminCampaignKeysApiCreateCampaignKey
     */
    readonly adminUpsertCampaignKeyRequest?: AdminUpsertCampaignKeyRequest
}

/**
 * Request parameters for updateCampaignKey operation in AdminCampaignKeysApi.
 * @export
 * @interface AdminCampaignKeysApiUpdateCampaignKeyRequest
 */
export interface AdminCampaignKeysApiUpdateCampaignKeyRequest {
    /**
     * Numeric ID of the Campaign Key to update
     * @type {number}
     * @memberof AdminCampaignKeysApiUpdateCampaignKey
     */
    readonly campaignKeyId: number

    /**
     * 
     * @type {AdminUpsertCampaignKeyRequest}
     * @memberof AdminCampaignKeysApiUpdateCampaignKey
     */
    readonly adminUpsertCampaignKeyRequest?: AdminUpsertCampaignKeyRequest
}

/**
 * AdminCampaignKeysApi - object-oriented interface
 * @export
 * @class AdminCampaignKeysApi
 * @extends {BaseAPI}
 */
export class AdminCampaignKeysApi extends BaseAPI {
    /**
     * 
     * @summary Create Campaign Key
     * @param {AdminCampaignKeysApiCreateCampaignKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignKeysApi
     */
    public createCampaignKey(requestParameters: AdminCampaignKeysApiCreateCampaignKeyRequest = {}, options?: AxiosRequestConfig) {
        return AdminCampaignKeysApiFp(this.configuration).createCampaignKey(requestParameters.adminUpsertCampaignKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Campaign Key
     * @param {AdminCampaignKeysApiUpdateCampaignKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignKeysApi
     */
    public updateCampaignKey(requestParameters: AdminCampaignKeysApiUpdateCampaignKeyRequest, options?: AxiosRequestConfig) {
        return AdminCampaignKeysApiFp(this.configuration).updateCampaignKey(requestParameters.campaignKeyId, requestParameters.adminUpsertCampaignKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
