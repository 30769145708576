/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherGetPostbacksReport200Response } from '../AfClApiModels';
// @ts-ignore
import { PublisherGetPostbacksReportTotals200Response } from '../AfClApiModels';
/**
 * PublisherPostbacksReportApi - axios parameter creator
 * @export
 */
export const PublisherPostbacksReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Publisher Postbacks Report
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [publisherClickId] 
         * @param {number} [brandId] 
         * @param {number} [siteGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherExportPostbacksReport: async (publisherId: number, from: string, to: string, publisherClickId?: string, brandId?: number, siteGroupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherExportPostbacksReport', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('publisherExportPostbacksReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('publisherExportPostbacksReport', 'to', to)
            const localVarPath = `/v1/publishers/{publisherId}/postbacks/report/export`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (publisherClickId !== undefined) {
                localVarQueryParameter['publisher_click_id'] = publisherClickId;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brand_id'] = brandId;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publisher Postbacks Report
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [publisherClickId] 
         * @param {number} [brandId] 
         * @param {number} [siteGroupId] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetPostbacksReport: async (publisherId: number, from: string, to: string, publisherClickId?: string, brandId?: number, siteGroupId?: number, sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherGetPostbacksReport', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('publisherGetPostbacksReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('publisherGetPostbacksReport', 'to', to)
            const localVarPath = `/v1/publishers/{publisherId}/postbacks/report`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (publisherClickId !== undefined) {
                localVarQueryParameter['publisher_click_id'] = publisherClickId;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brand_id'] = brandId;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publisher Postbacks Report Totals
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [previousFrom] 
         * @param {string} [previousTo] 
         * @param {string} [publisherClickId] 
         * @param {number} [brandId] 
         * @param {number} [siteGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetPostbacksReportTotals: async (publisherId: number, from: string, to: string, previousFrom?: string, previousTo?: string, publisherClickId?: string, brandId?: number, siteGroupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherGetPostbacksReportTotals', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('publisherGetPostbacksReportTotals', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('publisherGetPostbacksReportTotals', 'to', to)
            const localVarPath = `/v1/publishers/{publisherId}/postbacks/report/totals`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (previousFrom !== undefined) {
                localVarQueryParameter['previous_from'] = previousFrom;
            }

            if (previousTo !== undefined) {
                localVarQueryParameter['previous_to'] = previousTo;
            }

            if (publisherClickId !== undefined) {
                localVarQueryParameter['publisher_click_id'] = publisherClickId;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brand_id'] = brandId;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherPostbacksReportApi - functional programming interface
 * @export
 */
export const PublisherPostbacksReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherPostbacksReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Publisher Postbacks Report
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [publisherClickId] 
         * @param {number} [brandId] 
         * @param {number} [siteGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherExportPostbacksReport(publisherId: number, from: string, to: string, publisherClickId?: string, brandId?: number, siteGroupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherExportPostbacksReport(publisherId, from, to, publisherClickId, brandId, siteGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publisher Postbacks Report
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [publisherClickId] 
         * @param {number} [brandId] 
         * @param {number} [siteGroupId] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherGetPostbacksReport(publisherId: number, from: string, to: string, publisherClickId?: string, brandId?: number, siteGroupId?: number, sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherGetPostbacksReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherGetPostbacksReport(publisherId, from, to, publisherClickId, brandId, siteGroupId, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publisher Postbacks Report Totals
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [previousFrom] 
         * @param {string} [previousTo] 
         * @param {string} [publisherClickId] 
         * @param {number} [brandId] 
         * @param {number} [siteGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherGetPostbacksReportTotals(publisherId: number, from: string, to: string, previousFrom?: string, previousTo?: string, publisherClickId?: string, brandId?: number, siteGroupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherGetPostbacksReportTotals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherGetPostbacksReportTotals(publisherId, from, to, previousFrom, previousTo, publisherClickId, brandId, siteGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherPostbacksReportApi - factory interface
 * @export
 */
export const PublisherPostbacksReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherPostbacksReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Publisher Postbacks Report
         * @param {PublisherPostbacksReportApiPublisherExportPostbacksReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherExportPostbacksReport(requestParameters: PublisherPostbacksReportApiPublisherExportPostbacksReportRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.publisherExportPostbacksReport(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.publisherClickId, requestParameters.brandId, requestParameters.siteGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publisher Postbacks Report
         * @param {PublisherPostbacksReportApiPublisherGetPostbacksReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetPostbacksReport(requestParameters: PublisherPostbacksReportApiPublisherGetPostbacksReportRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherGetPostbacksReport200Response> {
            return localVarFp.publisherGetPostbacksReport(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.publisherClickId, requestParameters.brandId, requestParameters.siteGroupId, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publisher Postbacks Report Totals
         * @param {PublisherPostbacksReportApiPublisherGetPostbacksReportTotalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetPostbacksReportTotals(requestParameters: PublisherPostbacksReportApiPublisherGetPostbacksReportTotalsRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherGetPostbacksReportTotals200Response> {
            return localVarFp.publisherGetPostbacksReportTotals(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.previousFrom, requestParameters.previousTo, requestParameters.publisherClickId, requestParameters.brandId, requestParameters.siteGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publisherExportPostbacksReport operation in PublisherPostbacksReportApi.
 * @export
 * @interface PublisherPostbacksReportApiPublisherExportPostbacksReportRequest
 */
export interface PublisherPostbacksReportApiPublisherExportPostbacksReportRequest {
    /**
     * Numeric ID of the publisher to find
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherExportPostbacksReport
     */
    readonly publisherId: number

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherExportPostbacksReport
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherExportPostbacksReport
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherExportPostbacksReport
     */
    readonly publisherClickId?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherExportPostbacksReport
     */
    readonly brandId?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherExportPostbacksReport
     */
    readonly siteGroupId?: number
}

/**
 * Request parameters for publisherGetPostbacksReport operation in PublisherPostbacksReportApi.
 * @export
 * @interface PublisherPostbacksReportApiPublisherGetPostbacksReportRequest
 */
export interface PublisherPostbacksReportApiPublisherGetPostbacksReportRequest {
    /**
     * Numeric ID of the publisher to find
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly publisherId: number

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly publisherClickId?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly brandId?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReport
     */
    readonly perPage?: number
}

/**
 * Request parameters for publisherGetPostbacksReportTotals operation in PublisherPostbacksReportApi.
 * @export
 * @interface PublisherPostbacksReportApiPublisherGetPostbacksReportTotalsRequest
 */
export interface PublisherPostbacksReportApiPublisherGetPostbacksReportTotalsRequest {
    /**
     * Numeric ID of the publisher to find
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly publisherId: number

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly previousFrom?: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly previousTo?: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly publisherClickId?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly brandId?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksReportApiPublisherGetPostbacksReportTotals
     */
    readonly siteGroupId?: number
}

/**
 * PublisherPostbacksReportApi - object-oriented interface
 * @export
 * @class PublisherPostbacksReportApi
 * @extends {BaseAPI}
 */
export class PublisherPostbacksReportApi extends BaseAPI {
    /**
     * 
     * @summary Export Publisher Postbacks Report
     * @param {PublisherPostbacksReportApiPublisherExportPostbacksReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPostbacksReportApi
     */
    public publisherExportPostbacksReport(requestParameters: PublisherPostbacksReportApiPublisherExportPostbacksReportRequest, options?: AxiosRequestConfig) {
        return PublisherPostbacksReportApiFp(this.configuration).publisherExportPostbacksReport(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.publisherClickId, requestParameters.brandId, requestParameters.siteGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publisher Postbacks Report
     * @param {PublisherPostbacksReportApiPublisherGetPostbacksReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPostbacksReportApi
     */
    public publisherGetPostbacksReport(requestParameters: PublisherPostbacksReportApiPublisherGetPostbacksReportRequest, options?: AxiosRequestConfig) {
        return PublisherPostbacksReportApiFp(this.configuration).publisherGetPostbacksReport(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.publisherClickId, requestParameters.brandId, requestParameters.siteGroupId, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publisher Postbacks Report Totals
     * @param {PublisherPostbacksReportApiPublisherGetPostbacksReportTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPostbacksReportApi
     */
    public publisherGetPostbacksReportTotals(requestParameters: PublisherPostbacksReportApiPublisherGetPostbacksReportTotalsRequest, options?: AxiosRequestConfig) {
        return PublisherPostbacksReportApiFp(this.configuration).publisherGetPostbacksReportTotals(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.previousFrom, requestParameters.previousTo, requestParameters.publisherClickId, requestParameters.brandId, requestParameters.siteGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}
