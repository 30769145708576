/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminAffiliateSource } from '../AfClApiModels';
// @ts-ignore
import { GetAffiliateSources200Response } from '../AfClApiModels';
// @ts-ignore
import { GetAffiliateSourcesStatistic200Response } from '../AfClApiModels';
// @ts-ignore
import { UpdateAffiliateSourceRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateAffiliateSources200Response } from '../AfClApiModels';
/**
 * AdminAffiliateSourcesApi - axios parameter creator
 * @export
 */
export const AdminAffiliateSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Affiliate Sources Export
         * @param {Array<number>} [siteIds] 
         * @param {number} [programId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [campaignName] 
         * @param {string} [campaignId] 
         * @param {string} [alternativeSiteName] 
         * @param {string} [alternativeBrandName] 
         * @param {string} [from] data format Y-m-d
         * @param {string} [to] data format Y-m-d
         * @param {string} [sort] 
         * @param {string} [sortOrder] ASC or DESC
         * @param {boolean} [withActivity] 
         * @param {boolean} [noActivity] 
         * @param {boolean} [mapped] 
         * @param {boolean} [unmapped] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateSourcesExport: async (siteIds?: Array<number>, programId?: number, regionIds?: Array<number>, verticalIds?: Array<number>, affiliateAccountIds?: Array<number>, page?: number, perPage?: number, campaignName?: string, campaignId?: string, alternativeSiteName?: string, alternativeBrandName?: string, from?: string, to?: string, sort?: string, sortOrder?: string, withActivity?: boolean, noActivity?: boolean, mapped?: boolean, unmapped?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-sources/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programId !== undefined) {
                localVarQueryParameter['program_id'] = programId;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (affiliateAccountIds) {
                localVarQueryParameter['affiliate_account_ids[]'] = affiliateAccountIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }

            if (alternativeSiteName !== undefined) {
                localVarQueryParameter['alternative_site_name'] = alternativeSiteName;
            }

            if (alternativeBrandName !== undefined) {
                localVarQueryParameter['alternative_brand_name'] = alternativeBrandName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (withActivity !== undefined) {
                localVarQueryParameter['with_activity'] = withActivity;
            }

            if (noActivity !== undefined) {
                localVarQueryParameter['no_activity'] = noActivity;
            }

            if (mapped !== undefined) {
                localVarQueryParameter['mapped'] = mapped;
            }

            if (unmapped !== undefined) {
                localVarQueryParameter['unmapped'] = unmapped;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Source
         * @param {number} affiliateSourcesId Numeric ID of the Affiliate Source to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSource: async (affiliateSourcesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateSourcesId' is not null or undefined
            assertParamExists('getAffiliateSource', 'affiliateSourcesId', affiliateSourcesId)
            const localVarPath = `/v1/admin/affiliate-sources/{affiliateSourcesId}`
                .replace(`{${"affiliateSourcesId"}}`, encodeURIComponent(String(affiliateSourcesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Sources
         * @param {Array<number>} [siteIds] 
         * @param {number} [programId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [campaignName] 
         * @param {string} [campaignId] 
         * @param {string} [alternativeSiteName] 
         * @param {string} [alternativeBrandName] 
         * @param {string} [from] data format Y-m-d
         * @param {string} [to] data format Y-m-d
         * @param {string} [sort] 
         * @param {string} [sortOrder] ASC or DESC
         * @param {boolean} [withActivity] 
         * @param {boolean} [noActivity] 
         * @param {boolean} [mapped] 
         * @param {boolean} [unmapped] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSources: async (siteIds?: Array<number>, programId?: number, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, affiliateAccountIds?: Array<number>, page?: number, perPage?: number, campaignName?: string, campaignId?: string, alternativeSiteName?: string, alternativeBrandName?: string, from?: string, to?: string, sort?: string, sortOrder?: string, withActivity?: boolean, noActivity?: boolean, mapped?: boolean, unmapped?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programId !== undefined) {
                localVarQueryParameter['program_id'] = programId;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (affiliateAccountIds) {
                localVarQueryParameter['affiliate_account_ids[]'] = affiliateAccountIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }

            if (alternativeSiteName !== undefined) {
                localVarQueryParameter['alternative_site_name'] = alternativeSiteName;
            }

            if (alternativeBrandName !== undefined) {
                localVarQueryParameter['alternative_brand_name'] = alternativeBrandName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (withActivity !== undefined) {
                localVarQueryParameter['with_activity'] = withActivity;
            }

            if (noActivity !== undefined) {
                localVarQueryParameter['no_activity'] = noActivity;
            }

            if (mapped !== undefined) {
                localVarQueryParameter['mapped'] = mapped;
            }

            if (unmapped !== undefined) {
                localVarQueryParameter['unmapped'] = unmapped;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Sources Statistic
         * @param {Array<number>} [siteIds] 
         * @param {number} [programId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {string} [campaignName] 
         * @param {string} [campaignId] 
         * @param {string} [alternativeSiteName] 
         * @param {string} [alternativeBrandName] 
         * @param {string} [from] data format Y-m-d
         * @param {string} [to] data format Y-m-d
         * @param {string} [sort] 
         * @param {string} [sortOrder] ASC or DESC
         * @param {boolean} [withActivity] 
         * @param {boolean} [noActivity] 
         * @param {boolean} [mapped] 
         * @param {boolean} [unmapped] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSourcesStatistic: async (siteIds?: Array<number>, programId?: number, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, affiliateAccountIds?: Array<number>, campaignName?: string, campaignId?: string, alternativeSiteName?: string, alternativeBrandName?: string, from?: string, to?: string, sort?: string, sortOrder?: string, withActivity?: boolean, noActivity?: boolean, mapped?: boolean, unmapped?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-sources/statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programId !== undefined) {
                localVarQueryParameter['program_id'] = programId;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (affiliateAccountIds) {
                localVarQueryParameter['affiliate_account_ids[]'] = affiliateAccountIds;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }

            if (alternativeSiteName !== undefined) {
                localVarQueryParameter['alternative_site_name'] = alternativeSiteName;
            }

            if (alternativeBrandName !== undefined) {
                localVarQueryParameter['alternative_brand_name'] = alternativeBrandName;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (withActivity !== undefined) {
                localVarQueryParameter['with_activity'] = withActivity;
            }

            if (noActivity !== undefined) {
                localVarQueryParameter['no_activity'] = noActivity;
            }

            if (mapped !== undefined) {
                localVarQueryParameter['mapped'] = mapped;
            }

            if (unmapped !== undefined) {
                localVarQueryParameter['unmapped'] = unmapped;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update Affiliate Sources
         * @param {UpdateAffiliateSourceRequest} [updateAffiliateSourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAffiliateSources: async (updateAffiliateSourceRequest?: UpdateAffiliateSourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAffiliateSourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAffiliateSourcesApi - functional programming interface
 * @export
 */
export const AdminAffiliateSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAffiliateSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Affiliate Sources Export
         * @param {Array<number>} [siteIds] 
         * @param {number} [programId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [campaignName] 
         * @param {string} [campaignId] 
         * @param {string} [alternativeSiteName] 
         * @param {string} [alternativeBrandName] 
         * @param {string} [from] data format Y-m-d
         * @param {string} [to] data format Y-m-d
         * @param {string} [sort] 
         * @param {string} [sortOrder] ASC or DESC
         * @param {boolean} [withActivity] 
         * @param {boolean} [noActivity] 
         * @param {boolean} [mapped] 
         * @param {boolean} [unmapped] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateSourcesExport(siteIds?: Array<number>, programId?: number, regionIds?: Array<number>, verticalIds?: Array<number>, affiliateAccountIds?: Array<number>, page?: number, perPage?: number, campaignName?: string, campaignId?: string, alternativeSiteName?: string, alternativeBrandName?: string, from?: string, to?: string, sort?: string, sortOrder?: string, withActivity?: boolean, noActivity?: boolean, mapped?: boolean, unmapped?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateSourcesExport(siteIds, programId, regionIds, verticalIds, affiliateAccountIds, page, perPage, campaignName, campaignId, alternativeSiteName, alternativeBrandName, from, to, sort, sortOrder, withActivity, noActivity, mapped, unmapped, dataSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Source
         * @param {number} affiliateSourcesId Numeric ID of the Affiliate Source to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliateSource(affiliateSourcesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAffiliateSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliateSource(affiliateSourcesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Sources
         * @param {Array<number>} [siteIds] 
         * @param {number} [programId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [campaignName] 
         * @param {string} [campaignId] 
         * @param {string} [alternativeSiteName] 
         * @param {string} [alternativeBrandName] 
         * @param {string} [from] data format Y-m-d
         * @param {string} [to] data format Y-m-d
         * @param {string} [sort] 
         * @param {string} [sortOrder] ASC or DESC
         * @param {boolean} [withActivity] 
         * @param {boolean} [noActivity] 
         * @param {boolean} [mapped] 
         * @param {boolean} [unmapped] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliateSources(siteIds?: Array<number>, programId?: number, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, affiliateAccountIds?: Array<number>, page?: number, perPage?: number, campaignName?: string, campaignId?: string, alternativeSiteName?: string, alternativeBrandName?: string, from?: string, to?: string, sort?: string, sortOrder?: string, withActivity?: boolean, noActivity?: boolean, mapped?: boolean, unmapped?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAffiliateSources200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliateSources(siteIds, programId, regionIds, brandIds, verticalIds, affiliateAccountIds, page, perPage, campaignName, campaignId, alternativeSiteName, alternativeBrandName, from, to, sort, sortOrder, withActivity, noActivity, mapped, unmapped, dataSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Sources Statistic
         * @param {Array<number>} [siteIds] 
         * @param {number} [programId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {string} [campaignName] 
         * @param {string} [campaignId] 
         * @param {string} [alternativeSiteName] 
         * @param {string} [alternativeBrandName] 
         * @param {string} [from] data format Y-m-d
         * @param {string} [to] data format Y-m-d
         * @param {string} [sort] 
         * @param {string} [sortOrder] ASC or DESC
         * @param {boolean} [withActivity] 
         * @param {boolean} [noActivity] 
         * @param {boolean} [mapped] 
         * @param {boolean} [unmapped] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliateSourcesStatistic(siteIds?: Array<number>, programId?: number, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, affiliateAccountIds?: Array<number>, campaignName?: string, campaignId?: string, alternativeSiteName?: string, alternativeBrandName?: string, from?: string, to?: string, sort?: string, sortOrder?: string, withActivity?: boolean, noActivity?: boolean, mapped?: boolean, unmapped?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAffiliateSourcesStatistic200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliateSourcesStatistic(siteIds, programId, regionIds, brandIds, verticalIds, affiliateAccountIds, campaignName, campaignId, alternativeSiteName, alternativeBrandName, from, to, sort, sortOrder, withActivity, noActivity, mapped, unmapped, dataSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update Affiliate Sources
         * @param {UpdateAffiliateSourceRequest} [updateAffiliateSourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAffiliateSources(updateAffiliateSourceRequest?: UpdateAffiliateSourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAffiliateSources200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAffiliateSources(updateAffiliateSourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAffiliateSourcesApi - factory interface
 * @export
 */
export const AdminAffiliateSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAffiliateSourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Affiliate Sources Export
         * @param {AdminAffiliateSourcesApiAffiliateSourcesExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateSourcesExport(requestParameters: AdminAffiliateSourcesApiAffiliateSourcesExportRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.affiliateSourcesExport(requestParameters.siteIds, requestParameters.programId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.affiliateAccountIds, requestParameters.page, requestParameters.perPage, requestParameters.campaignName, requestParameters.campaignId, requestParameters.alternativeSiteName, requestParameters.alternativeBrandName, requestParameters.from, requestParameters.to, requestParameters.sort, requestParameters.sortOrder, requestParameters.withActivity, requestParameters.noActivity, requestParameters.mapped, requestParameters.unmapped, requestParameters.dataSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Source
         * @param {AdminAffiliateSourcesApiGetAffiliateSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSource(requestParameters: AdminAffiliateSourcesApiGetAffiliateSourceRequest, options?: AxiosRequestConfig): AxiosPromise<AdminAffiliateSource> {
            return localVarFp.getAffiliateSource(requestParameters.affiliateSourcesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Sources
         * @param {AdminAffiliateSourcesApiGetAffiliateSourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSources(requestParameters: AdminAffiliateSourcesApiGetAffiliateSourcesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetAffiliateSources200Response> {
            return localVarFp.getAffiliateSources(requestParameters.siteIds, requestParameters.programId, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.affiliateAccountIds, requestParameters.page, requestParameters.perPage, requestParameters.campaignName, requestParameters.campaignId, requestParameters.alternativeSiteName, requestParameters.alternativeBrandName, requestParameters.from, requestParameters.to, requestParameters.sort, requestParameters.sortOrder, requestParameters.withActivity, requestParameters.noActivity, requestParameters.mapped, requestParameters.unmapped, requestParameters.dataSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Sources Statistic
         * @param {AdminAffiliateSourcesApiGetAffiliateSourcesStatisticRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSourcesStatistic(requestParameters: AdminAffiliateSourcesApiGetAffiliateSourcesStatisticRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetAffiliateSourcesStatistic200Response> {
            return localVarFp.getAffiliateSourcesStatistic(requestParameters.siteIds, requestParameters.programId, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.affiliateAccountIds, requestParameters.campaignName, requestParameters.campaignId, requestParameters.alternativeSiteName, requestParameters.alternativeBrandName, requestParameters.from, requestParameters.to, requestParameters.sort, requestParameters.sortOrder, requestParameters.withActivity, requestParameters.noActivity, requestParameters.mapped, requestParameters.unmapped, requestParameters.dataSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update Affiliate Sources
         * @param {AdminAffiliateSourcesApiUpdateAffiliateSourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAffiliateSources(requestParameters: AdminAffiliateSourcesApiUpdateAffiliateSourcesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UpdateAffiliateSources200Response> {
            return localVarFp.updateAffiliateSources(requestParameters.updateAffiliateSourceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for affiliateSourcesExport operation in AdminAffiliateSourcesApi.
 * @export
 * @interface AdminAffiliateSourcesApiAffiliateSourcesExportRequest
 */
export interface AdminAffiliateSourcesApiAffiliateSourcesExportRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly programId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly affiliateAccountIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly campaignName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly campaignId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly alternativeSiteName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly alternativeBrandName?: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly from?: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly sort?: string

    /**
     * ASC or DESC
     * @type {string}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly sortOrder?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly withActivity?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly noActivity?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly mapped?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly unmapped?: boolean

    /**
     * 
     * @type {'VOONIX' | 'BOTS' | 'POSTBACKS'}
     * @memberof AdminAffiliateSourcesApiAffiliateSourcesExport
     */
    readonly dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS'
}

/**
 * Request parameters for getAffiliateSource operation in AdminAffiliateSourcesApi.
 * @export
 * @interface AdminAffiliateSourcesApiGetAffiliateSourceRequest
 */
export interface AdminAffiliateSourcesApiGetAffiliateSourceRequest {
    /**
     * Numeric ID of the Affiliate Source to find
     * @type {number}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSource
     */
    readonly affiliateSourcesId: number
}

/**
 * Request parameters for getAffiliateSources operation in AdminAffiliateSourcesApi.
 * @export
 * @interface AdminAffiliateSourcesApiGetAffiliateSourcesRequest
 */
export interface AdminAffiliateSourcesApiGetAffiliateSourcesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly programId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly affiliateAccountIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly campaignName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly campaignId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly alternativeSiteName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly alternativeBrandName?: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly from?: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly sort?: string

    /**
     * ASC or DESC
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly sortOrder?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly withActivity?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly noActivity?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly mapped?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly unmapped?: boolean

    /**
     * 
     * @type {'VOONIX' | 'BOTS' | 'POSTBACKS'}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSources
     */
    readonly dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS'
}

/**
 * Request parameters for getAffiliateSourcesStatistic operation in AdminAffiliateSourcesApi.
 * @export
 * @interface AdminAffiliateSourcesApiGetAffiliateSourcesStatisticRequest
 */
export interface AdminAffiliateSourcesApiGetAffiliateSourcesStatisticRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly programId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly affiliateAccountIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly campaignName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly campaignId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly alternativeSiteName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly alternativeBrandName?: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly from?: string

    /**
     * data format Y-m-d
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly sort?: string

    /**
     * ASC or DESC
     * @type {string}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly sortOrder?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly withActivity?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly noActivity?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly mapped?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly unmapped?: boolean

    /**
     * 
     * @type {'VOONIX' | 'BOTS' | 'POSTBACKS'}
     * @memberof AdminAffiliateSourcesApiGetAffiliateSourcesStatistic
     */
    readonly dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS'
}

/**
 * Request parameters for updateAffiliateSources operation in AdminAffiliateSourcesApi.
 * @export
 * @interface AdminAffiliateSourcesApiUpdateAffiliateSourcesRequest
 */
export interface AdminAffiliateSourcesApiUpdateAffiliateSourcesRequest {
    /**
     * 
     * @type {UpdateAffiliateSourceRequest}
     * @memberof AdminAffiliateSourcesApiUpdateAffiliateSources
     */
    readonly updateAffiliateSourceRequest?: UpdateAffiliateSourceRequest
}

/**
 * AdminAffiliateSourcesApi - object-oriented interface
 * @export
 * @class AdminAffiliateSourcesApi
 * @extends {BaseAPI}
 */
export class AdminAffiliateSourcesApi extends BaseAPI {
    /**
     * 
     * @summary Affiliate Sources Export
     * @param {AdminAffiliateSourcesApiAffiliateSourcesExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSourcesApi
     */
    public affiliateSourcesExport(requestParameters: AdminAffiliateSourcesApiAffiliateSourcesExportRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSourcesApiFp(this.configuration).affiliateSourcesExport(requestParameters.siteIds, requestParameters.programId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.affiliateAccountIds, requestParameters.page, requestParameters.perPage, requestParameters.campaignName, requestParameters.campaignId, requestParameters.alternativeSiteName, requestParameters.alternativeBrandName, requestParameters.from, requestParameters.to, requestParameters.sort, requestParameters.sortOrder, requestParameters.withActivity, requestParameters.noActivity, requestParameters.mapped, requestParameters.unmapped, requestParameters.dataSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Source
     * @param {AdminAffiliateSourcesApiGetAffiliateSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSourcesApi
     */
    public getAffiliateSource(requestParameters: AdminAffiliateSourcesApiGetAffiliateSourceRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateSourcesApiFp(this.configuration).getAffiliateSource(requestParameters.affiliateSourcesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Sources
     * @param {AdminAffiliateSourcesApiGetAffiliateSourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSourcesApi
     */
    public getAffiliateSources(requestParameters: AdminAffiliateSourcesApiGetAffiliateSourcesRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSourcesApiFp(this.configuration).getAffiliateSources(requestParameters.siteIds, requestParameters.programId, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.affiliateAccountIds, requestParameters.page, requestParameters.perPage, requestParameters.campaignName, requestParameters.campaignId, requestParameters.alternativeSiteName, requestParameters.alternativeBrandName, requestParameters.from, requestParameters.to, requestParameters.sort, requestParameters.sortOrder, requestParameters.withActivity, requestParameters.noActivity, requestParameters.mapped, requestParameters.unmapped, requestParameters.dataSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Sources Statistic
     * @param {AdminAffiliateSourcesApiGetAffiliateSourcesStatisticRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSourcesApi
     */
    public getAffiliateSourcesStatistic(requestParameters: AdminAffiliateSourcesApiGetAffiliateSourcesStatisticRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSourcesApiFp(this.configuration).getAffiliateSourcesStatistic(requestParameters.siteIds, requestParameters.programId, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.affiliateAccountIds, requestParameters.campaignName, requestParameters.campaignId, requestParameters.alternativeSiteName, requestParameters.alternativeBrandName, requestParameters.from, requestParameters.to, requestParameters.sort, requestParameters.sortOrder, requestParameters.withActivity, requestParameters.noActivity, requestParameters.mapped, requestParameters.unmapped, requestParameters.dataSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update Affiliate Sources
     * @param {AdminAffiliateSourcesApiUpdateAffiliateSourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSourcesApi
     */
    public updateAffiliateSources(requestParameters: AdminAffiliateSourcesApiUpdateAffiliateSourcesRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSourcesApiFp(this.configuration).updateAffiliateSources(requestParameters.updateAffiliateSourceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
