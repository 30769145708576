/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetAffiliateSystems200Response } from '../AfClApiModels';
// @ts-ignore
import { AffiliateSystemResource } from '../AfClApiModels';
// @ts-ignore
import { StoreAffiliateSystemRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateAffiliateSystemRequest } from '../AfClApiModels';
/**
 * AdminAffiliateSystemsApi - axios parameter creator
 * @export
 */
export const AdminAffiliateSystemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Affiliate System
         * @param {StoreAffiliateSystemRequest} [storeAffiliateSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAffiliateSystem: async (storeAffiliateSystemRequest?: StoreAffiliateSystemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeAffiliateSystemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Systems
         * @param {string} [name] 
         * @param {boolean} [twoFaEnabled] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateSystems: async (name?: string, twoFaEnabled?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (twoFaEnabled !== undefined) {
                localVarQueryParameter['two_fa_enabled'] = twoFaEnabled;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Systems Basic Pair
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateSystemsBasicPair: async (name?: string, advertiserId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-systems-basic-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Affiliate System
         * @param {number} affiliateSystemId Numeric ID of the Affiliate system to update
         * @param {UpdateAffiliateSystemRequest} [updateAffiliateSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAffiliateSystem: async (affiliateSystemId: number, updateAffiliateSystemRequest?: UpdateAffiliateSystemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateSystemId' is not null or undefined
            assertParamExists('adminUpdateAffiliateSystem', 'affiliateSystemId', affiliateSystemId)
            const localVarPath = `/v1/admin/affiliate-systems/{affiliateSystemId}`
                .replace(`{${"affiliateSystemId"}}`, encodeURIComponent(String(affiliateSystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAffiliateSystemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Affiliate System
         * @param {number} affiliateSystemId Numeric ID of the Affiliate System to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAffiliateSystem: async (affiliateSystemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateSystemId' is not null or undefined
            assertParamExists('deleteAffiliateSystem', 'affiliateSystemId', affiliateSystemId)
            const localVarPath = `/v1/admin/affiliate-systems/{affiliateSystemId}`
                .replace(`{${"affiliateSystemId"}}`, encodeURIComponent(String(affiliateSystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAffiliateSystemsApi - functional programming interface
 * @export
 */
export const AdminAffiliateSystemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAffiliateSystemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Affiliate System
         * @param {StoreAffiliateSystemRequest} [storeAffiliateSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAffiliateSystem(storeAffiliateSystemRequest?: StoreAffiliateSystemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateSystemResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAffiliateSystem(storeAffiliateSystemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Systems
         * @param {string} [name] 
         * @param {boolean} [twoFaEnabled] 
         * @param {'VOONIX' | 'BOTS' | 'POSTBACKS'} [dataSource] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateSystems(name?: string, twoFaEnabled?: boolean, dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS', page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAffiliateSystems200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateSystems(name, twoFaEnabled, dataSource, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Systems Basic Pair
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateSystemsBasicPair(name?: string, advertiserId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateSystemsBasicPair(name, advertiserId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Affiliate System
         * @param {number} affiliateSystemId Numeric ID of the Affiliate system to update
         * @param {UpdateAffiliateSystemRequest} [updateAffiliateSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAffiliateSystem(affiliateSystemId: number, updateAffiliateSystemRequest?: UpdateAffiliateSystemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateSystemResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAffiliateSystem(affiliateSystemId, updateAffiliateSystemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Affiliate System
         * @param {number} affiliateSystemId Numeric ID of the Affiliate System to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAffiliateSystem(affiliateSystemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAffiliateSystem(affiliateSystemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAffiliateSystemsApi - factory interface
 * @export
 */
export const AdminAffiliateSystemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAffiliateSystemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Affiliate System
         * @param {AdminAffiliateSystemsApiAdminCreateAffiliateSystemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAffiliateSystem(requestParameters: AdminAffiliateSystemsApiAdminCreateAffiliateSystemRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AffiliateSystemResource> {
            return localVarFp.adminCreateAffiliateSystem(requestParameters.storeAffiliateSystemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Systems
         * @param {AdminAffiliateSystemsApiAdminGetAffiliateSystemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateSystems(requestParameters: AdminAffiliateSystemsApiAdminGetAffiliateSystemsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAffiliateSystems200Response> {
            return localVarFp.adminGetAffiliateSystems(requestParameters.name, requestParameters.twoFaEnabled, requestParameters.dataSource, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Systems Basic Pair
         * @param {AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateSystemsBasicPair(requestParameters: AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPairRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.adminGetAffiliateSystemsBasicPair(requestParameters.name, requestParameters.advertiserId, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Affiliate System
         * @param {AdminAffiliateSystemsApiAdminUpdateAffiliateSystemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAffiliateSystem(requestParameters: AdminAffiliateSystemsApiAdminUpdateAffiliateSystemRequest, options?: AxiosRequestConfig): AxiosPromise<AffiliateSystemResource> {
            return localVarFp.adminUpdateAffiliateSystem(requestParameters.affiliateSystemId, requestParameters.updateAffiliateSystemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Affiliate System
         * @param {AdminAffiliateSystemsApiDeleteAffiliateSystemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAffiliateSystem(requestParameters: AdminAffiliateSystemsApiDeleteAffiliateSystemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAffiliateSystem(requestParameters.affiliateSystemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCreateAffiliateSystem operation in AdminAffiliateSystemsApi.
 * @export
 * @interface AdminAffiliateSystemsApiAdminCreateAffiliateSystemRequest
 */
export interface AdminAffiliateSystemsApiAdminCreateAffiliateSystemRequest {
    /**
     * 
     * @type {StoreAffiliateSystemRequest}
     * @memberof AdminAffiliateSystemsApiAdminCreateAffiliateSystem
     */
    readonly storeAffiliateSystemRequest?: StoreAffiliateSystemRequest
}

/**
 * Request parameters for adminGetAffiliateSystems operation in AdminAffiliateSystemsApi.
 * @export
 * @interface AdminAffiliateSystemsApiAdminGetAffiliateSystemsRequest
 */
export interface AdminAffiliateSystemsApiAdminGetAffiliateSystemsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystems
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystems
     */
    readonly twoFaEnabled?: boolean

    /**
     * 
     * @type {'VOONIX' | 'BOTS' | 'POSTBACKS'}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystems
     */
    readonly dataSource?: 'VOONIX' | 'BOTS' | 'POSTBACKS'

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystems
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystems
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminGetAffiliateSystemsBasicPair operation in AdminAffiliateSystemsApi.
 * @export
 * @interface AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPairRequest
 */
export interface AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPairRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPair
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPair
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPair
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminUpdateAffiliateSystem operation in AdminAffiliateSystemsApi.
 * @export
 * @interface AdminAffiliateSystemsApiAdminUpdateAffiliateSystemRequest
 */
export interface AdminAffiliateSystemsApiAdminUpdateAffiliateSystemRequest {
    /**
     * Numeric ID of the Affiliate system to update
     * @type {number}
     * @memberof AdminAffiliateSystemsApiAdminUpdateAffiliateSystem
     */
    readonly affiliateSystemId: number

    /**
     * 
     * @type {UpdateAffiliateSystemRequest}
     * @memberof AdminAffiliateSystemsApiAdminUpdateAffiliateSystem
     */
    readonly updateAffiliateSystemRequest?: UpdateAffiliateSystemRequest
}

/**
 * Request parameters for deleteAffiliateSystem operation in AdminAffiliateSystemsApi.
 * @export
 * @interface AdminAffiliateSystemsApiDeleteAffiliateSystemRequest
 */
export interface AdminAffiliateSystemsApiDeleteAffiliateSystemRequest {
    /**
     * Numeric ID of the Affiliate System to delete
     * @type {number}
     * @memberof AdminAffiliateSystemsApiDeleteAffiliateSystem
     */
    readonly affiliateSystemId: number
}

/**
 * AdminAffiliateSystemsApi - object-oriented interface
 * @export
 * @class AdminAffiliateSystemsApi
 * @extends {BaseAPI}
 */
export class AdminAffiliateSystemsApi extends BaseAPI {
    /**
     * 
     * @summary Create Affiliate System
     * @param {AdminAffiliateSystemsApiAdminCreateAffiliateSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSystemsApi
     */
    public adminCreateAffiliateSystem(requestParameters: AdminAffiliateSystemsApiAdminCreateAffiliateSystemRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSystemsApiFp(this.configuration).adminCreateAffiliateSystem(requestParameters.storeAffiliateSystemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Systems
     * @param {AdminAffiliateSystemsApiAdminGetAffiliateSystemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSystemsApi
     */
    public adminGetAffiliateSystems(requestParameters: AdminAffiliateSystemsApiAdminGetAffiliateSystemsRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSystemsApiFp(this.configuration).adminGetAffiliateSystems(requestParameters.name, requestParameters.twoFaEnabled, requestParameters.dataSource, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Systems Basic Pair
     * @param {AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSystemsApi
     */
    public adminGetAffiliateSystemsBasicPair(requestParameters: AdminAffiliateSystemsApiAdminGetAffiliateSystemsBasicPairRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateSystemsApiFp(this.configuration).adminGetAffiliateSystemsBasicPair(requestParameters.name, requestParameters.advertiserId, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Affiliate System
     * @param {AdminAffiliateSystemsApiAdminUpdateAffiliateSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSystemsApi
     */
    public adminUpdateAffiliateSystem(requestParameters: AdminAffiliateSystemsApiAdminUpdateAffiliateSystemRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateSystemsApiFp(this.configuration).adminUpdateAffiliateSystem(requestParameters.affiliateSystemId, requestParameters.updateAffiliateSystemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Affiliate System
     * @param {AdminAffiliateSystemsApiDeleteAffiliateSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateSystemsApi
     */
    public deleteAffiliateSystem(requestParameters: AdminAffiliateSystemsApiDeleteAffiliateSystemRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateSystemsApiFp(this.configuration).deleteAffiliateSystem(requestParameters.affiliateSystemId, options).then((request) => request(this.axios, this.basePath));
    }
}
