<template>
    <ContainerSection class="pb-10">
        <div class="flex relative bg-white overflow-x-auto px-5 py-4">
            <TreeChart
                :json="treeData"
                @click-node="clickNode($event)"
            />
            <KeysMediaModal
                v-model="keysMediaModalModel.opened"
                :data="keysMediaModalModel.data"
            />
            <SpinnerLoader :visible="mediaKeysFetching" />
        </div>
    </ContainerSection>
</template>
<script setup lang="ts">

import ContainerSection from '@/Components/Core/ContainerSection.vue';
import KeysMediaModal from '@/Components/Pages/Admin/Keys/KeysMediaModal.vue';
import SpinnerLoader from '@/Components/Core/SpinnerLoader.vue';
import { useMediaKeys } from '@/Services/ApiHooks/Shared/UseMediaKeys';
import { reactive, ref, watchEffect } from 'vue';
import TreeChart from 'vue-tree-chart-3';

const { data: mediaKeys, isFetching: mediaKeysFetching } = useMediaKeys();
const treeData = ref();

function nestedSetToObj(nestedSet) {
    const root = nestedSet.find(node => node.lft === 1);

    return nestedSet.reduce((obj, node) => {
        if (node.lft === 1) {
            obj[node.id] = { id: node.id, name: node.name, children: [] };
        } else {
            const parentNode = findParent(node, obj[root.id]);
            const newNode = { id: node.id, name: node.name, children: [] };
            parentNode.children.push(newNode);
        }
        return obj;
    }, {});

    function findParent(node, obj) {
        if (obj.id === node.parent_id) return obj;
        for (let child of obj.children) {
            const found = findParent(node, child);
            if (found) return found;
        }
        return null;
    }
}

watchEffect(() => {
    if (mediaKeys.value)
        treeData.value = nestedSetToObj(mediaKeys.value)[1];
});

const keysMediaModalModel = reactive({
    opened: false,
    data: {
        mediaName: '',
        parentId: 0,
        id: 0
    }
});

const clickNode = (payload) => {
    keysMediaModalModel.opened = true;
    keysMediaModalModel.data.mediaName = payload.name;
    keysMediaModalModel.data.parentId = payload.parentId;
    keysMediaModalModel.data.id = payload.id;
};

</script>
@/Services/ApiHooks/Shared/UseMediaKeys
