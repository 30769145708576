/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
// @ts-ignore
import { Brand } from '../AfClApiModels';
// @ts-ignore
import { BrandLogoResource } from '../AfClApiModels';
// @ts-ignore
import { GetBrands200Response } from '../AfClApiModels';
// @ts-ignore
import { LogoInUse200Response } from '../AfClApiModels';
/**
 * AdminBrandsApi - axios parameter creator
 * @export
 */
export const AdminBrandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Brands Basic Pair
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBrandsBasicPair: async (name?: string, advertiserId?: number, brandIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/brands-basic-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Brand
         * @param {number} advertiserId 
         * @param {string} name 
         * @param {string} status 
         * @param {string} [affiliateBackendUrl] 
         * @param {Array<string>} [brandAliases] 
         * @param {Array<BrandLogoResource>} [brandLogos] 
         * @param {string} [postbacksStatus] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (advertiserId: number, name: string, status: string, affiliateBackendUrl?: string, brandAliases?: Array<string>, brandLogos?: Array<BrandLogoResource>, postbacksStatus?: string, timezone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('createBrand', 'advertiserId', advertiserId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createBrand', 'name', name)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('createBrand', 'status', status)
            const localVarPath = `/v1/admin/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (advertiserId !== undefined) { 
                localVarFormParams.append('advertiser_id', advertiserId as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('status', status as any);
            }
    
            if (affiliateBackendUrl !== undefined) { 
                localVarFormParams.append('affiliate_backend_url', affiliateBackendUrl as any);
            }
                if (brandAliases) {
                localVarFormParams.append('brand_aliases', brandAliases.join(COLLECTION_FORMATS.csv));
            }

                if (brandLogos) {
                localVarFormParams.append('brand_logos', brandLogos.join(COLLECTION_FORMATS.csv));
            }

    
            if (postbacksStatus !== undefined) { 
                localVarFormParams.append('postbacks_status', postbacksStatus as any);
            }
    
            if (timezone !== undefined) { 
                localVarFormParams.append('timezone', timezone as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available brands
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>} [status] 
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [ignoreIds] Ignore brand ids
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands: async (status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>, name?: string, advertiserId?: number, brandIds?: Array<number>, ignoreIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status) {
                localVarQueryParameter['status[]'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (ignoreIds) {
                localVarQueryParameter['ignore_ids[]'] = ignoreIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if logo is in use
         * @param {number} brandLogo Numeric ID of the BrandLogo to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoInUse: async (brandLogo: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandLogo' is not null or undefined
            assertParamExists('logoInUse', 'brandLogo', brandLogo)
            const localVarPath = `/v1/admin/brands/logo/{brandLogo}/in-use`
                .replace(`{${"brandLogo"}}`, encodeURIComponent(String(brandLogo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Brand
         * @param {number} brandId Numeric ID of the Brand to update
         * @param {number} advertiserId 
         * @param {string} name 
         * @param {string} status 
         * @param {string} [affiliateBackendUrl] 
         * @param {Array<string>} [brandAliases] 
         * @param {Array<BrandLogoResource>} [brandLogos] 
         * @param {string} [postbacksStatus] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand: async (brandId: number, advertiserId: number, name: string, status: string, affiliateBackendUrl?: string, brandAliases?: Array<string>, brandLogos?: Array<BrandLogoResource>, postbacksStatus?: string, timezone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('updateBrand', 'brandId', brandId)
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('updateBrand', 'advertiserId', advertiserId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateBrand', 'name', name)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('updateBrand', 'status', status)
            const localVarPath = `/v1/admin/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (advertiserId !== undefined) { 
                localVarFormParams.append('advertiser_id', advertiserId as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('status', status as any);
            }
    
            if (affiliateBackendUrl !== undefined) { 
                localVarFormParams.append('affiliate_backend_url', affiliateBackendUrl as any);
            }
                if (brandAliases) {
                localVarFormParams.append('brand_aliases', brandAliases.join(COLLECTION_FORMATS.csv));
            }

                if (brandLogos) {
                localVarFormParams.append('brand_logos', brandLogos.join(COLLECTION_FORMATS.csv));
            }

    
            if (postbacksStatus !== undefined) { 
                localVarFormParams.append('postbacks_status', postbacksStatus as any);
            }
    
            if (timezone !== undefined) { 
                localVarFormParams.append('timezone', timezone as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBrandsApi - functional programming interface
 * @export
 */
export const AdminBrandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBrandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Brands Basic Pair
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBrandsBasicPair(name?: string, advertiserId?: number, brandIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBrandsBasicPair(name, advertiserId, brandIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Brand
         * @param {number} advertiserId 
         * @param {string} name 
         * @param {string} status 
         * @param {string} [affiliateBackendUrl] 
         * @param {Array<string>} [brandAliases] 
         * @param {Array<BrandLogoResource>} [brandLogos] 
         * @param {string} [postbacksStatus] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(advertiserId: number, name: string, status: string, affiliateBackendUrl?: string, brandAliases?: Array<string>, brandLogos?: Array<BrandLogoResource>, postbacksStatus?: string, timezone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(advertiserId, name, status, affiliateBackendUrl, brandAliases, brandLogos, postbacksStatus, timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available brands
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>} [status] 
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [ignoreIds] Ignore brand ids
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrands(status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>, name?: string, advertiserId?: number, brandIds?: Array<number>, ignoreIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrands200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrands(status, name, advertiserId, brandIds, ignoreIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if logo is in use
         * @param {number} brandLogo Numeric ID of the BrandLogo to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoInUse(brandLogo: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoInUse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoInUse(brandLogo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Brand
         * @param {number} brandId Numeric ID of the Brand to update
         * @param {number} advertiserId 
         * @param {string} name 
         * @param {string} status 
         * @param {string} [affiliateBackendUrl] 
         * @param {Array<string>} [brandAliases] 
         * @param {Array<BrandLogoResource>} [brandLogos] 
         * @param {string} [postbacksStatus] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrand(brandId: number, advertiserId: number, name: string, status: string, affiliateBackendUrl?: string, brandAliases?: Array<string>, brandLogos?: Array<BrandLogoResource>, postbacksStatus?: string, timezone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrand(brandId, advertiserId, name, status, affiliateBackendUrl, brandAliases, brandLogos, postbacksStatus, timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBrandsApi - factory interface
 * @export
 */
export const AdminBrandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBrandsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Brands Basic Pair
         * @param {AdminBrandsApiAdminGetBrandsBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBrandsBasicPair(requestParameters: AdminBrandsApiAdminGetBrandsBasicPairRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.adminGetBrandsBasicPair(requestParameters.name, requestParameters.advertiserId, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Brand
         * @param {AdminBrandsApiCreateBrandRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(requestParameters: AdminBrandsApiCreateBrandRequest, options?: AxiosRequestConfig): AxiosPromise<Brand> {
            return localVarFp.createBrand(requestParameters.advertiserId, requestParameters.name, requestParameters.status, requestParameters.affiliateBackendUrl, requestParameters.brandAliases, requestParameters.brandLogos, requestParameters.postbacksStatus, requestParameters.timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available brands
         * @param {AdminBrandsApiGetBrandsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(requestParameters: AdminBrandsApiGetBrandsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetBrands200Response> {
            return localVarFp.getBrands(requestParameters.status, requestParameters.name, requestParameters.advertiserId, requestParameters.brandIds, requestParameters.ignoreIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if logo is in use
         * @param {AdminBrandsApiLogoInUseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoInUse(requestParameters: AdminBrandsApiLogoInUseRequest, options?: AxiosRequestConfig): AxiosPromise<LogoInUse200Response> {
            return localVarFp.logoInUse(requestParameters.brandLogo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Brand
         * @param {AdminBrandsApiUpdateBrandRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand(requestParameters: AdminBrandsApiUpdateBrandRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateBrand(requestParameters.brandId, requestParameters.advertiserId, requestParameters.name, requestParameters.status, requestParameters.affiliateBackendUrl, requestParameters.brandAliases, requestParameters.brandLogos, requestParameters.postbacksStatus, requestParameters.timezone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminGetBrandsBasicPair operation in AdminBrandsApi.
 * @export
 * @interface AdminBrandsApiAdminGetBrandsBasicPairRequest
 */
export interface AdminBrandsApiAdminGetBrandsBasicPairRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiAdminGetBrandsBasicPair
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiAdminGetBrandsBasicPair
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminBrandsApiAdminGetBrandsBasicPair
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiAdminGetBrandsBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiAdminGetBrandsBasicPair
     */
    readonly perPage?: number
}

/**
 * Request parameters for createBrand operation in AdminBrandsApi.
 * @export
 * @interface AdminBrandsApiCreateBrandRequest
 */
export interface AdminBrandsApiCreateBrandRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly advertiserId: number

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly status: string

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly affiliateBackendUrl?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly brandAliases?: Array<string>

    /**
     * 
     * @type {Array<BrandLogoResource>}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly brandLogos?: Array<BrandLogoResource>

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly postbacksStatus?: string

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiCreateBrand
     */
    readonly timezone?: string
}

/**
 * Request parameters for getBrands operation in AdminBrandsApi.
 * @export
 * @interface AdminBrandsApiGetBrandsRequest
 */
export interface AdminBrandsApiGetBrandsRequest {
    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly brandIds?: Array<number>

    /**
     * Ignore brand ids
     * @type {Array<number>}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly ignoreIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiGetBrands
     */
    readonly perPage?: number
}

/**
 * Request parameters for logoInUse operation in AdminBrandsApi.
 * @export
 * @interface AdminBrandsApiLogoInUseRequest
 */
export interface AdminBrandsApiLogoInUseRequest {
    /**
     * Numeric ID of the BrandLogo to check
     * @type {number}
     * @memberof AdminBrandsApiLogoInUse
     */
    readonly brandLogo: number
}

/**
 * Request parameters for updateBrand operation in AdminBrandsApi.
 * @export
 * @interface AdminBrandsApiUpdateBrandRequest
 */
export interface AdminBrandsApiUpdateBrandRequest {
    /**
     * Numeric ID of the Brand to update
     * @type {number}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly brandId: number

    /**
     * 
     * @type {number}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly advertiserId: number

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly status: string

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly affiliateBackendUrl?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly brandAliases?: Array<string>

    /**
     * 
     * @type {Array<BrandLogoResource>}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly brandLogos?: Array<BrandLogoResource>

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly postbacksStatus?: string

    /**
     * 
     * @type {string}
     * @memberof AdminBrandsApiUpdateBrand
     */
    readonly timezone?: string
}

/**
 * AdminBrandsApi - object-oriented interface
 * @export
 * @class AdminBrandsApi
 * @extends {BaseAPI}
 */
export class AdminBrandsApi extends BaseAPI {
    /**
     * 
     * @summary Get Brands Basic Pair
     * @param {AdminBrandsApiAdminGetBrandsBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandsApi
     */
    public adminGetBrandsBasicPair(requestParameters: AdminBrandsApiAdminGetBrandsBasicPairRequest = {}, options?: AxiosRequestConfig) {
        return AdminBrandsApiFp(this.configuration).adminGetBrandsBasicPair(requestParameters.name, requestParameters.advertiserId, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Brand
     * @param {AdminBrandsApiCreateBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandsApi
     */
    public createBrand(requestParameters: AdminBrandsApiCreateBrandRequest, options?: AxiosRequestConfig) {
        return AdminBrandsApiFp(this.configuration).createBrand(requestParameters.advertiserId, requestParameters.name, requestParameters.status, requestParameters.affiliateBackendUrl, requestParameters.brandAliases, requestParameters.brandLogos, requestParameters.postbacksStatus, requestParameters.timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available brands
     * @param {AdminBrandsApiGetBrandsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandsApi
     */
    public getBrands(requestParameters: AdminBrandsApiGetBrandsRequest = {}, options?: AxiosRequestConfig) {
        return AdminBrandsApiFp(this.configuration).getBrands(requestParameters.status, requestParameters.name, requestParameters.advertiserId, requestParameters.brandIds, requestParameters.ignoreIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if logo is in use
     * @param {AdminBrandsApiLogoInUseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandsApi
     */
    public logoInUse(requestParameters: AdminBrandsApiLogoInUseRequest, options?: AxiosRequestConfig) {
        return AdminBrandsApiFp(this.configuration).logoInUse(requestParameters.brandLogo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Brand
     * @param {AdminBrandsApiUpdateBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandsApi
     */
    public updateBrand(requestParameters: AdminBrandsApiUpdateBrandRequest, options?: AxiosRequestConfig) {
        return AdminBrandsApiFp(this.configuration).updateBrand(requestParameters.brandId, requestParameters.advertiserId, requestParameters.name, requestParameters.status, requestParameters.affiliateBackendUrl, requestParameters.brandAliases, requestParameters.brandLogos, requestParameters.postbacksStatus, requestParameters.timezone, options).then((request) => request(this.axios, this.basePath));
    }
}
