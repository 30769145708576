import { Auth0Client } from '@auth0/auth0-spa-js';
import { App } from 'vue';

export const auth0:Auth0Client = new Auth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    }
});

export default {
    install: (app: App) => {
        app.config.globalProperties.$auth0 = auth0;
        app.provide('auth0', auth0);
    }
};
