/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Currency } from '../AfClApiModels';
/**
 * CurrenciesApi - axios parameter creator
 * @export
 */
export const CurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Currencies
         * @param {boolean} [isDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencies: async (isDisplay?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isDisplay !== undefined) {
                localVarQueryParameter['is_display'] = isDisplay;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrenciesApi - functional programming interface
 * @export
 */
export const CurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Currencies
         * @param {boolean} [isDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrencies(isDisplay?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currency>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencies(isDisplay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CurrenciesApi - factory interface
 * @export
 */
export const CurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Currencies
         * @param {CurrenciesApiGetCurrenciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencies(requestParameters: CurrenciesApiGetCurrenciesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Currency>> {
            return localVarFp.getCurrencies(requestParameters.isDisplay, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCurrencies operation in CurrenciesApi.
 * @export
 * @interface CurrenciesApiGetCurrenciesRequest
 */
export interface CurrenciesApiGetCurrenciesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CurrenciesApiGetCurrencies
     */
    readonly isDisplay?: boolean
}

/**
 * CurrenciesApi - object-oriented interface
 * @export
 * @class CurrenciesApi
 * @extends {BaseAPI}
 */
export class CurrenciesApi extends BaseAPI {
    /**
     * 
     * @summary Get Currencies
     * @param {CurrenciesApiGetCurrenciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public getCurrencies(requestParameters: CurrenciesApiGetCurrenciesRequest = {}, options?: AxiosRequestConfig) {
        return CurrenciesApiFp(this.configuration).getCurrencies(requestParameters.isDisplay, options).then((request) => request(this.axios, this.basePath));
    }
}
