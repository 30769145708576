/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminManagerResource } from '../AfClApiModels';
// @ts-ignore
import { AdminPublisherAccount } from '../AfClApiModels';
// @ts-ignore
import { AdminStorePublishersRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdatePublisherRequest } from '../AfClApiModels';
// @ts-ignore
import { GetPublishersPaginated200Response } from '../AfClApiModels';
// @ts-ignore
import { ShortPublisher } from '../AfClApiModels';
/**
 * AdminPublishersApi - axios parameter creator
 * @export
 */
export const AdminPublishersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Publisher
         * @param {number} publisherId The ID of the Publisher to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeletePublisher: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('adminDeletePublisher', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Publisher
         * @param {AdminStorePublishersRequest} [adminStorePublishersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher: async (adminStorePublishersRequest?: AdminStorePublishersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStorePublishersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publisher
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisher: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisher', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publishers
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishers: async (name?: string, email?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (publisherManagerIds) {
                localVarQueryParameter['publisher_manager_ids[]'] = publisherManagerIds;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publishers Managers
         * @param {number} [publisherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishersManagers: async (publisherId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publishers/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publishers with pagination
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishersPaginated: async (page?: number, perPage?: number, name?: string, email?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publishers/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (publisherManagerIds) {
                localVarQueryParameter['publisher_manager_ids[]'] = publisherManagerIds;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publishers Export
         * @param {string} [name] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {string} [email] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishersExport: async (name?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', email?: string, publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publishers/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (publisherManagerIds) {
                localVarQueryParameter['publisher_manager_ids[]'] = publisherManagerIds;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Publisher
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {AdminUpdatePublisherRequest} [adminUpdatePublisherRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher: async (publisherId: number, adminUpdatePublisherRequest?: AdminUpdatePublisherRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisher', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdatePublisherRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublishersApi - functional programming interface
 * @export
 */
export const AdminPublishersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublishersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Publisher
         * @param {number} publisherId The ID of the Publisher to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeletePublisher(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeletePublisher(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Publisher
         * @param {AdminStorePublishersRequest} [adminStorePublishersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisher(adminStorePublishersRequest?: AdminStorePublishersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisher(adminStorePublishersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publisher
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisher(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisher(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publishers
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishers(name?: string, email?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShortPublisher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishers(name, email, publisherType, publisherManagerIds, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publishers Managers
         * @param {number} [publisherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishersManagers(publisherId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminManagerResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishersManagers(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publishers with pagination
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishersPaginated(page?: number, perPage?: number, name?: string, email?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublishersPaginated200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishersPaginated(page, perPage, name, email, publisherType, publisherManagerIds, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publishers Export
         * @param {string} [name] 
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {string} [email] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishersExport(name?: string, publisherType?: 'INTERNAL' | 'EXTERNAL', email?: string, publisherManagerIds?: Array<number>, statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishersExport(name, publisherType, email, publisherManagerIds, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Publisher
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {AdminUpdatePublisherRequest} [adminUpdatePublisherRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisher(publisherId: number, adminUpdatePublisherRequest?: AdminUpdatePublisherRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisher(publisherId, adminUpdatePublisherRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublishersApi - factory interface
 * @export
 */
export const AdminPublishersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublishersApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Publisher
         * @param {AdminPublishersApiAdminDeletePublisherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeletePublisher(requestParameters: AdminPublishersApiAdminDeletePublisherRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminDeletePublisher(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Publisher
         * @param {AdminPublishersApiCreatePublisherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(requestParameters: AdminPublishersApiCreatePublisherRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherAccount> {
            return localVarFp.createPublisher(requestParameters.adminStorePublishersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publisher
         * @param {AdminPublishersApiGetPublisherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisher(requestParameters: AdminPublishersApiGetPublisherRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherAccount> {
            return localVarFp.getPublisher(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publishers
         * @param {AdminPublishersApiGetPublishersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishers(requestParameters: AdminPublishersApiGetPublishersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ShortPublisher>> {
            return localVarFp.getPublishers(requestParameters.name, requestParameters.email, requestParameters.publisherType, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publishers Managers
         * @param {AdminPublishersApiGetPublishersManagersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishersManagers(requestParameters: AdminPublishersApiGetPublishersManagersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminManagerResource>> {
            return localVarFp.getPublishersManagers(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publishers with pagination
         * @param {AdminPublishersApiGetPublishersPaginatedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishersPaginated(requestParameters: AdminPublishersApiGetPublishersPaginatedRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetPublishersPaginated200Response> {
            return localVarFp.getPublishersPaginated(requestParameters.page, requestParameters.perPage, requestParameters.name, requestParameters.email, requestParameters.publisherType, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publishers Export
         * @param {AdminPublishersApiPublishersExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishersExport(requestParameters: AdminPublishersApiPublishersExportRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.publishersExport(requestParameters.name, requestParameters.publisherType, requestParameters.email, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Publisher
         * @param {AdminPublishersApiUpdatePublisherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(requestParameters: AdminPublishersApiUpdatePublisherRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherAccount> {
            return localVarFp.updatePublisher(requestParameters.publisherId, requestParameters.adminUpdatePublisherRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminDeletePublisher operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiAdminDeletePublisherRequest
 */
export interface AdminPublishersApiAdminDeletePublisherRequest {
    /**
     * The ID of the Publisher to delete
     * @type {number}
     * @memberof AdminPublishersApiAdminDeletePublisher
     */
    readonly publisherId: number
}

/**
 * Request parameters for createPublisher operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiCreatePublisherRequest
 */
export interface AdminPublishersApiCreatePublisherRequest {
    /**
     * 
     * @type {AdminStorePublishersRequest}
     * @memberof AdminPublishersApiCreatePublisher
     */
    readonly adminStorePublishersRequest?: AdminStorePublishersRequest
}

/**
 * Request parameters for getPublisher operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiGetPublisherRequest
 */
export interface AdminPublishersApiGetPublisherRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof AdminPublishersApiGetPublisher
     */
    readonly publisherId: number
}

/**
 * Request parameters for getPublishers operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiGetPublishersRequest
 */
export interface AdminPublishersApiGetPublishersRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPublishersApiGetPublishers
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPublishersApiGetPublishers
     */
    readonly email?: string

    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminPublishersApiGetPublishers
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPublishersApiGetPublishers
     */
    readonly publisherManagerIds?: Array<number>

    /**
     * 
     * @type {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>}
     * @memberof AdminPublishersApiGetPublishers
     */
    readonly statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>
}

/**
 * Request parameters for getPublishersManagers operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiGetPublishersManagersRequest
 */
export interface AdminPublishersApiGetPublishersManagersRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminPublishersApiGetPublishersManagers
     */
    readonly publisherId?: number
}

/**
 * Request parameters for getPublishersPaginated operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiGetPublishersPaginatedRequest
 */
export interface AdminPublishersApiGetPublishersPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly email?: string

    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly publisherManagerIds?: Array<number>

    /**
     * 
     * @type {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>}
     * @memberof AdminPublishersApiGetPublishersPaginated
     */
    readonly statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>
}

/**
 * Request parameters for publishersExport operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiPublishersExportRequest
 */
export interface AdminPublishersApiPublishersExportRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPublishersApiPublishersExport
     */
    readonly name?: string

    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminPublishersApiPublishersExport
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {string}
     * @memberof AdminPublishersApiPublishersExport
     */
    readonly email?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPublishersApiPublishersExport
     */
    readonly publisherManagerIds?: Array<number>

    /**
     * 
     * @type {Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>}
     * @memberof AdminPublishersApiPublishersExport
     */
    readonly statuses?: Array<'NEW' | 'HAS_SITE' | 'PENDING' | 'LIVE' | 'APPROVED' | 'REJECTED' | 'DELETED'>
}

/**
 * Request parameters for updatePublisher operation in AdminPublishersApi.
 * @export
 * @interface AdminPublishersApiUpdatePublisherRequest
 */
export interface AdminPublishersApiUpdatePublisherRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof AdminPublishersApiUpdatePublisher
     */
    readonly publisherId: number

    /**
     * 
     * @type {AdminUpdatePublisherRequest}
     * @memberof AdminPublishersApiUpdatePublisher
     */
    readonly adminUpdatePublisherRequest?: AdminUpdatePublisherRequest
}

/**
 * AdminPublishersApi - object-oriented interface
 * @export
 * @class AdminPublishersApi
 * @extends {BaseAPI}
 */
export class AdminPublishersApi extends BaseAPI {
    /**
     * 
     * @summary Delete Publisher
     * @param {AdminPublishersApiAdminDeletePublisherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public adminDeletePublisher(requestParameters: AdminPublishersApiAdminDeletePublisherRequest, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).adminDeletePublisher(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Publisher
     * @param {AdminPublishersApiCreatePublisherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public createPublisher(requestParameters: AdminPublishersApiCreatePublisherRequest = {}, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).createPublisher(requestParameters.adminStorePublishersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publisher
     * @param {AdminPublishersApiGetPublisherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public getPublisher(requestParameters: AdminPublishersApiGetPublisherRequest, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).getPublisher(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publishers
     * @param {AdminPublishersApiGetPublishersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public getPublishers(requestParameters: AdminPublishersApiGetPublishersRequest = {}, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).getPublishers(requestParameters.name, requestParameters.email, requestParameters.publisherType, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publishers Managers
     * @param {AdminPublishersApiGetPublishersManagersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public getPublishersManagers(requestParameters: AdminPublishersApiGetPublishersManagersRequest = {}, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).getPublishersManagers(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publishers with pagination
     * @param {AdminPublishersApiGetPublishersPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public getPublishersPaginated(requestParameters: AdminPublishersApiGetPublishersPaginatedRequest = {}, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).getPublishersPaginated(requestParameters.page, requestParameters.perPage, requestParameters.name, requestParameters.email, requestParameters.publisherType, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publishers Export
     * @param {AdminPublishersApiPublishersExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public publishersExport(requestParameters: AdminPublishersApiPublishersExportRequest = {}, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).publishersExport(requestParameters.name, requestParameters.publisherType, requestParameters.email, requestParameters.publisherManagerIds, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Publisher
     * @param {AdminPublishersApiUpdatePublisherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublishersApi
     */
    public updatePublisher(requestParameters: AdminPublishersApiUpdatePublisherRequest, options?: AxiosRequestConfig) {
        return AdminPublishersApiFp(this.configuration).updatePublisher(requestParameters.publisherId, requestParameters.adminUpdatePublisherRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
