/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminStoreRegionRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateRegionRequest } from '../AfClApiModels';
// @ts-ignore
import { Region } from '../AfClApiModels';
/**
 * AdminRegionsApi - axios parameter creator
 * @export
 */
export const AdminRegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Region
         * @param {number} regionId Numeric ID of the Region to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRegion: async (regionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('adminGetRegion', 'regionId', regionId)
            const localVarPath = `/v1/admin/regions/{regionId}`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Regions
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRegions: async (parentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Root Region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRootRegion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/regions/root`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move Region from Parent
         * @param {number} regionId Numeric ID of the region to move
         * @param {number} parentId Numeric ID of the new parent region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveRegion: async (regionId: number, parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('adminMoveRegion', 'regionId', regionId)
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('adminMoveRegion', 'parentId', parentId)
            const localVarPath = `/v1/admin/regions/{regionId}/move/{parentId}`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Region
         * @param {AdminStoreRegionRequest} [adminStoreRegionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoreRegion: async (adminStoreRegionRequest?: AdminStoreRegionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreRegionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Region
         * @param {number} regionId Numeric ID of the Region to find
         * @param {AdminUpdateRegionRequest} [adminUpdateRegionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateRegion: async (regionId: number, adminUpdateRegionRequest?: AdminUpdateRegionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('adminUpdateRegion', 'regionId', regionId)
            const localVarPath = `/v1/admin/regions/{regionId}`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateRegionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRegionsApi - functional programming interface
 * @export
 */
export const AdminRegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Region
         * @param {number} regionId Numeric ID of the Region to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRegion(regionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRegion(regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Regions
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRegions(parentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRegions(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Root Region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetRootRegion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetRootRegion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move Region from Parent
         * @param {number} regionId Numeric ID of the region to move
         * @param {number} parentId Numeric ID of the new parent region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMoveRegion(regionId: number, parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMoveRegion(regionId, parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Region
         * @param {AdminStoreRegionRequest} [adminStoreRegionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoreRegion(adminStoreRegionRequest?: AdminStoreRegionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoreRegion(adminStoreRegionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Region
         * @param {number} regionId Numeric ID of the Region to find
         * @param {AdminUpdateRegionRequest} [adminUpdateRegionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateRegion(regionId: number, adminUpdateRegionRequest?: AdminUpdateRegionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateRegion(regionId, adminUpdateRegionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRegionsApi - factory interface
 * @export
 */
export const AdminRegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRegionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Region
         * @param {AdminRegionsApiAdminGetRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRegion(requestParameters: AdminRegionsApiAdminGetRegionRequest, options?: AxiosRequestConfig): AxiosPromise<Region> {
            return localVarFp.adminGetRegion(requestParameters.regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Regions
         * @param {AdminRegionsApiAdminGetRegionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRegions(requestParameters: AdminRegionsApiAdminGetRegionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Region>> {
            return localVarFp.adminGetRegions(requestParameters.parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Root Region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetRootRegion(options?: AxiosRequestConfig): AxiosPromise<Region> {
            return localVarFp.adminGetRootRegion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move Region from Parent
         * @param {AdminRegionsApiAdminMoveRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMoveRegion(requestParameters: AdminRegionsApiAdminMoveRegionRequest, options?: AxiosRequestConfig): AxiosPromise<Region> {
            return localVarFp.adminMoveRegion(requestParameters.regionId, requestParameters.parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Region
         * @param {AdminRegionsApiAdminStoreRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoreRegion(requestParameters: AdminRegionsApiAdminStoreRegionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Region> {
            return localVarFp.adminStoreRegion(requestParameters.adminStoreRegionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Region
         * @param {AdminRegionsApiAdminUpdateRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateRegion(requestParameters: AdminRegionsApiAdminUpdateRegionRequest, options?: AxiosRequestConfig): AxiosPromise<Region> {
            return localVarFp.adminUpdateRegion(requestParameters.regionId, requestParameters.adminUpdateRegionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminGetRegion operation in AdminRegionsApi.
 * @export
 * @interface AdminRegionsApiAdminGetRegionRequest
 */
export interface AdminRegionsApiAdminGetRegionRequest {
    /**
     * Numeric ID of the Region to find
     * @type {number}
     * @memberof AdminRegionsApiAdminGetRegion
     */
    readonly regionId: number
}

/**
 * Request parameters for adminGetRegions operation in AdminRegionsApi.
 * @export
 * @interface AdminRegionsApiAdminGetRegionsRequest
 */
export interface AdminRegionsApiAdminGetRegionsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminRegionsApiAdminGetRegions
     */
    readonly parentId?: number
}

/**
 * Request parameters for adminMoveRegion operation in AdminRegionsApi.
 * @export
 * @interface AdminRegionsApiAdminMoveRegionRequest
 */
export interface AdminRegionsApiAdminMoveRegionRequest {
    /**
     * Numeric ID of the region to move
     * @type {number}
     * @memberof AdminRegionsApiAdminMoveRegion
     */
    readonly regionId: number

    /**
     * Numeric ID of the new parent region
     * @type {number}
     * @memberof AdminRegionsApiAdminMoveRegion
     */
    readonly parentId: number
}

/**
 * Request parameters for adminStoreRegion operation in AdminRegionsApi.
 * @export
 * @interface AdminRegionsApiAdminStoreRegionRequest
 */
export interface AdminRegionsApiAdminStoreRegionRequest {
    /**
     * 
     * @type {AdminStoreRegionRequest}
     * @memberof AdminRegionsApiAdminStoreRegion
     */
    readonly adminStoreRegionRequest?: AdminStoreRegionRequest
}

/**
 * Request parameters for adminUpdateRegion operation in AdminRegionsApi.
 * @export
 * @interface AdminRegionsApiAdminUpdateRegionRequest
 */
export interface AdminRegionsApiAdminUpdateRegionRequest {
    /**
     * Numeric ID of the Region to find
     * @type {number}
     * @memberof AdminRegionsApiAdminUpdateRegion
     */
    readonly regionId: number

    /**
     * 
     * @type {AdminUpdateRegionRequest}
     * @memberof AdminRegionsApiAdminUpdateRegion
     */
    readonly adminUpdateRegionRequest?: AdminUpdateRegionRequest
}

/**
 * AdminRegionsApi - object-oriented interface
 * @export
 * @class AdminRegionsApi
 * @extends {BaseAPI}
 */
export class AdminRegionsApi extends BaseAPI {
    /**
     * 
     * @summary Get Region
     * @param {AdminRegionsApiAdminGetRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegionsApi
     */
    public adminGetRegion(requestParameters: AdminRegionsApiAdminGetRegionRequest, options?: AxiosRequestConfig) {
        return AdminRegionsApiFp(this.configuration).adminGetRegion(requestParameters.regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Regions
     * @param {AdminRegionsApiAdminGetRegionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegionsApi
     */
    public adminGetRegions(requestParameters: AdminRegionsApiAdminGetRegionsRequest = {}, options?: AxiosRequestConfig) {
        return AdminRegionsApiFp(this.configuration).adminGetRegions(requestParameters.parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Root Region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegionsApi
     */
    public adminGetRootRegion(options?: AxiosRequestConfig) {
        return AdminRegionsApiFp(this.configuration).adminGetRootRegion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move Region from Parent
     * @param {AdminRegionsApiAdminMoveRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegionsApi
     */
    public adminMoveRegion(requestParameters: AdminRegionsApiAdminMoveRegionRequest, options?: AxiosRequestConfig) {
        return AdminRegionsApiFp(this.configuration).adminMoveRegion(requestParameters.regionId, requestParameters.parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Region
     * @param {AdminRegionsApiAdminStoreRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegionsApi
     */
    public adminStoreRegion(requestParameters: AdminRegionsApiAdminStoreRegionRequest = {}, options?: AxiosRequestConfig) {
        return AdminRegionsApiFp(this.configuration).adminStoreRegion(requestParameters.adminStoreRegionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Region
     * @param {AdminRegionsApiAdminUpdateRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegionsApi
     */
    public adminUpdateRegion(requestParameters: AdminRegionsApiAdminUpdateRegionRequest, options?: AxiosRequestConfig) {
        return AdminRegionsApiFp(this.configuration).adminUpdateRegion(requestParameters.regionId, requestParameters.adminUpdateRegionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
