import { Locales } from '@/Locales/locales';
import { useCookies } from '@vueuse/integrations/useCookies';
import en from '@/Locales/Translations/en.json';
import sv from '@/Locales/Translations/sv.json';
import ja from '@/Locales/Translations/ja.json';
import fi from '@/Locales/Translations/fi.json';
import pt from '@/Locales/Translations/pt.json';
import es from '@/Locales/Translations/es.json';
import { getBaseLocale } from '@/Utils/BrowserHelper';

export const messages = {
    [Locales.EN]: en,
    [Locales.SV]: sv,
    [Locales.JA]: ja,
    [Locales.FI]: fi,
    [Locales.PT]: pt,
    [Locales.ES]: es,
};

const cookies = useCookies(['locale']);

export const defaultLocale = cookies.get('locale') ?? getBaseLocale();
