/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPublisherAccount } from '../AfClApiModels';
// @ts-ignore
import { AdminStorePublisherUsersRequest } from '../AfClApiModels';
/**
 * AdminPublisherUsersApi - axios parameter creator
 * @export
 */
export const AdminPublisherUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Publisher User
         * @param {number} publisherId Numeric ID of the publisher
         * @param {AdminStorePublisherUsersRequest} [adminStorePublisherUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherUser: async (publisherId: number, adminStorePublisherUsersRequest?: AdminStorePublisherUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createPublisherUser', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/users`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStorePublisherUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Publisher User
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} authId Numeric ID of the auth user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherUser: async (publisherId: number, authId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('deletePublisherUser', 'publisherId', publisherId)
            // verify required parameter 'authId' is not null or undefined
            assertParamExists('deletePublisherUser', 'authId', authId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/users/{authId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"authId"}}`, encodeURIComponent(String(authId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublisherUsersApi - functional programming interface
 * @export
 */
export const AdminPublisherUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublisherUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Publisher User
         * @param {number} publisherId Numeric ID of the publisher
         * @param {AdminStorePublisherUsersRequest} [adminStorePublisherUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisherUser(publisherId: number, adminStorePublisherUsersRequest?: AdminStorePublisherUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisherUser(publisherId, adminStorePublisherUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Publisher User
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} authId Numeric ID of the auth user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePublisherUser(publisherId: number, authId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePublisherUser(publisherId, authId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublisherUsersApi - factory interface
 * @export
 */
export const AdminPublisherUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublisherUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Publisher User
         * @param {AdminPublisherUsersApiCreatePublisherUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherUser(requestParameters: AdminPublisherUsersApiCreatePublisherUserRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherAccount> {
            return localVarFp.createPublisherUser(requestParameters.publisherId, requestParameters.adminStorePublisherUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Publisher User
         * @param {AdminPublisherUsersApiDeletePublisherUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherUser(requestParameters: AdminPublisherUsersApiDeletePublisherUserRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherAccount> {
            return localVarFp.deletePublisherUser(requestParameters.publisherId, requestParameters.authId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPublisherUser operation in AdminPublisherUsersApi.
 * @export
 * @interface AdminPublisherUsersApiCreatePublisherUserRequest
 */
export interface AdminPublisherUsersApiCreatePublisherUserRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof AdminPublisherUsersApiCreatePublisherUser
     */
    readonly publisherId: number

    /**
     * 
     * @type {AdminStorePublisherUsersRequest}
     * @memberof AdminPublisherUsersApiCreatePublisherUser
     */
    readonly adminStorePublisherUsersRequest?: AdminStorePublisherUsersRequest
}

/**
 * Request parameters for deletePublisherUser operation in AdminPublisherUsersApi.
 * @export
 * @interface AdminPublisherUsersApiDeletePublisherUserRequest
 */
export interface AdminPublisherUsersApiDeletePublisherUserRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof AdminPublisherUsersApiDeletePublisherUser
     */
    readonly publisherId: number

    /**
     * Numeric ID of the auth user
     * @type {number}
     * @memberof AdminPublisherUsersApiDeletePublisherUser
     */
    readonly authId: number
}

/**
 * AdminPublisherUsersApi - object-oriented interface
 * @export
 * @class AdminPublisherUsersApi
 * @extends {BaseAPI}
 */
export class AdminPublisherUsersApi extends BaseAPI {
    /**
     * 
     * @summary Create Publisher User
     * @param {AdminPublisherUsersApiCreatePublisherUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherUsersApi
     */
    public createPublisherUser(requestParameters: AdminPublisherUsersApiCreatePublisherUserRequest, options?: AxiosRequestConfig) {
        return AdminPublisherUsersApiFp(this.configuration).createPublisherUser(requestParameters.publisherId, requestParameters.adminStorePublisherUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Publisher User
     * @param {AdminPublisherUsersApiDeletePublisherUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherUsersApi
     */
    public deletePublisherUser(requestParameters: AdminPublisherUsersApiDeletePublisherUserRequest, options?: AxiosRequestConfig) {
        return AdminPublisherUsersApiFp(this.configuration).deletePublisherUser(requestParameters.publisherId, requestParameters.authId, options).then((request) => request(this.axios, this.basePath));
    }
}
