<template>
    <div>
        <slot name="title">
            <h2
                class="border-rt-yellow border-b-4 max-w-fit font-bold"
                :class="[sizes[size], dark ? 'text-white' : 'text-dark']"
            >
                <slot />
            </h2>
        </slot>
        <slot name="description">
            <p
                v-if="description"
                class="mt-2 text-gray-500"
            >
                {{ description }}
            </p>
        </slot>
    </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
    size?: 'sm' | 'md' | 'lg',
    dark?: boolean,
    description?: string | null
}>(), {
    size: 'lg',
    dark: false,
    description: undefined
});

defineSlots<{
    title?: () => void,
    default?: () => void,
    description?: () => void,
}>();

const sizes = {
    'sm': 'text-xl',
    'md': 'text-2xl',
    'lg': 'text-4xl'
};

</script>
