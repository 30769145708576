<script setup lang="ts" generic="T">
import BaseButton from '@/Components/Core/BaseButton.vue';
import { TableHeader } from '@/Models/Components/BaseTableContract';
import withCurrency from '@/Utils/Filters/WithCurrency.filter';
import { computed, h } from 'vue';

const {
    headers = [],
    item = null,
    stickyColumn = false,
    rowClass = '',
    cellClass = null,
    dark = false,
} = defineProps<{
    // eslint-disable-next-line no-undef
    headers: TableHeader<T>[],
    // eslint-disable-next-line no-undef
    item: T
    stickyColumn?: boolean,
    rowClass?: string
    cellClass?: string,
    dark?: boolean
}>();

const columns = computed(() => headers.filter((header) => !header.action));
const actions = computed(() => headers.filter((header) => Boolean(header.action)) ?? []);

// eslint-disable-next-line no-undef
const CellWrapper = ({ column }: { column: TableHeader<T> }, { slots }) => {
    const rawValue = item && item[column.key as string];
    let isNumeric = false;
    let fallbackAlignment: typeof column.forceAlign = 'start';
    try {
        const parsedInt = Number(rawValue);
        isNumeric = !isNaN(parsedInt) && isFinite(parsedInt);
    } catch (_e) {
        isNumeric = false;
    }

    // If item value is numeric by nature, we align it to the right. Force align still can take over
    if (isNumeric) {
        fallbackAlignment = 'end';
    }

    return h('div', { class: `flex gap-2 items-center justify-${column.forceAlign ?? fallbackAlignment}` }, slots.default && slots.default());
};

</script>
<template>
    <tr :class="rowClass">
        <template
            v-for="(column, index) in columns"
            :key="column.key"
        >
            <td
                class="base-table-cell"
                :class="[{ 'rt-sticky': stickyColumn && index === 0 }, { 'dark': dark }, cellClass]"
            >
                <CellWrapper :column>
                    <slot
                        :name="column.key"
                        v-bind="{ ...column, column: item[column.key as string] ?? [] }"
                    >
                        <template v-if="column.currency">
                            {{ withCurrency(item[column.key as string], column.currency) }}
                        </template>
                        <template v-else>
                            {{ item[column.key as string] }}
                        </template>
                    </slot>
                </CellWrapper>
            </td>
        </template>
        <template v-if="actions.length > 0">
            <td
                v-for="actionHeader in actions"
                :key="actionHeader.key"
                class="base-table-cell"
            >
                <CellWrapper :column="actionHeader">
                    <slot
                        :name="`action_${actionHeader.key as string}`"
                        :other-prop="null"
                        v-bind="{
                            ...actionHeader,
                            action: typeof actionHeader.action !== 'boolean' ? actionHeader.action : {}
                        }"
                    >
                        <template v-if="typeof actionHeader.action !== 'boolean'">
                            <BaseButton
                                v-if="actionHeader.action?.onClick"
                                v-bind="{ ...actionHeader.action?.buttonProps }"
                                @click="actionHeader.action?.onClick(item as T)"
                            >
                                {{ actionHeader.action?.label ?? actionHeader.label }}
                            </BaseButton>
                            <span v-else>
                                {{ actionHeader.action?.label ?? actionHeader.label }}
                            </span>
                        </template>
                    </slot>
                </CellWrapper>
            </td>
        </template>
        <slot name="last_column" />
    </tr>
</template>
