import { UserType } from '@/Enums/UserType';
import { useAccountStore } from '@/Stores/AccountStore';
import { useTitle } from '@vueuse/core';

export default ((pageTitle: string, type: UserType | null = null) => {
    const title = useTitle();
    const accountStore = useAccountStore();

    let fullTitle = pageTitle;

    if (type && type != UserType.ADMIN && accountStore.selectedAccount?.name) {
        fullTitle = accountStore.selectedAccount?.name + ' - ' + pageTitle;
    } else if(type === UserType.ADMIN) {
        fullTitle = 'Admin - ' + pageTitle;
    }

    title.value = fullTitle;
});
