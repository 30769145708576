/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BrandPostbackResource } from '../AfClApiModels';
// @ts-ignore
import { StoreBrandPostbackRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateBrandPostbackRequest } from '../AfClApiModels';
/**
 * AdminBrandPostbacksApi - axios parameter creator
 * @export
 */
export const AdminBrandPostbacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Postback for Brand
         * @param {number} brandId Numeric ID of the Brand
         * @param {StoreBrandPostbackRequest} [storeBrandPostbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrandPostback: async (brandId: number, storeBrandPostbackRequest?: StoreBrandPostbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('createBrandPostback', 'brandId', brandId)
            const localVarPath = `/v1/admin/brands/{brandId}/postbacks`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeBrandPostbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} brandId Numeric ID of the Brand
         * @param {number} brandPostbackId Numeric ID of the BrandPostback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrandPostback: async (brandId: number, brandPostbackId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('deleteBrandPostback', 'brandId', brandId)
            // verify required parameter 'brandPostbackId' is not null or undefined
            assertParamExists('deleteBrandPostback', 'brandPostbackId', brandPostbackId)
            const localVarPath = `/v1/admin/brands/{brandId}/postbacks/{brandPostbackId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"brandPostbackId"}}`, encodeURIComponent(String(brandPostbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} brandId Numeric ID of the Brand
         * @param {number} brandPostbackId Numeric ID of the BrandPostback
         * @param {UpdateBrandPostbackRequest} [updateBrandPostbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrandPostback: async (brandId: number, brandPostbackId: number, updateBrandPostbackRequest?: UpdateBrandPostbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('updateBrandPostback', 'brandId', brandId)
            // verify required parameter 'brandPostbackId' is not null or undefined
            assertParamExists('updateBrandPostback', 'brandPostbackId', brandPostbackId)
            const localVarPath = `/v1/admin/brands/{brandId}/postbacks/{brandPostbackId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)))
                .replace(`{${"brandPostbackId"}}`, encodeURIComponent(String(brandPostbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBrandPostbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBrandPostbacksApi - functional programming interface
 * @export
 */
export const AdminBrandPostbacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBrandPostbacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Postback for Brand
         * @param {number} brandId Numeric ID of the Brand
         * @param {StoreBrandPostbackRequest} [storeBrandPostbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrandPostback(brandId: number, storeBrandPostbackRequest?: StoreBrandPostbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandPostbackResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrandPostback(brandId, storeBrandPostbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} brandId Numeric ID of the Brand
         * @param {number} brandPostbackId Numeric ID of the BrandPostback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrandPostback(brandId: number, brandPostbackId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrandPostback(brandId, brandPostbackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} brandId Numeric ID of the Brand
         * @param {number} brandPostbackId Numeric ID of the BrandPostback
         * @param {UpdateBrandPostbackRequest} [updateBrandPostbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrandPostback(brandId: number, brandPostbackId: number, updateBrandPostbackRequest?: UpdateBrandPostbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandPostbackResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrandPostback(brandId, brandPostbackId, updateBrandPostbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBrandPostbacksApi - factory interface
 * @export
 */
export const AdminBrandPostbacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBrandPostbacksApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Postback for Brand
         * @param {AdminBrandPostbacksApiCreateBrandPostbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrandPostback(requestParameters: AdminBrandPostbacksApiCreateBrandPostbackRequest, options?: AxiosRequestConfig): AxiosPromise<BrandPostbackResource> {
            return localVarFp.createBrandPostback(requestParameters.brandId, requestParameters.storeBrandPostbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminBrandPostbacksApiDeleteBrandPostbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrandPostback(requestParameters: AdminBrandPostbacksApiDeleteBrandPostbackRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBrandPostback(requestParameters.brandId, requestParameters.brandPostbackId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminBrandPostbacksApiUpdateBrandPostbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrandPostback(requestParameters: AdminBrandPostbacksApiUpdateBrandPostbackRequest, options?: AxiosRequestConfig): AxiosPromise<BrandPostbackResource> {
            return localVarFp.updateBrandPostback(requestParameters.brandId, requestParameters.brandPostbackId, requestParameters.updateBrandPostbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createBrandPostback operation in AdminBrandPostbacksApi.
 * @export
 * @interface AdminBrandPostbacksApiCreateBrandPostbackRequest
 */
export interface AdminBrandPostbacksApiCreateBrandPostbackRequest {
    /**
     * Numeric ID of the Brand
     * @type {number}
     * @memberof AdminBrandPostbacksApiCreateBrandPostback
     */
    readonly brandId: number

    /**
     * 
     * @type {StoreBrandPostbackRequest}
     * @memberof AdminBrandPostbacksApiCreateBrandPostback
     */
    readonly storeBrandPostbackRequest?: StoreBrandPostbackRequest
}

/**
 * Request parameters for deleteBrandPostback operation in AdminBrandPostbacksApi.
 * @export
 * @interface AdminBrandPostbacksApiDeleteBrandPostbackRequest
 */
export interface AdminBrandPostbacksApiDeleteBrandPostbackRequest {
    /**
     * Numeric ID of the Brand
     * @type {number}
     * @memberof AdminBrandPostbacksApiDeleteBrandPostback
     */
    readonly brandId: number

    /**
     * Numeric ID of the BrandPostback
     * @type {number}
     * @memberof AdminBrandPostbacksApiDeleteBrandPostback
     */
    readonly brandPostbackId: number
}

/**
 * Request parameters for updateBrandPostback operation in AdminBrandPostbacksApi.
 * @export
 * @interface AdminBrandPostbacksApiUpdateBrandPostbackRequest
 */
export interface AdminBrandPostbacksApiUpdateBrandPostbackRequest {
    /**
     * Numeric ID of the Brand
     * @type {number}
     * @memberof AdminBrandPostbacksApiUpdateBrandPostback
     */
    readonly brandId: number

    /**
     * Numeric ID of the BrandPostback
     * @type {number}
     * @memberof AdminBrandPostbacksApiUpdateBrandPostback
     */
    readonly brandPostbackId: number

    /**
     * 
     * @type {UpdateBrandPostbackRequest}
     * @memberof AdminBrandPostbacksApiUpdateBrandPostback
     */
    readonly updateBrandPostbackRequest?: UpdateBrandPostbackRequest
}

/**
 * AdminBrandPostbacksApi - object-oriented interface
 * @export
 * @class AdminBrandPostbacksApi
 * @extends {BaseAPI}
 */
export class AdminBrandPostbacksApi extends BaseAPI {
    /**
     * 
     * @summary Create Postback for Brand
     * @param {AdminBrandPostbacksApiCreateBrandPostbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandPostbacksApi
     */
    public createBrandPostback(requestParameters: AdminBrandPostbacksApiCreateBrandPostbackRequest, options?: AxiosRequestConfig) {
        return AdminBrandPostbacksApiFp(this.configuration).createBrandPostback(requestParameters.brandId, requestParameters.storeBrandPostbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminBrandPostbacksApiDeleteBrandPostbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandPostbacksApi
     */
    public deleteBrandPostback(requestParameters: AdminBrandPostbacksApiDeleteBrandPostbackRequest, options?: AxiosRequestConfig) {
        return AdminBrandPostbacksApiFp(this.configuration).deleteBrandPostback(requestParameters.brandId, requestParameters.brandPostbackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminBrandPostbacksApiUpdateBrandPostbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBrandPostbacksApi
     */
    public updateBrandPostback(requestParameters: AdminBrandPostbacksApiUpdateBrandPostbackRequest, options?: AxiosRequestConfig) {
        return AdminBrandPostbacksApiFp(this.configuration).updateBrandPostback(requestParameters.brandId, requestParameters.brandPostbackId, requestParameters.updateBrandPostbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
