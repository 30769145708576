/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetSubscriptions200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminSubscriptionHistory } from '../AfClApiModels';
// @ts-ignore
import { AdminSubscriptionResource } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateSubscriptionRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateSubscriptionDealRequest } from '../AfClApiModels';
/**
 * AdminSubscriptionsApi - axios parameter creator
 * @export
 */
export const AdminSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get subscriptions
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubscriptions: async (publisherType?: 'INTERNAL' | 'EXTERNAL', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (redirectLinkRequestStatus !== undefined) {
                localVarQueryParameter['redirect_link_request_status'] = redirectLinkRequestStatus;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Subscription Deals History
         * @param {number} subscriptionId Numeric ID of the subscription to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionHistory: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getSubscriptionHistory', 'subscriptionId', subscriptionId)
            const localVarPath = `/v1/admin/subscriptions/{subscriptionId}/history`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Subscription
         * @param {number} subscriptionId Numeric ID of the subscription to find
         * @param {AdminUpdateSubscriptionRequest} [adminUpdateSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (subscriptionId: number, adminUpdateSubscriptionRequest?: AdminUpdateSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('updateSubscription', 'subscriptionId', subscriptionId)
            const localVarPath = `/v1/admin/subscriptions/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Subscription Deal and Margin
         * @param {UpdateSubscriptionDealRequest} [updateSubscriptionDealRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionDeal: async (updateSubscriptionDealRequest?: UpdateSubscriptionDealRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/subscriptions/deal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionDealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSubscriptionsApi - functional programming interface
 * @export
 */
export const AdminSubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get subscriptions
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSubscriptions(publisherType?: 'INTERNAL' | 'EXTERNAL', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSubscriptions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSubscriptions(publisherType, redirectLinkRequestStatus, programIds, siteIds, programManagerIds, regionIds, verticalIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Subscription Deals History
         * @param {number} subscriptionId Numeric ID of the subscription to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionHistory(subscriptionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminSubscriptionHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionHistory(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Subscription
         * @param {number} subscriptionId Numeric ID of the subscription to find
         * @param {AdminUpdateSubscriptionRequest} [adminUpdateSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(subscriptionId: number, adminUpdateSubscriptionRequest?: AdminUpdateSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSubscriptionResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(subscriptionId, adminUpdateSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Subscription Deal and Margin
         * @param {UpdateSubscriptionDealRequest} [updateSubscriptionDealRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionDeal(updateSubscriptionDealRequest?: UpdateSubscriptionDealRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionDeal(updateSubscriptionDealRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSubscriptionsApi - factory interface
 * @export
 */
export const AdminSubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get subscriptions
         * @param {AdminSubscriptionsApiAdminGetSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSubscriptions(requestParameters: AdminSubscriptionsApiAdminGetSubscriptionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetSubscriptions200Response> {
            return localVarFp.adminGetSubscriptions(requestParameters.publisherType, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Subscription Deals History
         * @param {AdminSubscriptionsApiGetSubscriptionHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionHistory(requestParameters: AdminSubscriptionsApiGetSubscriptionHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AdminSubscriptionHistory>> {
            return localVarFp.getSubscriptionHistory(requestParameters.subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Subscription
         * @param {AdminSubscriptionsApiUpdateSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(requestParameters: AdminSubscriptionsApiUpdateSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSubscriptionResource> {
            return localVarFp.updateSubscription(requestParameters.subscriptionId, requestParameters.adminUpdateSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Subscription Deal and Margin
         * @param {AdminSubscriptionsApiUpdateSubscriptionDealRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionDeal(requestParameters: AdminSubscriptionsApiUpdateSubscriptionDealRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateSubscriptionDeal(requestParameters.updateSubscriptionDealRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminGetSubscriptions operation in AdminSubscriptionsApi.
 * @export
 * @interface AdminSubscriptionsApiAdminGetSubscriptionsRequest
 */
export interface AdminSubscriptionsApiAdminGetSubscriptionsRequest {
    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionsApiAdminGetSubscriptions
     */
    readonly perPage?: number
}

/**
 * Request parameters for getSubscriptionHistory operation in AdminSubscriptionsApi.
 * @export
 * @interface AdminSubscriptionsApiGetSubscriptionHistoryRequest
 */
export interface AdminSubscriptionsApiGetSubscriptionHistoryRequest {
    /**
     * Numeric ID of the subscription to find
     * @type {number}
     * @memberof AdminSubscriptionsApiGetSubscriptionHistory
     */
    readonly subscriptionId: number
}

/**
 * Request parameters for updateSubscription operation in AdminSubscriptionsApi.
 * @export
 * @interface AdminSubscriptionsApiUpdateSubscriptionRequest
 */
export interface AdminSubscriptionsApiUpdateSubscriptionRequest {
    /**
     * Numeric ID of the subscription to find
     * @type {number}
     * @memberof AdminSubscriptionsApiUpdateSubscription
     */
    readonly subscriptionId: number

    /**
     * 
     * @type {AdminUpdateSubscriptionRequest}
     * @memberof AdminSubscriptionsApiUpdateSubscription
     */
    readonly adminUpdateSubscriptionRequest?: AdminUpdateSubscriptionRequest
}

/**
 * Request parameters for updateSubscriptionDeal operation in AdminSubscriptionsApi.
 * @export
 * @interface AdminSubscriptionsApiUpdateSubscriptionDealRequest
 */
export interface AdminSubscriptionsApiUpdateSubscriptionDealRequest {
    /**
     * 
     * @type {UpdateSubscriptionDealRequest}
     * @memberof AdminSubscriptionsApiUpdateSubscriptionDeal
     */
    readonly updateSubscriptionDealRequest?: UpdateSubscriptionDealRequest
}

/**
 * AdminSubscriptionsApi - object-oriented interface
 * @export
 * @class AdminSubscriptionsApi
 * @extends {BaseAPI}
 */
export class AdminSubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Get subscriptions
     * @param {AdminSubscriptionsApiAdminGetSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public adminGetSubscriptions(requestParameters: AdminSubscriptionsApiAdminGetSubscriptionsRequest = {}, options?: AxiosRequestConfig) {
        return AdminSubscriptionsApiFp(this.configuration).adminGetSubscriptions(requestParameters.publisherType, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Subscription Deals History
     * @param {AdminSubscriptionsApiGetSubscriptionHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public getSubscriptionHistory(requestParameters: AdminSubscriptionsApiGetSubscriptionHistoryRequest, options?: AxiosRequestConfig) {
        return AdminSubscriptionsApiFp(this.configuration).getSubscriptionHistory(requestParameters.subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Subscription
     * @param {AdminSubscriptionsApiUpdateSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public updateSubscription(requestParameters: AdminSubscriptionsApiUpdateSubscriptionRequest, options?: AxiosRequestConfig) {
        return AdminSubscriptionsApiFp(this.configuration).updateSubscription(requestParameters.subscriptionId, requestParameters.adminUpdateSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Subscription Deal and Margin
     * @param {AdminSubscriptionsApiUpdateSubscriptionDealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public updateSubscriptionDeal(requestParameters: AdminSubscriptionsApiUpdateSubscriptionDealRequest = {}, options?: AxiosRequestConfig) {
        return AdminSubscriptionsApiFp(this.configuration).updateSubscriptionDeal(requestParameters.updateSubscriptionDealRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
