import { useI18n } from 'vue-i18n';
import { createI18nMessage, required, requiredIf } from '@vuelidate/validators';

export const useValidateMessage = () => {

    const { t } = useI18n();

    const messagePath = ({ $validator }) => {
        return `validations.${$validator}`;
    };

    const withI18nMessage = createI18nMessage({ t, messagePath });

    return {
        required: withI18nMessage(required),
        requiredIf: withI18nMessage(requiredIf, { withArguments: true }),
        // TODO: add any others such as maxLength, minValue, etc (as needed)
    };


};
