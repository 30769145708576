import { AfClApi as OAAfClApi } from '@/stub/AfClApi';
import axios, { AxiosError } from 'axios';
import { ErrorContract } from '@/Models/Errors/ErrorContract';
import { useToast } from 'vue-toastification';

const toast = useToast();

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

axios.interceptors.response.use(
    res => res,
    (error: AxiosError<ErrorContract>) => {
        if (error.response?.status === 401) {
            // Go to login page
            if (window.location?.pathname?.split('/')?.length !== 0) {
                window.location.href = window.location.origin;
            }
        }
        else if (error.response?.status === 403) {
            displayErrors('Access Denied');
        }
        else if (error?.code == 'ERR_CANCELED') {
            // Do nothing if the request was canceled
        }
        else {
            displayErrors(error.response?.data?.errors);
        }
        return Promise.reject(error);
    }
);

export const AfClApi = new OAAfClApi(undefined, API_BASE_URL, axios);

export const setAccessToken = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const displayErrors = (errors) => {
    if(errors) {
        if(typeof errors === 'object') {
            Object.values(errors).flat(1).forEach(x => toast.error(x));
        }else if(Array.isArray(errors) === true) {
            errors.forEach(x => toast.error(x));
        }else {
            toast.error(errors);
        }
    }else {
        toast.error('Something went wrong');
    }
};
