<template>
    <BaseModal
        v-model:is-opened="opened"
        :title="isEditMode ? 'Update Key' : 'Create key'"
    >
        <template #content>
            <BaseInput
                v-model="form.name"
                label="Campaign name *"
                :vld-errors="v$.name.$errors"
            />
            <SelectLayout
                label="Start date *"
                class="mt-3"
                :vld-errors="v$.start_at.$errors"
            >
                <VueDatePicker
                    v-model="form.start_at"
                    placeholder="Select date"
                    class="w-full"
                    :enable-time-picker="false"
                    format="dd/MM/yyyy"
                />
            </SelectLayout>
            <SelectLayout
                label="End date"
                class="mt-3"
            >
                <VueDatePicker
                    v-model="form.end_at"
                    placeholder="Select date"
                    class="w-full"
                    :enable-time-picker="false"
                    format="dd/MM/yyyy"
                />
            </SelectLayout>
        </template>
        <template #actions>
            <BaseButton
                class="w-full"
                variant="primary"
                :loading="campaignKeyLoading || campaignKeyIsCreating"
                @click="updateCampaignKey"
            >
                {{ isEditMode ? 'Update' : 'Create' }}
            </BaseButton>
        </template>
    </BaseModal>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core';
import BaseModal from '@/Components/Core/BaseModal.vue';
import BaseButton from '@/Components/Core/BaseButton.vue';
import { computed, reactive, watch } from 'vue';
import BaseInput from '@/Components/Core/Form/BaseInput.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useCampaignKeyUpdate } from '@/Services/ApiHooks/Admin/Keys/UseCampaignKeyUpdate';
import { format, parse } from 'date-fns';
import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import { CampaignKey } from '@/stub';
import SelectLayout from '@/Components/Core/Form/SelectLayout.vue';
import { useCampaignKeyCreate } from '@/Services/ApiHooks/Admin/Keys/UseCampaignKeyCreate';
import { useValidateMessage } from '@/Utils/ValidateMessageHelper';

const props = defineProps<{
    modelValue: boolean,
    selectedKey: CampaignKey | undefined
}>();

const emit = defineEmits(['update:modelValue']);
const { modelValue: opened } = useVModels(props, emit);
const isEditMode = computed(() => !!props.selectedKey);
const toast = useToast();
const { required } = useValidateMessage();

const form = reactive<{
    name: string | null,
    start_at: Date,
    end_at: Date | null
}>({
    name: props?.selectedKey?.name ?? null,
    start_at: props?.selectedKey?.start_at
        ? parse(props.selectedKey.start_at, 'yyyy-MM-dd', new Date())
        : new Date(),
    end_at: props?.selectedKey?.end_at
        ? parse(props.selectedKey.end_at, 'yyyy-MM-dd', new Date())
        : null,
});

watch(() => props.selectedKey, (newKey) => {
    if (newKey) {
        const { name, end_at, start_at } = newKey;
        form.name = name;
        form.start_at = parse(start_at, 'yyyy-MM-dd', new Date());
        form.end_at = end_at ? parse(end_at, 'yyyy-MM-dd', new Date()) : null;
    } else {
        form.name = null;
        form.start_at = new Date();
    }
});

const rules = {
    name: { required },
    start_at: { required }
};
const v$ = useVuelidate(rules, form);

const {
    mutate: campaignKeyCreate,
    isLoading: campaignKeyIsCreating
} = useCampaignKeyCreate(() => {
    opened.value = false;
    toast.success('Source key successfully created');
});

const {
    mutate: campaignKeyUpdate,
    isLoading: campaignKeyLoading
} = useCampaignKeyUpdate(() => {
    opened.value = false;
    toast.success('Program license successfully created');
});

const updateCampaignKey = async () => {
    const isFormValid = await v$.value.$validate();
    const { start_at, name, end_at } = form;

    if(isEditMode.value && isFormValid) {
        let id = props.selectedKey?.id;
        campaignKeyUpdate({
            id: id!,
            name: name!,
            start_at: format(start_at, 'yyyy-MM-dd'),
            end_at: end_at ? format(end_at, 'yyyy-MM-dd') : null,
        });
    }else if(isFormValid) {
        campaignKeyCreate({
            name: name!,
            start_at: format(start_at, 'yyyy-MM-dd'),
            end_at: end_at ? format(end_at, 'yyyy-MM-dd') : null
        });
    }
};
</script>
