<template>
    <router-link
        v-if="to"
        :class="generatedClass"
        :to
    >
        <template v-if="loading">
            <ButtonSpinner />
            Loading...
        </template>
        <span
            v-else
            class="flex flex-col items-center justify-center text-inherit"
        >
            <slot />
        </span>
    </router-link>
    <button
        v-else
        :class="generatedClass"
        :disabled
        :title="title ?? ''"
    >
        <template v-if="loading">
            <ButtonSpinner />
            {{ $t('general.loading') }}
        </template>
        <span
            v-else
            class="flex flex-col items-center justify-center text-inherit"
        >
            <slot />
        </span>
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ButtonSpinner from '@/Components/Core/ButtonSpinner/ButtonSpinner.vue';
import { twMerge } from 'tailwind-merge';
import { ButtonProps, buttonSize, buttonVariants, buttonDefaults } from '@/Models/Components/BaseButton';

const props = withDefaults(defineProps<ButtonProps>(), {
    to: null,
    variant: 'primary',
    size: 'medium',
    disabled: false,
    title: null,
    customClass: null,
});

const generatedClass = computed(() => twMerge([
    buttonDefaults['general'],
    buttonDefaults['borders'],
    buttonDefaults['transitions'],
    buttonDefaults['text'],
    buttonSize[props.size],
    (props.disabled || props.loading) && buttonDefaults['disabled'],
    buttonVariants[props.variant],
    !props.noCaps && 'uppercase',
    props.customClass
]));
</script>
