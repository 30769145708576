/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherSiteProgram } from '../AfClApiModels';
// @ts-ignore
import { SiteToplists } from '../AfClApiModels';
// @ts-ignore
import { StoreSiteTopListRequest } from '../AfClApiModels';
// @ts-ignore
import { TopListResource } from '../AfClApiModels';
// @ts-ignore
import { UpdateSiteTopListRequest } from '../AfClApiModels';
/**
 * SiteTopListsApi - axios parameter creator
 * @export
 */
export const SiteTopListsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Site TopList
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {StoreSiteTopListRequest} [storeSiteTopListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSiteTopList: async (publisherId: number, storeSiteTopListRequest?: StoreSiteTopListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createSiteTopList', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeSiteTopListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete SiteTopList
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSiteTopList: async (publisherId: number, toplistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('deleteSiteTopList', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('deleteSiteTopList', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TopList Available Programs
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePrograms: async (publisherId: number, toplistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getAvailablePrograms', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('getAvailablePrograms', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}/available-programs`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Site TopList hidden items
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHiddenTopListItems: async (publisherId: number, toplistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getHiddenTopListItems', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('getHiddenTopListItems', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}/hidden`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Site TopList Detail
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteTopList: async (publisherId: number, toplistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getSiteTopList', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('getSiteTopList', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Site TopLists
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteTopLists: async (publisherId: number, siteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getSiteTopLists', 'publisherId', publisherId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteTopLists', 'siteId', siteId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Site TopList
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {UpdateSiteTopListRequest} [updateSiteTopListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteTopList: async (publisherId: number, toplistId: number, updateSiteTopListRequest?: UpdateSiteTopListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updateSiteTopList', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('updateSiteTopList', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSiteTopListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteTopListsApi - functional programming interface
 * @export
 */
export const SiteTopListsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteTopListsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Site TopList
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {StoreSiteTopListRequest} [storeSiteTopListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSiteTopList(publisherId: number, storeSiteTopListRequest?: StoreSiteTopListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopListResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSiteTopList(publisherId, storeSiteTopListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete SiteTopList
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSiteTopList(publisherId: number, toplistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSiteTopList(publisherId, toplistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TopList Available Programs
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailablePrograms(publisherId: number, toplistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherSiteProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailablePrograms(publisherId, toplistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Site TopList hidden items
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHiddenTopListItems(publisherId: number, toplistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherSiteProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHiddenTopListItems(publisherId, toplistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Site TopList Detail
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteTopList(publisherId: number, toplistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopListResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteTopList(publisherId, toplistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Site TopLists
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteTopLists(publisherId: number, siteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteToplists>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteTopLists(publisherId, siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Site TopList
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} toplistId Numeric ID of the top list
         * @param {UpdateSiteTopListRequest} [updateSiteTopListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSiteTopList(publisherId: number, toplistId: number, updateSiteTopListRequest?: UpdateSiteTopListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopListResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSiteTopList(publisherId, toplistId, updateSiteTopListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteTopListsApi - factory interface
 * @export
 */
export const SiteTopListsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteTopListsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Site TopList
         * @param {SiteTopListsApiCreateSiteTopListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSiteTopList(requestParameters: SiteTopListsApiCreateSiteTopListRequest, options?: AxiosRequestConfig): AxiosPromise<TopListResource> {
            return localVarFp.createSiteTopList(requestParameters.publisherId, requestParameters.storeSiteTopListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete SiteTopList
         * @param {SiteTopListsApiDeleteSiteTopListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSiteTopList(requestParameters: SiteTopListsApiDeleteSiteTopListRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSiteTopList(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TopList Available Programs
         * @param {SiteTopListsApiGetAvailableProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePrograms(requestParameters: SiteTopListsApiGetAvailableProgramsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherSiteProgram>> {
            return localVarFp.getAvailablePrograms(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Site TopList hidden items
         * @param {SiteTopListsApiGetHiddenTopListItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHiddenTopListItems(requestParameters: SiteTopListsApiGetHiddenTopListItemsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherSiteProgram>> {
            return localVarFp.getHiddenTopListItems(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Site TopList Detail
         * @param {SiteTopListsApiGetSiteTopListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteTopList(requestParameters: SiteTopListsApiGetSiteTopListRequest, options?: AxiosRequestConfig): AxiosPromise<TopListResource> {
            return localVarFp.getSiteTopList(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Site TopLists
         * @param {SiteTopListsApiGetSiteTopListsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteTopLists(requestParameters: SiteTopListsApiGetSiteTopListsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SiteToplists>> {
            return localVarFp.getSiteTopLists(requestParameters.publisherId, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Site TopList
         * @param {SiteTopListsApiUpdateSiteTopListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteTopList(requestParameters: SiteTopListsApiUpdateSiteTopListRequest, options?: AxiosRequestConfig): AxiosPromise<TopListResource> {
            return localVarFp.updateSiteTopList(requestParameters.publisherId, requestParameters.toplistId, requestParameters.updateSiteTopListRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSiteTopList operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiCreateSiteTopListRequest
 */
export interface SiteTopListsApiCreateSiteTopListRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiCreateSiteTopList
     */
    readonly publisherId: number

    /**
     * 
     * @type {StoreSiteTopListRequest}
     * @memberof SiteTopListsApiCreateSiteTopList
     */
    readonly storeSiteTopListRequest?: StoreSiteTopListRequest
}

/**
 * Request parameters for deleteSiteTopList operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiDeleteSiteTopListRequest
 */
export interface SiteTopListsApiDeleteSiteTopListRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiDeleteSiteTopList
     */
    readonly publisherId: number

    /**
     * Numeric ID of the top list
     * @type {number}
     * @memberof SiteTopListsApiDeleteSiteTopList
     */
    readonly toplistId: number
}

/**
 * Request parameters for getAvailablePrograms operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiGetAvailableProgramsRequest
 */
export interface SiteTopListsApiGetAvailableProgramsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiGetAvailablePrograms
     */
    readonly publisherId: number

    /**
     * Numeric ID of the top list
     * @type {number}
     * @memberof SiteTopListsApiGetAvailablePrograms
     */
    readonly toplistId: number
}

/**
 * Request parameters for getHiddenTopListItems operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiGetHiddenTopListItemsRequest
 */
export interface SiteTopListsApiGetHiddenTopListItemsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiGetHiddenTopListItems
     */
    readonly publisherId: number

    /**
     * Numeric ID of the top list
     * @type {number}
     * @memberof SiteTopListsApiGetHiddenTopListItems
     */
    readonly toplistId: number
}

/**
 * Request parameters for getSiteTopList operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiGetSiteTopListRequest
 */
export interface SiteTopListsApiGetSiteTopListRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiGetSiteTopList
     */
    readonly publisherId: number

    /**
     * Numeric ID of the top list
     * @type {number}
     * @memberof SiteTopListsApiGetSiteTopList
     */
    readonly toplistId: number
}

/**
 * Request parameters for getSiteTopLists operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiGetSiteTopListsRequest
 */
export interface SiteTopListsApiGetSiteTopListsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiGetSiteTopLists
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof SiteTopListsApiGetSiteTopLists
     */
    readonly siteId: string
}

/**
 * Request parameters for updateSiteTopList operation in SiteTopListsApi.
 * @export
 * @interface SiteTopListsApiUpdateSiteTopListRequest
 */
export interface SiteTopListsApiUpdateSiteTopListRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof SiteTopListsApiUpdateSiteTopList
     */
    readonly publisherId: number

    /**
     * Numeric ID of the top list
     * @type {number}
     * @memberof SiteTopListsApiUpdateSiteTopList
     */
    readonly toplistId: number

    /**
     * 
     * @type {UpdateSiteTopListRequest}
     * @memberof SiteTopListsApiUpdateSiteTopList
     */
    readonly updateSiteTopListRequest?: UpdateSiteTopListRequest
}

/**
 * SiteTopListsApi - object-oriented interface
 * @export
 * @class SiteTopListsApi
 * @extends {BaseAPI}
 */
export class SiteTopListsApi extends BaseAPI {
    /**
     * 
     * @summary Create Site TopList
     * @param {SiteTopListsApiCreateSiteTopListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public createSiteTopList(requestParameters: SiteTopListsApiCreateSiteTopListRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).createSiteTopList(requestParameters.publisherId, requestParameters.storeSiteTopListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete SiteTopList
     * @param {SiteTopListsApiDeleteSiteTopListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public deleteSiteTopList(requestParameters: SiteTopListsApiDeleteSiteTopListRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).deleteSiteTopList(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TopList Available Programs
     * @param {SiteTopListsApiGetAvailableProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public getAvailablePrograms(requestParameters: SiteTopListsApiGetAvailableProgramsRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).getAvailablePrograms(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Site TopList hidden items
     * @param {SiteTopListsApiGetHiddenTopListItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public getHiddenTopListItems(requestParameters: SiteTopListsApiGetHiddenTopListItemsRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).getHiddenTopListItems(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Site TopList Detail
     * @param {SiteTopListsApiGetSiteTopListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public getSiteTopList(requestParameters: SiteTopListsApiGetSiteTopListRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).getSiteTopList(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Site TopLists
     * @param {SiteTopListsApiGetSiteTopListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public getSiteTopLists(requestParameters: SiteTopListsApiGetSiteTopListsRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).getSiteTopLists(requestParameters.publisherId, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Site TopList
     * @param {SiteTopListsApiUpdateSiteTopListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTopListsApi
     */
    public updateSiteTopList(requestParameters: SiteTopListsApiUpdateSiteTopListRequest, options?: AxiosRequestConfig) {
        return SiteTopListsApiFp(this.configuration).updateSiteTopList(requestParameters.publisherId, requestParameters.toplistId, requestParameters.updateSiteTopListRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
