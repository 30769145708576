/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisers200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
// @ts-ignore
import { AdvertiserPostbackConfigResource } from '../AfClApiModels';
// @ts-ignore
import { AdvertiserResource } from '../AfClApiModels';
// @ts-ignore
import { StoreAdvertiserRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateAdvertiserPostbackConfigRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateAdvertiserRequest } from '../AfClApiModels';
/**
 * AdminAdvertisersApi - axios parameter creator
 * @export
 */
export const AdminAdvertisersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Advertiser
         * @param {StoreAdvertiserRequest} [storeAdvertiserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAdvertiser: async (storeAdvertiserRequest?: StoreAdvertiserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/advertisers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeAdvertiserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about an Advertiser
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdvertiser: async (advertiserId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('adminGetAdvertiser', 'advertiserId', advertiserId)
            const localVarPath = `/v1/admin/advertisers/{advertiserId}`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisers
         * @param {string} [name] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [affiliateSystemIds] 
         * @param {Array<number>} [ignoreIds] Ignore advertiser ids
         * @param {boolean} [supportsPostbacks] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdvertisers: async (name?: string, status?: 'ACTIVE' | 'INACTIVE', brandIds?: Array<number>, affiliateSystemIds?: Array<number>, ignoreIds?: Array<number>, supportsPostbacks?: boolean, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/advertisers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (affiliateSystemIds) {
                localVarQueryParameter['affiliate_system_ids[]'] = affiliateSystemIds;
            }

            if (ignoreIds) {
                localVarQueryParameter['ignore_ids[]'] = ignoreIds;
            }

            if (supportsPostbacks !== undefined) {
                localVarQueryParameter['supports_postbacks'] = supportsPostbacks;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisers Basic Pair
         * @param {string} [name] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [affiliateSystemIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdvertisersBasicPair: async (name?: string, status?: 'ACTIVE' | 'INACTIVE', brandIds?: Array<number>, affiliateSystemIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/advertisers-basic-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (affiliateSystemIds) {
                localVarQueryParameter['affiliate_system_ids[]'] = affiliateSystemIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Advertiser
         * @param {number} advertiserId Numeric ID of the Advertiser to update
         * @param {UpdateAdvertiserRequest} [updateAdvertiserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAdvertiser: async (advertiserId: number, updateAdvertiserRequest?: UpdateAdvertiserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('adminUpdateAdvertiser', 'advertiserId', advertiserId)
            const localVarPath = `/v1/admin/advertisers/{advertiserId}`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdvertiserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Advertiser Postback Config
         * @param {number} advertiserId Numeric ID of the Advertiser to update
         * @param {UpdateAdvertiserPostbackConfigRequest} [updateAdvertiserPostbackConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAdvertiserPostbackConfig: async (advertiserId: number, updateAdvertiserPostbackConfigRequest?: UpdateAdvertiserPostbackConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('adminUpdateAdvertiserPostbackConfig', 'advertiserId', advertiserId)
            const localVarPath = `/v1/admin/advertisers/{advertiserId}/postback-config`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdvertiserPostbackConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAdvertisersApi - functional programming interface
 * @export
 */
export const AdminAdvertisersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAdvertisersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Advertiser
         * @param {StoreAdvertiserRequest} [storeAdvertiserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAdvertiser(storeAdvertiserRequest?: StoreAdvertiserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertiserResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAdvertiser(storeAdvertiserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about an Advertiser
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAdvertiser(advertiserId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertiserResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAdvertiser(advertiserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisers
         * @param {string} [name] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [affiliateSystemIds] 
         * @param {Array<number>} [ignoreIds] Ignore advertiser ids
         * @param {boolean} [supportsPostbacks] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAdvertisers(name?: string, status?: 'ACTIVE' | 'INACTIVE', brandIds?: Array<number>, affiliateSystemIds?: Array<number>, ignoreIds?: Array<number>, supportsPostbacks?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAdvertisers(name, status, brandIds, affiliateSystemIds, ignoreIds, supportsPostbacks, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisers Basic Pair
         * @param {string} [name] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [affiliateSystemIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAdvertisersBasicPair(name?: string, status?: 'ACTIVE' | 'INACTIVE', brandIds?: Array<number>, affiliateSystemIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAdvertisersBasicPair(name, status, brandIds, affiliateSystemIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Advertiser
         * @param {number} advertiserId Numeric ID of the Advertiser to update
         * @param {UpdateAdvertiserRequest} [updateAdvertiserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAdvertiser(advertiserId: number, updateAdvertiserRequest?: UpdateAdvertiserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertiserResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAdvertiser(advertiserId, updateAdvertiserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Advertiser Postback Config
         * @param {number} advertiserId Numeric ID of the Advertiser to update
         * @param {UpdateAdvertiserPostbackConfigRequest} [updateAdvertiserPostbackConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAdvertiserPostbackConfig(advertiserId: number, updateAdvertiserPostbackConfigRequest?: UpdateAdvertiserPostbackConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertiserPostbackConfigResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAdvertiserPostbackConfig(advertiserId, updateAdvertiserPostbackConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAdvertisersApi - factory interface
 * @export
 */
export const AdminAdvertisersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAdvertisersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Advertiser
         * @param {AdminAdvertisersApiAdminCreateAdvertiserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAdvertiser(requestParameters: AdminAdvertisersApiAdminCreateAdvertiserRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdvertiserResource> {
            return localVarFp.adminCreateAdvertiser(requestParameters.storeAdvertiserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about an Advertiser
         * @param {AdminAdvertisersApiAdminGetAdvertiserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdvertiser(requestParameters: AdminAdvertisersApiAdminGetAdvertiserRequest, options?: AxiosRequestConfig): AxiosPromise<AdvertiserResource> {
            return localVarFp.adminGetAdvertiser(requestParameters.advertiserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisers
         * @param {AdminAdvertisersApiAdminGetAdvertisersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdvertisers(requestParameters: AdminAdvertisersApiAdminGetAdvertisersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisers200Response> {
            return localVarFp.adminGetAdvertisers(requestParameters.name, requestParameters.status, requestParameters.brandIds, requestParameters.affiliateSystemIds, requestParameters.ignoreIds, requestParameters.supportsPostbacks, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisers Basic Pair
         * @param {AdminAdvertisersApiAdminGetAdvertisersBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAdvertisersBasicPair(requestParameters: AdminAdvertisersApiAdminGetAdvertisersBasicPairRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.adminGetAdvertisersBasicPair(requestParameters.name, requestParameters.status, requestParameters.brandIds, requestParameters.affiliateSystemIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Advertiser
         * @param {AdminAdvertisersApiAdminUpdateAdvertiserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAdvertiser(requestParameters: AdminAdvertisersApiAdminUpdateAdvertiserRequest, options?: AxiosRequestConfig): AxiosPromise<AdvertiserResource> {
            return localVarFp.adminUpdateAdvertiser(requestParameters.advertiserId, requestParameters.updateAdvertiserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Advertiser Postback Config
         * @param {AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAdvertiserPostbackConfig(requestParameters: AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfigRequest, options?: AxiosRequestConfig): AxiosPromise<AdvertiserPostbackConfigResource> {
            return localVarFp.adminUpdateAdvertiserPostbackConfig(requestParameters.advertiserId, requestParameters.updateAdvertiserPostbackConfigRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCreateAdvertiser operation in AdminAdvertisersApi.
 * @export
 * @interface AdminAdvertisersApiAdminCreateAdvertiserRequest
 */
export interface AdminAdvertisersApiAdminCreateAdvertiserRequest {
    /**
     * 
     * @type {StoreAdvertiserRequest}
     * @memberof AdminAdvertisersApiAdminCreateAdvertiser
     */
    readonly storeAdvertiserRequest?: StoreAdvertiserRequest
}

/**
 * Request parameters for adminGetAdvertiser operation in AdminAdvertisersApi.
 * @export
 * @interface AdminAdvertisersApiAdminGetAdvertiserRequest
 */
export interface AdminAdvertisersApiAdminGetAdvertiserRequest {
    /**
     * Numeric ID of the Advertiser to find
     * @type {number}
     * @memberof AdminAdvertisersApiAdminGetAdvertiser
     */
    readonly advertiserId: number
}

/**
 * Request parameters for adminGetAdvertisers operation in AdminAdvertisersApi.
 * @export
 * @interface AdminAdvertisersApiAdminGetAdvertisersRequest
 */
export interface AdminAdvertisersApiAdminGetAdvertisersRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly name?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE'}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly status?: 'ACTIVE' | 'INACTIVE'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly affiliateSystemIds?: Array<number>

    /**
     * Ignore advertiser ids
     * @type {Array<number>}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly ignoreIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly supportsPostbacks?: boolean

    /**
     * 
     * @type {number}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAdvertisersApiAdminGetAdvertisers
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminGetAdvertisersBasicPair operation in AdminAdvertisersApi.
 * @export
 * @interface AdminAdvertisersApiAdminGetAdvertisersBasicPairRequest
 */
export interface AdminAdvertisersApiAdminGetAdvertisersBasicPairRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAdvertisersApiAdminGetAdvertisersBasicPair
     */
    readonly name?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE'}
     * @memberof AdminAdvertisersApiAdminGetAdvertisersBasicPair
     */
    readonly status?: 'ACTIVE' | 'INACTIVE'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAdvertisersApiAdminGetAdvertisersBasicPair
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAdvertisersApiAdminGetAdvertisersBasicPair
     */
    readonly affiliateSystemIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAdvertisersApiAdminGetAdvertisersBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAdvertisersApiAdminGetAdvertisersBasicPair
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminUpdateAdvertiser operation in AdminAdvertisersApi.
 * @export
 * @interface AdminAdvertisersApiAdminUpdateAdvertiserRequest
 */
export interface AdminAdvertisersApiAdminUpdateAdvertiserRequest {
    /**
     * Numeric ID of the Advertiser to update
     * @type {number}
     * @memberof AdminAdvertisersApiAdminUpdateAdvertiser
     */
    readonly advertiserId: number

    /**
     * 
     * @type {UpdateAdvertiserRequest}
     * @memberof AdminAdvertisersApiAdminUpdateAdvertiser
     */
    readonly updateAdvertiserRequest?: UpdateAdvertiserRequest
}

/**
 * Request parameters for adminUpdateAdvertiserPostbackConfig operation in AdminAdvertisersApi.
 * @export
 * @interface AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfigRequest
 */
export interface AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfigRequest {
    /**
     * Numeric ID of the Advertiser to update
     * @type {number}
     * @memberof AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfig
     */
    readonly advertiserId: number

    /**
     * 
     * @type {UpdateAdvertiserPostbackConfigRequest}
     * @memberof AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfig
     */
    readonly updateAdvertiserPostbackConfigRequest?: UpdateAdvertiserPostbackConfigRequest
}

/**
 * AdminAdvertisersApi - object-oriented interface
 * @export
 * @class AdminAdvertisersApi
 * @extends {BaseAPI}
 */
export class AdminAdvertisersApi extends BaseAPI {
    /**
     * 
     * @summary Create Advertiser
     * @param {AdminAdvertisersApiAdminCreateAdvertiserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdvertisersApi
     */
    public adminCreateAdvertiser(requestParameters: AdminAdvertisersApiAdminCreateAdvertiserRequest = {}, options?: AxiosRequestConfig) {
        return AdminAdvertisersApiFp(this.configuration).adminCreateAdvertiser(requestParameters.storeAdvertiserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about an Advertiser
     * @param {AdminAdvertisersApiAdminGetAdvertiserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdvertisersApi
     */
    public adminGetAdvertiser(requestParameters: AdminAdvertisersApiAdminGetAdvertiserRequest, options?: AxiosRequestConfig) {
        return AdminAdvertisersApiFp(this.configuration).adminGetAdvertiser(requestParameters.advertiserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisers
     * @param {AdminAdvertisersApiAdminGetAdvertisersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdvertisersApi
     */
    public adminGetAdvertisers(requestParameters: AdminAdvertisersApiAdminGetAdvertisersRequest = {}, options?: AxiosRequestConfig) {
        return AdminAdvertisersApiFp(this.configuration).adminGetAdvertisers(requestParameters.name, requestParameters.status, requestParameters.brandIds, requestParameters.affiliateSystemIds, requestParameters.ignoreIds, requestParameters.supportsPostbacks, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisers Basic Pair
     * @param {AdminAdvertisersApiAdminGetAdvertisersBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdvertisersApi
     */
    public adminGetAdvertisersBasicPair(requestParameters: AdminAdvertisersApiAdminGetAdvertisersBasicPairRequest = {}, options?: AxiosRequestConfig) {
        return AdminAdvertisersApiFp(this.configuration).adminGetAdvertisersBasicPair(requestParameters.name, requestParameters.status, requestParameters.brandIds, requestParameters.affiliateSystemIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Advertiser
     * @param {AdminAdvertisersApiAdminUpdateAdvertiserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdvertisersApi
     */
    public adminUpdateAdvertiser(requestParameters: AdminAdvertisersApiAdminUpdateAdvertiserRequest, options?: AxiosRequestConfig) {
        return AdminAdvertisersApiFp(this.configuration).adminUpdateAdvertiser(requestParameters.advertiserId, requestParameters.updateAdvertiserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Advertiser Postback Config
     * @param {AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdvertisersApi
     */
    public adminUpdateAdvertiserPostbackConfig(requestParameters: AdminAdvertisersApiAdminUpdateAdvertiserPostbackConfigRequest, options?: AxiosRequestConfig) {
        return AdminAdvertisersApiFp(this.configuration).adminUpdateAdvertiserPostbackConfig(requestParameters.advertiserId, requestParameters.updateAdvertiserPostbackConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
