import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { AdminUpsertSourceKeyRequest } from '@/stub';
import { AfClApi } from '@/Services/AfClApi';

export const useSourceKeyCreate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (payload: AdminUpsertSourceKeyRequest) =>
            await AfClApi.adminSourceKeys.createSourceKey({
                adminUpsertSourceKeyRequest: payload
            }),
        onSuccess: async () => {
            onSuccess ? onSuccess() : null;
            await queryClient.invalidateQueries(['source-keys']);
        },
    });
};
