import {
    CorporationAccount as CorporationAccountContract,
    CorporationAccountCorporationTypeEnum,
    CorporationAccountInvoicingStatusEnum,
    CurrencyRateResource,
    Currency
} from '@/stub';

export class CorporationAccount {
    public readonly type = 'CORPORATION';
    public _currencyRatesMap?: Map<string, number>;

    constructor(
        public id: number,
        public auth_team_id: number,
        public name: string,
        public corporation_type: CorporationAccountCorporationTypeEnum,
        public invoicing_status: CorporationAccountInvoicingStatusEnum,
        public currency: Currency,
        public direct_invoicing: boolean,
        public rates?: CurrencyRateResource[]
    ) {
        this._currencyRatesMap = new Map();
    }

    get currencyRatesMap() {
        if (this._currencyRatesMap?.size === 0) {
            this._currencyRatesMap = new Map(
                this.rates?.map((rate: CurrencyRateResource) => [rate.currency, rate.rate])
            );
        }

        return this._currencyRatesMap;
    }

    static fromPlain(model: CorporationAccountContract) {
        return new this(
            model.id,
            model.auth_team_id,
            model.name,
            model.corporation_type,
            model.invoicing_status,
            model.currency,
            model.direct_invoicing,
            model.rates
        );
    }
}
