/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminInvoiceCountByStatus } from '../AfClApiModels';
// @ts-ignore
import { ListPublisherInvoices200Response } from '../AfClApiModels';
// @ts-ignore
import { PublisherInvoiceStatus } from '../AfClApiModels';
/**
 * AdminInvoicesApi - axios parameter creator
 * @export
 */
export const AdminInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Publisher Invoices count by status
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {PublisherInvoiceStatus} [invoiceStatus] 
         * @param {number} [publisherId] 
         * @param {number} [publisherManagerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherInvoicesStatusCount: async (year?: number, month?: number, invoiceStatus?: PublisherInvoiceStatus, publisherId?: number, publisherManagerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publisher-invoices/status-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (invoiceStatus !== undefined) {
                localVarQueryParameter['invoice_status'] = invoiceStatus;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (publisherManagerId !== undefined) {
                localVarQueryParameter['publisher_manager_id'] = publisherManagerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Publisher Invoices
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {PublisherInvoiceStatus} [invoiceStatus] 
         * @param {number} [publisherId] 
         * @param {number} [publisherManagerId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublisherInvoices: async (year?: number, month?: number, invoiceStatus?: PublisherInvoiceStatus, publisherId?: number, publisherManagerId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publisher-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (invoiceStatus !== undefined) {
                localVarQueryParameter['invoice_status'] = invoiceStatus;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (publisherManagerId !== undefined) {
                localVarQueryParameter['publisher_manager_id'] = publisherManagerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminInvoicesApi - functional programming interface
 * @export
 */
export const AdminInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Invoices count by status
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {PublisherInvoiceStatus} [invoiceStatus] 
         * @param {number} [publisherId] 
         * @param {number} [publisherManagerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherInvoicesStatusCount(year?: number, month?: number, invoiceStatus?: PublisherInvoiceStatus, publisherId?: number, publisherManagerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminInvoiceCountByStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherInvoicesStatusCount(year, month, invoiceStatus, publisherId, publisherManagerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Publisher Invoices
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {PublisherInvoiceStatus} [invoiceStatus] 
         * @param {number} [publisherId] 
         * @param {number} [publisherManagerId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublisherInvoices(year?: number, month?: number, invoiceStatus?: PublisherInvoiceStatus, publisherId?: number, publisherManagerId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublisherInvoices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublisherInvoices(year, month, invoiceStatus, publisherId, publisherManagerId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminInvoicesApi - factory interface
 * @export
 */
export const AdminInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Invoices count by status
         * @param {AdminInvoicesApiGetPublisherInvoicesStatusCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherInvoicesStatusCount(requestParameters: AdminInvoicesApiGetPublisherInvoicesStatusCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminInvoiceCountByStatus>> {
            return localVarFp.getPublisherInvoicesStatusCount(requestParameters.year, requestParameters.month, requestParameters.invoiceStatus, requestParameters.publisherId, requestParameters.publisherManagerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Publisher Invoices
         * @param {AdminInvoicesApiListPublisherInvoicesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublisherInvoices(requestParameters: AdminInvoicesApiListPublisherInvoicesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ListPublisherInvoices200Response> {
            return localVarFp.listPublisherInvoices(requestParameters.year, requestParameters.month, requestParameters.invoiceStatus, requestParameters.publisherId, requestParameters.publisherManagerId, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPublisherInvoicesStatusCount operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiGetPublisherInvoicesStatusCountRequest
 */
export interface AdminInvoicesApiGetPublisherInvoicesStatusCountRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiGetPublisherInvoicesStatusCount
     */
    readonly year?: number

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiGetPublisherInvoicesStatusCount
     */
    readonly month?: number

    /**
     * 
     * @type {PublisherInvoiceStatus}
     * @memberof AdminInvoicesApiGetPublisherInvoicesStatusCount
     */
    readonly invoiceStatus?: PublisherInvoiceStatus

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiGetPublisherInvoicesStatusCount
     */
    readonly publisherId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiGetPublisherInvoicesStatusCount
     */
    readonly publisherManagerId?: number
}

/**
 * Request parameters for listPublisherInvoices operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiListPublisherInvoicesRequest
 */
export interface AdminInvoicesApiListPublisherInvoicesRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly year?: number

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly month?: number

    /**
     * 
     * @type {PublisherInvoiceStatus}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly invoiceStatus?: PublisherInvoiceStatus

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly publisherId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly publisherManagerId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminInvoicesApiListPublisherInvoices
     */
    readonly perPage?: number
}

/**
 * AdminInvoicesApi - object-oriented interface
 * @export
 * @class AdminInvoicesApi
 * @extends {BaseAPI}
 */
export class AdminInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Get Publisher Invoices count by status
     * @param {AdminInvoicesApiGetPublisherInvoicesStatusCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public getPublisherInvoicesStatusCount(requestParameters: AdminInvoicesApiGetPublisherInvoicesStatusCountRequest = {}, options?: AxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).getPublisherInvoicesStatusCount(requestParameters.year, requestParameters.month, requestParameters.invoiceStatus, requestParameters.publisherId, requestParameters.publisherManagerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Publisher Invoices
     * @param {AdminInvoicesApiListPublisherInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public listPublisherInvoices(requestParameters: AdminInvoicesApiListPublisherInvoicesRequest = {}, options?: AxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).listPublisherInvoices(requestParameters.year, requestParameters.month, requestParameters.invoiceStatus, requestParameters.publisherId, requestParameters.publisherManagerId, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
