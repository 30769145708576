import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMenuStore = defineStore('menuStore', () => {
    const opened = ref<boolean>(false);

    const setOpened = (payload: boolean) => {
        opened.value = payload;
    };

    return { opened, setOpened };
});
