/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPaymentMethod } from '../AfClApiModels';
// @ts-ignore
import { AdminStoreProgramPaymentMethodRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateProgramPaymentMethodRequest } from '../AfClApiModels';
/**
 * AdminPaymentMethodsApi - axios parameter creator
 * @export
 */
export const AdminPaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Payment Method
         * @param {number} paymentMethodId Numeric ID of the program payment method to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethod: async (paymentMethodId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('deletePaymentMethod', 'paymentMethodId', paymentMethodId)
            const localVarPath = `/v1/admin/payment-methods/{paymentMethodId}`
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment Methods
         * @param {number} [programId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (programId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (programId !== undefined) {
                localVarQueryParameter['program_id'] = programId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Payment Method
         * @param {AdminStoreProgramPaymentMethodRequest} [adminStoreProgramPaymentMethodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePaymentMethod: async (adminStoreProgramPaymentMethodRequest?: AdminStoreProgramPaymentMethodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreProgramPaymentMethodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Payment Method
         * @param {number} paymentMethodId Numeric ID of the program payment method to search
         * @param {AdminUpdateProgramPaymentMethodRequest} [adminUpdateProgramPaymentMethodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethod: async (paymentMethodId: number, adminUpdateProgramPaymentMethodRequest?: AdminUpdateProgramPaymentMethodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('updatePaymentMethod', 'paymentMethodId', paymentMethodId)
            const localVarPath = `/v1/admin/payment-methods/{paymentMethodId}`
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateProgramPaymentMethodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPaymentMethodsApi - functional programming interface
 * @export
 */
export const AdminPaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Payment Method
         * @param {number} paymentMethodId Numeric ID of the program payment method to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePaymentMethod(paymentMethodId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePaymentMethod(paymentMethodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Payment Methods
         * @param {number} [programId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(programId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminPaymentMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethods(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Payment Method
         * @param {AdminStoreProgramPaymentMethodRequest} [adminStoreProgramPaymentMethodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePaymentMethod(adminStoreProgramPaymentMethodRequest?: AdminStoreProgramPaymentMethodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminPaymentMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePaymentMethod(adminStoreProgramPaymentMethodRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Payment Method
         * @param {number} paymentMethodId Numeric ID of the program payment method to search
         * @param {AdminUpdateProgramPaymentMethodRequest} [adminUpdateProgramPaymentMethodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentMethod(paymentMethodId: number, adminUpdateProgramPaymentMethodRequest?: AdminUpdateProgramPaymentMethodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaymentMethod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentMethod(paymentMethodId, adminUpdateProgramPaymentMethodRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPaymentMethodsApi - factory interface
 * @export
 */
export const AdminPaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Payment Method
         * @param {AdminPaymentMethodsApiDeletePaymentMethodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethod(requestParameters: AdminPaymentMethodsApiDeletePaymentMethodRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePaymentMethod(requestParameters.paymentMethodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Payment Methods
         * @param {AdminPaymentMethodsApiGetPaymentMethodsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(requestParameters: AdminPaymentMethodsApiGetPaymentMethodsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminPaymentMethod>> {
            return localVarFp.getPaymentMethods(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Payment Method
         * @param {AdminPaymentMethodsApiStorePaymentMethodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePaymentMethod(requestParameters: AdminPaymentMethodsApiStorePaymentMethodRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminPaymentMethod>> {
            return localVarFp.storePaymentMethod(requestParameters.adminStoreProgramPaymentMethodRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Payment Method
         * @param {AdminPaymentMethodsApiUpdatePaymentMethodRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethod(requestParameters: AdminPaymentMethodsApiUpdatePaymentMethodRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPaymentMethod> {
            return localVarFp.updatePaymentMethod(requestParameters.paymentMethodId, requestParameters.adminUpdateProgramPaymentMethodRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deletePaymentMethod operation in AdminPaymentMethodsApi.
 * @export
 * @interface AdminPaymentMethodsApiDeletePaymentMethodRequest
 */
export interface AdminPaymentMethodsApiDeletePaymentMethodRequest {
    /**
     * Numeric ID of the program payment method to search
     * @type {number}
     * @memberof AdminPaymentMethodsApiDeletePaymentMethod
     */
    readonly paymentMethodId: number
}

/**
 * Request parameters for getPaymentMethods operation in AdminPaymentMethodsApi.
 * @export
 * @interface AdminPaymentMethodsApiGetPaymentMethodsRequest
 */
export interface AdminPaymentMethodsApiGetPaymentMethodsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminPaymentMethodsApiGetPaymentMethods
     */
    readonly programId?: number
}

/**
 * Request parameters for storePaymentMethod operation in AdminPaymentMethodsApi.
 * @export
 * @interface AdminPaymentMethodsApiStorePaymentMethodRequest
 */
export interface AdminPaymentMethodsApiStorePaymentMethodRequest {
    /**
     * 
     * @type {AdminStoreProgramPaymentMethodRequest}
     * @memberof AdminPaymentMethodsApiStorePaymentMethod
     */
    readonly adminStoreProgramPaymentMethodRequest?: AdminStoreProgramPaymentMethodRequest
}

/**
 * Request parameters for updatePaymentMethod operation in AdminPaymentMethodsApi.
 * @export
 * @interface AdminPaymentMethodsApiUpdatePaymentMethodRequest
 */
export interface AdminPaymentMethodsApiUpdatePaymentMethodRequest {
    /**
     * Numeric ID of the program payment method to search
     * @type {number}
     * @memberof AdminPaymentMethodsApiUpdatePaymentMethod
     */
    readonly paymentMethodId: number

    /**
     * 
     * @type {AdminUpdateProgramPaymentMethodRequest}
     * @memberof AdminPaymentMethodsApiUpdatePaymentMethod
     */
    readonly adminUpdateProgramPaymentMethodRequest?: AdminUpdateProgramPaymentMethodRequest
}

/**
 * AdminPaymentMethodsApi - object-oriented interface
 * @export
 * @class AdminPaymentMethodsApi
 * @extends {BaseAPI}
 */
export class AdminPaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Payment Method
     * @param {AdminPaymentMethodsApiDeletePaymentMethodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentMethodsApi
     */
    public deletePaymentMethod(requestParameters: AdminPaymentMethodsApiDeletePaymentMethodRequest, options?: AxiosRequestConfig) {
        return AdminPaymentMethodsApiFp(this.configuration).deletePaymentMethod(requestParameters.paymentMethodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Payment Methods
     * @param {AdminPaymentMethodsApiGetPaymentMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentMethodsApi
     */
    public getPaymentMethods(requestParameters: AdminPaymentMethodsApiGetPaymentMethodsRequest = {}, options?: AxiosRequestConfig) {
        return AdminPaymentMethodsApiFp(this.configuration).getPaymentMethods(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Payment Method
     * @param {AdminPaymentMethodsApiStorePaymentMethodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentMethodsApi
     */
    public storePaymentMethod(requestParameters: AdminPaymentMethodsApiStorePaymentMethodRequest = {}, options?: AxiosRequestConfig) {
        return AdminPaymentMethodsApiFp(this.configuration).storePaymentMethod(requestParameters.adminStoreProgramPaymentMethodRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Payment Method
     * @param {AdminPaymentMethodsApiUpdatePaymentMethodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPaymentMethodsApi
     */
    public updatePaymentMethod(requestParameters: AdminPaymentMethodsApiUpdatePaymentMethodRequest, options?: AxiosRequestConfig) {
        return AdminPaymentMethodsApiFp(this.configuration).updatePaymentMethod(requestParameters.paymentMethodId, requestParameters.adminUpdateProgramPaymentMethodRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
