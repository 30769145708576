<template>
    <div>
        <label
            v-if="label"
            class="block font-medium text-sm"
            :class="dark ? 'text-rt-yellow' : 'text-gray-700'"
        >
            <span>{{ label }}</span>
            <span
                v-if="required"
                class="required text-red-600"
                title="This field is required"
            > *</span>
        </label>
        <div :class="`flex items-center relative` + (containerClasses !== undefined ? ' ' + containerClasses : '')">
            <input
                :list="'datalist' + $.uid"
                :class="inputClass"
                :type
                :disabled
                :placeholder
                :value="modelValue"
                :min
                :max
                :readonly
                @input="onInput"
                @wheel.prevent
            >
            <datalist
                v-if="$slots.datalist"
                :id="'datalist' + $.uid"
            >
                <slot name="datalist" />
            </datalist>
            <div
                v-if="$slots.suffix"
                class="absolute rounded-r-md bg-white h-full right-0 py-2 px-2 border border-gray-300"
            >
                <slot name="suffix" />
            </div>
            <div
                v-if="$slots.selector"
                class="absolute rounded-r-md bg-white h-full right-0 border border-gray-300 z-0"
            >
                <slot name="selector" />
            </div>
        </div>
        <InputErrorList
            :vld-errors
            :errors
        />
    </div>
</template>

<script setup lang="ts">
import { ErrorObject } from '@vuelidate/core';
import InputErrorList from '@/Components/Core/Form/InputErrorList.vue';
import { computed } from 'vue';
import { debounce as debounceFn } from '@/Utils/Debounce';

const props = withDefaults(defineProps<{
    modelValue: string | number | null,
    label?: string | null,
    required?: boolean,
    placeholder?: string
    vldErrors?: ErrorObject[],
    disabled?: boolean,
    errors?: string[],
    dark?: boolean,
    type?: string
    min?: number,
    max?: number,
    inputClasses?: string,
    containerClasses?: string,
    readonly?: boolean,
    debounce?: boolean
}>(), {
    modelValue: null,
    label: null,
    required: false,
    placeholder: '',
    vldErrors: () => [],
    disabled: false,
    errors: () => [],
    dark: false,
    type: 'text',
    min: undefined,
    max: undefined,
    inputClasses: undefined,
    containerClasses: undefined,
    readonly: false,
    debounce: false
});

const emits = defineEmits<{
    (event: 'update:modelValue', value: string ): void;
    (event: 'input', value: string ): void;
}>();

const onInput = debounceFn((event) => {
    emits('update:modelValue', event.target.value);
    emits('input', event.target.value);
}, props.debounce ? 1000 : 0);

const inputClass = computed(() => {
    const classes = 'px-2 py-2 block w-full border border-gray-300 focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50 focus:outline-none rounded-md shadow-s';
    let additionalClasses = props.disabled ? 'opacity-75' : '';
    if (props.inputClasses !== undefined) {
        additionalClasses += ' ' + props.inputClasses;
    }

    return classes + ' ' + additionalClasses;
});
</script>
