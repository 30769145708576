import { UserType } from '@/Enums/UserType';
import { useAccountStore } from '@/Stores/AccountStore';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';

export const EnsureAccountIs = (type: UserType): MiddlewareFunction => {
    return async (context) => {
        const accountStore = useAccountStore();
        if (type === UserType.ADVERTISER && !accountStore.isAdvertiser) {
            return { name: 'accounts' };
        }
        if (type === UserType.PUBLISHER && !accountStore.isPublisher) {
            return { name: 'accounts' };
        }
        if (type === UserType.CORPORATION && !accountStore.isCorporation) {
            return { name: 'accounts' };
        }
        return context.next();
    };
};
