import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

export function useSentry(app: App, router: Router) {
    if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration()
            ],
            trackComponents: true,
            // Performance Monitoring
            tracesSampleRate: 0.1,
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
                'Request failed with status code 422',
                'Failed to fetch dynamically imported module'
            ],
            environment: import.meta.env.VITE_ENV,
        });
    }
}
