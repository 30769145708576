interface INumericOptions {
    addZero: boolean,
    decimalSeparator: string,
    thousandsSeparator: string,
    minimumFractionDigits: number,
    maximumFractionDigits: number
}

export const formatNumeric = (value: string | number, options?: Partial<INumericOptions>): string => {
    const defaultOptions: INumericOptions = {
        addZero: true,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    let numericOptions: INumericOptions;
    if (options === undefined) {
        numericOptions = { ...defaultOptions };
    } else {
        Object.keys(options).forEach(key => options[key] === undefined && delete options[key]);
        numericOptions = { ...defaultOptions, ...options };
    }

    const parsedValue = parseFloat(value as unknown as string);
    if (isNaN(parsedValue)) {
        return String(0);
    }

    return parsedValue.toLocaleString('en-US', numericOptions);
};
