import {
    createRouter,
    createWebHistory
} from 'vue-router';
import { useMenuStore } from '@/Stores/MenuStore';
import { registerMiddlewareProcessing } from 'vue-lite-route';
import { routes } from '@/Router/Routes/Routes';
import { useGlobalLoaderStore } from '@/Stores/GlobalLoaderStore';

export const router = createRouter({
    history: createWebHistory(),
    routes
});
registerMiddlewareProcessing(router);

router.beforeEach(() => {
    const { setLoading } = useGlobalLoaderStore();
    const menuStore = useMenuStore();
    menuStore.setOpened(false);
    setLoading(true);
});

router.afterEach(() => {
    const { setLoading } = useGlobalLoaderStore();
    setLoading(false);
});