<template>
    <Teleport to="#modals">
        <transition name="fade">
            <div
                v-if="isOpened"
                class="fixed top-0 bg-neutral-900 bg-opacity-60 z-30 w-screen h-[100dvh] flex overflow-y-hidden overflow-x-hidden"
                :class="{ 'flex justify-center items-center': placement === 'center' }"
                @keydown.esc="emits('update:isOpened', false)"
            >
                <div
                    ref="modalInner"
                    :class="[
                        {
                            'right-0': placement === 'right',
                            'left-0': placement === 'left',
                        },
                        {
                            'md:rounded-l-xl': placement === 'right',
                            'md:rounded-r-xl': placement === 'left',
                            'md:rounded-xl': placement === 'center',
                        },
                        hFit ? 'md:h-fit' : 'bottom-0 absolute md:h-screen'
                    ]"
                    class="h-[80vh] md:rounded-none flex flex-col  w-[100vw] sm:w-1/2 lg:w-[50vw] xl:w-[40vw] 2xl:w-[32vw] duration-1000 transition-all overflow-y-auto bg-rt-white"
                >
                    <div 
                        class="flex justify-between rounded-t-xl p-5"
                    >
                        <slot name="header">
                            <div class="flex flex-col gap-4 justify-center">
                                <TitleSection
                                    size="md"
                                    :class="{ '-mb-3': !title }"
                                    :description
                                >
                                    {{ title }}
                                </TitleSection>
                            </div>
                        </slot>
                        <button
                            class="w-[14px] h-[14px] cursor-pointer pr-8"
                            :class="{ '-mb-3': !title }"
                            @click="onModalClose"
                        >
                            <OhVueIcon
                                name="fa-times"
                                :scale="1.25"
                            />
                        </button>
                    </div>
                    <div class="flex flex-col gap-5 overflow-y-auto rt-scroll-bar h-full p-5 mr-1">
                        <slot name="content" />
                    </div>
                    <div class="flex flex-col gap-4 w-full p-5">
                        <slot
                            name="actions"
                        />
                    </div>
                    <SpinnerLoader
                        class="rounded-lg"
                        :visible="isLoading"
                    />
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script setup lang='ts' generic="T">
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import SpinnerLoader from '@/Components/Core/SpinnerLoader.vue';
import TitleSection from '@/Components/Core/TitleSection.vue';
import { addIcons, OhVueIcon } from 'oh-vue-icons';
import { FaTimes } from 'oh-vue-icons/icons';

addIcons(FaTimes);

const { isOpened = false, placement = 'right', isLoading = false, hFit = false, description = null } = defineProps<{
    placement?: 'right' | 'left' | 'center',
    title?: string,
    description?: string,
    isOpened: boolean,
    isLoading?: boolean,
    hFit?: boolean
}>();

const emits = defineEmits<{
    'update:isOpened': [value: boolean];
    'modal:close';
}>();

defineSlots<{
    header?: () => void,
    content?: () => void,
    actions?: () => void,
}>();

const modalInner = ref(null);

onClickOutside(modalInner, (event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'DIV' && (
        target.classList.contains('fixed') &&
        !target.classList.value.includes('rt-disable-click-outside')
    )) {
        emits('update:isOpened', false);
        emits('modal:close');
    }
});

const onModalClose = () => {
    emits('update:isOpened', false);
    emits('modal:close');
};
</script>

<style scoped lang="scss">
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s;
}
</style>
