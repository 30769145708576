import axios, { AxiosError } from 'axios';
import { ErrorContract } from '@/Models/Errors/ErrorContract';
import { useToast } from 'vue-toastification';
import { API_BASE_URL } from '@/Services/AfClApi';

// TODO: check if BaseApiClient is still needed
// Perhaps replace all remaining request that use BaseApiClient with new AfClApi
export class BaseApiClient {
    protected _axios = axios.create({
        baseURL: API_BASE_URL + '/v1',
    });

    private static accessToken = '';

    constructor() {
        const toast = useToast();

        this._axios.defaults.headers.common['Authorization'] = `Bearer ${BaseApiClient.accessToken}`;
        this._axios.interceptors.response.use(
            res => res,
            (error: AxiosError<ErrorContract>) => {
                if (error.response?.status === 409 && error.response.data.errors) {
                    error.response.data.errors.forEach(x => toast.error(x));
                } else if (error.response?.status === 422 && error.response.data.errors) {
                    Object.values(error.response.data.errors).flat(1).forEach(x => toast.error(x));
                } else {
                    toast.error('Something went wrong');
                }
                return Promise.reject(error);
            }
        );
    }

    static setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
    }
}

