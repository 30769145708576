/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetAuthUsers200Response } from '../AfClApiModels';
/**
 * AuthUsersApi - axios parameter creator
 * @export
 */
export const AuthUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all Auth Users
         * @param {Array<number>} [usersIds] 
         * @param {Array<string>} [usersEmails] 
         * @param {string} [orderByField] 
         * @param {string} [orderByDirection] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUsers: async (usersIds?: Array<number>, usersEmails?: Array<string>, orderByField?: string, orderByDirection?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (usersIds) {
                localVarQueryParameter['users_ids[]'] = usersIds;
            }

            if (usersEmails) {
                localVarQueryParameter['users_emails[]'] = usersEmails;
            }

            if (orderByField !== undefined) {
                localVarQueryParameter['order_by_field'] = orderByField;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['order_by_direction'] = orderByDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthUsersApi - functional programming interface
 * @export
 */
export const AuthUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all Auth Users
         * @param {Array<number>} [usersIds] 
         * @param {Array<string>} [usersEmails] 
         * @param {string} [orderByField] 
         * @param {string} [orderByDirection] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUsers(usersIds?: Array<number>, usersEmails?: Array<string>, orderByField?: string, orderByDirection?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuthUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUsers(usersIds, usersEmails, orderByField, orderByDirection, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthUsersApi - factory interface
 * @export
 */
export const AuthUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all Auth Users
         * @param {AuthUsersApiGetAuthUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUsers(requestParameters: AuthUsersApiGetAuthUsersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetAuthUsers200Response> {
            return localVarFp.getAuthUsers(requestParameters.usersIds, requestParameters.usersEmails, requestParameters.orderByField, requestParameters.orderByDirection, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAuthUsers operation in AuthUsersApi.
 * @export
 * @interface AuthUsersApiGetAuthUsersRequest
 */
export interface AuthUsersApiGetAuthUsersRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AuthUsersApiGetAuthUsers
     */
    readonly usersIds?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuthUsersApiGetAuthUsers
     */
    readonly usersEmails?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AuthUsersApiGetAuthUsers
     */
    readonly orderByField?: string

    /**
     * 
     * @type {string}
     * @memberof AuthUsersApiGetAuthUsers
     */
    readonly orderByDirection?: string

    /**
     * 
     * @type {number}
     * @memberof AuthUsersApiGetAuthUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AuthUsersApiGetAuthUsers
     */
    readonly perPage?: number
}

/**
 * AuthUsersApi - object-oriented interface
 * @export
 * @class AuthUsersApi
 * @extends {BaseAPI}
 */
export class AuthUsersApi extends BaseAPI {
    /**
     * 
     * @summary Get all Auth Users
     * @param {AuthUsersApiGetAuthUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthUsersApi
     */
    public getAuthUsers(requestParameters: AuthUsersApiGetAuthUsersRequest = {}, options?: AxiosRequestConfig) {
        return AuthUsersApiFp(this.configuration).getAuthUsers(requestParameters.usersIds, requestParameters.usersEmails, requestParameters.orderByField, requestParameters.orderByDirection, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
