/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdvertiserGetDashboardTotals200Response } from '../AfClApiModels';
// @ts-ignore
import { TopProgramResource } from '../AfClApiModels';
// @ts-ignore
import { TopSiteResource } from '../AfClApiModels';
/**
 * AdvertiserDashboardDataApi - axios parameter creator
 * @export
 */
export const AdvertiserDashboardDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Dashboard Totals
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advertiserGetDashboardTotals: async (advertiserId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('advertiserGetDashboardTotals', 'advertiserId', advertiserId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('advertiserGetDashboardTotals', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('advertiserGetDashboardTotals', 'to', to)
            const localVarPath = `/v1/advertisers/{advertiserId}/totals`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Programs
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advertiserGetTopPrograms: async (advertiserId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('advertiserGetTopPrograms', 'advertiserId', advertiserId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('advertiserGetTopPrograms', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('advertiserGetTopPrograms', 'to', to)
            const localVarPath = `/v1/advertisers/{advertiserId}/top-programs`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Sites
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advertiserGetTopSites: async (advertiserId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('advertiserGetTopSites', 'advertiserId', advertiserId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('advertiserGetTopSites', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('advertiserGetTopSites', 'to', to)
            const localVarPath = `/v1/advertisers/{advertiserId}/top-sites`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertiserDashboardDataApi - functional programming interface
 * @export
 */
export const AdvertiserDashboardDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertiserDashboardDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Dashboard Totals
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advertiserGetDashboardTotals(advertiserId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertiserGetDashboardTotals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advertiserGetDashboardTotals(advertiserId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Top Programs
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advertiserGetTopPrograms(advertiserId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopProgramResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advertiserGetTopPrograms(advertiserId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Top Sites
         * @param {number} advertiserId Numeric ID of the Advertiser to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advertiserGetTopSites(advertiserId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopSiteResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advertiserGetTopSites(advertiserId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertiserDashboardDataApi - factory interface
 * @export
 */
export const AdvertiserDashboardDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertiserDashboardDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Dashboard Totals
         * @param {AdvertiserDashboardDataApiAdvertiserGetDashboardTotalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advertiserGetDashboardTotals(requestParameters: AdvertiserDashboardDataApiAdvertiserGetDashboardTotalsRequest, options?: AxiosRequestConfig): AxiosPromise<AdvertiserGetDashboardTotals200Response> {
            return localVarFp.advertiserGetDashboardTotals(requestParameters.advertiserId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Top Programs
         * @param {AdvertiserDashboardDataApiAdvertiserGetTopProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advertiserGetTopPrograms(requestParameters: AdvertiserDashboardDataApiAdvertiserGetTopProgramsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TopProgramResource>> {
            return localVarFp.advertiserGetTopPrograms(requestParameters.advertiserId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Top Sites
         * @param {AdvertiserDashboardDataApiAdvertiserGetTopSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advertiserGetTopSites(requestParameters: AdvertiserDashboardDataApiAdvertiserGetTopSitesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TopSiteResource>> {
            return localVarFp.advertiserGetTopSites(requestParameters.advertiserId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for advertiserGetDashboardTotals operation in AdvertiserDashboardDataApi.
 * @export
 * @interface AdvertiserDashboardDataApiAdvertiserGetDashboardTotalsRequest
 */
export interface AdvertiserDashboardDataApiAdvertiserGetDashboardTotalsRequest {
    /**
     * Numeric ID of the Advertiser to find
     * @type {number}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetDashboardTotals
     */
    readonly advertiserId: number

    /**
     * 
     * @type {string}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetDashboardTotals
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetDashboardTotals
     */
    readonly to: string
}

/**
 * Request parameters for advertiserGetTopPrograms operation in AdvertiserDashboardDataApi.
 * @export
 * @interface AdvertiserDashboardDataApiAdvertiserGetTopProgramsRequest
 */
export interface AdvertiserDashboardDataApiAdvertiserGetTopProgramsRequest {
    /**
     * Numeric ID of the Advertiser to find
     * @type {number}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetTopPrograms
     */
    readonly advertiserId: number

    /**
     * 
     * @type {string}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetTopPrograms
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetTopPrograms
     */
    readonly to: string
}

/**
 * Request parameters for advertiserGetTopSites operation in AdvertiserDashboardDataApi.
 * @export
 * @interface AdvertiserDashboardDataApiAdvertiserGetTopSitesRequest
 */
export interface AdvertiserDashboardDataApiAdvertiserGetTopSitesRequest {
    /**
     * Numeric ID of the Advertiser to find
     * @type {number}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetTopSites
     */
    readonly advertiserId: number

    /**
     * 
     * @type {string}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetTopSites
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof AdvertiserDashboardDataApiAdvertiserGetTopSites
     */
    readonly to: string
}

/**
 * AdvertiserDashboardDataApi - object-oriented interface
 * @export
 * @class AdvertiserDashboardDataApi
 * @extends {BaseAPI}
 */
export class AdvertiserDashboardDataApi extends BaseAPI {
    /**
     * 
     * @summary Get Dashboard Totals
     * @param {AdvertiserDashboardDataApiAdvertiserGetDashboardTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserDashboardDataApi
     */
    public advertiserGetDashboardTotals(requestParameters: AdvertiserDashboardDataApiAdvertiserGetDashboardTotalsRequest, options?: AxiosRequestConfig) {
        return AdvertiserDashboardDataApiFp(this.configuration).advertiserGetDashboardTotals(requestParameters.advertiserId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Top Programs
     * @param {AdvertiserDashboardDataApiAdvertiserGetTopProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserDashboardDataApi
     */
    public advertiserGetTopPrograms(requestParameters: AdvertiserDashboardDataApiAdvertiserGetTopProgramsRequest, options?: AxiosRequestConfig) {
        return AdvertiserDashboardDataApiFp(this.configuration).advertiserGetTopPrograms(requestParameters.advertiserId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Top Sites
     * @param {AdvertiserDashboardDataApiAdvertiserGetTopSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserDashboardDataApi
     */
    public advertiserGetTopSites(requestParameters: AdvertiserDashboardDataApiAdvertiserGetTopSitesRequest, options?: AxiosRequestConfig) {
        return AdvertiserDashboardDataApiFp(this.configuration).advertiserGetTopSites(requestParameters.advertiserId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }
}
