/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAffiliateAccountUsageLogs200Response } from '../AfClApiModels';
/**
 * AdminAffiliateAccountUsageLogsApi - axios parameter creator
 * @export
 */
export const AdminAffiliateAccountUsageLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Affiliate Account Usage Logs
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to find
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountUsageLogs: async (affiliateAccountId: number, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminGetAffiliateAccountUsageLogs', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/usage-log`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAffiliateAccountUsageLogsApi - functional programming interface
 * @export
 */
export const AdminAffiliateAccountUsageLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAffiliateAccountUsageLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Affiliate Account Usage Logs
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to find
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccountUsageLogs(affiliateAccountId: number, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAffiliateAccountUsageLogs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccountUsageLogs(affiliateAccountId, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAffiliateAccountUsageLogsApi - factory interface
 * @export
 */
export const AdminAffiliateAccountUsageLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAffiliateAccountUsageLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Affiliate Account Usage Logs
         * @param {AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountUsageLogs(requestParameters: AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogsRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetAffiliateAccountUsageLogs200Response> {
            return localVarFp.adminGetAffiliateAccountUsageLogs(requestParameters.affiliateAccountId, requestParameters.perPage, requestParameters.page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminGetAffiliateAccountUsageLogs operation in AdminAffiliateAccountUsageLogsApi.
 * @export
 * @interface AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogsRequest
 */
export interface AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogsRequest {
    /**
     * Numeric ID of the Affiliate Account to find
     * @type {number}
     * @memberof AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogs
     */
    readonly affiliateAccountId: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogs
     */
    readonly perPage?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogs
     */
    readonly page?: number
}

/**
 * AdminAffiliateAccountUsageLogsApi - object-oriented interface
 * @export
 * @class AdminAffiliateAccountUsageLogsApi
 * @extends {BaseAPI}
 */
export class AdminAffiliateAccountUsageLogsApi extends BaseAPI {
    /**
     * 
     * @summary Get Affiliate Account Usage Logs
     * @param {AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountUsageLogsApi
     */
    public adminGetAffiliateAccountUsageLogs(requestParameters: AdminAffiliateAccountUsageLogsApiAdminGetAffiliateAccountUsageLogsRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountUsageLogsApiFp(this.configuration).adminGetAffiliateAccountUsageLogs(requestParameters.affiliateAccountId, requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }
}
