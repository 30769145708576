/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminStorePublisherInvoiceRequestInvoiceItemsInner } from '../AfClApiModels';
// @ts-ignore
import { GetPublisherCorporations200Response } from '../AfClApiModels';
// @ts-ignore
import { PublisherInvoice } from '../AfClApiModels';
// @ts-ignore
import { PublisherInvoiceItem } from '../AfClApiModels';
/**
 * AdminPublisherInvoicesApi - axios parameter creator
 * @export
 */
export const AdminPublisherInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all corporations linked to the publisher through his sites
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherCorporations: async (publisherId: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherCorporations', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/invoices/corporations`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all invoice items linked to the publisher for his sites on the specified corporation
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} date 
         * @param {number} corporationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherInvoiceItems: async (publisherId: number, date: string, corporationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherInvoiceItems', 'publisherId', publisherId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPublisherInvoiceItems', 'date', date)
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('getPublisherInvoiceItems', 'corporationId', corporationId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/invoices/invoice-items`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (corporationId !== undefined) {
                localVarQueryParameter['corporation_id'] = corporationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish Publisher Invoice
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} invoiceId Numeric ID of the invoice to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishPublisherInvoice: async (publisherId: number, invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publishPublisherInvoice', 'publisherId', publisherId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('publishPublisherInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/invoices/{invoiceId}/publish`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Publisher Invoice
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} period 
         * @param {Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>} invoiceItems 
         * @param {string} action 
         * @param {number} corporationId 
         * @param {File} [invoiceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePublisherInvoice: async (publisherId: number, period: string, invoiceItems: Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>, action: string, corporationId: number, invoiceFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('storePublisherInvoice', 'publisherId', publisherId)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('storePublisherInvoice', 'period', period)
            // verify required parameter 'invoiceItems' is not null or undefined
            assertParamExists('storePublisherInvoice', 'invoiceItems', invoiceItems)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('storePublisherInvoice', 'action', action)
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('storePublisherInvoice', 'corporationId', corporationId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/invoices`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (period !== undefined) { 
                localVarFormParams.append('period', period as any);
            }
                if (invoiceItems) {
                localVarFormParams.append('invoice_items', invoiceItems.join(COLLECTION_FORMATS.csv));
            }

    
            if (invoiceFile !== undefined) { 
                localVarFormParams.append('invoice_file', invoiceFile as any);
            }
    
            if (action !== undefined) { 
                localVarFormParams.append('action', action as any);
            }
    
            if (corporationId !== undefined) { 
                localVarFormParams.append('corporation_id', corporationId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Publisher Invoice
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} invoiceId Numeric ID of the invoice to search
         * @param {string} period 
         * @param {Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>} invoiceItems 
         * @param {File} [invoiceFile] 
         * @param {number} [corporationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherInvoice: async (publisherId: number, invoiceId: number, period: string, invoiceItems: Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>, invoiceFile?: File, corporationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisherInvoice', 'publisherId', publisherId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('updatePublisherInvoice', 'invoiceId', invoiceId)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('updatePublisherInvoice', 'period', period)
            // verify required parameter 'invoiceItems' is not null or undefined
            assertParamExists('updatePublisherInvoice', 'invoiceItems', invoiceItems)
            const localVarPath = `/v1/admin/publishers/{publisherId}/invoices/{invoiceId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (period !== undefined) { 
                localVarFormParams.append('period', period as any);
            }
                if (invoiceItems) {
                localVarFormParams.append('invoice_items', invoiceItems.join(COLLECTION_FORMATS.csv));
            }

    
            if (invoiceFile !== undefined) { 
                localVarFormParams.append('invoice_file', invoiceFile as any);
            }
    
            if (corporationId !== undefined) { 
                localVarFormParams.append('corporation_id', corporationId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublisherInvoicesApi - functional programming interface
 * @export
 */
export const AdminPublisherInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublisherInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all corporations linked to the publisher through his sites
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherCorporations(publisherId: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublisherCorporations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherCorporations(publisherId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all invoice items linked to the publisher for his sites on the specified corporation
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} date 
         * @param {number} corporationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherInvoiceItems(publisherId: number, date: string, corporationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherInvoiceItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherInvoiceItems(publisherId, date, corporationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish Publisher Invoice
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} invoiceId Numeric ID of the invoice to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishPublisherInvoice(publisherId: number, invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishPublisherInvoice(publisherId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Publisher Invoice
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {string} period 
         * @param {Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>} invoiceItems 
         * @param {string} action 
         * @param {number} corporationId 
         * @param {File} [invoiceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePublisherInvoice(publisherId: number, period: string, invoiceItems: Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>, action: string, corporationId: number, invoiceFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePublisherInvoice(publisherId, period, invoiceItems, action, corporationId, invoiceFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Publisher Invoice
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} invoiceId Numeric ID of the invoice to search
         * @param {string} period 
         * @param {Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>} invoiceItems 
         * @param {File} [invoiceFile] 
         * @param {number} [corporationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherInvoice(publisherId: number, invoiceId: number, period: string, invoiceItems: Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>, invoiceFile?: File, corporationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherInvoice(publisherId, invoiceId, period, invoiceItems, invoiceFile, corporationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublisherInvoicesApi - factory interface
 * @export
 */
export const AdminPublisherInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublisherInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all corporations linked to the publisher through his sites
         * @param {AdminPublisherInvoicesApiGetPublisherCorporationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherCorporations(requestParameters: AdminPublisherInvoicesApiGetPublisherCorporationsRequest, options?: AxiosRequestConfig): AxiosPromise<GetPublisherCorporations200Response> {
            return localVarFp.getPublisherCorporations(requestParameters.publisherId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all invoice items linked to the publisher for his sites on the specified corporation
         * @param {AdminPublisherInvoicesApiGetPublisherInvoiceItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherInvoiceItems(requestParameters: AdminPublisherInvoicesApiGetPublisherInvoiceItemsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherInvoiceItem>> {
            return localVarFp.getPublisherInvoiceItems(requestParameters.publisherId, requestParameters.date, requestParameters.corporationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish Publisher Invoice
         * @param {AdminPublisherInvoicesApiPublishPublisherInvoiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishPublisherInvoice(requestParameters: AdminPublisherInvoicesApiPublishPublisherInvoiceRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherInvoice> {
            return localVarFp.publishPublisherInvoice(requestParameters.publisherId, requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Publisher Invoice
         * @param {AdminPublisherInvoicesApiStorePublisherInvoiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePublisherInvoice(requestParameters: AdminPublisherInvoicesApiStorePublisherInvoiceRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherInvoice> {
            return localVarFp.storePublisherInvoice(requestParameters.publisherId, requestParameters.period, requestParameters.invoiceItems, requestParameters.action, requestParameters.corporationId, requestParameters.invoiceFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Publisher Invoice
         * @param {AdminPublisherInvoicesApiUpdatePublisherInvoiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherInvoice(requestParameters: AdminPublisherInvoicesApiUpdatePublisherInvoiceRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherInvoice> {
            return localVarFp.updatePublisherInvoice(requestParameters.publisherId, requestParameters.invoiceId, requestParameters.period, requestParameters.invoiceItems, requestParameters.invoiceFile, requestParameters.corporationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPublisherCorporations operation in AdminPublisherInvoicesApi.
 * @export
 * @interface AdminPublisherInvoicesApiGetPublisherCorporationsRequest
 */
export interface AdminPublisherInvoicesApiGetPublisherCorporationsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiGetPublisherCorporations
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof AdminPublisherInvoicesApiGetPublisherCorporations
     */
    readonly name?: string
}

/**
 * Request parameters for getPublisherInvoiceItems operation in AdminPublisherInvoicesApi.
 * @export
 * @interface AdminPublisherInvoicesApiGetPublisherInvoiceItemsRequest
 */
export interface AdminPublisherInvoicesApiGetPublisherInvoiceItemsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiGetPublisherInvoiceItems
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof AdminPublisherInvoicesApiGetPublisherInvoiceItems
     */
    readonly date: string

    /**
     * 
     * @type {number}
     * @memberof AdminPublisherInvoicesApiGetPublisherInvoiceItems
     */
    readonly corporationId: number
}

/**
 * Request parameters for publishPublisherInvoice operation in AdminPublisherInvoicesApi.
 * @export
 * @interface AdminPublisherInvoicesApiPublishPublisherInvoiceRequest
 */
export interface AdminPublisherInvoicesApiPublishPublisherInvoiceRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiPublishPublisherInvoice
     */
    readonly publisherId: number

    /**
     * Numeric ID of the invoice to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiPublishPublisherInvoice
     */
    readonly invoiceId: number
}

/**
 * Request parameters for storePublisherInvoice operation in AdminPublisherInvoicesApi.
 * @export
 * @interface AdminPublisherInvoicesApiStorePublisherInvoiceRequest
 */
export interface AdminPublisherInvoicesApiStorePublisherInvoiceRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiStorePublisherInvoice
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof AdminPublisherInvoicesApiStorePublisherInvoice
     */
    readonly period: string

    /**
     * 
     * @type {Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>}
     * @memberof AdminPublisherInvoicesApiStorePublisherInvoice
     */
    readonly invoiceItems: Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>

    /**
     * 
     * @type {string}
     * @memberof AdminPublisherInvoicesApiStorePublisherInvoice
     */
    readonly action: string

    /**
     * 
     * @type {number}
     * @memberof AdminPublisherInvoicesApiStorePublisherInvoice
     */
    readonly corporationId: number

    /**
     * 
     * @type {File}
     * @memberof AdminPublisherInvoicesApiStorePublisherInvoice
     */
    readonly invoiceFile?: File
}

/**
 * Request parameters for updatePublisherInvoice operation in AdminPublisherInvoicesApi.
 * @export
 * @interface AdminPublisherInvoicesApiUpdatePublisherInvoiceRequest
 */
export interface AdminPublisherInvoicesApiUpdatePublisherInvoiceRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiUpdatePublisherInvoice
     */
    readonly publisherId: number

    /**
     * Numeric ID of the invoice to search
     * @type {number}
     * @memberof AdminPublisherInvoicesApiUpdatePublisherInvoice
     */
    readonly invoiceId: number

    /**
     * 
     * @type {string}
     * @memberof AdminPublisherInvoicesApiUpdatePublisherInvoice
     */
    readonly period: string

    /**
     * 
     * @type {Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>}
     * @memberof AdminPublisherInvoicesApiUpdatePublisherInvoice
     */
    readonly invoiceItems: Array<AdminStorePublisherInvoiceRequestInvoiceItemsInner>

    /**
     * 
     * @type {File}
     * @memberof AdminPublisherInvoicesApiUpdatePublisherInvoice
     */
    readonly invoiceFile?: File

    /**
     * 
     * @type {number}
     * @memberof AdminPublisherInvoicesApiUpdatePublisherInvoice
     */
    readonly corporationId?: number
}

/**
 * AdminPublisherInvoicesApi - object-oriented interface
 * @export
 * @class AdminPublisherInvoicesApi
 * @extends {BaseAPI}
 */
export class AdminPublisherInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Get all corporations linked to the publisher through his sites
     * @param {AdminPublisherInvoicesApiGetPublisherCorporationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherInvoicesApi
     */
    public getPublisherCorporations(requestParameters: AdminPublisherInvoicesApiGetPublisherCorporationsRequest, options?: AxiosRequestConfig) {
        return AdminPublisherInvoicesApiFp(this.configuration).getPublisherCorporations(requestParameters.publisherId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all invoice items linked to the publisher for his sites on the specified corporation
     * @param {AdminPublisherInvoicesApiGetPublisherInvoiceItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherInvoicesApi
     */
    public getPublisherInvoiceItems(requestParameters: AdminPublisherInvoicesApiGetPublisherInvoiceItemsRequest, options?: AxiosRequestConfig) {
        return AdminPublisherInvoicesApiFp(this.configuration).getPublisherInvoiceItems(requestParameters.publisherId, requestParameters.date, requestParameters.corporationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish Publisher Invoice
     * @param {AdminPublisherInvoicesApiPublishPublisherInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherInvoicesApi
     */
    public publishPublisherInvoice(requestParameters: AdminPublisherInvoicesApiPublishPublisherInvoiceRequest, options?: AxiosRequestConfig) {
        return AdminPublisherInvoicesApiFp(this.configuration).publishPublisherInvoice(requestParameters.publisherId, requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Publisher Invoice
     * @param {AdminPublisherInvoicesApiStorePublisherInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherInvoicesApi
     */
    public storePublisherInvoice(requestParameters: AdminPublisherInvoicesApiStorePublisherInvoiceRequest, options?: AxiosRequestConfig) {
        return AdminPublisherInvoicesApiFp(this.configuration).storePublisherInvoice(requestParameters.publisherId, requestParameters.period, requestParameters.invoiceItems, requestParameters.action, requestParameters.corporationId, requestParameters.invoiceFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Publisher Invoice
     * @param {AdminPublisherInvoicesApiUpdatePublisherInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherInvoicesApi
     */
    public updatePublisherInvoice(requestParameters: AdminPublisherInvoicesApiUpdatePublisherInvoiceRequest, options?: AxiosRequestConfig) {
        return AdminPublisherInvoicesApiFp(this.configuration).updatePublisherInvoice(requestParameters.publisherId, requestParameters.invoiceId, requestParameters.period, requestParameters.invoiceItems, requestParameters.invoiceFile, requestParameters.corporationId, options).then((request) => request(this.axios, this.basePath));
    }
}
