/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * MaintenanceAdminApiReferenceApi - axios parameter creator
 * @export
 */
export const MaintenanceAdminApiReferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Admin Api Reference
         * @param {'postbacks' | 'currency' | 'redirector' | 'rakecollect'} [service] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminApiReference: async (service?: 'postbacks' | 'currency' | 'redirector' | 'rakecollect', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/maintenance/admin-api-reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceAdminApiReferenceApi - functional programming interface
 * @export
 */
export const MaintenanceAdminApiReferenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceAdminApiReferenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Admin Api Reference
         * @param {'postbacks' | 'currency' | 'redirector' | 'rakecollect'} [service] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminApiReference(service?: 'postbacks' | 'currency' | 'redirector' | 'rakecollect', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminApiReference(service, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceAdminApiReferenceApi - factory interface
 * @export
 */
export const MaintenanceAdminApiReferenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceAdminApiReferenceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Admin Api Reference
         * @param {MaintenanceAdminApiReferenceApiGetAdminApiReferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminApiReference(requestParameters: MaintenanceAdminApiReferenceApiGetAdminApiReferenceRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getAdminApiReference(requestParameters.service, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdminApiReference operation in MaintenanceAdminApiReferenceApi.
 * @export
 * @interface MaintenanceAdminApiReferenceApiGetAdminApiReferenceRequest
 */
export interface MaintenanceAdminApiReferenceApiGetAdminApiReferenceRequest {
    /**
     * 
     * @type {'postbacks' | 'currency' | 'redirector' | 'rakecollect'}
     * @memberof MaintenanceAdminApiReferenceApiGetAdminApiReference
     */
    readonly service?: 'postbacks' | 'currency' | 'redirector' | 'rakecollect'
}

/**
 * MaintenanceAdminApiReferenceApi - object-oriented interface
 * @export
 * @class MaintenanceAdminApiReferenceApi
 * @extends {BaseAPI}
 */
export class MaintenanceAdminApiReferenceApi extends BaseAPI {
    /**
     * 
     * @summary Get Admin Api Reference
     * @param {MaintenanceAdminApiReferenceApiGetAdminApiReferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAdminApiReferenceApi
     */
    public getAdminApiReference(requestParameters: MaintenanceAdminApiReferenceApiGetAdminApiReferenceRequest = {}, options?: AxiosRequestConfig) {
        return MaintenanceAdminApiReferenceApiFp(this.configuration).getAdminApiReference(requestParameters.service, options).then((request) => request(this.axios, this.basePath));
    }
}
