/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetPostbacksReport200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminPostbacksReportTotalsResource } from '../AfClApiModels';
/**
 * AdminPostbacksReportApi - axios parameter creator
 * @export
 */
export const AdminPostbacksReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Postbacks Report
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [redirectCode] 
         * @param {string} [campaignName] 
         * @param {number} [verticalId] 
         * @param {number} [publisherId] 
         * @param {Array<number>} [brandIds] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportPostbacksReport: async (from: string, to: string, redirectCode?: string, campaignName?: string, verticalId?: number, publisherId?: number, brandIds?: Array<number>, eventType?: 'signup' | 'deposit', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('adminExportPostbacksReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('adminExportPostbacksReport', 'to', to)
            const localVarPath = `/v1/admin/postbacks/report/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (redirectCode !== undefined) {
                localVarQueryParameter['redirect_code'] = redirectCode;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (verticalId !== undefined) {
                localVarQueryParameter['vertical_id'] = verticalId;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event_type'] = eventType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Postbacks Report
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [redirectCode] 
         * @param {number} [verticalId] 
         * @param {number} [publisherId] 
         * @param {Array<number>} [brandIds] 
         * @param {string} [campaignName] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPostbacksReport: async (from: string, to: string, redirectCode?: string, verticalId?: number, publisherId?: number, brandIds?: Array<number>, campaignName?: string, eventType?: 'signup' | 'deposit', sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('adminGetPostbacksReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('adminGetPostbacksReport', 'to', to)
            const localVarPath = `/v1/admin/postbacks/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (redirectCode !== undefined) {
                localVarQueryParameter['redirect_code'] = redirectCode;
            }

            if (verticalId !== undefined) {
                localVarQueryParameter['vertical_id'] = verticalId;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event_type'] = eventType;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Postbacks Report Totals
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [redirectCode] 
         * @param {string} [campaignName] 
         * @param {number} [verticalId] 
         * @param {number} [publisherId] 
         * @param {Array<number>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPostbacksReportTotals: async (from: string, to: string, redirectCode?: string, campaignName?: string, verticalId?: number, publisherId?: number, brandIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('adminGetPostbacksReportTotals', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('adminGetPostbacksReportTotals', 'to', to)
            const localVarPath = `/v1/admin/postbacks/report/totals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (redirectCode !== undefined) {
                localVarQueryParameter['redirect_code'] = redirectCode;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (verticalId !== undefined) {
                localVarQueryParameter['vertical_id'] = verticalId;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPostbacksReportApi - functional programming interface
 * @export
 */
export const AdminPostbacksReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPostbacksReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Postbacks Report
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [redirectCode] 
         * @param {string} [campaignName] 
         * @param {number} [verticalId] 
         * @param {number} [publisherId] 
         * @param {Array<number>} [brandIds] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportPostbacksReport(from: string, to: string, redirectCode?: string, campaignName?: string, verticalId?: number, publisherId?: number, brandIds?: Array<number>, eventType?: 'signup' | 'deposit', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportPostbacksReport(from, to, redirectCode, campaignName, verticalId, publisherId, brandIds, eventType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Postbacks Report
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [redirectCode] 
         * @param {number} [verticalId] 
         * @param {number} [publisherId] 
         * @param {Array<number>} [brandIds] 
         * @param {string} [campaignName] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPostbacksReport(from: string, to: string, redirectCode?: string, verticalId?: number, publisherId?: number, brandIds?: Array<number>, campaignName?: string, eventType?: 'signup' | 'deposit', sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetPostbacksReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPostbacksReport(from, to, redirectCode, verticalId, publisherId, brandIds, campaignName, eventType, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Postbacks Report Totals
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {string} [redirectCode] 
         * @param {string} [campaignName] 
         * @param {number} [verticalId] 
         * @param {number} [publisherId] 
         * @param {Array<number>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetPostbacksReportTotals(from: string, to: string, redirectCode?: string, campaignName?: string, verticalId?: number, publisherId?: number, brandIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPostbacksReportTotalsResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetPostbacksReportTotals(from, to, redirectCode, campaignName, verticalId, publisherId, brandIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPostbacksReportApi - factory interface
 * @export
 */
export const AdminPostbacksReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPostbacksReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Postbacks Report
         * @param {AdminPostbacksReportApiAdminExportPostbacksReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportPostbacksReport(requestParameters: AdminPostbacksReportApiAdminExportPostbacksReportRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.adminExportPostbacksReport(requestParameters.from, requestParameters.to, requestParameters.redirectCode, requestParameters.campaignName, requestParameters.verticalId, requestParameters.publisherId, requestParameters.brandIds, requestParameters.eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Postbacks Report
         * @param {AdminPostbacksReportApiAdminGetPostbacksReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPostbacksReport(requestParameters: AdminPostbacksReportApiAdminGetPostbacksReportRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetPostbacksReport200Response> {
            return localVarFp.adminGetPostbacksReport(requestParameters.from, requestParameters.to, requestParameters.redirectCode, requestParameters.verticalId, requestParameters.publisherId, requestParameters.brandIds, requestParameters.campaignName, requestParameters.eventType, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Postbacks Report Totals
         * @param {AdminPostbacksReportApiAdminGetPostbacksReportTotalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetPostbacksReportTotals(requestParameters: AdminPostbacksReportApiAdminGetPostbacksReportTotalsRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPostbacksReportTotalsResource> {
            return localVarFp.adminGetPostbacksReportTotals(requestParameters.from, requestParameters.to, requestParameters.redirectCode, requestParameters.campaignName, requestParameters.verticalId, requestParameters.publisherId, requestParameters.brandIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminExportPostbacksReport operation in AdminPostbacksReportApi.
 * @export
 * @interface AdminPostbacksReportApiAdminExportPostbacksReportRequest
 */
export interface AdminPostbacksReportApiAdminExportPostbacksReportRequest {
    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly redirectCode?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly campaignName?: string

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly verticalId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly publisherId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {'signup' | 'deposit'}
     * @memberof AdminPostbacksReportApiAdminExportPostbacksReport
     */
    readonly eventType?: 'signup' | 'deposit'
}

/**
 * Request parameters for adminGetPostbacksReport operation in AdminPostbacksReportApi.
 * @export
 * @interface AdminPostbacksReportApiAdminGetPostbacksReportRequest
 */
export interface AdminPostbacksReportApiAdminGetPostbacksReportRequest {
    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly redirectCode?: string

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly verticalId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly publisherId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly campaignName?: string

    /**
     * 
     * @type {'signup' | 'deposit'}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly eventType?: 'signup' | 'deposit'

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReport
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminGetPostbacksReportTotals operation in AdminPostbacksReportApi.
 * @export
 * @interface AdminPostbacksReportApiAdminGetPostbacksReportTotalsRequest
 */
export interface AdminPostbacksReportApiAdminGetPostbacksReportTotalsRequest {
    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly redirectCode?: string

    /**
     * 
     * @type {string}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly campaignName?: string

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly verticalId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly publisherId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPostbacksReportApiAdminGetPostbacksReportTotals
     */
    readonly brandIds?: Array<number>
}

/**
 * AdminPostbacksReportApi - object-oriented interface
 * @export
 * @class AdminPostbacksReportApi
 * @extends {BaseAPI}
 */
export class AdminPostbacksReportApi extends BaseAPI {
    /**
     * 
     * @summary Export Postbacks Report
     * @param {AdminPostbacksReportApiAdminExportPostbacksReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPostbacksReportApi
     */
    public adminExportPostbacksReport(requestParameters: AdminPostbacksReportApiAdminExportPostbacksReportRequest, options?: AxiosRequestConfig) {
        return AdminPostbacksReportApiFp(this.configuration).adminExportPostbacksReport(requestParameters.from, requestParameters.to, requestParameters.redirectCode, requestParameters.campaignName, requestParameters.verticalId, requestParameters.publisherId, requestParameters.brandIds, requestParameters.eventType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Postbacks Report
     * @param {AdminPostbacksReportApiAdminGetPostbacksReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPostbacksReportApi
     */
    public adminGetPostbacksReport(requestParameters: AdminPostbacksReportApiAdminGetPostbacksReportRequest, options?: AxiosRequestConfig) {
        return AdminPostbacksReportApiFp(this.configuration).adminGetPostbacksReport(requestParameters.from, requestParameters.to, requestParameters.redirectCode, requestParameters.verticalId, requestParameters.publisherId, requestParameters.brandIds, requestParameters.campaignName, requestParameters.eventType, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Postbacks Report Totals
     * @param {AdminPostbacksReportApiAdminGetPostbacksReportTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPostbacksReportApi
     */
    public adminGetPostbacksReportTotals(requestParameters: AdminPostbacksReportApiAdminGetPostbacksReportTotalsRequest, options?: AxiosRequestConfig) {
        return AdminPostbacksReportApiFp(this.configuration).adminGetPostbacksReportTotals(requestParameters.from, requestParameters.to, requestParameters.redirectCode, requestParameters.campaignName, requestParameters.verticalId, requestParameters.publisherId, requestParameters.brandIds, options).then((request) => request(this.axios, this.basePath));
    }
}
