import { RouteLocationRaw } from 'vue-router';

export const buttonDefaults = {
    general: 'inline-flex items-stretch justify-center relative px-4 py-2 min-w-[85px]',
    borders: 'border-2',
    text: 'font-semibold',
    transitions: 'transition-colors duration-400',
    disabled: 'cursor-not-allowed opacity-50 pointer-events-none select-none'
};

export const buttonVariants = {
    primary: 'bg-rt-yellow text-rt-black hover:bg-rt-yellow-600 border-transparent',
    accent: 'bg-rt-green text-rt-white hover:bg-rt-green-600 border-transparent',
    dark: 'border-rt-black text-rt-black hover:border-rt-yellow',
    darkActive: 'border-rt-black bg-rt-black text-rt-white hover:border-rt-yellow',
    light: 'border-rt-white text-white hover:border-rt-yellow',
    secondary: 'bg-rt-white border-black',
    danger: 'bg-red-500 text-rt-white underline font-bold hover:bg-red-600 border-transparent',
    notification: 'bg-gray-100 border-0 border-b-2 rounded-md border-gray-200 hover:bg-gray-200 hover:border-gray-300 !py-1.5',
    asLink: 'bg-transparent border-0 text-rt-black hover:bg-gray-100',
    transparent: 'bg-transparent border-0 text-rt-black hover:bg-gray-100'
};

export const buttonSize = {
    small: 'text-xs py-1 px-2',
    medium: 'text-sm py-2.5 px-4',
    large: 'text-base py-3 px-6'
};

export interface ButtonProps {
    to?: RouteLocationRaw | null,
    variant?: keyof typeof buttonVariants,
    size?: keyof typeof buttonSize
    disabled?: boolean
    loading?: boolean
    title?: string | null;
    noCaps?: boolean;
    customClass?: string | null;
}
