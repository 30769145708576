/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminProgramDetailResource } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateProgramDetailRequest } from '../AfClApiModels';
/**
 * AdminProgramDetailsApi - axios parameter creator
 * @export
 */
export const AdminProgramDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Program Details
         * @param {number} programId Numeric ID of the program to find details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDetails: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramDetails', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/details`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Program Details
         * @param {number} programId Numeric ID of the program for details update
         * @param {AdminUpdateProgramDetailRequest} [adminUpdateProgramDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramDetails: async (programId: number, adminUpdateProgramDetailRequest?: AdminUpdateProgramDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('updateProgramDetails', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/details`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateProgramDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramDetailsApi - functional programming interface
 * @export
 */
export const AdminProgramDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Program Details
         * @param {number} programId Numeric ID of the program to find details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramDetails(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramDetailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramDetails(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Program Details
         * @param {number} programId Numeric ID of the program for details update
         * @param {AdminUpdateProgramDetailRequest} [adminUpdateProgramDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramDetails(programId: number, adminUpdateProgramDetailRequest?: AdminUpdateProgramDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramDetailResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramDetails(programId, adminUpdateProgramDetailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramDetailsApi - factory interface
 * @export
 */
export const AdminProgramDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Program Details
         * @param {AdminProgramDetailsApiGetProgramDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDetails(requestParameters: AdminProgramDetailsApiGetProgramDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramDetailResource> {
            return localVarFp.getProgramDetails(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Program Details
         * @param {AdminProgramDetailsApiUpdateProgramDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramDetails(requestParameters: AdminProgramDetailsApiUpdateProgramDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramDetailResource> {
            return localVarFp.updateProgramDetails(requestParameters.programId, requestParameters.adminUpdateProgramDetailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProgramDetails operation in AdminProgramDetailsApi.
 * @export
 * @interface AdminProgramDetailsApiGetProgramDetailsRequest
 */
export interface AdminProgramDetailsApiGetProgramDetailsRequest {
    /**
     * Numeric ID of the program to find details
     * @type {number}
     * @memberof AdminProgramDetailsApiGetProgramDetails
     */
    readonly programId: number
}

/**
 * Request parameters for updateProgramDetails operation in AdminProgramDetailsApi.
 * @export
 * @interface AdminProgramDetailsApiUpdateProgramDetailsRequest
 */
export interface AdminProgramDetailsApiUpdateProgramDetailsRequest {
    /**
     * Numeric ID of the program for details update
     * @type {number}
     * @memberof AdminProgramDetailsApiUpdateProgramDetails
     */
    readonly programId: number

    /**
     * 
     * @type {AdminUpdateProgramDetailRequest}
     * @memberof AdminProgramDetailsApiUpdateProgramDetails
     */
    readonly adminUpdateProgramDetailRequest?: AdminUpdateProgramDetailRequest
}

/**
 * AdminProgramDetailsApi - object-oriented interface
 * @export
 * @class AdminProgramDetailsApi
 * @extends {BaseAPI}
 */
export class AdminProgramDetailsApi extends BaseAPI {
    /**
     * 
     * @summary Get Program Details
     * @param {AdminProgramDetailsApiGetProgramDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDetailsApi
     */
    public getProgramDetails(requestParameters: AdminProgramDetailsApiGetProgramDetailsRequest, options?: AxiosRequestConfig) {
        return AdminProgramDetailsApiFp(this.configuration).getProgramDetails(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Program Details
     * @param {AdminProgramDetailsApiUpdateProgramDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDetailsApi
     */
    public updateProgramDetails(requestParameters: AdminProgramDetailsApiUpdateProgramDetailsRequest, options?: AxiosRequestConfig) {
        return AdminProgramDetailsApiFp(this.configuration).updateProgramDetails(requestParameters.programId, requestParameters.adminUpdateProgramDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
