/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasicPair } from '../AfClApiModels';
// @ts-ignore
import { GetBrands200Response } from '../AfClApiModels';
/**
 * BrandsApi - axios parameter creator
 * @export
 */
export const BrandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all available brands
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>} [status] 
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrands: async (status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>, name?: string, advertiserId?: number, siteId?: number, brandIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status) {
                localVarQueryParameter['status[]'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Brands Basic Pair
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [corporationId] 
         * @param {number} [advertiserId] 
         * @param {number} [siteGroupId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [withInactivePrograms] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandsBasicPair: async (name?: string, publisherId?: number, corporationId?: number, advertiserId?: number, siteGroupId?: number, siteId?: number, brandIds?: Array<number>, withInactivePrograms?: boolean, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/brands-basic-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (corporationId !== undefined) {
                localVarQueryParameter['corporation_id'] = corporationId;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (withInactivePrograms !== undefined) {
                localVarQueryParameter['with_inactive_programs'] = withInactivePrograms;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandsApi - functional programming interface
 * @export
 */
export const BrandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all available brands
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>} [status] 
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBrands(status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>, name?: string, advertiserId?: number, siteId?: number, brandIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrands200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBrands(status, name, advertiserId, siteId, brandIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Brands Basic Pair
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [corporationId] 
         * @param {number} [advertiserId] 
         * @param {number} [siteGroupId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [withInactivePrograms] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandsBasicPair(name?: string, publisherId?: number, corporationId?: number, advertiserId?: number, siteGroupId?: number, siteId?: number, brandIds?: Array<number>, withInactivePrograms?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicPair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsBasicPair(name, publisherId, corporationId, advertiserId, siteGroupId, siteId, brandIds, withInactivePrograms, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandsApi - factory interface
 * @export
 */
export const BrandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all available brands
         * @param {BrandsApiGetAllBrandsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrands(requestParameters: BrandsApiGetAllBrandsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetBrands200Response> {
            return localVarFp.getAllBrands(requestParameters.status, requestParameters.name, requestParameters.advertiserId, requestParameters.siteId, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Brands Basic Pair
         * @param {BrandsApiGetBrandsBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandsBasicPair(requestParameters: BrandsApiGetBrandsBasicPairRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<BasicPair>> {
            return localVarFp.getBrandsBasicPair(requestParameters.name, requestParameters.publisherId, requestParameters.corporationId, requestParameters.advertiserId, requestParameters.siteGroupId, requestParameters.siteId, requestParameters.brandIds, requestParameters.withInactivePrograms, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAllBrands operation in BrandsApi.
 * @export
 * @interface BrandsApiGetAllBrandsRequest
 */
export interface BrandsApiGetAllBrandsRequest {
    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>}
     * @memberof BrandsApiGetAllBrands
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>

    /**
     * 
     * @type {string}
     * @memberof BrandsApiGetAllBrands
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetAllBrands
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetAllBrands
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof BrandsApiGetAllBrands
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetAllBrands
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetAllBrands
     */
    readonly perPage?: number
}

/**
 * Request parameters for getBrandsBasicPair operation in BrandsApi.
 * @export
 * @interface BrandsApiGetBrandsBasicPairRequest
 */
export interface BrandsApiGetBrandsBasicPairRequest {
    /**
     * 
     * @type {string}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly publisherId?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly corporationId?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly withInactivePrograms?: boolean

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BrandsApiGetBrandsBasicPair
     */
    readonly perPage?: number
}

/**
 * BrandsApi - object-oriented interface
 * @export
 * @class BrandsApi
 * @extends {BaseAPI}
 */
export class BrandsApi extends BaseAPI {
    /**
     * 
     * @summary Get all available brands
     * @param {BrandsApiGetAllBrandsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public getAllBrands(requestParameters: BrandsApiGetAllBrandsRequest = {}, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).getAllBrands(requestParameters.status, requestParameters.name, requestParameters.advertiserId, requestParameters.siteId, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Brands Basic Pair
     * @param {BrandsApiGetBrandsBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public getBrandsBasicPair(requestParameters: BrandsApiGetBrandsBasicPairRequest = {}, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).getBrandsBasicPair(requestParameters.name, requestParameters.publisherId, requestParameters.corporationId, requestParameters.advertiserId, requestParameters.siteGroupId, requestParameters.siteId, requestParameters.brandIds, requestParameters.withInactivePrograms, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
