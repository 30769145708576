/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPublisherSiteProgram200Response } from '../AfClApiModels';
/**
 * PublisherSiteProgramsApi - axios parameter creator
 * @export
 */
export const PublisherSiteProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Publisher Site Program
         * @param {number} siteId Numeric ID of the Site to find
         * @param {number} publisherId Numeric ID of the Account to find
         * @param {'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'} [dealType] 
         * @param {string} [sort] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [programLabelNames] 
         * @param {Array<number>} [paymentSolutionsIds] 
         * @param {'Not Joined' | 'Pending' | 'Connected' | 'Live'} [linksStatus] 
         * @param {number} [brandId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherSiteProgram: async (siteId: number, publisherId: number, dealType?: 'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM', sort?: string, programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', programLabelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, paymentSolutionsIds?: Array<number>, linksStatus?: 'Not Joined' | 'Pending' | 'Connected' | 'Live', brandId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getPublisherSiteProgram', 'siteId', siteId)
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherSiteProgram', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/sites/{siteId}/programs`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dealType !== undefined) {
                localVarQueryParameter['deal_type'] = dealType;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (programStatus !== undefined) {
                localVarQueryParameter['program_status'] = programStatus;
            }

            if (programLabelNames) {
                localVarQueryParameter['program_label_names[]'] = programLabelNames;
            }

            if (paymentSolutionsIds) {
                localVarQueryParameter['payment_solutions_ids[]'] = paymentSolutionsIds;
            }

            if (linksStatus !== undefined) {
                localVarQueryParameter['links_status'] = linksStatus;
            }

            if (brandId !== undefined) {
                localVarQueryParameter['brand_id'] = brandId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherSiteProgramsApi - functional programming interface
 * @export
 */
export const PublisherSiteProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherSiteProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Site Program
         * @param {number} siteId Numeric ID of the Site to find
         * @param {number} publisherId Numeric ID of the Account to find
         * @param {'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'} [dealType] 
         * @param {string} [sort] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [programLabelNames] 
         * @param {Array<number>} [paymentSolutionsIds] 
         * @param {'Not Joined' | 'Pending' | 'Connected' | 'Live'} [linksStatus] 
         * @param {number} [brandId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherSiteProgram(siteId: number, publisherId: number, dealType?: 'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM', sort?: string, programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', programLabelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, paymentSolutionsIds?: Array<number>, linksStatus?: 'Not Joined' | 'Pending' | 'Connected' | 'Live', brandId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublisherSiteProgram200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherSiteProgram(siteId, publisherId, dealType, sort, programStatus, programLabelNames, paymentSolutionsIds, linksStatus, brandId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherSiteProgramsApi - factory interface
 * @export
 */
export const PublisherSiteProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherSiteProgramsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Site Program
         * @param {PublisherSiteProgramsApiGetPublisherSiteProgramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherSiteProgram(requestParameters: PublisherSiteProgramsApiGetPublisherSiteProgramRequest, options?: AxiosRequestConfig): AxiosPromise<GetPublisherSiteProgram200Response> {
            return localVarFp.getPublisherSiteProgram(requestParameters.siteId, requestParameters.publisherId, requestParameters.dealType, requestParameters.sort, requestParameters.programStatus, requestParameters.programLabelNames, requestParameters.paymentSolutionsIds, requestParameters.linksStatus, requestParameters.brandId, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPublisherSiteProgram operation in PublisherSiteProgramsApi.
 * @export
 * @interface PublisherSiteProgramsApiGetPublisherSiteProgramRequest
 */
export interface PublisherSiteProgramsApiGetPublisherSiteProgramRequest {
    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly siteId: number

    /**
     * Numeric ID of the Account to find
     * @type {number}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly publisherId: number

    /**
     * 
     * @type {'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly dealType?: 'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'

    /**
     * 
     * @type {string}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly sort?: string

    /**
     * 
     * @type {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'

    /**
     * 
     * @type {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly programLabelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly paymentSolutionsIds?: Array<number>

    /**
     * 
     * @type {'Not Joined' | 'Pending' | 'Connected' | 'Live'}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly linksStatus?: 'Not Joined' | 'Pending' | 'Connected' | 'Live'

    /**
     * 
     * @type {number}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly brandId?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherSiteProgramsApiGetPublisherSiteProgram
     */
    readonly perPage?: number
}

/**
 * PublisherSiteProgramsApi - object-oriented interface
 * @export
 * @class PublisherSiteProgramsApi
 * @extends {BaseAPI}
 */
export class PublisherSiteProgramsApi extends BaseAPI {
    /**
     * 
     * @summary Get Publisher Site Program
     * @param {PublisherSiteProgramsApiGetPublisherSiteProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSiteProgramsApi
     */
    public getPublisherSiteProgram(requestParameters: PublisherSiteProgramsApiGetPublisherSiteProgramRequest, options?: AxiosRequestConfig) {
        return PublisherSiteProgramsApiFp(this.configuration).getPublisherSiteProgram(requestParameters.siteId, requestParameters.publisherId, requestParameters.dealType, requestParameters.sort, requestParameters.programStatus, requestParameters.programLabelNames, requestParameters.paymentSolutionsIds, requestParameters.linksStatus, requestParameters.brandId, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
