import { useAuthStore } from '@/Stores/AuthStore';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';

export const EnsureAuthenticated: MiddlewareFunction = async (context) => {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
        return { name: 'login' };
    }
    return context.next();
};
