/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminProgramLicenseDaysToExpiration } from '../AfClApiModels';
/**
 * AdminProgramLicensesDaysToExpireApi - axios parameter creator
 * @export
 */
export const AdminProgramLicensesDaysToExpireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Program Licenses Days to Expire
         * @param {number} [licenseId] 
         * @param {string} [complianceName] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {number} [programId] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [complianceIds] 
         * @param {Array<number>} [accountCoordinatorIds] 
         * @param {Array<number>} [accountManagerIds] 
         * @param {number} [expiresIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramLicensesDaysToExpire: async (licenseId?: number, complianceName?: string, status?: 'ACTIVE' | 'INACTIVE', programId?: number, verticalIds?: Array<number>, regionIds?: Array<number>, complianceIds?: Array<number>, accountCoordinatorIds?: Array<number>, accountManagerIds?: Array<number>, expiresIn?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/program-licenses/days-to-expire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (licenseId !== undefined) {
                localVarQueryParameter['license_id'] = licenseId;
            }

            if (complianceName !== undefined) {
                localVarQueryParameter['compliance_name'] = complianceName;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (programId !== undefined) {
                localVarQueryParameter['program_id'] = programId;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (complianceIds) {
                localVarQueryParameter['compliance_ids[]'] = complianceIds;
            }

            if (accountCoordinatorIds) {
                localVarQueryParameter['account_coordinator_ids[]'] = accountCoordinatorIds;
            }

            if (accountManagerIds) {
                localVarQueryParameter['account_manager_ids[]'] = accountManagerIds;
            }

            if (expiresIn !== undefined) {
                localVarQueryParameter['expires_in'] = expiresIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramLicensesDaysToExpireApi - functional programming interface
 * @export
 */
export const AdminProgramLicensesDaysToExpireApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramLicensesDaysToExpireApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Program Licenses Days to Expire
         * @param {number} [licenseId] 
         * @param {string} [complianceName] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {number} [programId] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [complianceIds] 
         * @param {Array<number>} [accountCoordinatorIds] 
         * @param {Array<number>} [accountManagerIds] 
         * @param {number} [expiresIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramLicensesDaysToExpire(licenseId?: number, complianceName?: string, status?: 'ACTIVE' | 'INACTIVE', programId?: number, verticalIds?: Array<number>, regionIds?: Array<number>, complianceIds?: Array<number>, accountCoordinatorIds?: Array<number>, accountManagerIds?: Array<number>, expiresIn?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramLicenseDaysToExpiration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramLicensesDaysToExpire(licenseId, complianceName, status, programId, verticalIds, regionIds, complianceIds, accountCoordinatorIds, accountManagerIds, expiresIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramLicensesDaysToExpireApi - factory interface
 * @export
 */
export const AdminProgramLicensesDaysToExpireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramLicensesDaysToExpireApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Program Licenses Days to Expire
         * @param {AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpireRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramLicensesDaysToExpire(requestParameters: AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpireRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminProgramLicenseDaysToExpiration> {
            return localVarFp.getProgramLicensesDaysToExpire(requestParameters.licenseId, requestParameters.complianceName, requestParameters.status, requestParameters.programId, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.complianceIds, requestParameters.accountCoordinatorIds, requestParameters.accountManagerIds, requestParameters.expiresIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProgramLicensesDaysToExpire operation in AdminProgramLicensesDaysToExpireApi.
 * @export
 * @interface AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpireRequest
 */
export interface AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpireRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly licenseId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly complianceName?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE'}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly status?: 'ACTIVE' | 'INACTIVE'

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly programId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly complianceIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly accountCoordinatorIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly accountManagerIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpire
     */
    readonly expiresIn?: number
}

/**
 * AdminProgramLicensesDaysToExpireApi - object-oriented interface
 * @export
 * @class AdminProgramLicensesDaysToExpireApi
 * @extends {BaseAPI}
 */
export class AdminProgramLicensesDaysToExpireApi extends BaseAPI {
    /**
     * 
     * @summary Get Program Licenses Days to Expire
     * @param {AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpireRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLicensesDaysToExpireApi
     */
    public getProgramLicensesDaysToExpire(requestParameters: AdminProgramLicensesDaysToExpireApiGetProgramLicensesDaysToExpireRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramLicensesDaysToExpireApiFp(this.configuration).getProgramLicensesDaysToExpire(requestParameters.licenseId, requestParameters.complianceName, requestParameters.status, requestParameters.programId, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.complianceIds, requestParameters.accountCoordinatorIds, requestParameters.accountManagerIds, requestParameters.expiresIn, options).then((request) => request(this.axios, this.basePath));
    }
}
