<script setup lang="ts" generic="T">
import { TableHeader } from '@/Models/Components/BaseTableContract';
import { ref, computed } from 'vue';
import BaseTableMainRow from '@/Components/Core/Table/BaseTableMainRow.vue';
import { BiCaretUpFill, BiChevronUp } from 'oh-vue-icons/icons';
import { addIcons, OhVueIcon } from 'oh-vue-icons';

addIcons(BiCaretUpFill, BiChevronUp);

const {
    headers = [],
    item = null,
    expandItems = false,
    stickyColumn = false,
    dark = false,
    rowClass = '',
    cellClass = null,
} = defineProps<{
    // eslint-disable-next-line no-undef
    headers: TableHeader<T>[],
    // eslint-disable-next-line no-undef
    item: T
    stickyColumn?: boolean,
    expandItems?: boolean,
    dark?: boolean,
    rowClass?: string,
    cellClass?: string,
}>();

const opened = ref(false);

const columns = computed(() => headers.filter((header) => !header.action));

const toggle = () => {
    opened.value = !opened.value;
};


</script>
<template>
    <BaseTableMainRow
        :headers
        :item="(item as T)"
        :expand-items
        :sticky-column
        :dark
        :row-class
        :cell-class
    >
        <template
            v-for="(column) in columns"
            :key="column.key"
            #[column.key]
        >
            <slot
                :name="column.key"
                v-bind="{ ...column, column: item?.[column.key as string] ?? null }"
            />
        </template>

        <template #last_column>
            <td
                v-if="expandItems"
                class="cursor-pointer w-10"
                @click="toggle"
            >
                <OhVueIcon
                    class="duration-300 ml-auto mr-2"
                    :class="{ 'rotate-180': !opened }"
                    :name="dark ? 'bi-caret-up-fill': 'bi-chevron-up'"
                    scale="1.2"
                    :color="dark ? '#ffffff': '#000000'"
                />
            </td>
        </template>
    </BaseTableMainRow>
    <tr
        v-if="expandItems"
        v-show="opened"
        class="expanded-item-container"
    >
        <td
            class="base-table-cell"
            :colspan="headers.length + 1"
        >
            <slot
                name="expandedItem"
                :toggle
            />
        </td>
    </tr>
</template>
