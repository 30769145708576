import { AccountSelectionHelper } from '@/Services/Support/AccountSelectionHelper';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';
import { AccountType } from '@/Models/AccountType';

export const EnsureAccountSelected = (type: AccountType): MiddlewareFunction => {
    return async (context) => {
        const accountId = parseInt(context.to.params.accountId as string);
        const account = await AccountSelectionHelper.attemptSelectAccountById(accountId, type);
        if (!account) {
            return { name: 'accounts' };
        }
        return context.next();
    };
};
