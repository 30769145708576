import { useAccountStore } from '@/Stores/AccountStore';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';

export const AcceptedTerms: MiddlewareFunction = async (context) => {
    const accountStore = useAccountStore();
    if (accountStore.selectedAccount && accountStore.selectedAccount.type === 'ADVERTISER') {
        if (!accountStore.selectedAccount.is_tnc_approved) {
            return { name: 'advertiser.terms', params: { accountId: accountStore.selectedAccount.id } };
        }
        return await context.next();
    }
};
