import {
    AdvertiserAccount as AdvertiserAccountContract,
    AdvertiserAccountStatusEnum,
    CurrencyRateResource,
    Currency
} from '@/stub';

export class AdvertiserAccount {
    public readonly type = 'ADVERTISER';
    public _currencyRatesMap?: Map<string, number>;

    constructor(
        public id: number,
        public auth_team_id: number,
        public name: string,
        public is_tnc_approved: boolean,
        public status: AdvertiserAccountStatusEnum,
        public currency: Currency,
        public rates: CurrencyRateResource[]
    ) {
        this._currencyRatesMap = new Map();
    }

    get currencyRatesMap() {
        if (this._currencyRatesMap?.size === 0) {
            this._currencyRatesMap = new Map(
                this.rates?.map((rate: CurrencyRateResource) => [rate.currency, rate.rate])
            );
        }

        return this._currencyRatesMap;
    }

    static fromPlain(model: AdvertiserAccountContract) {
        return new this(
            model.id,
            model.auth_team_id,
            model.name,
            model.is_tnc_approved,
            model.status,
            model.currency,
            model.rates
        );
    }
}
