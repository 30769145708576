/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiGetAffiliateAccounts200Response } from '../AfClApiModels';
/**
 * BiAffiliateAccountsApi - axios parameter creator
 * @export
 */
export const BiAffiliateAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {string} [username] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {number} [affiliateSystemId] 
         * @param {string} [affiliateSystemName] 
         * @param {number} [advertiserId] 
         * @param {string} [updatedFrom] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetAffiliateAccounts: async (username?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', affiliateSystemAccountId?: string, affiliateSystemId?: number, affiliateSystemName?: string, advertiserId?: number, updatedFrom?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/affiliate-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (affiliateSystemAccountId !== undefined) {
                localVarQueryParameter['affiliate_system_account_id'] = affiliateSystemAccountId;
            }

            if (affiliateSystemId !== undefined) {
                localVarQueryParameter['affiliate_system_id'] = affiliateSystemId;
            }

            if (affiliateSystemName !== undefined) {
                localVarQueryParameter['affiliate_system_name'] = affiliateSystemName;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (updatedFrom !== undefined) {
                localVarQueryParameter['updated_from'] = (updatedFrom as any instanceof Date) ?
                    (updatedFrom as any).toISOString() :
                    updatedFrom;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiAffiliateAccountsApi - functional programming interface
 * @export
 */
export const BiAffiliateAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiAffiliateAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {string} [username] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {number} [affiliateSystemId] 
         * @param {string} [affiliateSystemName] 
         * @param {number} [advertiserId] 
         * @param {string} [updatedFrom] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biGetAffiliateAccounts(username?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', affiliateSystemAccountId?: string, affiliateSystemId?: number, affiliateSystemName?: string, advertiserId?: number, updatedFrom?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiGetAffiliateAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biGetAffiliateAccounts(username, status, affiliateSystemAccountId, affiliateSystemId, affiliateSystemName, advertiserId, updatedFrom, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiAffiliateAccountsApi - factory interface
 * @export
 */
export const BiAffiliateAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiAffiliateAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {BiAffiliateAccountsApiBiGetAffiliateAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetAffiliateAccounts(requestParameters: BiAffiliateAccountsApiBiGetAffiliateAccountsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BiGetAffiliateAccounts200Response> {
            return localVarFp.biGetAffiliateAccounts(requestParameters.username, requestParameters.status, requestParameters.affiliateSystemAccountId, requestParameters.affiliateSystemId, requestParameters.affiliateSystemName, requestParameters.advertiserId, requestParameters.updatedFrom, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for biGetAffiliateAccounts operation in BiAffiliateAccountsApi.
 * @export
 * @interface BiAffiliateAccountsApiBiGetAffiliateAccountsRequest
 */
export interface BiAffiliateAccountsApiBiGetAffiliateAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly username?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'

    /**
     * 
     * @type {string}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly affiliateSystemAccountId?: string

    /**
     * 
     * @type {number}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly affiliateSystemId?: number

    /**
     * 
     * @type {string}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly affiliateSystemName?: string

    /**
     * 
     * @type {number}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {string}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly updatedFrom?: string

    /**
     * 
     * @type {number}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BiAffiliateAccountsApiBiGetAffiliateAccounts
     */
    readonly perPage?: number
}

/**
 * BiAffiliateAccountsApi - object-oriented interface
 * @export
 * @class BiAffiliateAccountsApi
 * @extends {BaseAPI}
 */
export class BiAffiliateAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Get Affiliate Accounts
     * @param {BiAffiliateAccountsApiBiGetAffiliateAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiAffiliateAccountsApi
     */
    public biGetAffiliateAccounts(requestParameters: BiAffiliateAccountsApiBiGetAffiliateAccountsRequest = {}, options?: AxiosRequestConfig) {
        return BiAffiliateAccountsApiFp(this.configuration).biGetAffiliateAccounts(requestParameters.username, requestParameters.status, requestParameters.affiliateSystemAccountId, requestParameters.affiliateSystemId, requestParameters.affiliateSystemName, requestParameters.advertiserId, requestParameters.updatedFrom, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
