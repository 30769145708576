import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { AdminStoreMediaKeyRequest } from '@/stub';
import { AfClApi } from '@/Services/AfClApi';

export const useMediaKeysCreate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (payload: AdminStoreMediaKeyRequest) =>
            await AfClApi.adminMediaKeys.createMediaKey({
                adminStoreMediaKeyRequest: payload
            }),
        onSuccess: async () => {
            onSuccess ? onSuccess() : null;
            await queryClient.invalidateQueries(['media-keys']);
        },
    });
};
