/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminStoreProgramBonusRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateProgramBonusRequest } from '../AfClApiModels';
// @ts-ignore
import { ProgramBonusResource } from '../AfClApiModels';
/**
 * AdminProgramBonusesApi - axios parameter creator
 * @export
 */
export const AdminProgramBonusesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Program Bonus
         * @param {number} programId Numeric ID of the program for bonus creating
         * @param {AdminStoreProgramBonusRequest} [adminStoreProgramBonusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramBonus: async (programId: number, adminStoreProgramBonusRequest?: AdminStoreProgramBonusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('createProgramBonus', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/bonus`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreProgramBonusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Program Bonus
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} bonusId Numeric ID of the program bonus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramBonus: async (programId: number, bonusId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('deleteProgramBonus', 'programId', programId)
            // verify required parameter 'bonusId' is not null or undefined
            assertParamExists('deleteProgramBonus', 'bonusId', bonusId)
            const localVarPath = `/v1/admin/programs/{programId}/bonus/{bonusId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"bonusId"}}`, encodeURIComponent(String(bonusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Program Bonuses
         * @param {number} programId Numeric ID of the program to find bonuses
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [siteGroupIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramBonuses: async (programId: number, siteIds?: Array<number>, siteGroupIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramBonuses', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/bonus`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (siteGroupIds) {
                localVarQueryParameter['site_group_ids[]'] = siteGroupIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Program Bonus
         * @param {number} programId Numeric ID of the program for bonus update
         * @param {number} bonusId Numeric ID of the program bonus
         * @param {AdminUpdateProgramBonusRequest} [adminUpdateProgramBonusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramBonus: async (programId: number, bonusId: number, adminUpdateProgramBonusRequest?: AdminUpdateProgramBonusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('updateProgramBonus', 'programId', programId)
            // verify required parameter 'bonusId' is not null or undefined
            assertParamExists('updateProgramBonus', 'bonusId', bonusId)
            const localVarPath = `/v1/admin/programs/{programId}/bonus/{bonusId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"bonusId"}}`, encodeURIComponent(String(bonusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateProgramBonusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramBonusesApi - functional programming interface
 * @export
 */
export const AdminProgramBonusesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramBonusesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Program Bonus
         * @param {number} programId Numeric ID of the program for bonus creating
         * @param {AdminStoreProgramBonusRequest} [adminStoreProgramBonusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProgramBonus(programId: number, adminStoreProgramBonusRequest?: AdminStoreProgramBonusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramBonusResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProgramBonus(programId, adminStoreProgramBonusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Program Bonus
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} bonusId Numeric ID of the program bonus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramBonus(programId: number, bonusId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProgramBonus(programId, bonusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Program Bonuses
         * @param {number} programId Numeric ID of the program to find bonuses
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [siteGroupIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramBonuses(programId: number, siteIds?: Array<number>, siteGroupIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramBonusResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramBonuses(programId, siteIds, siteGroupIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Program Bonus
         * @param {number} programId Numeric ID of the program for bonus update
         * @param {number} bonusId Numeric ID of the program bonus
         * @param {AdminUpdateProgramBonusRequest} [adminUpdateProgramBonusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramBonus(programId: number, bonusId: number, adminUpdateProgramBonusRequest?: AdminUpdateProgramBonusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramBonusResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramBonus(programId, bonusId, adminUpdateProgramBonusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramBonusesApi - factory interface
 * @export
 */
export const AdminProgramBonusesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramBonusesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Program Bonus
         * @param {AdminProgramBonusesApiCreateProgramBonusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramBonus(requestParameters: AdminProgramBonusesApiCreateProgramBonusRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramBonusResource> {
            return localVarFp.createProgramBonus(requestParameters.programId, requestParameters.adminStoreProgramBonusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Program Bonus
         * @param {AdminProgramBonusesApiDeleteProgramBonusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramBonus(requestParameters: AdminProgramBonusesApiDeleteProgramBonusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProgramBonus(requestParameters.programId, requestParameters.bonusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Program Bonuses
         * @param {AdminProgramBonusesApiGetProgramBonusesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramBonuses(requestParameters: AdminProgramBonusesApiGetProgramBonusesRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramBonusResource> {
            return localVarFp.getProgramBonuses(requestParameters.programId, requestParameters.siteIds, requestParameters.siteGroupIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Program Bonus
         * @param {AdminProgramBonusesApiUpdateProgramBonusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramBonus(requestParameters: AdminProgramBonusesApiUpdateProgramBonusRequest, options?: AxiosRequestConfig): AxiosPromise<ProgramBonusResource> {
            return localVarFp.updateProgramBonus(requestParameters.programId, requestParameters.bonusId, requestParameters.adminUpdateProgramBonusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProgramBonus operation in AdminProgramBonusesApi.
 * @export
 * @interface AdminProgramBonusesApiCreateProgramBonusRequest
 */
export interface AdminProgramBonusesApiCreateProgramBonusRequest {
    /**
     * Numeric ID of the program for bonus creating
     * @type {number}
     * @memberof AdminProgramBonusesApiCreateProgramBonus
     */
    readonly programId: number

    /**
     * 
     * @type {AdminStoreProgramBonusRequest}
     * @memberof AdminProgramBonusesApiCreateProgramBonus
     */
    readonly adminStoreProgramBonusRequest?: AdminStoreProgramBonusRequest
}

/**
 * Request parameters for deleteProgramBonus operation in AdminProgramBonusesApi.
 * @export
 * @interface AdminProgramBonusesApiDeleteProgramBonusRequest
 */
export interface AdminProgramBonusesApiDeleteProgramBonusRequest {
    /**
     * Numeric ID of the program to create deal for
     * @type {number}
     * @memberof AdminProgramBonusesApiDeleteProgramBonus
     */
    readonly programId: number

    /**
     * Numeric ID of the program bonus
     * @type {number}
     * @memberof AdminProgramBonusesApiDeleteProgramBonus
     */
    readonly bonusId: number
}

/**
 * Request parameters for getProgramBonuses operation in AdminProgramBonusesApi.
 * @export
 * @interface AdminProgramBonusesApiGetProgramBonusesRequest
 */
export interface AdminProgramBonusesApiGetProgramBonusesRequest {
    /**
     * Numeric ID of the program to find bonuses
     * @type {number}
     * @memberof AdminProgramBonusesApiGetProgramBonuses
     */
    readonly programId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramBonusesApiGetProgramBonuses
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramBonusesApiGetProgramBonuses
     */
    readonly siteGroupIds?: Array<number>
}

/**
 * Request parameters for updateProgramBonus operation in AdminProgramBonusesApi.
 * @export
 * @interface AdminProgramBonusesApiUpdateProgramBonusRequest
 */
export interface AdminProgramBonusesApiUpdateProgramBonusRequest {
    /**
     * Numeric ID of the program for bonus update
     * @type {number}
     * @memberof AdminProgramBonusesApiUpdateProgramBonus
     */
    readonly programId: number

    /**
     * Numeric ID of the program bonus
     * @type {number}
     * @memberof AdminProgramBonusesApiUpdateProgramBonus
     */
    readonly bonusId: number

    /**
     * 
     * @type {AdminUpdateProgramBonusRequest}
     * @memberof AdminProgramBonusesApiUpdateProgramBonus
     */
    readonly adminUpdateProgramBonusRequest?: AdminUpdateProgramBonusRequest
}

/**
 * AdminProgramBonusesApi - object-oriented interface
 * @export
 * @class AdminProgramBonusesApi
 * @extends {BaseAPI}
 */
export class AdminProgramBonusesApi extends BaseAPI {
    /**
     * 
     * @summary Create Program Bonus
     * @param {AdminProgramBonusesApiCreateProgramBonusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramBonusesApi
     */
    public createProgramBonus(requestParameters: AdminProgramBonusesApiCreateProgramBonusRequest, options?: AxiosRequestConfig) {
        return AdminProgramBonusesApiFp(this.configuration).createProgramBonus(requestParameters.programId, requestParameters.adminStoreProgramBonusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Program Bonus
     * @param {AdminProgramBonusesApiDeleteProgramBonusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramBonusesApi
     */
    public deleteProgramBonus(requestParameters: AdminProgramBonusesApiDeleteProgramBonusRequest, options?: AxiosRequestConfig) {
        return AdminProgramBonusesApiFp(this.configuration).deleteProgramBonus(requestParameters.programId, requestParameters.bonusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Program Bonuses
     * @param {AdminProgramBonusesApiGetProgramBonusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramBonusesApi
     */
    public getProgramBonuses(requestParameters: AdminProgramBonusesApiGetProgramBonusesRequest, options?: AxiosRequestConfig) {
        return AdminProgramBonusesApiFp(this.configuration).getProgramBonuses(requestParameters.programId, requestParameters.siteIds, requestParameters.siteGroupIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Program Bonus
     * @param {AdminProgramBonusesApiUpdateProgramBonusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramBonusesApi
     */
    public updateProgramBonus(requestParameters: AdminProgramBonusesApiUpdateProgramBonusRequest, options?: AxiosRequestConfig) {
        return AdminProgramBonusesApiFp(this.configuration).updateProgramBonus(requestParameters.programId, requestParameters.bonusId, requestParameters.adminUpdateProgramBonusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
