/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminProgramLogoResource } from '../AfClApiModels';
/**
 * AdminProgramLogosApi - axios parameter creator
 * @export
 */
export const AdminProgramLogosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create logo for a specific program
         * @param {number} programId Numeric ID of the program
         * @param {File} logo 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramLogo: async (programId: number, logo: File, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('createProgramLogo', 'programId', programId)
            // verify required parameter 'logo' is not null or undefined
            assertParamExists('createProgramLogo', 'logo', logo)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('createProgramLogo', 'type', type)
            const localVarPath = `/v1/admin/programs/{programId}/logos`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete logo for a specific program
         * @param {number} programId Numeric ID of the program
         * @param {number} logoId Numeric ID of the logo to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramLogo: async (programId: number, logoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('deleteProgramLogo', 'programId', programId)
            // verify required parameter 'logoId' is not null or undefined
            assertParamExists('deleteProgramLogo', 'logoId', logoId)
            const localVarPath = `/v1/admin/programs/{programId}/logos/{logoId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"logoId"}}`, encodeURIComponent(String(logoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logos for a specific program
         * @param {number} programId Numeric ID of the program to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramLogos: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramLogos', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/logos`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update logo for a specific program
         * @param {number} programId Numeric ID of the program
         * @param {number} [id] 
         * @param {string} [background] 
         * @param {File} [file] 
         * @param {string} [themeColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLogo: async (programId: number, id?: number, background?: string, file?: File, themeColor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('updateProgramLogo', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/logos`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (background !== undefined) { 
                localVarFormParams.append('background', background as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (themeColor !== undefined) { 
                localVarFormParams.append('theme_color', themeColor as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramLogosApi - functional programming interface
 * @export
 */
export const AdminProgramLogosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramLogosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create logo for a specific program
         * @param {number} programId Numeric ID of the program
         * @param {File} logo 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProgramLogo(programId: number, logo: File, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramLogoResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProgramLogo(programId, logo, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete logo for a specific program
         * @param {number} programId Numeric ID of the program
         * @param {number} logoId Numeric ID of the logo to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramLogo(programId: number, logoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProgramLogo(programId, logoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get logos for a specific program
         * @param {number} programId Numeric ID of the program to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramLogos(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminProgramLogoResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramLogos(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update logo for a specific program
         * @param {number} programId Numeric ID of the program
         * @param {number} [id] 
         * @param {string} [background] 
         * @param {File} [file] 
         * @param {string} [themeColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramLogo(programId: number, id?: number, background?: string, file?: File, themeColor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramLogoResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramLogo(programId, id, background, file, themeColor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramLogosApi - factory interface
 * @export
 */
export const AdminProgramLogosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramLogosApiFp(configuration)
    return {
        /**
         * 
         * @summary Create logo for a specific program
         * @param {AdminProgramLogosApiCreateProgramLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramLogo(requestParameters: AdminProgramLogosApiCreateProgramLogoRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramLogoResource> {
            return localVarFp.createProgramLogo(requestParameters.programId, requestParameters.logo, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete logo for a specific program
         * @param {AdminProgramLogosApiDeleteProgramLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramLogo(requestParameters: AdminProgramLogosApiDeleteProgramLogoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProgramLogo(requestParameters.programId, requestParameters.logoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get logos for a specific program
         * @param {AdminProgramLogosApiGetProgramLogosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramLogos(requestParameters: AdminProgramLogosApiGetProgramLogosRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AdminProgramLogoResource>> {
            return localVarFp.getProgramLogos(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update logo for a specific program
         * @param {AdminProgramLogosApiUpdateProgramLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLogo(requestParameters: AdminProgramLogosApiUpdateProgramLogoRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramLogoResource> {
            return localVarFp.updateProgramLogo(requestParameters.programId, requestParameters.id, requestParameters.background, requestParameters.file, requestParameters.themeColor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProgramLogo operation in AdminProgramLogosApi.
 * @export
 * @interface AdminProgramLogosApiCreateProgramLogoRequest
 */
export interface AdminProgramLogosApiCreateProgramLogoRequest {
    /**
     * Numeric ID of the program
     * @type {number}
     * @memberof AdminProgramLogosApiCreateProgramLogo
     */
    readonly programId: number

    /**
     * 
     * @type {File}
     * @memberof AdminProgramLogosApiCreateProgramLogo
     */
    readonly logo: File

    /**
     * 
     * @type {string}
     * @memberof AdminProgramLogosApiCreateProgramLogo
     */
    readonly type: string
}

/**
 * Request parameters for deleteProgramLogo operation in AdminProgramLogosApi.
 * @export
 * @interface AdminProgramLogosApiDeleteProgramLogoRequest
 */
export interface AdminProgramLogosApiDeleteProgramLogoRequest {
    /**
     * Numeric ID of the program
     * @type {number}
     * @memberof AdminProgramLogosApiDeleteProgramLogo
     */
    readonly programId: number

    /**
     * Numeric ID of the logo to delete
     * @type {number}
     * @memberof AdminProgramLogosApiDeleteProgramLogo
     */
    readonly logoId: number
}

/**
 * Request parameters for getProgramLogos operation in AdminProgramLogosApi.
 * @export
 * @interface AdminProgramLogosApiGetProgramLogosRequest
 */
export interface AdminProgramLogosApiGetProgramLogosRequest {
    /**
     * Numeric ID of the program to search
     * @type {number}
     * @memberof AdminProgramLogosApiGetProgramLogos
     */
    readonly programId: number
}

/**
 * Request parameters for updateProgramLogo operation in AdminProgramLogosApi.
 * @export
 * @interface AdminProgramLogosApiUpdateProgramLogoRequest
 */
export interface AdminProgramLogosApiUpdateProgramLogoRequest {
    /**
     * Numeric ID of the program
     * @type {number}
     * @memberof AdminProgramLogosApiUpdateProgramLogo
     */
    readonly programId: number

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLogosApiUpdateProgramLogo
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof AdminProgramLogosApiUpdateProgramLogo
     */
    readonly background?: string

    /**
     * 
     * @type {File}
     * @memberof AdminProgramLogosApiUpdateProgramLogo
     */
    readonly file?: File

    /**
     * 
     * @type {string}
     * @memberof AdminProgramLogosApiUpdateProgramLogo
     */
    readonly themeColor?: string
}

/**
 * AdminProgramLogosApi - object-oriented interface
 * @export
 * @class AdminProgramLogosApi
 * @extends {BaseAPI}
 */
export class AdminProgramLogosApi extends BaseAPI {
    /**
     * 
     * @summary Create logo for a specific program
     * @param {AdminProgramLogosApiCreateProgramLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLogosApi
     */
    public createProgramLogo(requestParameters: AdminProgramLogosApiCreateProgramLogoRequest, options?: AxiosRequestConfig) {
        return AdminProgramLogosApiFp(this.configuration).createProgramLogo(requestParameters.programId, requestParameters.logo, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete logo for a specific program
     * @param {AdminProgramLogosApiDeleteProgramLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLogosApi
     */
    public deleteProgramLogo(requestParameters: AdminProgramLogosApiDeleteProgramLogoRequest, options?: AxiosRequestConfig) {
        return AdminProgramLogosApiFp(this.configuration).deleteProgramLogo(requestParameters.programId, requestParameters.logoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get logos for a specific program
     * @param {AdminProgramLogosApiGetProgramLogosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLogosApi
     */
    public getProgramLogos(requestParameters: AdminProgramLogosApiGetProgramLogosRequest, options?: AxiosRequestConfig) {
        return AdminProgramLogosApiFp(this.configuration).getProgramLogos(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update logo for a specific program
     * @param {AdminProgramLogosApiUpdateProgramLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLogosApi
     */
    public updateProgramLogo(requestParameters: AdminProgramLogosApiUpdateProgramLogoRequest, options?: AxiosRequestConfig) {
        return AdminProgramLogosApiFp(this.configuration).updateProgramLogo(requestParameters.programId, requestParameters.id, requestParameters.background, requestParameters.file, requestParameters.themeColor, options).then((request) => request(this.axios, this.basePath));
    }
}
