import { ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { AfClApi } from '@/Services/AfClApi';

// AC_MAINTENANCE_STATUS is a global variable set in the app entry point (resources\ts\ControlPanel\main.ts)
declare const AC_MAINTENANCE_STATUS: boolean;
export const maintenanceStatus = ref<boolean>(AC_MAINTENANCE_STATUS ?? false);

export const useMaintenance = (onSuccess?: (data) => void) => useQuery({
    queryKey: ['maintenance.status'],
    queryFn: async () => {
        const { data } = await AfClApi.maintenance.getMaintenanceStatus();
        return data || { status: false };
    },
    refetchInterval: 60000,
    onSuccess: async (response) => {
        maintenanceStatus.value = response.status || false;
        onSuccess ? onSuccess(response) : null;
    }
});
