import { Route } from 'vue-lite-route';

export const RegisterRedirectRoutes = () => {
    Route.add('/admin/sources', { redirect: { name: 'admin.operations.data-mapping' } } );
    Route.add('/admin/operations/dataMapping', { redirect: { name: 'admin.operations.data-mapping' } } );
    Route.add('/admin/operational/linkRequests', { redirect: { name: 'admin.operations.link-requests' } } );
    Route.add('/admin/operations/linkRequests', { redirect: { name: 'admin.operations.link-requests' } } );
    Route.add('/admin/operational/siteCompliances', { redirect: { name: 'admin.compliance.site-compliance' } } );
    Route.add('/admin/compliance/siteCompliance', { redirect: { name: 'admin.compliance.site-compliance' } } );
    Route.add('/admin/operational/programLicenses', { redirect: { name: 'admin.compliance.license-audit' } } );
    Route.add('/admin/compliance/licenseAudit', { redirect: { name: 'admin.compliance.license-audit' } } );
    Route.add('/admin/operational/compliances', { redirect: { name: 'admin.compliance.site-audit' } } );
    Route.add('/admin/operational/licenses', { redirect: { name: 'admin.compliance.licenses' } } );
    Route.add('/admin/accounts/affiliateAccounts', { redirect: { name: 'admin.operations.affiliate-accounts' } } );
    Route.add('/admin/operations/affiliateAccounts', { redirect: { name: 'admin.operations.affiliate-accounts' } } );
    Route.add('/admin/accounts/voonixAccounts', { redirect: { name: 'admin.operations.voonix-accounts' } } );
    Route.add('/admin/operations/voonixAccounts', { redirect: { name: 'admin.operations.voonix-accounts' } } );
    Route.add('/admin/operations/affiliateSystems', { redirect: { name: 'admin.operations.affiliate-systems' } } );
    Route.add('/admin/keys/info/media', { redirect: { name: 'admin.data.keys.media' } } );
    Route.add('/admin/keys/info/campaign', { redirect: { name: 'admin.data.keys.campaign' } } );
    Route.add('/admin/keys/info/source', { redirect: { name: 'admin.data.keys.source' } } );
    Route.add('/admin/keysmedia', { redirect: { name: 'admin.data.keys' } } );
};
