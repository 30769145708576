import { UserType } from '@/Enums/UserType';
import { maintenanceStatus } from '@/Services/ApiHooks/Administration/UseMaintenance';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';
import { useAuthStore } from '@/Stores/AuthStore';

export const MaintenanceCheck = (type: UserType): MiddlewareFunction => {
    return async (context) => {
        const authStore = useAuthStore();
        // AC Admin users are not block by the maintenance mode
        if (authStore.isACAdministrator) {
            return context.next();
        }

        if (Object.values(UserType).includes(type) && maintenanceStatus.value) {
            return { name: 'maintenance' };
        }

        return context.next();
    };
};
