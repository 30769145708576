/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdvertiserGetDashboardTotals200Response } from '../AfClApiModels';
// @ts-ignore
import { TopProgramResource } from '../AfClApiModels';
// @ts-ignore
import { TopSiteResource } from '../AfClApiModels';
/**
 * CorporationDashboardDataApi - axios parameter creator
 * @export
 */
export const CorporationDashboardDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Dashboard Totals
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetDashboardTotals: async (corporationId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('corporationGetDashboardTotals', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('corporationGetDashboardTotals', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('corporationGetDashboardTotals', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/totals`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Programs
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetTopPrograms: async (corporationId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('corporationGetTopPrograms', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('corporationGetTopPrograms', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('corporationGetTopPrograms', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/top-programs`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Top Sites
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetTopSites: async (corporationId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('corporationGetTopSites', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('corporationGetTopSites', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('corporationGetTopSites', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/top-sites`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorporationDashboardDataApi - functional programming interface
 * @export
 */
export const CorporationDashboardDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorporationDashboardDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Dashboard Totals
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corporationGetDashboardTotals(corporationId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertiserGetDashboardTotals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corporationGetDashboardTotals(corporationId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Top Programs
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corporationGetTopPrograms(corporationId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopProgramResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corporationGetTopPrograms(corporationId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Top Sites
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corporationGetTopSites(corporationId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopSiteResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.corporationGetTopSites(corporationId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorporationDashboardDataApi - factory interface
 * @export
 */
export const CorporationDashboardDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorporationDashboardDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Dashboard Totals
         * @param {CorporationDashboardDataApiCorporationGetDashboardTotalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetDashboardTotals(requestParameters: CorporationDashboardDataApiCorporationGetDashboardTotalsRequest, options?: AxiosRequestConfig): AxiosPromise<AdvertiserGetDashboardTotals200Response> {
            return localVarFp.corporationGetDashboardTotals(requestParameters.corporationId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Top Programs
         * @param {CorporationDashboardDataApiCorporationGetTopProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetTopPrograms(requestParameters: CorporationDashboardDataApiCorporationGetTopProgramsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TopProgramResource>> {
            return localVarFp.corporationGetTopPrograms(requestParameters.corporationId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Top Sites
         * @param {CorporationDashboardDataApiCorporationGetTopSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corporationGetTopSites(requestParameters: CorporationDashboardDataApiCorporationGetTopSitesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TopSiteResource>> {
            return localVarFp.corporationGetTopSites(requestParameters.corporationId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for corporationGetDashboardTotals operation in CorporationDashboardDataApi.
 * @export
 * @interface CorporationDashboardDataApiCorporationGetDashboardTotalsRequest
 */
export interface CorporationDashboardDataApiCorporationGetDashboardTotalsRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationDashboardDataApiCorporationGetDashboardTotals
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationDashboardDataApiCorporationGetDashboardTotals
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationDashboardDataApiCorporationGetDashboardTotals
     */
    readonly to: string
}

/**
 * Request parameters for corporationGetTopPrograms operation in CorporationDashboardDataApi.
 * @export
 * @interface CorporationDashboardDataApiCorporationGetTopProgramsRequest
 */
export interface CorporationDashboardDataApiCorporationGetTopProgramsRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationDashboardDataApiCorporationGetTopPrograms
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationDashboardDataApiCorporationGetTopPrograms
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationDashboardDataApiCorporationGetTopPrograms
     */
    readonly to: string
}

/**
 * Request parameters for corporationGetTopSites operation in CorporationDashboardDataApi.
 * @export
 * @interface CorporationDashboardDataApiCorporationGetTopSitesRequest
 */
export interface CorporationDashboardDataApiCorporationGetTopSitesRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationDashboardDataApiCorporationGetTopSites
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationDashboardDataApiCorporationGetTopSites
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationDashboardDataApiCorporationGetTopSites
     */
    readonly to: string
}

/**
 * CorporationDashboardDataApi - object-oriented interface
 * @export
 * @class CorporationDashboardDataApi
 * @extends {BaseAPI}
 */
export class CorporationDashboardDataApi extends BaseAPI {
    /**
     * 
     * @summary Get Dashboard Totals
     * @param {CorporationDashboardDataApiCorporationGetDashboardTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationDashboardDataApi
     */
    public corporationGetDashboardTotals(requestParameters: CorporationDashboardDataApiCorporationGetDashboardTotalsRequest, options?: AxiosRequestConfig) {
        return CorporationDashboardDataApiFp(this.configuration).corporationGetDashboardTotals(requestParameters.corporationId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Top Programs
     * @param {CorporationDashboardDataApiCorporationGetTopProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationDashboardDataApi
     */
    public corporationGetTopPrograms(requestParameters: CorporationDashboardDataApiCorporationGetTopProgramsRequest, options?: AxiosRequestConfig) {
        return CorporationDashboardDataApiFp(this.configuration).corporationGetTopPrograms(requestParameters.corporationId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Top Sites
     * @param {CorporationDashboardDataApiCorporationGetTopSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationDashboardDataApi
     */
    public corporationGetTopSites(requestParameters: CorporationDashboardDataApiCorporationGetTopSitesRequest, options?: AxiosRequestConfig) {
        return CorporationDashboardDataApiFp(this.configuration).corporationGetTopSites(requestParameters.corporationId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }
}
