export enum Currency {
    EUR = 'EUR',
    USD = 'USD'
}

export enum CurrencyDisplayType {
    SYMBOL = 'symbol',
    CODE = 'code'
}

export enum CurrencyExchangeValues {
    original = 'original',
    converted = 'converted',
    base = 'base'
}
