/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPublisherSiteGroups200Response } from '../AfClApiModels';
// @ts-ignore
import { PublisherSiteGroupResource } from '../AfClApiModels';
/**
 * PublisherSiteGroupsApi - axios parameter creator
 * @export
 */
export const PublisherSiteGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Publisher Site Group
         * @param {number} publisherId Numeric ID of the publisher
         * @param {string} url 
         * @param {string} trafficType 
         * @param {File} logo 
         * @param {string} segments JSON array of segments [{url:string, region_id:integer, vertical_id:integer}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherSiteGroup: async (publisherId: number, url: string, trafficType: string, logo: File, segments: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createPublisherSiteGroup', 'publisherId', publisherId)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('createPublisherSiteGroup', 'url', url)
            // verify required parameter 'trafficType' is not null or undefined
            assertParamExists('createPublisherSiteGroup', 'trafficType', trafficType)
            // verify required parameter 'logo' is not null or undefined
            assertParamExists('createPublisherSiteGroup', 'logo', logo)
            // verify required parameter 'segments' is not null or undefined
            assertParamExists('createPublisherSiteGroup', 'segments', segments)
            const localVarPath = `/v1/publishers/{publisherId}/site-groups`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
            if (trafficType !== undefined) { 
                localVarFormParams.append('traffic_type', trafficType as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (segments !== undefined) { 
                localVarFormParams.append('segments', segments as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sites groups
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {string} [name] 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherSiteGroups: async (publisherId: number, name?: string, siteGroupId?: number, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherSiteGroups', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/site-groups`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (siteStatus !== undefined) {
                localVarQueryParameter['site_status'] = siteStatus;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Publisher Site Group
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} siteGroupId Numeric ID of the siteGroup
         * @param {File} [logo] Send null or String to delete logo from Site Group.
         * @param {string} [segments] JSON array of segments [{url:string, region_id:integer, vertical_id:integer}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherSiteGroup: async (publisherId: number, siteGroupId: number, logo?: File, segments?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisherSiteGroup', 'publisherId', publisherId)
            // verify required parameter 'siteGroupId' is not null or undefined
            assertParamExists('updatePublisherSiteGroup', 'siteGroupId', siteGroupId)
            const localVarPath = `/v1/publishers/{publisherId}/site-groups/{siteGroupId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"siteGroupId"}}`, encodeURIComponent(String(siteGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (segments !== undefined) { 
                localVarFormParams.append('segments', segments as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherSiteGroupsApi - functional programming interface
 * @export
 */
export const PublisherSiteGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherSiteGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Publisher Site Group
         * @param {number} publisherId Numeric ID of the publisher
         * @param {string} url 
         * @param {string} trafficType 
         * @param {File} logo 
         * @param {string} segments JSON array of segments [{url:string, region_id:integer, vertical_id:integer}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisherSiteGroup(publisherId: number, url: string, trafficType: string, logo: File, segments: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherSiteGroupResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisherSiteGroup(publisherId, url, trafficType, logo, segments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sites groups
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {string} [name] 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherSiteGroups(publisherId: number, name?: string, siteGroupId?: number, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublisherSiteGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherSiteGroups(publisherId, name, siteGroupId, siteIds, regionIds, verticalIds, siteStatus, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Publisher Site Group
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} siteGroupId Numeric ID of the siteGroup
         * @param {File} [logo] Send null or String to delete logo from Site Group.
         * @param {string} [segments] JSON array of segments [{url:string, region_id:integer, vertical_id:integer}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherSiteGroup(publisherId: number, siteGroupId: number, logo?: File, segments?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherSiteGroupResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherSiteGroup(publisherId, siteGroupId, logo, segments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherSiteGroupsApi - factory interface
 * @export
 */
export const PublisherSiteGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherSiteGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Publisher Site Group
         * @param {PublisherSiteGroupsApiCreatePublisherSiteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherSiteGroup(requestParameters: PublisherSiteGroupsApiCreatePublisherSiteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherSiteGroupResource> {
            return localVarFp.createPublisherSiteGroup(requestParameters.publisherId, requestParameters.url, requestParameters.trafficType, requestParameters.logo, requestParameters.segments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sites groups
         * @param {PublisherSiteGroupsApiGetPublisherSiteGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherSiteGroups(requestParameters: PublisherSiteGroupsApiGetPublisherSiteGroupsRequest, options?: AxiosRequestConfig): AxiosPromise<GetPublisherSiteGroups200Response> {
            return localVarFp.getPublisherSiteGroups(requestParameters.publisherId, requestParameters.name, requestParameters.siteGroupId, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, requestParameters.perPage, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Publisher Site Group
         * @param {PublisherSiteGroupsApiUpdatePublisherSiteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherSiteGroup(requestParameters: PublisherSiteGroupsApiUpdatePublisherSiteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherSiteGroupResource> {
            return localVarFp.updatePublisherSiteGroup(requestParameters.publisherId, requestParameters.siteGroupId, requestParameters.logo, requestParameters.segments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPublisherSiteGroup operation in PublisherSiteGroupsApi.
 * @export
 * @interface PublisherSiteGroupsApiCreatePublisherSiteGroupRequest
 */
export interface PublisherSiteGroupsApiCreatePublisherSiteGroupRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof PublisherSiteGroupsApiCreatePublisherSiteGroup
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof PublisherSiteGroupsApiCreatePublisherSiteGroup
     */
    readonly url: string

    /**
     * 
     * @type {string}
     * @memberof PublisherSiteGroupsApiCreatePublisherSiteGroup
     */
    readonly trafficType: string

    /**
     * 
     * @type {File}
     * @memberof PublisherSiteGroupsApiCreatePublisherSiteGroup
     */
    readonly logo: File

    /**
     * JSON array of segments [{url:string, region_id:integer, vertical_id:integer}]
     * @type {string}
     * @memberof PublisherSiteGroupsApiCreatePublisherSiteGroup
     */
    readonly segments: string
}

/**
 * Request parameters for getPublisherSiteGroups operation in PublisherSiteGroupsApi.
 * @export
 * @interface PublisherSiteGroupsApiGetPublisherSiteGroupsRequest
 */
export interface PublisherSiteGroupsApiGetPublisherSiteGroupsRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly siteStatus?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly perPage?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherSiteGroupsApiGetPublisherSiteGroups
     */
    readonly page?: number
}

/**
 * Request parameters for updatePublisherSiteGroup operation in PublisherSiteGroupsApi.
 * @export
 * @interface PublisherSiteGroupsApiUpdatePublisherSiteGroupRequest
 */
export interface PublisherSiteGroupsApiUpdatePublisherSiteGroupRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof PublisherSiteGroupsApiUpdatePublisherSiteGroup
     */
    readonly publisherId: number

    /**
     * Numeric ID of the siteGroup
     * @type {number}
     * @memberof PublisherSiteGroupsApiUpdatePublisherSiteGroup
     */
    readonly siteGroupId: number

    /**
     * Send null or String to delete logo from Site Group.
     * @type {File}
     * @memberof PublisherSiteGroupsApiUpdatePublisherSiteGroup
     */
    readonly logo?: File

    /**
     * JSON array of segments [{url:string, region_id:integer, vertical_id:integer}]
     * @type {string}
     * @memberof PublisherSiteGroupsApiUpdatePublisherSiteGroup
     */
    readonly segments?: string
}

/**
 * PublisherSiteGroupsApi - object-oriented interface
 * @export
 * @class PublisherSiteGroupsApi
 * @extends {BaseAPI}
 */
export class PublisherSiteGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create Publisher Site Group
     * @param {PublisherSiteGroupsApiCreatePublisherSiteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSiteGroupsApi
     */
    public createPublisherSiteGroup(requestParameters: PublisherSiteGroupsApiCreatePublisherSiteGroupRequest, options?: AxiosRequestConfig) {
        return PublisherSiteGroupsApiFp(this.configuration).createPublisherSiteGroup(requestParameters.publisherId, requestParameters.url, requestParameters.trafficType, requestParameters.logo, requestParameters.segments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sites groups
     * @param {PublisherSiteGroupsApiGetPublisherSiteGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSiteGroupsApi
     */
    public getPublisherSiteGroups(requestParameters: PublisherSiteGroupsApiGetPublisherSiteGroupsRequest, options?: AxiosRequestConfig) {
        return PublisherSiteGroupsApiFp(this.configuration).getPublisherSiteGroups(requestParameters.publisherId, requestParameters.name, requestParameters.siteGroupId, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Publisher Site Group
     * @param {PublisherSiteGroupsApiUpdatePublisherSiteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSiteGroupsApi
     */
    public updatePublisherSiteGroup(requestParameters: PublisherSiteGroupsApiUpdatePublisherSiteGroupRequest, options?: AxiosRequestConfig) {
        return PublisherSiteGroupsApiFp(this.configuration).updatePublisherSiteGroup(requestParameters.publisherId, requestParameters.siteGroupId, requestParameters.logo, requestParameters.segments, options).then((request) => request(this.axios, this.basePath));
    }
}
