/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { WidgetSiteToplistResource } from '../AfClApiModels';
/**
 * WidgetSitesApi - axios parameter creator
 * @export
 */
export const WidgetSitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get SiteToplist
         * @param {number} topListId Numeric ID of the toplist to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteToplist: async (topListId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topListId' is not null or undefined
            assertParamExists('getSiteToplist', 'topListId', topListId)
            const localVarPath = `/v1/widget/sites/{topListId}/toplist`
                .replace(`{${"topListId"}}`, encodeURIComponent(String(topListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetSitesApi - functional programming interface
 * @export
 */
export const WidgetSitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetSitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get SiteToplist
         * @param {number} topListId Numeric ID of the toplist to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteToplist(topListId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetSiteToplistResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteToplist(topListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WidgetSitesApi - factory interface
 * @export
 */
export const WidgetSitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetSitesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get SiteToplist
         * @param {WidgetSitesApiGetSiteToplistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteToplist(requestParameters: WidgetSitesApiGetSiteToplistRequest, options?: AxiosRequestConfig): AxiosPromise<Array<WidgetSiteToplistResource>> {
            return localVarFp.getSiteToplist(requestParameters.topListId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSiteToplist operation in WidgetSitesApi.
 * @export
 * @interface WidgetSitesApiGetSiteToplistRequest
 */
export interface WidgetSitesApiGetSiteToplistRequest {
    /**
     * Numeric ID of the toplist to find
     * @type {number}
     * @memberof WidgetSitesApiGetSiteToplist
     */
    readonly topListId: number
}

/**
 * WidgetSitesApi - object-oriented interface
 * @export
 * @class WidgetSitesApi
 * @extends {BaseAPI}
 */
export class WidgetSitesApi extends BaseAPI {
    /**
     * 
     * @summary Get SiteToplist
     * @param {WidgetSitesApiGetSiteToplistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetSitesApi
     */
    public getSiteToplist(requestParameters: WidgetSitesApiGetSiteToplistRequest, options?: AxiosRequestConfig) {
        return WidgetSitesApiFp(this.configuration).getSiteToplist(requestParameters.topListId, options).then((request) => request(this.axios, this.basePath));
    }
}
