/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminUpdateApprovalRequest } from '../AfClApiModels';
// @ts-ignore
import { GetSiteApprovals200Response } from '../AfClApiModels';
// @ts-ignore
import { Site } from '../AfClApiModels';
/**
 * AdminSiteApprovalsApi - axios parameter creator
 * @export
 */
export const AdminSiteApprovalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Site Approvals
         * @param {Array<number>} [publisherIds] 
         * @param {Array<number>} [managerIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [type] 
         * @param {'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'} [status] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {boolean} [kycStatus] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSiteApprovals: async (publisherIds?: Array<number>, managerIds?: Array<number>, type?: 'PAID' | 'ORGANIC' | 'INTEGRATION', status?: 'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB', publisherType?: string, siteIds?: Array<number>, kycStatus?: boolean, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sites/approvals/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherIds) {
                localVarQueryParameter['publisher_ids[]'] = publisherIds;
            }

            if (managerIds) {
                localVarQueryParameter['manager_ids[]'] = managerIds;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (kycStatus !== undefined) {
                localVarQueryParameter['kyc_status'] = kycStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Site Approvals
         * @param {Array<number>} [publisherIds] 
         * @param {Array<number>} [managerIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [type] 
         * @param {'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'} [status] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {boolean} [kycStatus] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteApprovals: async (publisherIds?: Array<number>, managerIds?: Array<number>, type?: 'PAID' | 'ORGANIC' | 'INTEGRATION', status?: 'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB', publisherType?: string, siteIds?: Array<number>, kycStatus?: boolean, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sites/approvals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherIds) {
                localVarQueryParameter['publisher_ids[]'] = publisherIds;
            }

            if (managerIds) {
                localVarQueryParameter['manager_ids[]'] = managerIds;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (kycStatus !== undefined) {
                localVarQueryParameter['kyc_status'] = kycStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Site Approval
         * @param {number} siteId Numeric ID of the Site to find
         * @param {number} approvalId Numeric ID of the Approval to find
         * @param {AdminUpdateApprovalRequest} [adminUpdateApprovalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteApproval: async (siteId: number, approvalId: number, adminUpdateApprovalRequest?: AdminUpdateApprovalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateSiteApproval', 'siteId', siteId)
            // verify required parameter 'approvalId' is not null or undefined
            assertParamExists('updateSiteApproval', 'approvalId', approvalId)
            const localVarPath = `/v1/admin/sites/{siteId}/approvals/{approvalId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"approvalId"}}`, encodeURIComponent(String(approvalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateApprovalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSiteApprovalsApi - functional programming interface
 * @export
 */
export const AdminSiteApprovalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSiteApprovalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Site Approvals
         * @param {Array<number>} [publisherIds] 
         * @param {Array<number>} [managerIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [type] 
         * @param {'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'} [status] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {boolean} [kycStatus] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportSiteApprovals(publisherIds?: Array<number>, managerIds?: Array<number>, type?: 'PAID' | 'ORGANIC' | 'INTEGRATION', status?: 'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB', publisherType?: string, siteIds?: Array<number>, kycStatus?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportSiteApprovals(publisherIds, managerIds, type, status, publisherType, siteIds, kycStatus, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Site Approvals
         * @param {Array<number>} [publisherIds] 
         * @param {Array<number>} [managerIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [type] 
         * @param {'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'} [status] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {boolean} [kycStatus] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteApprovals(publisherIds?: Array<number>, managerIds?: Array<number>, type?: 'PAID' | 'ORGANIC' | 'INTEGRATION', status?: 'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB', publisherType?: string, siteIds?: Array<number>, kycStatus?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteApprovals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteApprovals(publisherIds, managerIds, type, status, publisherType, siteIds, kycStatus, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Site Approval
         * @param {number} siteId Numeric ID of the Site to find
         * @param {number} approvalId Numeric ID of the Approval to find
         * @param {AdminUpdateApprovalRequest} [adminUpdateApprovalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSiteApproval(siteId: number, approvalId: number, adminUpdateApprovalRequest?: AdminUpdateApprovalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSiteApproval(siteId, approvalId, adminUpdateApprovalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSiteApprovalsApi - factory interface
 * @export
 */
export const AdminSiteApprovalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSiteApprovalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Site Approvals
         * @param {AdminSiteApprovalsApiExportSiteApprovalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSiteApprovals(requestParameters: AdminSiteApprovalsApiExportSiteApprovalsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.exportSiteApprovals(requestParameters.publisherIds, requestParameters.managerIds, requestParameters.type, requestParameters.status, requestParameters.publisherType, requestParameters.siteIds, requestParameters.kycStatus, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Site Approvals
         * @param {AdminSiteApprovalsApiGetSiteApprovalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteApprovals(requestParameters: AdminSiteApprovalsApiGetSiteApprovalsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetSiteApprovals200Response> {
            return localVarFp.getSiteApprovals(requestParameters.publisherIds, requestParameters.managerIds, requestParameters.type, requestParameters.status, requestParameters.publisherType, requestParameters.siteIds, requestParameters.kycStatus, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Site Approval
         * @param {AdminSiteApprovalsApiUpdateSiteApprovalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteApproval(requestParameters: AdminSiteApprovalsApiUpdateSiteApprovalRequest, options?: AxiosRequestConfig): AxiosPromise<Site> {
            return localVarFp.updateSiteApproval(requestParameters.siteId, requestParameters.approvalId, requestParameters.adminUpdateApprovalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for exportSiteApprovals operation in AdminSiteApprovalsApi.
 * @export
 * @interface AdminSiteApprovalsApiExportSiteApprovalsRequest
 */
export interface AdminSiteApprovalsApiExportSiteApprovalsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly publisherIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly managerIds?: Array<number>

    /**
     * 
     * @type {'PAID' | 'ORGANIC' | 'INTEGRATION'}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly type?: 'PAID' | 'ORGANIC' | 'INTEGRATION'

    /**
     * 
     * @type {'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly status?: 'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'

    /**
     * 
     * @type {string}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly publisherType?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly kycStatus?: boolean

    /**
     * 
     * @type {number}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteApprovalsApiExportSiteApprovals
     */
    readonly perPage?: number
}

/**
 * Request parameters for getSiteApprovals operation in AdminSiteApprovalsApi.
 * @export
 * @interface AdminSiteApprovalsApiGetSiteApprovalsRequest
 */
export interface AdminSiteApprovalsApiGetSiteApprovalsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly publisherIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly managerIds?: Array<number>

    /**
     * 
     * @type {'PAID' | 'ORGANIC' | 'INTEGRATION'}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly type?: 'PAID' | 'ORGANIC' | 'INTEGRATION'

    /**
     * 
     * @type {'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly status?: 'REVIEW' | 'NEW' | 'PENDING' | 'PAUSED' | 'APPROVED' | 'REJECTED' | 'CLOSED' | 'FROM_SOURCE' | 'FROM_HUB'

    /**
     * 
     * @type {string}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly publisherType?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly kycStatus?: boolean

    /**
     * 
     * @type {number}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteApprovalsApiGetSiteApprovals
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateSiteApproval operation in AdminSiteApprovalsApi.
 * @export
 * @interface AdminSiteApprovalsApiUpdateSiteApprovalRequest
 */
export interface AdminSiteApprovalsApiUpdateSiteApprovalRequest {
    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof AdminSiteApprovalsApiUpdateSiteApproval
     */
    readonly siteId: number

    /**
     * Numeric ID of the Approval to find
     * @type {number}
     * @memberof AdminSiteApprovalsApiUpdateSiteApproval
     */
    readonly approvalId: number

    /**
     * 
     * @type {AdminUpdateApprovalRequest}
     * @memberof AdminSiteApprovalsApiUpdateSiteApproval
     */
    readonly adminUpdateApprovalRequest?: AdminUpdateApprovalRequest
}

/**
 * AdminSiteApprovalsApi - object-oriented interface
 * @export
 * @class AdminSiteApprovalsApi
 * @extends {BaseAPI}
 */
export class AdminSiteApprovalsApi extends BaseAPI {
    /**
     * 
     * @summary Export Site Approvals
     * @param {AdminSiteApprovalsApiExportSiteApprovalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteApprovalsApi
     */
    public exportSiteApprovals(requestParameters: AdminSiteApprovalsApiExportSiteApprovalsRequest = {}, options?: AxiosRequestConfig) {
        return AdminSiteApprovalsApiFp(this.configuration).exportSiteApprovals(requestParameters.publisherIds, requestParameters.managerIds, requestParameters.type, requestParameters.status, requestParameters.publisherType, requestParameters.siteIds, requestParameters.kycStatus, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Site Approvals
     * @param {AdminSiteApprovalsApiGetSiteApprovalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteApprovalsApi
     */
    public getSiteApprovals(requestParameters: AdminSiteApprovalsApiGetSiteApprovalsRequest = {}, options?: AxiosRequestConfig) {
        return AdminSiteApprovalsApiFp(this.configuration).getSiteApprovals(requestParameters.publisherIds, requestParameters.managerIds, requestParameters.type, requestParameters.status, requestParameters.publisherType, requestParameters.siteIds, requestParameters.kycStatus, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Site Approval
     * @param {AdminSiteApprovalsApiUpdateSiteApprovalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteApprovalsApi
     */
    public updateSiteApproval(requestParameters: AdminSiteApprovalsApiUpdateSiteApprovalRequest, options?: AxiosRequestConfig) {
        return AdminSiteApprovalsApiFp(this.configuration).updateSiteApproval(requestParameters.siteId, requestParameters.approvalId, requestParameters.adminUpdateApprovalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
