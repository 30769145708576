/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetAdvertiserPrograms200Response } from '../AfClApiModels';
/**
 * AdvertiserProgramsApi - axios parameter creator
 * @export
 */
export const AdvertiserProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get advertiser programs
         * @param {number} advertiserId Numeric ID of the advertiser
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserPrograms: async (advertiserId: number, regionIds?: Array<number>, verticalIds?: Array<number>, brandIds?: Array<number>, page?: number, perPage?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('getAdvertiserPrograms', 'advertiserId', advertiserId)
            const localVarPath = `/v1/advertisers/{advertiserId}/programs`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertiserProgramsApi - functional programming interface
 * @export
 */
export const AdvertiserProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertiserProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get advertiser programs
         * @param {number} advertiserId Numeric ID of the advertiser
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertiserPrograms(advertiserId: number, regionIds?: Array<number>, verticalIds?: Array<number>, brandIds?: Array<number>, page?: number, perPage?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvertiserPrograms200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertiserPrograms(advertiserId, regionIds, verticalIds, brandIds, page, perPage, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertiserProgramsApi - factory interface
 * @export
 */
export const AdvertiserProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertiserProgramsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get advertiser programs
         * @param {AdvertiserProgramsApiGetAdvertiserProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserPrograms(requestParameters: AdvertiserProgramsApiGetAdvertiserProgramsRequest, options?: AxiosRequestConfig): AxiosPromise<GetAdvertiserPrograms200Response> {
            return localVarFp.getAdvertiserPrograms(requestParameters.advertiserId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, requestParameters.name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdvertiserPrograms operation in AdvertiserProgramsApi.
 * @export
 * @interface AdvertiserProgramsApiGetAdvertiserProgramsRequest
 */
export interface AdvertiserProgramsApiGetAdvertiserProgramsRequest {
    /**
     * Numeric ID of the advertiser
     * @type {number}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly advertiserId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof AdvertiserProgramsApiGetAdvertiserPrograms
     */
    readonly name?: string
}

/**
 * AdvertiserProgramsApi - object-oriented interface
 * @export
 * @class AdvertiserProgramsApi
 * @extends {BaseAPI}
 */
export class AdvertiserProgramsApi extends BaseAPI {
    /**
     * 
     * @summary Get advertiser programs
     * @param {AdvertiserProgramsApiGetAdvertiserProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserProgramsApi
     */
    public getAdvertiserPrograms(requestParameters: AdvertiserProgramsApiGetAdvertiserProgramsRequest, options?: AxiosRequestConfig) {
        return AdvertiserProgramsApiFp(this.configuration).getAdvertiserPrograms(requestParameters.advertiserId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }
}
