/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetSiteAffiliation200Response } from '../AfClApiModels';
/**
 * AdminSiteAffiliationApi - axios parameter creator
 * @export
 */
export const AdminSiteAffiliationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Site Affiliation
         * @param {number} siteId Numeric ID of the Site to find
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSiteAffiliation: async (siteId: number, perPage?: number, page?: number, search?: string, redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, programManagerIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('adminGetSiteAffiliation', 'siteId', siteId)
            const localVarPath = `/v1/admin/sites/{siteId}/affiliation`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (redirectLinkRequestStatus !== undefined) {
                localVarQueryParameter['redirect_link_request_status'] = redirectLinkRequestStatus;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSiteAffiliationApi - functional programming interface
 * @export
 */
export const AdminSiteAffiliationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSiteAffiliationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Site Affiliation
         * @param {number} siteId Numeric ID of the Site to find
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSiteAffiliation(siteId: number, perPage?: number, page?: number, search?: string, redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, programManagerIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSiteAffiliation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSiteAffiliation(siteId, perPage, page, search, redirectLinkRequestStatus, programIds, programManagerIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSiteAffiliationApi - factory interface
 * @export
 */
export const AdminSiteAffiliationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSiteAffiliationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Site Affiliation
         * @param {AdminSiteAffiliationApiAdminGetSiteAffiliationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSiteAffiliation(requestParameters: AdminSiteAffiliationApiAdminGetSiteAffiliationRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetSiteAffiliation200Response> {
            return localVarFp.adminGetSiteAffiliation(requestParameters.siteId, requestParameters.perPage, requestParameters.page, requestParameters.search, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.programManagerIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminGetSiteAffiliation operation in AdminSiteAffiliationApi.
 * @export
 * @interface AdminSiteAffiliationApiAdminGetSiteAffiliationRequest
 */
export interface AdminSiteAffiliationApiAdminGetSiteAffiliationRequest {
    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly perPage?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly search?: string

    /**
     * 
     * @type {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteAffiliationApiAdminGetSiteAffiliation
     */
    readonly programManagerIds?: Array<number>
}

/**
 * AdminSiteAffiliationApi - object-oriented interface
 * @export
 * @class AdminSiteAffiliationApi
 * @extends {BaseAPI}
 */
export class AdminSiteAffiliationApi extends BaseAPI {
    /**
     * 
     * @summary Get Site Affiliation
     * @param {AdminSiteAffiliationApiAdminGetSiteAffiliationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteAffiliationApi
     */
    public adminGetSiteAffiliation(requestParameters: AdminSiteAffiliationApiAdminGetSiteAffiliationRequest, options?: AxiosRequestConfig) {
        return AdminSiteAffiliationApiFp(this.configuration).adminGetSiteAffiliation(requestParameters.siteId, requestParameters.perPage, requestParameters.page, requestParameters.search, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.programManagerIds, options).then((request) => request(this.axios, this.basePath));
    }
}
