/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPublisherAccount } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateApprovalRequest } from '../AfClApiModels';
/**
 * AdminPublisherApprovalsApi - axios parameter creator
 * @export
 */
export const AdminPublisherApprovalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Publisher Approval
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} approvalId Numeric ID of the Approval to find
         * @param {AdminUpdateApprovalRequest} [adminUpdateApprovalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherApproval: async (publisherId: number, approvalId: number, adminUpdateApprovalRequest?: AdminUpdateApprovalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisherApproval', 'publisherId', publisherId)
            // verify required parameter 'approvalId' is not null or undefined
            assertParamExists('updatePublisherApproval', 'approvalId', approvalId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/approvals/{approvalId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"approvalId"}}`, encodeURIComponent(String(approvalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateApprovalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublisherApprovalsApi - functional programming interface
 * @export
 */
export const AdminPublisherApprovalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublisherApprovalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Publisher Approval
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} approvalId Numeric ID of the Approval to find
         * @param {AdminUpdateApprovalRequest} [adminUpdateApprovalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherApproval(publisherId: number, approvalId: number, adminUpdateApprovalRequest?: AdminUpdateApprovalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherApproval(publisherId, approvalId, adminUpdateApprovalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublisherApprovalsApi - factory interface
 * @export
 */
export const AdminPublisherApprovalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublisherApprovalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Publisher Approval
         * @param {AdminPublisherApprovalsApiUpdatePublisherApprovalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherApproval(requestParameters: AdminPublisherApprovalsApiUpdatePublisherApprovalRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherAccount> {
            return localVarFp.updatePublisherApproval(requestParameters.publisherId, requestParameters.approvalId, requestParameters.adminUpdateApprovalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updatePublisherApproval operation in AdminPublisherApprovalsApi.
 * @export
 * @interface AdminPublisherApprovalsApiUpdatePublisherApprovalRequest
 */
export interface AdminPublisherApprovalsApiUpdatePublisherApprovalRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof AdminPublisherApprovalsApiUpdatePublisherApproval
     */
    readonly publisherId: number

    /**
     * Numeric ID of the Approval to find
     * @type {number}
     * @memberof AdminPublisherApprovalsApiUpdatePublisherApproval
     */
    readonly approvalId: number

    /**
     * 
     * @type {AdminUpdateApprovalRequest}
     * @memberof AdminPublisherApprovalsApiUpdatePublisherApproval
     */
    readonly adminUpdateApprovalRequest?: AdminUpdateApprovalRequest
}

/**
 * AdminPublisherApprovalsApi - object-oriented interface
 * @export
 * @class AdminPublisherApprovalsApi
 * @extends {BaseAPI}
 */
export class AdminPublisherApprovalsApi extends BaseAPI {
    /**
     * 
     * @summary Update Publisher Approval
     * @param {AdminPublisherApprovalsApiUpdatePublisherApprovalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherApprovalsApi
     */
    public updatePublisherApproval(requestParameters: AdminPublisherApprovalsApiUpdatePublisherApprovalRequest, options?: AxiosRequestConfig) {
        return AdminPublisherApprovalsApiFp(this.configuration).updatePublisherApproval(requestParameters.publisherId, requestParameters.approvalId, requestParameters.adminUpdateApprovalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
