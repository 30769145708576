/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPublisherNotifications200Response } from '../AfClApiModels';
// @ts-ignore
import { Notification } from '../AfClApiModels';
// @ts-ignore
import { UpdateNotificationsRequest } from '../AfClApiModels';
/**
 * PublisherPublisherNotificationsApi - axios parameter creator
 * @export
 */
export const PublisherPublisherNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Publisher Notifications
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Array<'NEW' | 'READ'>} [statuses] 
         * @param {Array<'ACTIONS' | 'INVOICES' | 'NEWS' | 'UPDATES' | 'PROGRAM'>} [types] 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherNotifications: async (publisherId: number, page?: number, perPage?: number, statuses?: Array<'NEW' | 'READ'>, types?: Array<'ACTIONS' | 'INVOICES' | 'NEWS' | 'UPDATES' | 'PROGRAM'>, showAll?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherNotifications', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/notifications`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }

            if (types) {
                localVarQueryParameter['types[]'] = types;
            }

            if (showAll !== undefined) {
                localVarQueryParameter['show_all'] = showAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set status Read for all Notifications
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPublisherNotificationMarkAllRead: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('postPublisherNotificationMarkAllRead', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/notifications/mark-all-read`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Notification
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} notificationId Numeric ID of the Notification to find
         * @param {UpdateNotificationsRequest} [updateNotificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherNotification: async (publisherId: number, notificationId: number, updateNotificationsRequest?: UpdateNotificationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisherNotification', 'publisherId', publisherId)
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('updatePublisherNotification', 'notificationId', notificationId)
            const localVarPath = `/v1/publishers/{publisherId}/notifications/{notificationId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherPublisherNotificationsApi - functional programming interface
 * @export
 */
export const PublisherPublisherNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherPublisherNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Notifications
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Array<'NEW' | 'READ'>} [statuses] 
         * @param {Array<'ACTIONS' | 'INVOICES' | 'NEWS' | 'UPDATES' | 'PROGRAM'>} [types] 
         * @param {boolean} [showAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherNotifications(publisherId: number, page?: number, perPage?: number, statuses?: Array<'NEW' | 'READ'>, types?: Array<'ACTIONS' | 'INVOICES' | 'NEWS' | 'UPDATES' | 'PROGRAM'>, showAll?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublisherNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherNotifications(publisherId, page, perPage, statuses, types, showAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set status Read for all Notifications
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPublisherNotificationMarkAllRead(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPublisherNotificationMarkAllRead(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Notification
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} notificationId Numeric ID of the Notification to find
         * @param {UpdateNotificationsRequest} [updateNotificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherNotification(publisherId: number, notificationId: number, updateNotificationsRequest?: UpdateNotificationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherNotification(publisherId, notificationId, updateNotificationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherPublisherNotificationsApi - factory interface
 * @export
 */
export const PublisherPublisherNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherPublisherNotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Publisher Notifications
         * @param {PublisherPublisherNotificationsApiGetPublisherNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherNotifications(requestParameters: PublisherPublisherNotificationsApiGetPublisherNotificationsRequest, options?: AxiosRequestConfig): AxiosPromise<GetPublisherNotifications200Response> {
            return localVarFp.getPublisherNotifications(requestParameters.publisherId, requestParameters.page, requestParameters.perPage, requestParameters.statuses, requestParameters.types, requestParameters.showAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set status Read for all Notifications
         * @param {PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPublisherNotificationMarkAllRead(requestParameters: PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllReadRequest, options?: AxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.postPublisherNotificationMarkAllRead(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Notification
         * @param {PublisherPublisherNotificationsApiUpdatePublisherNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherNotification(requestParameters: PublisherPublisherNotificationsApiUpdatePublisherNotificationRequest, options?: AxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.updatePublisherNotification(requestParameters.publisherId, requestParameters.notificationId, requestParameters.updateNotificationsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPublisherNotifications operation in PublisherPublisherNotificationsApi.
 * @export
 * @interface PublisherPublisherNotificationsApiGetPublisherNotificationsRequest
 */
export interface PublisherPublisherNotificationsApiGetPublisherNotificationsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof PublisherPublisherNotificationsApiGetPublisherNotifications
     */
    readonly publisherId: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPublisherNotificationsApiGetPublisherNotifications
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPublisherNotificationsApiGetPublisherNotifications
     */
    readonly perPage?: number

    /**
     * 
     * @type {Array<'NEW' | 'READ'>}
     * @memberof PublisherPublisherNotificationsApiGetPublisherNotifications
     */
    readonly statuses?: Array<'NEW' | 'READ'>

    /**
     * 
     * @type {Array<'ACTIONS' | 'INVOICES' | 'NEWS' | 'UPDATES' | 'PROGRAM'>}
     * @memberof PublisherPublisherNotificationsApiGetPublisherNotifications
     */
    readonly types?: Array<'ACTIONS' | 'INVOICES' | 'NEWS' | 'UPDATES' | 'PROGRAM'>

    /**
     * 
     * @type {boolean}
     * @memberof PublisherPublisherNotificationsApiGetPublisherNotifications
     */
    readonly showAll?: boolean
}

/**
 * Request parameters for postPublisherNotificationMarkAllRead operation in PublisherPublisherNotificationsApi.
 * @export
 * @interface PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllReadRequest
 */
export interface PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllReadRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllRead
     */
    readonly publisherId: number
}

/**
 * Request parameters for updatePublisherNotification operation in PublisherPublisherNotificationsApi.
 * @export
 * @interface PublisherPublisherNotificationsApiUpdatePublisherNotificationRequest
 */
export interface PublisherPublisherNotificationsApiUpdatePublisherNotificationRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherPublisherNotificationsApiUpdatePublisherNotification
     */
    readonly publisherId: number

    /**
     * Numeric ID of the Notification to find
     * @type {number}
     * @memberof PublisherPublisherNotificationsApiUpdatePublisherNotification
     */
    readonly notificationId: number

    /**
     * 
     * @type {UpdateNotificationsRequest}
     * @memberof PublisherPublisherNotificationsApiUpdatePublisherNotification
     */
    readonly updateNotificationsRequest?: UpdateNotificationsRequest
}

/**
 * PublisherPublisherNotificationsApi - object-oriented interface
 * @export
 * @class PublisherPublisherNotificationsApi
 * @extends {BaseAPI}
 */
export class PublisherPublisherNotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Publisher Notifications
     * @param {PublisherPublisherNotificationsApiGetPublisherNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherNotificationsApi
     */
    public getPublisherNotifications(requestParameters: PublisherPublisherNotificationsApiGetPublisherNotificationsRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherNotificationsApiFp(this.configuration).getPublisherNotifications(requestParameters.publisherId, requestParameters.page, requestParameters.perPage, requestParameters.statuses, requestParameters.types, requestParameters.showAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set status Read for all Notifications
     * @param {PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherNotificationsApi
     */
    public postPublisherNotificationMarkAllRead(requestParameters: PublisherPublisherNotificationsApiPostPublisherNotificationMarkAllReadRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherNotificationsApiFp(this.configuration).postPublisherNotificationMarkAllRead(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Notification
     * @param {PublisherPublisherNotificationsApiUpdatePublisherNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherNotificationsApi
     */
    public updatePublisherNotification(requestParameters: PublisherPublisherNotificationsApiUpdatePublisherNotificationRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherNotificationsApiFp(this.configuration).updatePublisherNotification(requestParameters.publisherId, requestParameters.notificationId, requestParameters.updateNotificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
