/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaymentProfile } from '../AfClApiModels';
// @ts-ignore
import { PublisherCreateBillingRequest } from '../AfClApiModels';
/**
 * PublisherBillingApi - axios parameter creator
 * @export
 */
export const PublisherBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Billing
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {PublisherCreateBillingRequest} [publisherCreateBillingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherCreateBilling: async (publisherId: number, publisherCreateBillingRequest?: PublisherCreateBillingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherCreateBilling', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/billing`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publisherCreateBillingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Billing
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetBilling: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherGetBilling', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/billing`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherBillingApi - functional programming interface
 * @export
 */
export const PublisherBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Billing
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {PublisherCreateBillingRequest} [publisherCreateBillingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherCreateBilling(publisherId: number, publisherCreateBillingRequest?: PublisherCreateBillingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherCreateBilling(publisherId, publisherCreateBillingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Billing
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherGetBilling(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherGetBilling(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherBillingApi - factory interface
 * @export
 */
export const PublisherBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherBillingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Billing
         * @param {PublisherBillingApiPublisherCreateBillingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherCreateBilling(requestParameters: PublisherBillingApiPublisherCreateBillingRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentProfile> {
            return localVarFp.publisherCreateBilling(requestParameters.publisherId, requestParameters.publisherCreateBillingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Billing
         * @param {PublisherBillingApiPublisherGetBillingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetBilling(requestParameters: PublisherBillingApiPublisherGetBillingRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentProfile> {
            return localVarFp.publisherGetBilling(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publisherCreateBilling operation in PublisherBillingApi.
 * @export
 * @interface PublisherBillingApiPublisherCreateBillingRequest
 */
export interface PublisherBillingApiPublisherCreateBillingRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherBillingApiPublisherCreateBilling
     */
    readonly publisherId: number

    /**
     * 
     * @type {PublisherCreateBillingRequest}
     * @memberof PublisherBillingApiPublisherCreateBilling
     */
    readonly publisherCreateBillingRequest?: PublisherCreateBillingRequest
}

/**
 * Request parameters for publisherGetBilling operation in PublisherBillingApi.
 * @export
 * @interface PublisherBillingApiPublisherGetBillingRequest
 */
export interface PublisherBillingApiPublisherGetBillingRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherBillingApiPublisherGetBilling
     */
    readonly publisherId: number
}

/**
 * PublisherBillingApi - object-oriented interface
 * @export
 * @class PublisherBillingApi
 * @extends {BaseAPI}
 */
export class PublisherBillingApi extends BaseAPI {
    /**
     * 
     * @summary Create Billing
     * @param {PublisherBillingApiPublisherCreateBillingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherBillingApi
     */
    public publisherCreateBilling(requestParameters: PublisherBillingApiPublisherCreateBillingRequest, options?: AxiosRequestConfig) {
        return PublisherBillingApiFp(this.configuration).publisherCreateBilling(requestParameters.publisherId, requestParameters.publisherCreateBillingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Billing
     * @param {PublisherBillingApiPublisherGetBillingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherBillingApi
     */
    public publisherGetBilling(requestParameters: PublisherBillingApiPublisherGetBillingRequest, options?: AxiosRequestConfig) {
        return PublisherBillingApiFp(this.configuration).publisherGetBilling(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }
}
