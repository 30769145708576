import { Route } from 'vue-lite-route';
import { MiddlewareGroups } from '@/Router/Middleware/MiddlewareGroups';

const DashboardPage = () => import('@/Pages/Shared/DashboardPage.vue');
const SitesPage = () => import('@/Pages/Corporation/SitesPage.vue');
const ReportsGeneralPage = () => import('@/Pages/Corporation/Reports/ReportsGeneralPage.vue');
const InvoicesPage = () => import('@/Pages/Corporation/InvoicesPage.vue');

export const RegisterCorporationRoutes = () => {
    Route.group({
        prefix: '/corporations/:accountId',
        name: 'corporation',
        middleware: MiddlewareGroups.corporation
    }, () => {
        Route.add('/dashboard', { component: DashboardPage }).name('.dashboard');
        Route.add('/sites', { component: SitesPage }).name('.sites');
        Route.add('/reports', { component: ReportsGeneralPage }).name('.reports');
        Route.add('/invoices', { component: InvoicesPage }).name('.invoices');
    });
};
