/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetBrands200Response } from '../AfClApiModels';
/**
 * BiBrandsApi - axios parameter creator
 * @export
 */
export const BiBrandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Brands for the BI endpoint
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>} [status] 
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetBrands: async (status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>, name?: string, advertiserId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bi/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status) {
                localVarQueryParameter['status[]'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiBrandsApi - functional programming interface
 * @export
 */
export const BiBrandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiBrandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Brands for the BI endpoint
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>} [status] 
         * @param {string} [name] 
         * @param {number} [advertiserId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biGetBrands(status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>, name?: string, advertiserId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrands200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biGetBrands(status, name, advertiserId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiBrandsApi - factory interface
 * @export
 */
export const BiBrandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiBrandsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Brands for the BI endpoint
         * @param {BiBrandsApiBiGetBrandsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biGetBrands(requestParameters: BiBrandsApiBiGetBrandsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetBrands200Response> {
            return localVarFp.biGetBrands(requestParameters.status, requestParameters.name, requestParameters.advertiserId, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for biGetBrands operation in BiBrandsApi.
 * @export
 * @interface BiBrandsApiBiGetBrandsRequest
 */
export interface BiBrandsApiBiGetBrandsRequest {
    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>}
     * @memberof BiBrandsApiBiGetBrands
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'DUPLICATED'>

    /**
     * 
     * @type {string}
     * @memberof BiBrandsApiBiGetBrands
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof BiBrandsApiBiGetBrands
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof BiBrandsApiBiGetBrands
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BiBrandsApiBiGetBrands
     */
    readonly perPage?: number
}

/**
 * BiBrandsApi - object-oriented interface
 * @export
 * @class BiBrandsApi
 * @extends {BaseAPI}
 */
export class BiBrandsApi extends BaseAPI {
    /**
     * 
     * @summary Get Brands for the BI endpoint
     * @param {BiBrandsApiBiGetBrandsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiBrandsApi
     */
    public biGetBrands(requestParameters: BiBrandsApiBiGetBrandsRequest = {}, options?: AxiosRequestConfig) {
        return BiBrandsApiFp(this.configuration).biGetBrands(requestParameters.status, requestParameters.name, requestParameters.advertiserId, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
