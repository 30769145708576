import 'core-js/proposals/reflect-metadata';
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { AfClApi } from '@/Services/AfClApi';
import { AdvertiserAccount } from '@/Models/AdvertiserAccount';
import { PublisherAccount } from '@/Models/PublisherAccount';
import { CorporationAccount } from '@/Models/CorporationAccount';
import { Account } from '@/Models/Account';
import { GetAuthUserAccounts200ResponseDataInner } from '@/stub';
import { AccountContract } from '@/Models/Contracts/AccountContract';
import { AccountType } from '@/Models/AccountType';
import { useRoute } from 'vue-router';

export const useAccountStore = defineStore('accountStore', () => {
    const accounts = ref<Account[]>([]);
    const selectedAccount = ref<Account | null>(null);
    const route = useRoute();

    const selectAccount = (payload: GetAuthUserAccounts200ResponseDataInner | AccountContract | null) => {
        if (!payload) {
            selectedAccount.value = null;
            return null;
        }

        if ('publisher_type' in payload) {
            selectedAccount.value = PublisherAccount.fromPlain(payload);
        }
        else if ('corporation_type' in payload) {
            selectedAccount.value = CorporationAccount.fromPlain(payload);
        }
        else {
            selectedAccount.value = AdvertiserAccount.fromPlain(payload);
        }

        return selectedAccount.value;
    };

    const isAdvertiser = computed<boolean>(() => selectedAccount.value instanceof AdvertiserAccount);
    const isCorporation = computed<boolean>(() => selectedAccount.value instanceof CorporationAccount);
    const isPublisher = computed<boolean>(() => selectedAccount.value instanceof PublisherAccount);
    const isAdmin = computed(() => route.path.includes('admin'));
    const isPublisherPostbacksEnabled = computed<boolean>(() => {
        return isPublisher.value && (selectedAccount.value as PublisherAccount).postbacks_status === 'ACTIVE';
    });

    const fetchAccount = async (accountType: AccountType, accountId: number) => {
        const { data } = await AfClApi.auth.getAuthUserAccounts({
            teamType: accountType,
            ids: [accountId]
        });

        return data.data[0];
    };

    return {
        accounts,
        selectedAccount,
        selectAccount,
        fetchAccount,
        isPublisher,
        isAdvertiser,
        isCorporation,
        isAdmin,
        isPublisherPostbacksEnabled
    };
});
