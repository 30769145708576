import { UserContract } from '@/Models/Contracts/UserContract';
import { RoleContract } from '@/Models/Contracts/RoleContract';
import { AccountOption } from '@/Models/Account';
import { UserType } from '@/Enums/UserType';

export class User {
    id: number;
    name: string;
    email: string;
    default_locale: string;
    user_type: string;
    roles: RoleContract[];

    constructor(
        id: number,
        name: string,
        email: string,
        default_locale: string,
        user_type: string,
        roles: RoleContract[]
    ) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.default_locale = default_locale;
        this.user_type = user_type;
        this.roles = roles;
    }

    static fromPlain(model: UserContract) {
        return new this(
            model.id,
            model.name,
            model.email,
            model.default_locale,
            model.user_type,
            model.roles
        );
    }

    hasRole(roleForCheck: string) {
        return Object.values(this.roles).some(role => role.role === roleForCheck);
    }

    hasPermission(permissionForCheck: string) {
        for (const role of this.roles) {
            if (role.permissions.includes(permissionForCheck)) {
                return true;
            }
        }
        return false;
    }

    accountTypeOptions() {
        const options = <AccountOption[]>[
            { name: 'Publisher', id: UserType.PUBLISHER }
        ];

        if (this.hasPermission('access.accounts.corporations')
            || this.hasPermission('corporations.view')
        ) {
            options.push({ name: 'Corporation', id: UserType.CORPORATION });
        }

        if (this.hasPermission('access.accounts.advertisers')
            || this.hasPermission('advertisers.view')
        ) {
            options.push({ name: 'Advertiser', id: UserType.ADVERTISER });
        }

        return options;
    }
}
