/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherAccount } from '../AfClApiModels';
// @ts-ignore
import { StorePublisherAccountRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdatePublisherAccountRequest } from '../AfClApiModels';
/**
 * PublisherAccountsApi - axios parameter creator
 * @export
 */
export const PublisherAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Publisher Account
         * @param {StorePublisherAccountRequest} [storePublisherAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherAccount: async (storePublisherAccountRequest?: StorePublisherAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storePublisherAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Publisher Account
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {UpdatePublisherAccountRequest} [updatePublisherAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherAccount: async (publisherId: number, updatePublisherAccountRequest?: UpdatePublisherAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisherAccount', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePublisherAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherAccountsApi - functional programming interface
 * @export
 */
export const PublisherAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Publisher Account
         * @param {StorePublisherAccountRequest} [storePublisherAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisherAccount(storePublisherAccountRequest?: StorePublisherAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisherAccount(storePublisherAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Publisher Account
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {UpdatePublisherAccountRequest} [updatePublisherAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherAccount(publisherId: number, updatePublisherAccountRequest?: UpdatePublisherAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherAccount(publisherId, updatePublisherAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherAccountsApi - factory interface
 * @export
 */
export const PublisherAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Publisher Account
         * @param {PublisherAccountsApiCreatePublisherAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherAccount(requestParameters: PublisherAccountsApiCreatePublisherAccountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PublisherAccount> {
            return localVarFp.createPublisherAccount(requestParameters.storePublisherAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Publisher Account
         * @param {PublisherAccountsApiUpdatePublisherAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherAccount(requestParameters: PublisherAccountsApiUpdatePublisherAccountRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherAccount> {
            return localVarFp.updatePublisherAccount(requestParameters.publisherId, requestParameters.updatePublisherAccountRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPublisherAccount operation in PublisherAccountsApi.
 * @export
 * @interface PublisherAccountsApiCreatePublisherAccountRequest
 */
export interface PublisherAccountsApiCreatePublisherAccountRequest {
    /**
     * 
     * @type {StorePublisherAccountRequest}
     * @memberof PublisherAccountsApiCreatePublisherAccount
     */
    readonly storePublisherAccountRequest?: StorePublisherAccountRequest
}

/**
 * Request parameters for updatePublisherAccount operation in PublisherAccountsApi.
 * @export
 * @interface PublisherAccountsApiUpdatePublisherAccountRequest
 */
export interface PublisherAccountsApiUpdatePublisherAccountRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherAccountsApiUpdatePublisherAccount
     */
    readonly publisherId: number

    /**
     * 
     * @type {UpdatePublisherAccountRequest}
     * @memberof PublisherAccountsApiUpdatePublisherAccount
     */
    readonly updatePublisherAccountRequest?: UpdatePublisherAccountRequest
}

/**
 * PublisherAccountsApi - object-oriented interface
 * @export
 * @class PublisherAccountsApi
 * @extends {BaseAPI}
 */
export class PublisherAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Create Publisher Account
     * @param {PublisherAccountsApiCreatePublisherAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherAccountsApi
     */
    public createPublisherAccount(requestParameters: PublisherAccountsApiCreatePublisherAccountRequest = {}, options?: AxiosRequestConfig) {
        return PublisherAccountsApiFp(this.configuration).createPublisherAccount(requestParameters.storePublisherAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Publisher Account
     * @param {PublisherAccountsApiUpdatePublisherAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherAccountsApi
     */
    public updatePublisherAccount(requestParameters: PublisherAccountsApiUpdatePublisherAccountRequest, options?: AxiosRequestConfig) {
        return PublisherAccountsApiFp(this.configuration).updatePublisherAccount(requestParameters.publisherId, requestParameters.updatePublisherAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
