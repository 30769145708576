import { useAccountStore } from '@/Stores/AccountStore';
import { Account } from '@/Models/Account';
import { AccountType } from '@/Models/AccountType';

export class AccountSelectionHelper {
    static async attemptSelectAccountById(accountId: number, type: AccountType): Promise<Account | null> {
        const accountStore = useAccountStore();
        if (accountStore.selectedAccount?.id === accountId) {
            return accountStore.selectedAccount;
        }

        const account = await accountStore.fetchAccount(type, accountId);
        if (!account) {
            return null;
        }
        return accountStore.selectAccount(account);
    }
}
