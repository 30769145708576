/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
/**
 * PublisherProgramsApi - axios parameter creator
 * @export
 */
export const PublisherProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns Programs that are compatible with the Sites of the Publisher, based on subscriptions.
         * @summary Get Publisher Programs
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherPrograms: async (publisherId: number, name?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherPrograms', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/programs`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherProgramsApi - functional programming interface
 * @export
 */
export const PublisherProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns Programs that are compatible with the Sites of the Publisher, based on subscriptions.
         * @summary Get Publisher Programs
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherPrograms(publisherId: number, name?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherPrograms(publisherId, name, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherProgramsApi - factory interface
 * @export
 */
export const PublisherProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherProgramsApiFp(configuration)
    return {
        /**
         * Returns Programs that are compatible with the Sites of the Publisher, based on subscriptions.
         * @summary Get Publisher Programs
         * @param {PublisherProgramsApiGetPublisherProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherPrograms(requestParameters: PublisherProgramsApiGetPublisherProgramsRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.getPublisherPrograms(requestParameters.publisherId, requestParameters.name, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPublisherPrograms operation in PublisherProgramsApi.
 * @export
 * @interface PublisherProgramsApiGetPublisherProgramsRequest
 */
export interface PublisherProgramsApiGetPublisherProgramsRequest {
    /**
     * Numeric ID of the publisher to find
     * @type {number}
     * @memberof PublisherProgramsApiGetPublisherPrograms
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof PublisherProgramsApiGetPublisherPrograms
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherProgramsApiGetPublisherPrograms
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherProgramsApiGetPublisherPrograms
     */
    readonly perPage?: number
}

/**
 * PublisherProgramsApi - object-oriented interface
 * @export
 * @class PublisherProgramsApi
 * @extends {BaseAPI}
 */
export class PublisherProgramsApi extends BaseAPI {
    /**
     * Returns Programs that are compatible with the Sites of the Publisher, based on subscriptions.
     * @summary Get Publisher Programs
     * @param {PublisherProgramsApiGetPublisherProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherProgramsApi
     */
    public getPublisherPrograms(requestParameters: PublisherProgramsApiGetPublisherProgramsRequest, options?: AxiosRequestConfig) {
        return PublisherProgramsApiFp(this.configuration).getPublisherPrograms(requestParameters.publisherId, requestParameters.name, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
