/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherCustomDomainStatusResource } from '../AfClApiModels';
// @ts-ignore
import { PublisherSite } from '../AfClApiModels';
// @ts-ignore
import { StoreSiteRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateSiteRequest } from '../AfClApiModels';
/**
 * PublisherSitesApi - axios parameter creator
 * @export
 */
export const PublisherSitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Site
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {StoreSiteRequest} [storeSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite: async (publisherId: number, storeSiteRequest?: StoreSiteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createSite', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/sites`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeSiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Site Custom Domain Status
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} siteId Numeric ID of the Site to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteCustomDomainStatus: async (publisherId: number, siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getSiteCustomDomainStatus', 'publisherId', publisherId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteCustomDomainStatus', 'siteId', siteId)
            const localVarPath = `/v1/publishers/{publisherId}/sites/{siteId}/custom-domain-status`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sites
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSites: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getSites', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/sites`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Site
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} siteId Numeric ID of the Site to find
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite: async (publisherId: number, siteId: number, updateSiteRequest?: UpdateSiteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updateSite', 'publisherId', publisherId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateSite', 'siteId', siteId)
            const localVarPath = `/v1/publishers/{publisherId}/sites/{siteId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherSitesApi - functional programming interface
 * @export
 */
export const PublisherSitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherSitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Site
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {StoreSiteRequest} [storeSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSite(publisherId: number, storeSiteRequest?: StoreSiteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherSite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSite(publisherId, storeSiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Site Custom Domain Status
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} siteId Numeric ID of the Site to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteCustomDomainStatus(publisherId: number, siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherCustomDomainStatusResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteCustomDomainStatus(publisherId, siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Sites
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSites(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherSite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSites(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Site
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} siteId Numeric ID of the Site to find
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSite(publisherId: number, siteId: number, updateSiteRequest?: UpdateSiteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherSite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSite(publisherId, siteId, updateSiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherSitesApi - factory interface
 * @export
 */
export const PublisherSitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherSitesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Site
         * @param {PublisherSitesApiCreateSiteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(requestParameters: PublisherSitesApiCreateSiteRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherSite> {
            return localVarFp.createSite(requestParameters.publisherId, requestParameters.storeSiteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Site Custom Domain Status
         * @param {PublisherSitesApiGetSiteCustomDomainStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteCustomDomainStatus(requestParameters: PublisherSitesApiGetSiteCustomDomainStatusRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherCustomDomainStatusResource> {
            return localVarFp.getSiteCustomDomainStatus(requestParameters.publisherId, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sites
         * @param {PublisherSitesApiGetSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSites(requestParameters: PublisherSitesApiGetSitesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherSite>> {
            return localVarFp.getSites(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Site
         * @param {PublisherSitesApiUpdateSiteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(requestParameters: PublisherSitesApiUpdateSiteRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherSite> {
            return localVarFp.updateSite(requestParameters.publisherId, requestParameters.siteId, requestParameters.updateSiteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSite operation in PublisherSitesApi.
 * @export
 * @interface PublisherSitesApiCreateSiteRequest
 */
export interface PublisherSitesApiCreateSiteRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherSitesApiCreateSite
     */
    readonly publisherId: number

    /**
     * 
     * @type {StoreSiteRequest}
     * @memberof PublisherSitesApiCreateSite
     */
    readonly storeSiteRequest?: StoreSiteRequest
}

/**
 * Request parameters for getSiteCustomDomainStatus operation in PublisherSitesApi.
 * @export
 * @interface PublisherSitesApiGetSiteCustomDomainStatusRequest
 */
export interface PublisherSitesApiGetSiteCustomDomainStatusRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherSitesApiGetSiteCustomDomainStatus
     */
    readonly publisherId: number

    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof PublisherSitesApiGetSiteCustomDomainStatus
     */
    readonly siteId: number
}

/**
 * Request parameters for getSites operation in PublisherSitesApi.
 * @export
 * @interface PublisherSitesApiGetSitesRequest
 */
export interface PublisherSitesApiGetSitesRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherSitesApiGetSites
     */
    readonly publisherId: number
}

/**
 * Request parameters for updateSite operation in PublisherSitesApi.
 * @export
 * @interface PublisherSitesApiUpdateSiteRequest
 */
export interface PublisherSitesApiUpdateSiteRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherSitesApiUpdateSite
     */
    readonly publisherId: number

    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof PublisherSitesApiUpdateSite
     */
    readonly siteId: number

    /**
     * 
     * @type {UpdateSiteRequest}
     * @memberof PublisherSitesApiUpdateSite
     */
    readonly updateSiteRequest?: UpdateSiteRequest
}

/**
 * PublisherSitesApi - object-oriented interface
 * @export
 * @class PublisherSitesApi
 * @extends {BaseAPI}
 */
export class PublisherSitesApi extends BaseAPI {
    /**
     * 
     * @summary Create Site
     * @param {PublisherSitesApiCreateSiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSitesApi
     */
    public createSite(requestParameters: PublisherSitesApiCreateSiteRequest, options?: AxiosRequestConfig) {
        return PublisherSitesApiFp(this.configuration).createSite(requestParameters.publisherId, requestParameters.storeSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Site Custom Domain Status
     * @param {PublisherSitesApiGetSiteCustomDomainStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSitesApi
     */
    public getSiteCustomDomainStatus(requestParameters: PublisherSitesApiGetSiteCustomDomainStatusRequest, options?: AxiosRequestConfig) {
        return PublisherSitesApiFp(this.configuration).getSiteCustomDomainStatus(requestParameters.publisherId, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sites
     * @param {PublisherSitesApiGetSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSitesApi
     */
    public getSites(requestParameters: PublisherSitesApiGetSitesRequest, options?: AxiosRequestConfig) {
        return PublisherSitesApiFp(this.configuration).getSites(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Site
     * @param {PublisherSitesApiUpdateSiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSitesApi
     */
    public updateSite(requestParameters: PublisherSitesApiUpdateSiteRequest, options?: AxiosRequestConfig) {
        return PublisherSitesApiFp(this.configuration).updateSite(requestParameters.publisherId, requestParameters.siteId, requestParameters.updateSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
