import { RouteRecordRaw } from 'vue-router';
import { RegisterPublisherRoutes } from '@/Router/Routes/PublisherRoutes';
import { RegisterAdvertiserRoutes } from '@/Router/Routes/AdvertiserRoutes';
import { RegisterAdminRoutes } from '@/Router/Routes/AdminRoutes';
import { RegisterCorporationRoutes } from '@/Router/Routes/CorporationRoutes';
import { Route } from 'vue-lite-route';
import { MiddlewareGroups } from '@/Router/Middleware/MiddlewareGroups';
import { RegisterRedirectRoutes } from '@/Router/Routes/Redirects';
import { EnsureAuthenticated } from '@/Router/Middleware/EnsureAuthenticated';

const DefaultLayout = () => import('@/Layouts/DefaultLayout.vue');
const NotFound = () => import('@/Pages/Error/NotFound.vue');
const AccountSelectPage = () => import('@/Pages/Shared/AccountSelectPage.vue');
const AccountSetupPage = () => import('@/Pages/Shared/AccountSetupPage.vue');
const TermsAndConditionPage = () => import('@/Pages/Shared/TermsAndConditionPage.vue');
const MaintenancePage = () => import('@/Pages/Shared/MaintenancePage.vue');
const SkeletonLayout = () => import('@/Layouts/SkeletonLayout.vue');
const LoginPage = () => import('@/Pages/Shared/LoginPage.vue');

Route.childrenGroup('/', { action: { component: SkeletonLayout } }, () => {
    Route.group({ prefix: '/accounts', name: 'accounts', middleware: EnsureAuthenticated }, () => {
        Route.add('', { component: AccountSelectPage });
        Route.add('/setup', { component: AccountSetupPage }).name('.setup');
    });
});

Route.add('/terms-and-conditions', { component: TermsAndConditionPage }).name('terms-and-conditions');

Route.childrenGroup('', { action: {
    component: DefaultLayout,
    redirect: () => ({ name: 'login' })
} }, () => {
    RegisterRedirectRoutes();
    RegisterPublisherRoutes();
    RegisterAdvertiserRoutes();
    RegisterAdminRoutes();
    RegisterCorporationRoutes();
});

Route.childrenGroup('/', { action: { component: SkeletonLayout } }, () => {
    Route.add('', { component: LoginPage }).name('login');
}).clearName();
Route.add('/advertisers/:accountId/terms-and-conditions', { component: TermsAndConditionPage })
    .middleware(MiddlewareGroups.advertiser).name('advertiser.terms');
Route.add('/maintenance', { component: MaintenancePage }).name('maintenance');
Route.add('/:pathMatch(.*)*', { component: NotFound }).name('notFound');

export const routes: RouteRecordRaw[] = Route.buildRoutes();
