export enum Locales {
    EN = 'en',
    SV = 'sv',
    JA = 'ja',
    FI = 'fi',
    PT = 'pt',
    ES = 'es'
}

export const LOCALES = [
    { value: Locales.EN, caption: 'English' },
    // { value: Locales.SV, caption: 'Svenska' },
    { value: Locales.JA, caption: '日本語' },
    // { value: Locales.FI, caption: 'Suomi' }
    // { value: Locales.PT, caption: 'Portuguese' },
    // { value: Locales.ES, caption: 'Spanish' }
];
