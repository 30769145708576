<template>
    <VueMultiselect
        ref="multiselectRef"
        v-bind="{
            ...$attrs,
            ...$props,
            modelValue: selectedValues
        }"
        :placeholder="multiSelectProps.placeholder?.default == $props.placeholder ? $t('general.dropdowns.placeholder.select_option') : $props.placeholder"
        :select-label="multiSelectProps.selectLabel?.default == $props.selectLabel ? $t('general.dropdowns.select') : $props.selectLabel"
        :selected-label="multiSelectProps.selectedLabel?.default == $props.selectedLabel ? $t('general.dropdowns.selected') : $props.selectedLabel"
        :select-group-label="multiSelectProps.selectGroupLabel?.default == $props.selectGroupLabel ? $t('general.dropdowns.select_group') : $props.selectGroupLabel"
        :deselect-label="multiSelectProps.deselectLabel?.default == $props.deselectLabel ?$t('general.dropdowns.deselect') : $props.deselectLabel"
        :deselect-group-label="multiSelectProps.deselectGroupLabel?.default == $props.deselectGroupLabel ? $t('general.dropdowns.deselect_group') : $props.deselectGroupLabel"
        @open="passThroughEvent('open', $event)"
        @search-change="passThroughEvent('search-change', $event)"
        @close="passThroughEvent('close', $event)"
        @select="passThroughEvent('select', $event)"
        @update:model-value="updateSelected($event)"
        @remove="passThroughEvent('remove', $event)"
        @tag="passThroughEvent('tag', $event)"
    >
        <template
            v-for="(_, name) in $slots"
            #[name]="slotData"
        >
            <slot
                :name
                v-bind="slotData"
            />
        </template>
    </VueMultiselect>
</template>

<script lang="ts">
import VueMultiselect from 'vue-multiselect';

const { emits, props: mixinsProps } = VueMultiselect.mixins[0]; 

/* 
*  Extra property called keyValue was added, this paramenter will contain only the value on the track-by key of the selection
*  it can also be used to fill the value of the select
*  EX: 
*       <VueMultiselect 
*             track-by="id" 
*             v-model:key-value="param"
*             :options="[{ id: 1, text: 'text1'}, {id: 2, text: 'text2'}]"
*       >
*       
*       when selecting both the param ill contain only 1,2.
* 
* It Also works for non object types
* EX: 
*       <VueMultiselect 
*             v-model:key-value="param"
*             :options="['text1', 'text2']"
*       >
*       
*       when selecting both the param ill contain 'text1','text2'.
* 
* 
* for retroactive compatibility, the base model-value still works the same as before. 
* ***********
* IMPORTANT: 
* - the key-value can't fully work with server side paginated dropdowns since only
* having keys is not enough to identify the labels of the elements not yet retrived from the backend.
* 
* - if both properties 'key-value' and 'model-value' are passed, the model-value takes priority
* 
* ***********
*/
export default {
    components: {
        VueMultiselect,
    },
    props: {
        keyValue: { 
            type: [String, Number],
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        ...VueMultiselect.props,
        ...mixinsProps
    },
    emits: [ ...emits, 'update:keyValue' ],
    computed: {
        multiSelectProps() { return { ...VueMultiselect.props, ...mixinsProps }; },
        trackByKey()  { return this.trackBy ?? 'id'; },
        selectedValues() {
            return this.multiple ? this.getValuesForMultiple() : this.getValuesForSingle();
        }
    },
    methods: {
        passThroughEvent(eventName: string, payload) {
            if (!emits.includes(eventName)) {
                console.warn(`Event name "${ eventName }" is not available within the multiselect library. Please make sure not to make any unnecessary alterations to the library's behavior.`);
            }
            this.$emit(eventName, payload);
        },
        updateSelected(selected) {
            if(this.multiple) {
                this.$emit('update:modelValue', selected);
                this.$emit('update:keyValue', selected?.map((item) => item?.[this.trackByKey] ?? item));
            }else {
                this.$emit('update:modelValue', selected);
                this.$emit('update:keyValue', selected?.[this.trackByKey] ?? selected);
            }
        },
        getMultiselectOptions() {
            return this.$refs.multiselectRef?.groupValues ? this.$refs.multiselectRef?.flatAndStrip(this.options) : this.options;
        },
        getValuesForMultiple() {
            const localOptions = this.getMultiselectOptions();

            if(this.keyValue == null && this.modelValue == null) {
                return [];
            }

            if(this.modelValue != null && this.modelValue?.length > 0) {
                return this.modelValue;
            }

            return localOptions?.filter(
                (option) => {
                    return (option[this.trackByKey] !== undefined && this.keyValue?.includes(option[this.trackByKey])) ||
                            this.keyValue?.map(JSON.stringify).includes(JSON.stringify(option));
                }
            );
        },
        getValuesForSingle() {
            const localOptions = this.getMultiselectOptions();

            if(this.keyValue == null && this.modelValue == null) {
                return null;
            }

            if(this.modelValue != null && !Array.isArray(this.modelValue)) {
                return this.modelValue;
            }

            return localOptions?.find(
                (option) => {
                    return (option[this.trackByKey] !== undefined && option[this.trackByKey] == this.keyValue) || JSON.stringify(this.keyValue) == JSON.stringify(option);
                }
            );
        }
    }
};
</script>
