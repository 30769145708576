import { useAuthStore } from '@/Stores/AuthStore';

export const RoutesPermissionsIndex: Record<string, string | string[]> = {
    'admin.publishers'              : 'access.publishers',
    'admin.publishers.view'         : 'access.publishers',
    'admin.publishers.kyc'          : 'access.publishers',
    'admin.publishers.sites'        : 'access.publishers',
    'admin.publishers.financials'   : 'access.publishers',
    'admin.invoicing'               : 'access.publishers',

    'admin.advertisers'         : 'access.advertisers',
    'admin.brands'              : 'access.brands',

    'admin.programs'            : 'access.programs',
    'admin.programs.general'    : 'access.programs',
    'admin.programs.licenses'   : 'access.programs',
    'admin.programs.details'    : 'access.programs',
    'admin.programs.deals'      : 'access.programs',

    'admin.sites'               : 'access.sites',
    'admin.sites.view'          : 'access.sites',
    'admin.sites.kyc'           : 'access.sites',
    'admin.sites.affiliation'   : 'access.sites',
    'admin.site-groups.view'    : 'access.sites',
    'admin.site-groups.kyc'     : 'access.sites',
    'admin.site-groups.sites'   : 'access.sites',

    'admin.compliance'                  : 'access.compliances',
    'admin.compliance.licenses'         : 'access.compliances',
    'admin.compliance.license-audit'     : 'access.programs',
    'admin.compliance.site-audit'        : 'access.sites',
    'admin.compliance.site-compliance'   : 'access.siteapprovalitems',

    'admin.operations.link-requests'         : 'access.redirectlinks',
    'admin.operations.data-mapping'          : 'access.affiliatesources',
    'admin.operations.affiliate-systems'     : 'access.affiliatesystems',
    'admin.operations.postbacks'            : 'access.postbacks',
    'admin.operations.voonix-accounts'       : 'access.voonixaccounts',
    'admin.operations.affiliate-accounts'    : ['access.affiliateaccounts.readall', 'access.affiliateaccounts.read'],

    'admin.data.markets'                : 'access.regions',
    'admin.data.verticals'              : 'access.verticals',
    'admin.data.corporations'           : 'access.corporations',
    'admin.data.keys.media'             : 'access.campaignkeys',
    'admin.data.keys.campaign'          : 'access.campaignkeys',
    'admin.data.keys.source'            : 'access.campaignkeys',

    'admin.widgets.translations'    : 'access.lexicon',
    'admin.docs.service-docs'       : 'access.documentation',
};

export const routePermissionsCheck = (routeName: string): boolean => {
    const { authUser } = useAuthStore();
    let routePermissions = RoutesPermissionsIndex[routeName] ?? [];
    if (!routePermissions.length) {
        return true;
    }
    if (typeof routePermissions === 'string') {
        routePermissions = [routePermissions];
    }

    return routePermissions.some(permission => authUser?.hasPermission(permission));
};

export const firstRouteWithPermission = (): string | null => {
    for (const routeName in RoutesPermissionsIndex) {
        if (routePermissionsCheck(routeName)) {
            return routeName;
        }
    }

    return null;
};
