import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';
import { routePermissionsCheck } from '@/Router/Routes/RoutesPermissions';

export const EnsureHasPermission: MiddlewareFunction = async (context) => {
    const { to, from, next } = context;
    if (!routePermissionsCheck(to.name as string)) {
        console.warn(`Access denied to route: ${String(to.name)}.`);
        return { name: from.name };
    }

    return next();
};
