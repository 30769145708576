<template>
    <tr class="bg-gray-100">
        <th
            class="pl-2"
            scope="row"
            :class="generateHeaderAlignmentClass(items, columns[0])"
        >
            {{ $t('general.totals') }}
        </th>
        <td
            v-for="column in columns.slice(1)"
            :key="`${String(column.key)}-total`"
            class="base-table-cell font-bold"
            :class="generateHeaderAlignmentClass<T>(items, column)"
        >
            <slot
                :name="`total_${String(column.key)}`"
                :total="getColumnTotal(column)"
                :currency="column.currency"
            >
                <template v-if="getColumnTotal(column)">
                    <span v-if="column.currency">
                        {{ withCurrency(getColumnTotal(column).value, column.currency) }}
                    </span>
                    <span v-else>
                        {{ formatNumeric(getColumnTotal(column).value) }}
                    </span>
                </template>
            </slot>
        </td>
    </tr>
</template>

<script setup lang="ts" generic="T">
import { TableHeader, TableTotal } from '@/Models/Components/BaseTableContract';
import withCurrency from '@/Utils/Filters/WithCurrency.filter';
import { formatNumeric } from '@/Utils/Filters/FormatNumeric';
import { generateHeaderAlignmentClass } from '@/Utils/TableHelper.ts';


const {
    items = [],
    columns = [],
    totals = null
} = defineProps<{
    // eslint-disable-next-line no-undef
    items: T[] | undefined;
    totals: TableTotal[] | null;
    columns: [];
}>();

defineSlots<{
    [key: `total_${string}`]: (props: { total: TableTotal | null, currency?: TableHeader['currency'] }) => void;
}>();

const getColumnTotal = (column: TableHeader<T>): TableTotal => {
    return totals?.find((total) => total.key === column.key) ?? {
        key: column.key,
        value: ''
    } as TableTotal;
};
</script>