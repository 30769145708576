/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HubGetAffiliateAccounts200Response } from '../AfClApiModels';
/**
 * HubAffiliateAccountsApi - axios parameter creator
 * @export
 */
export const HubAffiliateAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {string} [updatedFrom] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubGetAffiliateAccounts: async (status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', updatedFrom?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/hub/affiliate-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedFrom !== undefined) {
                localVarQueryParameter['updated_from'] = (updatedFrom as any instanceof Date) ?
                    (updatedFrom as any).toISOString() :
                    updatedFrom;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HubAffiliateAccountsApi - functional programming interface
 * @export
 */
export const HubAffiliateAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HubAffiliateAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {string} [updatedFrom] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubGetAffiliateAccounts(status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', updatedFrom?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HubGetAffiliateAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubGetAffiliateAccounts(status, updatedFrom, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HubAffiliateAccountsApi - factory interface
 * @export
 */
export const HubAffiliateAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HubAffiliateAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {HubAffiliateAccountsApiHubGetAffiliateAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubGetAffiliateAccounts(requestParameters: HubAffiliateAccountsApiHubGetAffiliateAccountsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<HubGetAffiliateAccounts200Response> {
            return localVarFp.hubGetAffiliateAccounts(requestParameters.status, requestParameters.updatedFrom, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hubGetAffiliateAccounts operation in HubAffiliateAccountsApi.
 * @export
 * @interface HubAffiliateAccountsApiHubGetAffiliateAccountsRequest
 */
export interface HubAffiliateAccountsApiHubGetAffiliateAccountsRequest {
    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'}
     * @memberof HubAffiliateAccountsApiHubGetAffiliateAccounts
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'

    /**
     * 
     * @type {string}
     * @memberof HubAffiliateAccountsApiHubGetAffiliateAccounts
     */
    readonly updatedFrom?: string

    /**
     * 
     * @type {number}
     * @memberof HubAffiliateAccountsApiHubGetAffiliateAccounts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof HubAffiliateAccountsApiHubGetAffiliateAccounts
     */
    readonly perPage?: number
}

/**
 * HubAffiliateAccountsApi - object-oriented interface
 * @export
 * @class HubAffiliateAccountsApi
 * @extends {BaseAPI}
 */
export class HubAffiliateAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Get Affiliate Accounts
     * @param {HubAffiliateAccountsApiHubGetAffiliateAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubAffiliateAccountsApi
     */
    public hubGetAffiliateAccounts(requestParameters: HubAffiliateAccountsApiHubGetAffiliateAccountsRequest = {}, options?: AxiosRequestConfig) {
        return HubAffiliateAccountsApiFp(this.configuration).hubGetAffiliateAccounts(requestParameters.status, requestParameters.updatedFrom, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
