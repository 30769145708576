/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UpdateSiteTopListItemRequest } from '../AfClApiModels';
/**
 * TopListItemApi - axios parameter creator
 * @export
 */
export const TopListItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Top List Item
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} toplistId Numeric ID of the toplist
         * @param {number} subscriptionId Numeric ID of the subscription
         * @param {UpdateSiteTopListItemRequest} [updateSiteTopListItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopListItem: async (publisherId: number, toplistId: number, subscriptionId: number, updateSiteTopListItemRequest?: UpdateSiteTopListItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updateTopListItem', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('updateTopListItem', 'toplistId', toplistId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('updateTopListItem', 'subscriptionId', subscriptionId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}/item/{subscriptionId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)))
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSiteTopListItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopListItemApi - functional programming interface
 * @export
 */
export const TopListItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopListItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Top List Item
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} toplistId Numeric ID of the toplist
         * @param {number} subscriptionId Numeric ID of the subscription
         * @param {UpdateSiteTopListItemRequest} [updateSiteTopListItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTopListItem(publisherId: number, toplistId: number, subscriptionId: number, updateSiteTopListItemRequest?: UpdateSiteTopListItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTopListItem(publisherId, toplistId, subscriptionId, updateSiteTopListItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopListItemApi - factory interface
 * @export
 */
export const TopListItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopListItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Top List Item
         * @param {TopListItemApiUpdateTopListItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopListItem(requestParameters: TopListItemApiUpdateTopListItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTopListItem(requestParameters.publisherId, requestParameters.toplistId, requestParameters.subscriptionId, requestParameters.updateSiteTopListItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateTopListItem operation in TopListItemApi.
 * @export
 * @interface TopListItemApiUpdateTopListItemRequest
 */
export interface TopListItemApiUpdateTopListItemRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof TopListItemApiUpdateTopListItem
     */
    readonly publisherId: number

    /**
     * Numeric ID of the toplist
     * @type {number}
     * @memberof TopListItemApiUpdateTopListItem
     */
    readonly toplistId: number

    /**
     * Numeric ID of the subscription
     * @type {number}
     * @memberof TopListItemApiUpdateTopListItem
     */
    readonly subscriptionId: number

    /**
     * 
     * @type {UpdateSiteTopListItemRequest}
     * @memberof TopListItemApiUpdateTopListItem
     */
    readonly updateSiteTopListItemRequest?: UpdateSiteTopListItemRequest
}

/**
 * TopListItemApi - object-oriented interface
 * @export
 * @class TopListItemApi
 * @extends {BaseAPI}
 */
export class TopListItemApi extends BaseAPI {
    /**
     * 
     * @summary Update Top List Item
     * @param {TopListItemApiUpdateTopListItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopListItemApi
     */
    public updateTopListItem(requestParameters: TopListItemApiUpdateTopListItemRequest, options?: AxiosRequestConfig) {
        return TopListItemApiFp(this.configuration).updateTopListItem(requestParameters.publisherId, requestParameters.toplistId, requestParameters.subscriptionId, requestParameters.updateSiteTopListItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
