/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherDocument } from '../AfClApiModels';
// @ts-ignore
import { PublisherStorePublisherDocumentsRequestDocumentsInner } from '../AfClApiModels';
/**
 * PublisherPublisherDocumentsApi - axios parameter creator
 * @export
 */
export const PublisherPublisherDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Publisher Document
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} documentId Numeric ID of the document to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherDocument: async (publisherId: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('deletePublisherDocument', 'publisherId', publisherId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deletePublisherDocument', 'documentId', documentId)
            const localVarPath = `/v1/publishers/{publisherId}/documents/{documentId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Publisher Document
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} documentId Numeric ID of the document to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPublisherProfileDocument: async (publisherId: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('downloadPublisherProfileDocument', 'publisherId', publisherId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('downloadPublisherProfileDocument', 'documentId', documentId)
            const localVarPath = `/v1/publishers/{publisherId}/documents/{documentId}/download`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publisher Documents
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherDocuments: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getPublisherDocuments', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/documents`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Publisher Documents
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {Array<PublisherStorePublisherDocumentsRequestDocumentsInner>} documents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePublisherDocuments: async (publisherId: number, documents: Array<PublisherStorePublisherDocumentsRequestDocumentsInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('storePublisherDocuments', 'publisherId', publisherId)
            // verify required parameter 'documents' is not null or undefined
            assertParamExists('storePublisherDocuments', 'documents', documents)
            const localVarPath = `/v1/publishers/{publisherId}/documents`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (documents) {
                localVarFormParams.append('documents', documents.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Publisher Documents
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} documentId Numeric ID of the publisher document to search
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherDocuments: async (publisherId: number, documentId: number, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updatePublisherDocuments', 'publisherId', publisherId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updatePublisherDocuments', 'documentId', documentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updatePublisherDocuments', 'file', file)
            const localVarPath = `/v1/publishers/{publisherId}/documents/{documentId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherPublisherDocumentsApi - functional programming interface
 * @export
 */
export const PublisherPublisherDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherPublisherDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Publisher Document
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} documentId Numeric ID of the document to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePublisherDocument(publisherId: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePublisherDocument(publisherId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Publisher Document
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} documentId Numeric ID of the document to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPublisherProfileDocument(publisherId: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPublisherProfileDocument(publisherId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publisher Documents
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublisherDocuments(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublisherDocuments(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Publisher Documents
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {Array<PublisherStorePublisherDocumentsRequestDocumentsInner>} documents 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePublisherDocuments(publisherId: number, documents: Array<PublisherStorePublisherDocumentsRequestDocumentsInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePublisherDocuments(publisherId, documents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Publisher Documents
         * @param {number} publisherId Numeric ID of the publisher to search
         * @param {number} documentId Numeric ID of the publisher document to search
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherDocuments(publisherId: number, documentId: number, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherDocuments(publisherId, documentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherPublisherDocumentsApi - factory interface
 * @export
 */
export const PublisherPublisherDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherPublisherDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Publisher Document
         * @param {PublisherPublisherDocumentsApiDeletePublisherDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherDocument(requestParameters: PublisherPublisherDocumentsApiDeletePublisherDocumentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePublisherDocument(requestParameters.publisherId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Publisher Document
         * @param {PublisherPublisherDocumentsApiDownloadPublisherProfileDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPublisherProfileDocument(requestParameters: PublisherPublisherDocumentsApiDownloadPublisherProfileDocumentRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadPublisherProfileDocument(requestParameters.publisherId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publisher Documents
         * @param {PublisherPublisherDocumentsApiGetPublisherDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublisherDocuments(requestParameters: PublisherPublisherDocumentsApiGetPublisherDocumentsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherDocument>> {
            return localVarFp.getPublisherDocuments(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Publisher Documents
         * @param {PublisherPublisherDocumentsApiStorePublisherDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePublisherDocuments(requestParameters: PublisherPublisherDocumentsApiStorePublisherDocumentsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherDocument>> {
            return localVarFp.storePublisherDocuments(requestParameters.publisherId, requestParameters.documents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Publisher Documents
         * @param {PublisherPublisherDocumentsApiUpdatePublisherDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherDocuments(requestParameters: PublisherPublisherDocumentsApiUpdatePublisherDocumentsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherDocument>> {
            return localVarFp.updatePublisherDocuments(requestParameters.publisherId, requestParameters.documentId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deletePublisherDocument operation in PublisherPublisherDocumentsApi.
 * @export
 * @interface PublisherPublisherDocumentsApiDeletePublisherDocumentRequest
 */
export interface PublisherPublisherDocumentsApiDeletePublisherDocumentRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiDeletePublisherDocument
     */
    readonly publisherId: number

    /**
     * Numeric ID of the document to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiDeletePublisherDocument
     */
    readonly documentId: number
}

/**
 * Request parameters for downloadPublisherProfileDocument operation in PublisherPublisherDocumentsApi.
 * @export
 * @interface PublisherPublisherDocumentsApiDownloadPublisherProfileDocumentRequest
 */
export interface PublisherPublisherDocumentsApiDownloadPublisherProfileDocumentRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiDownloadPublisherProfileDocument
     */
    readonly publisherId: number

    /**
     * Numeric ID of the document to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiDownloadPublisherProfileDocument
     */
    readonly documentId: number
}

/**
 * Request parameters for getPublisherDocuments operation in PublisherPublisherDocumentsApi.
 * @export
 * @interface PublisherPublisherDocumentsApiGetPublisherDocumentsRequest
 */
export interface PublisherPublisherDocumentsApiGetPublisherDocumentsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiGetPublisherDocuments
     */
    readonly publisherId: number
}

/**
 * Request parameters for storePublisherDocuments operation in PublisherPublisherDocumentsApi.
 * @export
 * @interface PublisherPublisherDocumentsApiStorePublisherDocumentsRequest
 */
export interface PublisherPublisherDocumentsApiStorePublisherDocumentsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiStorePublisherDocuments
     */
    readonly publisherId: number

    /**
     * 
     * @type {Array<PublisherStorePublisherDocumentsRequestDocumentsInner>}
     * @memberof PublisherPublisherDocumentsApiStorePublisherDocuments
     */
    readonly documents: Array<PublisherStorePublisherDocumentsRequestDocumentsInner>
}

/**
 * Request parameters for updatePublisherDocuments operation in PublisherPublisherDocumentsApi.
 * @export
 * @interface PublisherPublisherDocumentsApiUpdatePublisherDocumentsRequest
 */
export interface PublisherPublisherDocumentsApiUpdatePublisherDocumentsRequest {
    /**
     * Numeric ID of the publisher to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiUpdatePublisherDocuments
     */
    readonly publisherId: number

    /**
     * Numeric ID of the publisher document to search
     * @type {number}
     * @memberof PublisherPublisherDocumentsApiUpdatePublisherDocuments
     */
    readonly documentId: number

    /**
     * 
     * @type {File}
     * @memberof PublisherPublisherDocumentsApiUpdatePublisherDocuments
     */
    readonly file: File
}

/**
 * PublisherPublisherDocumentsApi - object-oriented interface
 * @export
 * @class PublisherPublisherDocumentsApi
 * @extends {BaseAPI}
 */
export class PublisherPublisherDocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Publisher Document
     * @param {PublisherPublisherDocumentsApiDeletePublisherDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherDocumentsApi
     */
    public deletePublisherDocument(requestParameters: PublisherPublisherDocumentsApiDeletePublisherDocumentRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherDocumentsApiFp(this.configuration).deletePublisherDocument(requestParameters.publisherId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Publisher Document
     * @param {PublisherPublisherDocumentsApiDownloadPublisherProfileDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherDocumentsApi
     */
    public downloadPublisherProfileDocument(requestParameters: PublisherPublisherDocumentsApiDownloadPublisherProfileDocumentRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherDocumentsApiFp(this.configuration).downloadPublisherProfileDocument(requestParameters.publisherId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publisher Documents
     * @param {PublisherPublisherDocumentsApiGetPublisherDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherDocumentsApi
     */
    public getPublisherDocuments(requestParameters: PublisherPublisherDocumentsApiGetPublisherDocumentsRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherDocumentsApiFp(this.configuration).getPublisherDocuments(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Publisher Documents
     * @param {PublisherPublisherDocumentsApiStorePublisherDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherDocumentsApi
     */
    public storePublisherDocuments(requestParameters: PublisherPublisherDocumentsApiStorePublisherDocumentsRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherDocumentsApiFp(this.configuration).storePublisherDocuments(requestParameters.publisherId, requestParameters.documents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Publisher Documents
     * @param {PublisherPublisherDocumentsApiUpdatePublisherDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPublisherDocumentsApi
     */
    public updatePublisherDocuments(requestParameters: PublisherPublisherDocumentsApiUpdatePublisherDocumentsRequest, options?: AxiosRequestConfig) {
        return PublisherPublisherDocumentsApiFp(this.configuration).updatePublisherDocuments(requestParameters.publisherId, requestParameters.documentId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}
