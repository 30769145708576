/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetTopListWidget200Response } from '../AfClApiModels';
// @ts-ignore
import { PublisherUpdateTopListRequest } from '../AfClApiModels';
/**
 * TopListWidgetApi - axios parameter creator
 * @export
 */
export const TopListWidgetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get TopList Widget
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} toplistId Numeric ID of the toplist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopListWidget: async (publisherId: number, toplistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getTopListWidget', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('getTopListWidget', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}/widget`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Top List Widget
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} toplistId Numeric ID of the toplist
         * @param {PublisherUpdateTopListRequest} [publisherUpdateTopListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopListWidget: async (publisherId: number, toplistId: number, publisherUpdateTopListRequest?: PublisherUpdateTopListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('updateTopListWidget', 'publisherId', publisherId)
            // verify required parameter 'toplistId' is not null or undefined
            assertParamExists('updateTopListWidget', 'toplistId', toplistId)
            const localVarPath = `/v1/publishers/{publisherId}/site-top-lists/{toplistId}/widget`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"toplistId"}}`, encodeURIComponent(String(toplistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publisherUpdateTopListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopListWidgetApi - functional programming interface
 * @export
 */
export const TopListWidgetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopListWidgetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get TopList Widget
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} toplistId Numeric ID of the toplist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopListWidget(publisherId: number, toplistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTopListWidget200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopListWidget(publisherId, toplistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Top List Widget
         * @param {number} publisherId Numeric ID of the publisher
         * @param {number} toplistId Numeric ID of the toplist
         * @param {PublisherUpdateTopListRequest} [publisherUpdateTopListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTopListWidget(publisherId: number, toplistId: number, publisherUpdateTopListRequest?: PublisherUpdateTopListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTopListWidget(publisherId, toplistId, publisherUpdateTopListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopListWidgetApi - factory interface
 * @export
 */
export const TopListWidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopListWidgetApiFp(configuration)
    return {
        /**
         * 
         * @summary Get TopList Widget
         * @param {TopListWidgetApiGetTopListWidgetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopListWidget(requestParameters: TopListWidgetApiGetTopListWidgetRequest, options?: AxiosRequestConfig): AxiosPromise<GetTopListWidget200Response> {
            return localVarFp.getTopListWidget(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Top List Widget
         * @param {TopListWidgetApiUpdateTopListWidgetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopListWidget(requestParameters: TopListWidgetApiUpdateTopListWidgetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTopListWidget(requestParameters.publisherId, requestParameters.toplistId, requestParameters.publisherUpdateTopListRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTopListWidget operation in TopListWidgetApi.
 * @export
 * @interface TopListWidgetApiGetTopListWidgetRequest
 */
export interface TopListWidgetApiGetTopListWidgetRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof TopListWidgetApiGetTopListWidget
     */
    readonly publisherId: number

    /**
     * Numeric ID of the toplist
     * @type {number}
     * @memberof TopListWidgetApiGetTopListWidget
     */
    readonly toplistId: number
}

/**
 * Request parameters for updateTopListWidget operation in TopListWidgetApi.
 * @export
 * @interface TopListWidgetApiUpdateTopListWidgetRequest
 */
export interface TopListWidgetApiUpdateTopListWidgetRequest {
    /**
     * Numeric ID of the publisher
     * @type {number}
     * @memberof TopListWidgetApiUpdateTopListWidget
     */
    readonly publisherId: number

    /**
     * Numeric ID of the toplist
     * @type {number}
     * @memberof TopListWidgetApiUpdateTopListWidget
     */
    readonly toplistId: number

    /**
     * 
     * @type {PublisherUpdateTopListRequest}
     * @memberof TopListWidgetApiUpdateTopListWidget
     */
    readonly publisherUpdateTopListRequest?: PublisherUpdateTopListRequest
}

/**
 * TopListWidgetApi - object-oriented interface
 * @export
 * @class TopListWidgetApi
 * @extends {BaseAPI}
 */
export class TopListWidgetApi extends BaseAPI {
    /**
     * 
     * @summary Get TopList Widget
     * @param {TopListWidgetApiGetTopListWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopListWidgetApi
     */
    public getTopListWidget(requestParameters: TopListWidgetApiGetTopListWidgetRequest, options?: AxiosRequestConfig) {
        return TopListWidgetApiFp(this.configuration).getTopListWidget(requestParameters.publisherId, requestParameters.toplistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Top List Widget
     * @param {TopListWidgetApiUpdateTopListWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopListWidgetApi
     */
    public updateTopListWidget(requestParameters: TopListWidgetApiUpdateTopListWidgetRequest, options?: AxiosRequestConfig) {
        return TopListWidgetApiFp(this.configuration).updateTopListWidget(requestParameters.publisherId, requestParameters.toplistId, requestParameters.publisherUpdateTopListRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
