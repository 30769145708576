import { TableHeader } from '@/Models/Components/BaseTableContract';

// eslint-disable-next-line no-undef
export function generateHeaderAlignmentClass<T>(items, column: TableHeader<T>): string {
    // Find the first non undefined available item in the list to get the raw value from
    const firstItemValue = items.length > 0 ? items.find((item => typeof item !== 'undefined'))?.[column.key as string] : undefined;
    let isNumeric = false;
    // Specifying alignment for typing purposes
    const alignment: {
        [key in Exclude<typeof column.forceAlign, undefined>]: 'left' | 'center' | 'right'
    } = {
        start: 'left',
        center: 'center',
        end: 'right'
    };
    let fallbackAlignment = alignment.start;

    // If no items were found in this column's list, then there's no need to check if it's numeric
    if (typeof firstItemValue !== 'undefined') {
        // Check if value is numeric
        try {
            const parsedInt = Number(firstItemValue);
            isNumeric = !isNaN(parsedInt) && isFinite(parsedInt);
        } catch (_e) {
            isNumeric = false;
        }
    }

    if (isNumeric) {
        fallbackAlignment = alignment.end;
    }

    return `text-${column?.forceAlign ? alignment[column.forceAlign] : fallbackAlignment}`;
}
