import { defineStore } from 'pinia';
import { defaultLocale } from '@/Locales';
import { computed, ref } from 'vue';

export const useLocaleStore = defineStore('localeStore', () => {
    const locale = ref<string>(defaultLocale);

    const getLocale = computed(() => locale.value);

    const setLocale = (payload: string) => {
        locale.value = payload;
    };

    return { locale, getLocale, setLocale };
});
