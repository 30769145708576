/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminLexiconCategory } from '../AfClApiModels';
// @ts-ignore
import { AdminStoreLexiconRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateLexiconTranslationRequest } from '../AfClApiModels';
// @ts-ignore
import { GetLexicons200Response } from '../AfClApiModels';
// @ts-ignore
import { Lexicon } from '../AfClApiModels';
/**
 * AdminLexiconsApi - axios parameter creator
 * @export
 */
export const AdminLexiconsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new widget translation (slug)
         * @param {AdminStoreLexiconRequest} [adminStoreLexiconRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLexicon: async (adminStoreLexiconRequest?: AdminStoreLexiconRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/lexicons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreLexiconRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete lexicon
         * @param {number} lexiconId Numeric ID of the lexicon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLexicon: async (lexiconId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lexiconId' is not null or undefined
            assertParamExists('deleteLexicon', 'lexiconId', lexiconId)
            const localVarPath = `/v1/admin/lexicons/{lexiconId}`
                .replace(`{${"lexiconId"}}`, encodeURIComponent(String(lexiconId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Lexicon Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLexiconCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/lexicon-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available lexicons.
         * @param {string} [slug] 
         * @param {Array<number>} [lexiconCategoryIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLexicons: async (slug?: string, lexiconCategoryIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/lexicons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (lexiconCategoryIds) {
                localVarQueryParameter['lexicon_category_ids[]'] = lexiconCategoryIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a lexicon translation
         * @param {number} lexiconId Numeric ID of the lexicon to update its translations
         * @param {AdminUpdateLexiconTranslationRequest} [adminUpdateLexiconTranslationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLexiconTranslation: async (lexiconId: number, adminUpdateLexiconTranslationRequest?: AdminUpdateLexiconTranslationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lexiconId' is not null or undefined
            assertParamExists('updateLexiconTranslation', 'lexiconId', lexiconId)
            const localVarPath = `/v1/admin/lexicons/{lexiconId}/translations`
                .replace(`{${"lexiconId"}}`, encodeURIComponent(String(lexiconId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateLexiconTranslationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLexiconsApi - functional programming interface
 * @export
 */
export const AdminLexiconsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLexiconsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new widget translation (slug)
         * @param {AdminStoreLexiconRequest} [adminStoreLexiconRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLexicon(adminStoreLexiconRequest?: AdminStoreLexiconRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lexicon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLexicon(adminStoreLexiconRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete lexicon
         * @param {number} lexiconId Numeric ID of the lexicon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLexicon(lexiconId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLexicon(lexiconId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Available Lexicon Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLexiconCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminLexiconCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLexiconCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available lexicons.
         * @param {string} [slug] 
         * @param {Array<number>} [lexiconCategoryIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLexicons(slug?: string, lexiconCategoryIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLexicons200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLexicons(slug, lexiconCategoryIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a lexicon translation
         * @param {number} lexiconId Numeric ID of the lexicon to update its translations
         * @param {AdminUpdateLexiconTranslationRequest} [adminUpdateLexiconTranslationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLexiconTranslation(lexiconId: number, adminUpdateLexiconTranslationRequest?: AdminUpdateLexiconTranslationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lexicon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLexiconTranslation(lexiconId, adminUpdateLexiconTranslationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLexiconsApi - factory interface
 * @export
 */
export const AdminLexiconsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLexiconsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new widget translation (slug)
         * @param {AdminLexiconsApiCreateLexiconRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLexicon(requestParameters: AdminLexiconsApiCreateLexiconRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Lexicon> {
            return localVarFp.createLexicon(requestParameters.adminStoreLexiconRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete lexicon
         * @param {AdminLexiconsApiDeleteLexiconRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLexicon(requestParameters: AdminLexiconsApiDeleteLexiconRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLexicon(requestParameters.lexiconId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Lexicon Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLexiconCategories(options?: AxiosRequestConfig): AxiosPromise<Array<AdminLexiconCategory>> {
            return localVarFp.getLexiconCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available lexicons.
         * @param {AdminLexiconsApiGetLexiconsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLexicons(requestParameters: AdminLexiconsApiGetLexiconsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetLexicons200Response> {
            return localVarFp.getLexicons(requestParameters.slug, requestParameters.lexiconCategoryIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a lexicon translation
         * @param {AdminLexiconsApiUpdateLexiconTranslationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLexiconTranslation(requestParameters: AdminLexiconsApiUpdateLexiconTranslationRequest, options?: AxiosRequestConfig): AxiosPromise<Lexicon> {
            return localVarFp.updateLexiconTranslation(requestParameters.lexiconId, requestParameters.adminUpdateLexiconTranslationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLexicon operation in AdminLexiconsApi.
 * @export
 * @interface AdminLexiconsApiCreateLexiconRequest
 */
export interface AdminLexiconsApiCreateLexiconRequest {
    /**
     * 
     * @type {AdminStoreLexiconRequest}
     * @memberof AdminLexiconsApiCreateLexicon
     */
    readonly adminStoreLexiconRequest?: AdminStoreLexiconRequest
}

/**
 * Request parameters for deleteLexicon operation in AdminLexiconsApi.
 * @export
 * @interface AdminLexiconsApiDeleteLexiconRequest
 */
export interface AdminLexiconsApiDeleteLexiconRequest {
    /**
     * Numeric ID of the lexicon to delete
     * @type {number}
     * @memberof AdminLexiconsApiDeleteLexicon
     */
    readonly lexiconId: number
}

/**
 * Request parameters for getLexicons operation in AdminLexiconsApi.
 * @export
 * @interface AdminLexiconsApiGetLexiconsRequest
 */
export interface AdminLexiconsApiGetLexiconsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminLexiconsApiGetLexicons
     */
    readonly slug?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminLexiconsApiGetLexicons
     */
    readonly lexiconCategoryIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminLexiconsApiGetLexicons
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminLexiconsApiGetLexicons
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateLexiconTranslation operation in AdminLexiconsApi.
 * @export
 * @interface AdminLexiconsApiUpdateLexiconTranslationRequest
 */
export interface AdminLexiconsApiUpdateLexiconTranslationRequest {
    /**
     * Numeric ID of the lexicon to update its translations
     * @type {number}
     * @memberof AdminLexiconsApiUpdateLexiconTranslation
     */
    readonly lexiconId: number

    /**
     * 
     * @type {AdminUpdateLexiconTranslationRequest}
     * @memberof AdminLexiconsApiUpdateLexiconTranslation
     */
    readonly adminUpdateLexiconTranslationRequest?: AdminUpdateLexiconTranslationRequest
}

/**
 * AdminLexiconsApi - object-oriented interface
 * @export
 * @class AdminLexiconsApi
 * @extends {BaseAPI}
 */
export class AdminLexiconsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new widget translation (slug)
     * @param {AdminLexiconsApiCreateLexiconRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLexiconsApi
     */
    public createLexicon(requestParameters: AdminLexiconsApiCreateLexiconRequest = {}, options?: AxiosRequestConfig) {
        return AdminLexiconsApiFp(this.configuration).createLexicon(requestParameters.adminStoreLexiconRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete lexicon
     * @param {AdminLexiconsApiDeleteLexiconRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLexiconsApi
     */
    public deleteLexicon(requestParameters: AdminLexiconsApiDeleteLexiconRequest, options?: AxiosRequestConfig) {
        return AdminLexiconsApiFp(this.configuration).deleteLexicon(requestParameters.lexiconId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Lexicon Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLexiconsApi
     */
    public getLexiconCategories(options?: AxiosRequestConfig) {
        return AdminLexiconsApiFp(this.configuration).getLexiconCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available lexicons.
     * @param {AdminLexiconsApiGetLexiconsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLexiconsApi
     */
    public getLexicons(requestParameters: AdminLexiconsApiGetLexiconsRequest = {}, options?: AxiosRequestConfig) {
        return AdminLexiconsApiFp(this.configuration).getLexicons(requestParameters.slug, requestParameters.lexiconCategoryIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a lexicon translation
     * @param {AdminLexiconsApiUpdateLexiconTranslationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLexiconsApi
     */
    public updateLexiconTranslation(requestParameters: AdminLexiconsApiUpdateLexiconTranslationRequest, options?: AxiosRequestConfig) {
        return AdminLexiconsApiFp(this.configuration).updateLexiconTranslation(requestParameters.lexiconId, requestParameters.adminUpdateLexiconTranslationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
