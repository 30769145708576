/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetDetailedReports200Response } from '../AfClApiModels';
/**
 * PublisherDetailedReportsApi - axios parameter creator
 * @export
 */
export const PublisherDetailedReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Detailed Reports
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {string} from 
         * @param {string} to 
         * @param {'program' | 'site' | 'key'} groupBy 
         * @param {Array<number>} [mediaKeys] 
         * @param {Array<number>} [campaignKeys] 
         * @param {Array<number>} [sourceKeys] 
         * @param {Array<number>} [siteGroupIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [programIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDetailedReports: async (publisherId: number, from: string, to: string, groupBy: 'program' | 'site' | 'key', mediaKeys?: Array<number>, campaignKeys?: Array<number>, sourceKeys?: Array<number>, siteGroupIds?: Array<number>, siteIds?: Array<number>, verticalIds?: Array<number>, regionIds?: Array<number>, programIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('exportDetailedReports', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportDetailedReports', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportDetailedReports', 'to', to)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('exportDetailedReports', 'groupBy', groupBy)
            const localVarPath = `/v1/publishers/{publisherId}/reports/detailed/export`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (mediaKeys) {
                localVarQueryParameter['media_keys[]'] = mediaKeys;
            }

            if (campaignKeys) {
                localVarQueryParameter['campaign_keys[]'] = campaignKeys;
            }

            if (sourceKeys) {
                localVarQueryParameter['source_keys[]'] = sourceKeys;
            }

            if (siteGroupIds) {
                localVarQueryParameter['site_group_ids[]'] = siteGroupIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Detailed Reports
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {string} from 
         * @param {string} to 
         * @param {'program' | 'site' | 'key'} groupBy 
         * @param {Array<number>} [mediaKeys] 
         * @param {Array<number>} [campaignKeys] 
         * @param {Array<number>} [sourceKeys] 
         * @param {Array<number>} [siteGroupIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [programIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedReports: async (publisherId: number, from: string, to: string, groupBy: 'program' | 'site' | 'key', mediaKeys?: Array<number>, campaignKeys?: Array<number>, sourceKeys?: Array<number>, siteGroupIds?: Array<number>, siteIds?: Array<number>, verticalIds?: Array<number>, regionIds?: Array<number>, programIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getDetailedReports', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getDetailedReports', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getDetailedReports', 'to', to)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('getDetailedReports', 'groupBy', groupBy)
            const localVarPath = `/v1/publishers/{publisherId}/reports/detailed`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (mediaKeys) {
                localVarQueryParameter['media_keys[]'] = mediaKeys;
            }

            if (campaignKeys) {
                localVarQueryParameter['campaign_keys[]'] = campaignKeys;
            }

            if (sourceKeys) {
                localVarQueryParameter['source_keys[]'] = sourceKeys;
            }

            if (siteGroupIds) {
                localVarQueryParameter['site_group_ids[]'] = siteGroupIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherDetailedReportsApi - functional programming interface
 * @export
 */
export const PublisherDetailedReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherDetailedReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Detailed Reports
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {string} from 
         * @param {string} to 
         * @param {'program' | 'site' | 'key'} groupBy 
         * @param {Array<number>} [mediaKeys] 
         * @param {Array<number>} [campaignKeys] 
         * @param {Array<number>} [sourceKeys] 
         * @param {Array<number>} [siteGroupIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [programIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDetailedReports(publisherId: number, from: string, to: string, groupBy: 'program' | 'site' | 'key', mediaKeys?: Array<number>, campaignKeys?: Array<number>, sourceKeys?: Array<number>, siteGroupIds?: Array<number>, siteIds?: Array<number>, verticalIds?: Array<number>, regionIds?: Array<number>, programIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDetailedReports(publisherId, from, to, groupBy, mediaKeys, campaignKeys, sourceKeys, siteGroupIds, siteIds, verticalIds, regionIds, programIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Detailed Reports
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {string} from 
         * @param {string} to 
         * @param {'program' | 'site' | 'key'} groupBy 
         * @param {Array<number>} [mediaKeys] 
         * @param {Array<number>} [campaignKeys] 
         * @param {Array<number>} [sourceKeys] 
         * @param {Array<number>} [siteGroupIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [programIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedReports(publisherId: number, from: string, to: string, groupBy: 'program' | 'site' | 'key', mediaKeys?: Array<number>, campaignKeys?: Array<number>, sourceKeys?: Array<number>, siteGroupIds?: Array<number>, siteIds?: Array<number>, verticalIds?: Array<number>, regionIds?: Array<number>, programIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDetailedReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedReports(publisherId, from, to, groupBy, mediaKeys, campaignKeys, sourceKeys, siteGroupIds, siteIds, verticalIds, regionIds, programIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherDetailedReportsApi - factory interface
 * @export
 */
export const PublisherDetailedReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherDetailedReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Detailed Reports
         * @param {PublisherDetailedReportsApiExportDetailedReportsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDetailedReports(requestParameters: PublisherDetailedReportsApiExportDetailedReportsRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.exportDetailedReports(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.mediaKeys, requestParameters.campaignKeys, requestParameters.sourceKeys, requestParameters.siteGroupIds, requestParameters.siteIds, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.programIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Detailed Reports
         * @param {PublisherDetailedReportsApiGetDetailedReportsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedReports(requestParameters: PublisherDetailedReportsApiGetDetailedReportsRequest, options?: AxiosRequestConfig): AxiosPromise<GetDetailedReports200Response> {
            return localVarFp.getDetailedReports(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.mediaKeys, requestParameters.campaignKeys, requestParameters.sourceKeys, requestParameters.siteGroupIds, requestParameters.siteIds, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.programIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for exportDetailedReports operation in PublisherDetailedReportsApi.
 * @export
 * @interface PublisherDetailedReportsApiExportDetailedReportsRequest
 */
export interface PublisherDetailedReportsApiExportDetailedReportsRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly to: string

    /**
     * 
     * @type {'program' | 'site' | 'key'}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly groupBy: 'program' | 'site' | 'key'

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly mediaKeys?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly campaignKeys?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly sourceKeys?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly siteGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherDetailedReportsApiExportDetailedReports
     */
    readonly perPage?: number
}

/**
 * Request parameters for getDetailedReports operation in PublisherDetailedReportsApi.
 * @export
 * @interface PublisherDetailedReportsApiGetDetailedReportsRequest
 */
export interface PublisherDetailedReportsApiGetDetailedReportsRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly publisherId: number

    /**
     * 
     * @type {string}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly to: string

    /**
     * 
     * @type {'program' | 'site' | 'key'}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly groupBy: 'program' | 'site' | 'key'

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly mediaKeys?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly campaignKeys?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly sourceKeys?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly siteGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherDetailedReportsApiGetDetailedReports
     */
    readonly perPage?: number
}

/**
 * PublisherDetailedReportsApi - object-oriented interface
 * @export
 * @class PublisherDetailedReportsApi
 * @extends {BaseAPI}
 */
export class PublisherDetailedReportsApi extends BaseAPI {
    /**
     * 
     * @summary Export Detailed Reports
     * @param {PublisherDetailedReportsApiExportDetailedReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherDetailedReportsApi
     */
    public exportDetailedReports(requestParameters: PublisherDetailedReportsApiExportDetailedReportsRequest, options?: AxiosRequestConfig) {
        return PublisherDetailedReportsApiFp(this.configuration).exportDetailedReports(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.mediaKeys, requestParameters.campaignKeys, requestParameters.sourceKeys, requestParameters.siteGroupIds, requestParameters.siteIds, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.programIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Detailed Reports
     * @param {PublisherDetailedReportsApiGetDetailedReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherDetailedReportsApi
     */
    public getDetailedReports(requestParameters: PublisherDetailedReportsApiGetDetailedReportsRequest, options?: AxiosRequestConfig) {
        return PublisherDetailedReportsApiFp(this.configuration).getDetailedReports(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.groupBy, requestParameters.mediaKeys, requestParameters.campaignKeys, requestParameters.sourceKeys, requestParameters.siteGroupIds, requestParameters.siteIds, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.programIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
