/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminStoreDealRequest } from '../AfClApiModels';
// @ts-ignore
import { Deal } from '../AfClApiModels';
// @ts-ignore
import { ExceptionsBasicApiException } from '../AfClApiModels';
// @ts-ignore
import { GetProgramDealSites200Response } from '../AfClApiModels';
/**
 * AdminProgramDealsApi - axios parameter creator
 * @export
 */
export const AdminProgramDealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new program deal. Marks previous deals of the same type as ended.
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {AdminStoreDealRequest} [adminStoreDealRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramDeal: async (programId: number, adminStoreDealRequest?: AdminStoreDealRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('createProgramDeal', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/deals`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreDealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a program deal
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} dealId The deal to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramDeal: async (programId: number, dealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('deleteProgramDeal', 'programId', programId)
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('deleteProgramDeal', 'dealId', dealId)
            const localVarPath = `/v1/admin/programs/{programId}/deals/{dealId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the sites of the deal
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} dealId The deal to delete
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDealSites: async (programId: number, dealId: number, name?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramDealSites', 'programId', programId)
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('getProgramDealSites', 'dealId', dealId)
            const localVarPath = `/v1/admin/programs/{programId}/deals/{dealId}/sites`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deals for a specific program
         * @param {number} programId Numeric ID of the program to find deals for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDeals: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramDeals', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/deals`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a program deal as ended
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} dealId The deal to mark as ended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramDeal: async (programId: number, dealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('updateProgramDeal', 'programId', programId)
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('updateProgramDeal', 'dealId', dealId)
            const localVarPath = `/v1/admin/programs/{programId}/deals/{dealId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramDealsApi - functional programming interface
 * @export
 */
export const AdminProgramDealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramDealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new program deal. Marks previous deals of the same type as ended.
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {AdminStoreDealRequest} [adminStoreDealRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProgramDeal(programId: number, adminStoreDealRequest?: AdminStoreDealRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProgramDeal(programId, adminStoreDealRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a program deal
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} dealId The deal to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramDeal(programId: number, dealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProgramDeal(programId, dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the sites of the deal
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} dealId The deal to delete
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramDealSites(programId: number, dealId: number, name?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramDealSites200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramDealSites(programId, dealId, name, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deals for a specific program
         * @param {number} programId Numeric ID of the program to find deals for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramDeals(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Deal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramDeals(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a program deal as ended
         * @param {number} programId Numeric ID of the program to create deal for
         * @param {number} dealId The deal to mark as ended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramDeal(programId: number, dealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramDeal(programId, dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramDealsApi - factory interface
 * @export
 */
export const AdminProgramDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramDealsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new program deal. Marks previous deals of the same type as ended.
         * @param {AdminProgramDealsApiCreateProgramDealRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgramDeal(requestParameters: AdminProgramDealsApiCreateProgramDealRequest, options?: AxiosRequestConfig): AxiosPromise<Deal> {
            return localVarFp.createProgramDeal(requestParameters.programId, requestParameters.adminStoreDealRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a program deal
         * @param {AdminProgramDealsApiDeleteProgramDealRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramDeal(requestParameters: AdminProgramDealsApiDeleteProgramDealRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProgramDeal(requestParameters.programId, requestParameters.dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the sites of the deal
         * @param {AdminProgramDealsApiGetProgramDealSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDealSites(requestParameters: AdminProgramDealsApiGetProgramDealSitesRequest, options?: AxiosRequestConfig): AxiosPromise<GetProgramDealSites200Response> {
            return localVarFp.getProgramDealSites(requestParameters.programId, requestParameters.dealId, requestParameters.name, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deals for a specific program
         * @param {AdminProgramDealsApiGetProgramDealsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramDeals(requestParameters: AdminProgramDealsApiGetProgramDealsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Deal>> {
            return localVarFp.getProgramDeals(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a program deal as ended
         * @param {AdminProgramDealsApiUpdateProgramDealRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramDeal(requestParameters: AdminProgramDealsApiUpdateProgramDealRequest, options?: AxiosRequestConfig): AxiosPromise<Deal> {
            return localVarFp.updateProgramDeal(requestParameters.programId, requestParameters.dealId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProgramDeal operation in AdminProgramDealsApi.
 * @export
 * @interface AdminProgramDealsApiCreateProgramDealRequest
 */
export interface AdminProgramDealsApiCreateProgramDealRequest {
    /**
     * Numeric ID of the program to create deal for
     * @type {number}
     * @memberof AdminProgramDealsApiCreateProgramDeal
     */
    readonly programId: number

    /**
     * 
     * @type {AdminStoreDealRequest}
     * @memberof AdminProgramDealsApiCreateProgramDeal
     */
    readonly adminStoreDealRequest?: AdminStoreDealRequest
}

/**
 * Request parameters for deleteProgramDeal operation in AdminProgramDealsApi.
 * @export
 * @interface AdminProgramDealsApiDeleteProgramDealRequest
 */
export interface AdminProgramDealsApiDeleteProgramDealRequest {
    /**
     * Numeric ID of the program to create deal for
     * @type {number}
     * @memberof AdminProgramDealsApiDeleteProgramDeal
     */
    readonly programId: number

    /**
     * The deal to delete
     * @type {number}
     * @memberof AdminProgramDealsApiDeleteProgramDeal
     */
    readonly dealId: number
}

/**
 * Request parameters for getProgramDealSites operation in AdminProgramDealsApi.
 * @export
 * @interface AdminProgramDealsApiGetProgramDealSitesRequest
 */
export interface AdminProgramDealsApiGetProgramDealSitesRequest {
    /**
     * Numeric ID of the program to create deal for
     * @type {number}
     * @memberof AdminProgramDealsApiGetProgramDealSites
     */
    readonly programId: number

    /**
     * The deal to delete
     * @type {number}
     * @memberof AdminProgramDealsApiGetProgramDealSites
     */
    readonly dealId: number

    /**
     * 
     * @type {string}
     * @memberof AdminProgramDealsApiGetProgramDealSites
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminProgramDealsApiGetProgramDealSites
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminProgramDealsApiGetProgramDealSites
     */
    readonly perPage?: number
}

/**
 * Request parameters for getProgramDeals operation in AdminProgramDealsApi.
 * @export
 * @interface AdminProgramDealsApiGetProgramDealsRequest
 */
export interface AdminProgramDealsApiGetProgramDealsRequest {
    /**
     * Numeric ID of the program to find deals for
     * @type {number}
     * @memberof AdminProgramDealsApiGetProgramDeals
     */
    readonly programId: number
}

/**
 * Request parameters for updateProgramDeal operation in AdminProgramDealsApi.
 * @export
 * @interface AdminProgramDealsApiUpdateProgramDealRequest
 */
export interface AdminProgramDealsApiUpdateProgramDealRequest {
    /**
     * Numeric ID of the program to create deal for
     * @type {number}
     * @memberof AdminProgramDealsApiUpdateProgramDeal
     */
    readonly programId: number

    /**
     * The deal to mark as ended
     * @type {number}
     * @memberof AdminProgramDealsApiUpdateProgramDeal
     */
    readonly dealId: number
}

/**
 * AdminProgramDealsApi - object-oriented interface
 * @export
 * @class AdminProgramDealsApi
 * @extends {BaseAPI}
 */
export class AdminProgramDealsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new program deal. Marks previous deals of the same type as ended.
     * @param {AdminProgramDealsApiCreateProgramDealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDealsApi
     */
    public createProgramDeal(requestParameters: AdminProgramDealsApiCreateProgramDealRequest, options?: AxiosRequestConfig) {
        return AdminProgramDealsApiFp(this.configuration).createProgramDeal(requestParameters.programId, requestParameters.adminStoreDealRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a program deal
     * @param {AdminProgramDealsApiDeleteProgramDealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDealsApi
     */
    public deleteProgramDeal(requestParameters: AdminProgramDealsApiDeleteProgramDealRequest, options?: AxiosRequestConfig) {
        return AdminProgramDealsApiFp(this.configuration).deleteProgramDeal(requestParameters.programId, requestParameters.dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the sites of the deal
     * @param {AdminProgramDealsApiGetProgramDealSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDealsApi
     */
    public getProgramDealSites(requestParameters: AdminProgramDealsApiGetProgramDealSitesRequest, options?: AxiosRequestConfig) {
        return AdminProgramDealsApiFp(this.configuration).getProgramDealSites(requestParameters.programId, requestParameters.dealId, requestParameters.name, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deals for a specific program
     * @param {AdminProgramDealsApiGetProgramDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDealsApi
     */
    public getProgramDeals(requestParameters: AdminProgramDealsApiGetProgramDealsRequest, options?: AxiosRequestConfig) {
        return AdminProgramDealsApiFp(this.configuration).getProgramDeals(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a program deal as ended
     * @param {AdminProgramDealsApiUpdateProgramDealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramDealsApi
     */
    public updateProgramDeal(requestParameters: AdminProgramDealsApiUpdateProgramDealRequest, options?: AxiosRequestConfig) {
        return AdminProgramDealsApiFp(this.configuration).updateProgramDeal(requestParameters.programId, requestParameters.dealId, options).then((request) => request(this.axios, this.basePath));
    }
}
