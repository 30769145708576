/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetSite200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetSitesByGroup200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateSiteRequest } from '../AfClApiModels';
// @ts-ignore
import { GetSiteApprovals200Response } from '../AfClApiModels';
// @ts-ignore
import { Site } from '../AfClApiModels';
/**
 * AdminSitesApi - axios parameter creator
 * @export
 */
export const AdminSitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Site
         * @param {number} siteId Numeric ID of the Site to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteSite: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('adminDeleteSite', 'siteId', siteId)
            const localVarPath = `/v1/admin/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Site
         * @param {number} siteId Numeric ID of the Site to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSite: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('adminGetSite', 'siteId', siteId)
            const localVarPath = `/v1/admin/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sites
         * @param {string} [name] 
         * @param {number} [siteGroupId] 
         * @param {number} [publisherId] 
         * @param {string} [publisherName] 
         * @param {boolean} [withoutPublisher] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSites: async (name?: string, siteGroupId?: number, publisherId?: number, publisherName?: string, withoutPublisher?: boolean, publisherType?: string, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (publisherName !== undefined) {
                localVarQueryParameter['publisher_name'] = publisherName;
            }

            if (withoutPublisher !== undefined) {
                localVarQueryParameter['without_publisher'] = withoutPublisher;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (siteStatus !== undefined) {
                localVarQueryParameter['site_status'] = siteStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sites grouped by the site group they belong to
         * @summary Get Sites by Group
         * @param {number} [siteGroupId] 
         * @param {number} [publisherId] 
         * @param {string} [publisherName] 
         * @param {boolean} [withoutPublisher] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSitesByGroup: async (siteGroupId?: number, publisherId?: number, publisherName?: string, withoutPublisher?: boolean, publisherType?: string, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sites/bygroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (publisherName !== undefined) {
                localVarQueryParameter['publisher_name'] = publisherName;
            }

            if (withoutPublisher !== undefined) {
                localVarQueryParameter['without_publisher'] = withoutPublisher;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (siteStatus !== undefined) {
                localVarQueryParameter['site_status'] = siteStatus;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Site
         * @param {number} siteId Numeric ID of the Site to find
         * @param {AdminUpdateSiteRequest} [adminUpdateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSite: async (siteId: number, adminUpdateSiteRequest?: AdminUpdateSiteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('adminUpdateSite', 'siteId', siteId)
            const localVarPath = `/v1/admin/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateSiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sites Export
         * @param {string} [name] 
         * @param {number} [siteGroupId] 
         * @param {number} [publisherId] 
         * @param {string} [publisherName] 
         * @param {boolean} [withoutPublisher] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitesExport: async (name?: string, siteGroupId?: number, publisherId?: number, publisherName?: string, withoutPublisher?: boolean, publisherType?: string, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sites/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (publisherName !== undefined) {
                localVarQueryParameter['publisher_name'] = publisherName;
            }

            if (withoutPublisher !== undefined) {
                localVarQueryParameter['without_publisher'] = withoutPublisher;
            }

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (siteStatus !== undefined) {
                localVarQueryParameter['site_status'] = siteStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSitesApi - functional programming interface
 * @export
 */
export const AdminSitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Site
         * @param {number} siteId Numeric ID of the Site to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteSite(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteSite(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Site
         * @param {number} siteId Numeric ID of the Site to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSite(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSite(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Sites
         * @param {string} [name] 
         * @param {number} [siteGroupId] 
         * @param {number} [publisherId] 
         * @param {string} [publisherName] 
         * @param {boolean} [withoutPublisher] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSites(name?: string, siteGroupId?: number, publisherId?: number, publisherName?: string, withoutPublisher?: boolean, publisherType?: string, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteApprovals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSites(name, siteGroupId, publisherId, publisherName, withoutPublisher, publisherType, siteIds, regionIds, verticalIds, siteStatus, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get sites grouped by the site group they belong to
         * @summary Get Sites by Group
         * @param {number} [siteGroupId] 
         * @param {number} [publisherId] 
         * @param {string} [publisherName] 
         * @param {boolean} [withoutPublisher] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSitesByGroup(siteGroupId?: number, publisherId?: number, publisherName?: string, withoutPublisher?: boolean, publisherType?: string, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSitesByGroup200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSitesByGroup(siteGroupId, publisherId, publisherName, withoutPublisher, publisherType, siteIds, regionIds, verticalIds, siteStatus, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Site
         * @param {number} siteId Numeric ID of the Site to find
         * @param {AdminUpdateSiteRequest} [adminUpdateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateSite(siteId: number, adminUpdateSiteRequest?: AdminUpdateSiteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateSite(siteId, adminUpdateSiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sites Export
         * @param {string} [name] 
         * @param {number} [siteGroupId] 
         * @param {number} [publisherId] 
         * @param {string} [publisherName] 
         * @param {boolean} [withoutPublisher] 
         * @param {string} [publisherType] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {string} [siteStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sitesExport(name?: string, siteGroupId?: number, publisherId?: number, publisherName?: string, withoutPublisher?: boolean, publisherType?: string, siteIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, siteStatus?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sitesExport(name, siteGroupId, publisherId, publisherName, withoutPublisher, publisherType, siteIds, regionIds, verticalIds, siteStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSitesApi - factory interface
 * @export
 */
export const AdminSitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSitesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Site
         * @param {AdminSitesApiAdminDeleteSiteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteSite(requestParameters: AdminSitesApiAdminDeleteSiteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminDeleteSite(requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Site
         * @param {AdminSitesApiAdminGetSiteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSite(requestParameters: AdminSitesApiAdminGetSiteRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetSite200Response> {
            return localVarFp.adminGetSite(requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sites
         * @param {AdminSitesApiAdminGetSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSites(requestParameters: AdminSitesApiAdminGetSitesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetSiteApprovals200Response> {
            return localVarFp.adminGetSites(requestParameters.name, requestParameters.siteGroupId, requestParameters.publisherId, requestParameters.publisherName, requestParameters.withoutPublisher, requestParameters.publisherType, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sites grouped by the site group they belong to
         * @summary Get Sites by Group
         * @param {AdminSitesApiAdminGetSitesByGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSitesByGroup(requestParameters: AdminSitesApiAdminGetSitesByGroupRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetSitesByGroup200Response> {
            return localVarFp.adminGetSitesByGroup(requestParameters.siteGroupId, requestParameters.publisherId, requestParameters.publisherName, requestParameters.withoutPublisher, requestParameters.publisherType, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, requestParameters.perPage, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Site
         * @param {AdminSitesApiAdminUpdateSiteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateSite(requestParameters: AdminSitesApiAdminUpdateSiteRequest, options?: AxiosRequestConfig): AxiosPromise<Site> {
            return localVarFp.adminUpdateSite(requestParameters.siteId, requestParameters.adminUpdateSiteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sites Export
         * @param {AdminSitesApiSitesExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sitesExport(requestParameters: AdminSitesApiSitesExportRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.sitesExport(requestParameters.name, requestParameters.siteGroupId, requestParameters.publisherId, requestParameters.publisherName, requestParameters.withoutPublisher, requestParameters.publisherType, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminDeleteSite operation in AdminSitesApi.
 * @export
 * @interface AdminSitesApiAdminDeleteSiteRequest
 */
export interface AdminSitesApiAdminDeleteSiteRequest {
    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof AdminSitesApiAdminDeleteSite
     */
    readonly siteId: number
}

/**
 * Request parameters for adminGetSite operation in AdminSitesApi.
 * @export
 * @interface AdminSitesApiAdminGetSiteRequest
 */
export interface AdminSitesApiAdminGetSiteRequest {
    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof AdminSitesApiAdminGetSite
     */
    readonly siteId: number
}

/**
 * Request parameters for adminGetSites operation in AdminSitesApi.
 * @export
 * @interface AdminSitesApiAdminGetSitesRequest
 */
export interface AdminSitesApiAdminGetSitesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly publisherId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly publisherName?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly withoutPublisher?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly publisherType?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly siteStatus?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSites
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminGetSitesByGroup operation in AdminSitesApi.
 * @export
 * @interface AdminSitesApiAdminGetSitesByGroupRequest
 */
export interface AdminSitesApiAdminGetSitesByGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly publisherId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly publisherName?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly withoutPublisher?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly publisherType?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly siteStatus?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly perPage?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiAdminGetSitesByGroup
     */
    readonly page?: number
}

/**
 * Request parameters for adminUpdateSite operation in AdminSitesApi.
 * @export
 * @interface AdminSitesApiAdminUpdateSiteRequest
 */
export interface AdminSitesApiAdminUpdateSiteRequest {
    /**
     * Numeric ID of the Site to find
     * @type {number}
     * @memberof AdminSitesApiAdminUpdateSite
     */
    readonly siteId: number

    /**
     * 
     * @type {AdminUpdateSiteRequest}
     * @memberof AdminSitesApiAdminUpdateSite
     */
    readonly adminUpdateSiteRequest?: AdminUpdateSiteRequest
}

/**
 * Request parameters for sitesExport operation in AdminSitesApi.
 * @export
 * @interface AdminSitesApiSitesExportRequest
 */
export interface AdminSitesApiSitesExportRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiSitesExport
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiSitesExport
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSitesApiSitesExport
     */
    readonly publisherId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiSitesExport
     */
    readonly publisherName?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminSitesApiSitesExport
     */
    readonly withoutPublisher?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiSitesExport
     */
    readonly publisherType?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiSitesExport
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiSitesExport
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSitesApiSitesExport
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminSitesApiSitesExport
     */
    readonly siteStatus?: string
}

/**
 * AdminSitesApi - object-oriented interface
 * @export
 * @class AdminSitesApi
 * @extends {BaseAPI}
 */
export class AdminSitesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Site
     * @param {AdminSitesApiAdminDeleteSiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSitesApi
     */
    public adminDeleteSite(requestParameters: AdminSitesApiAdminDeleteSiteRequest, options?: AxiosRequestConfig) {
        return AdminSitesApiFp(this.configuration).adminDeleteSite(requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Site
     * @param {AdminSitesApiAdminGetSiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSitesApi
     */
    public adminGetSite(requestParameters: AdminSitesApiAdminGetSiteRequest, options?: AxiosRequestConfig) {
        return AdminSitesApiFp(this.configuration).adminGetSite(requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sites
     * @param {AdminSitesApiAdminGetSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSitesApi
     */
    public adminGetSites(requestParameters: AdminSitesApiAdminGetSitesRequest = {}, options?: AxiosRequestConfig) {
        return AdminSitesApiFp(this.configuration).adminGetSites(requestParameters.name, requestParameters.siteGroupId, requestParameters.publisherId, requestParameters.publisherName, requestParameters.withoutPublisher, requestParameters.publisherType, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sites grouped by the site group they belong to
     * @summary Get Sites by Group
     * @param {AdminSitesApiAdminGetSitesByGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSitesApi
     */
    public adminGetSitesByGroup(requestParameters: AdminSitesApiAdminGetSitesByGroupRequest = {}, options?: AxiosRequestConfig) {
        return AdminSitesApiFp(this.configuration).adminGetSitesByGroup(requestParameters.siteGroupId, requestParameters.publisherId, requestParameters.publisherName, requestParameters.withoutPublisher, requestParameters.publisherType, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, requestParameters.perPage, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Site
     * @param {AdminSitesApiAdminUpdateSiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSitesApi
     */
    public adminUpdateSite(requestParameters: AdminSitesApiAdminUpdateSiteRequest, options?: AxiosRequestConfig) {
        return AdminSitesApiFp(this.configuration).adminUpdateSite(requestParameters.siteId, requestParameters.adminUpdateSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sites Export
     * @param {AdminSitesApiSitesExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSitesApi
     */
    public sitesExport(requestParameters: AdminSitesApiSitesExportRequest = {}, options?: AxiosRequestConfig) {
        return AdminSitesApiFp(this.configuration).sitesExport(requestParameters.name, requestParameters.siteGroupId, requestParameters.publisherId, requestParameters.publisherName, requestParameters.withoutPublisher, requestParameters.publisherType, requestParameters.siteIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.siteStatus, options).then((request) => request(this.axios, this.basePath));
    }
}
