import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGlobalLoaderStore = defineStore('globalLoaderStore', () => {
    const isLoading = ref<boolean>(false);

    const setLoading = (payload: boolean) => {
        isLoading.value = payload;
    };

    return { isLoading, setLoading };
});
