/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminStoreMediaKeyRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateMediaKeyRequest } from '../AfClApiModels';
// @ts-ignore
import { GetMediaKeys200Response } from '../AfClApiModels';
// @ts-ignore
import { MediaKey } from '../AfClApiModels';
/**
 * AdminMediaKeysApi - axios parameter creator
 * @export
 */
export const AdminMediaKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Media Key
         * @param {AdminStoreMediaKeyRequest} [adminStoreMediaKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMediaKey: async (adminStoreMediaKeyRequest?: AdminStoreMediaKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/media-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreMediaKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get MediaKeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/media-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Media Key
         * @param {number} mediaKeyId Numeric ID of the Media Key to update
         * @param {AdminUpdateMediaKeyRequest} [adminUpdateMediaKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediaKey: async (mediaKeyId: number, adminUpdateMediaKeyRequest?: AdminUpdateMediaKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaKeyId' is not null or undefined
            assertParamExists('updateMediaKey', 'mediaKeyId', mediaKeyId)
            const localVarPath = `/v1/admin/media-keys/{mediaKeyId}`
                .replace(`{${"mediaKeyId"}}`, encodeURIComponent(String(mediaKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateMediaKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMediaKeysApi - functional programming interface
 * @export
 */
export const AdminMediaKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMediaKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Media Key
         * @param {AdminStoreMediaKeyRequest} [adminStoreMediaKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMediaKey(adminStoreMediaKeyRequest?: AdminStoreMediaKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMediaKey(adminStoreMediaKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get MediaKeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMediaKeys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Media Key
         * @param {number} mediaKeyId Numeric ID of the Media Key to update
         * @param {AdminUpdateMediaKeyRequest} [adminUpdateMediaKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMediaKey(mediaKeyId: number, adminUpdateMediaKeyRequest?: AdminUpdateMediaKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMediaKey(mediaKeyId, adminUpdateMediaKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMediaKeysApi - factory interface
 * @export
 */
export const AdminMediaKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMediaKeysApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Media Key
         * @param {AdminMediaKeysApiCreateMediaKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMediaKey(requestParameters: AdminMediaKeysApiCreateMediaKeyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<MediaKey>> {
            return localVarFp.createMediaKey(requestParameters.adminStoreMediaKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get MediaKeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaKeys(options?: AxiosRequestConfig): AxiosPromise<GetMediaKeys200Response> {
            return localVarFp.getMediaKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Media Key
         * @param {AdminMediaKeysApiUpdateMediaKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediaKey(requestParameters: AdminMediaKeysApiUpdateMediaKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MediaKey>> {
            return localVarFp.updateMediaKey(requestParameters.mediaKeyId, requestParameters.adminUpdateMediaKeyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMediaKey operation in AdminMediaKeysApi.
 * @export
 * @interface AdminMediaKeysApiCreateMediaKeyRequest
 */
export interface AdminMediaKeysApiCreateMediaKeyRequest {
    /**
     * 
     * @type {AdminStoreMediaKeyRequest}
     * @memberof AdminMediaKeysApiCreateMediaKey
     */
    readonly adminStoreMediaKeyRequest?: AdminStoreMediaKeyRequest
}

/**
 * Request parameters for updateMediaKey operation in AdminMediaKeysApi.
 * @export
 * @interface AdminMediaKeysApiUpdateMediaKeyRequest
 */
export interface AdminMediaKeysApiUpdateMediaKeyRequest {
    /**
     * Numeric ID of the Media Key to update
     * @type {number}
     * @memberof AdminMediaKeysApiUpdateMediaKey
     */
    readonly mediaKeyId: number

    /**
     * 
     * @type {AdminUpdateMediaKeyRequest}
     * @memberof AdminMediaKeysApiUpdateMediaKey
     */
    readonly adminUpdateMediaKeyRequest?: AdminUpdateMediaKeyRequest
}

/**
 * AdminMediaKeysApi - object-oriented interface
 * @export
 * @class AdminMediaKeysApi
 * @extends {BaseAPI}
 */
export class AdminMediaKeysApi extends BaseAPI {
    /**
     * 
     * @summary Create Media Key
     * @param {AdminMediaKeysApiCreateMediaKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediaKeysApi
     */
    public createMediaKey(requestParameters: AdminMediaKeysApiCreateMediaKeyRequest = {}, options?: AxiosRequestConfig) {
        return AdminMediaKeysApiFp(this.configuration).createMediaKey(requestParameters.adminStoreMediaKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get MediaKeys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediaKeysApi
     */
    public getMediaKeys(options?: AxiosRequestConfig) {
        return AdminMediaKeysApiFp(this.configuration).getMediaKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Media Key
     * @param {AdminMediaKeysApiUpdateMediaKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediaKeysApi
     */
    public updateMediaKey(requestParameters: AdminMediaKeysApiUpdateMediaKeyRequest, options?: AxiosRequestConfig) {
        return AdminMediaKeysApiFp(this.configuration).updateMediaKey(requestParameters.mediaKeyId, requestParameters.adminUpdateMediaKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
