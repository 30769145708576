/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthUpdateUserRequest } from '../AfClApiModels';
// @ts-ignore
import { GetAuthUserAccounts200Response } from '../AfClApiModels';
// @ts-ignore
import { User } from '../AfClApiModels';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Auth User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Auth User Accounts
         * @param {'PUBLISHER' | 'ADVERTISER' | 'CORPORATION'} teamType 
         * @param {string} [name] 
         * @param {Array<number>} [ids] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserAccounts: async (teamType: 'PUBLISHER' | 'ADVERTISER' | 'CORPORATION', name?: string, ids?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamType' is not null or undefined
            assertParamExists('getAuthUserAccounts', 'teamType', teamType)
            const localVarPath = `/v1/auth/user/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamType !== undefined) {
                localVarQueryParameter['team_type'] = teamType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (ids) {
                localVarQueryParameter['ids[]'] = ids;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Auth User information
         * @param {AuthUpdateUserRequest} [authUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthUser: async (authUpdateUserRequest?: AuthUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authUpdateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Auth User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Auth User Accounts
         * @param {'PUBLISHER' | 'ADVERTISER' | 'CORPORATION'} teamType 
         * @param {string} [name] 
         * @param {Array<number>} [ids] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUserAccounts(teamType: 'PUBLISHER' | 'ADVERTISER' | 'CORPORATION', name?: string, ids?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuthUserAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUserAccounts(teamType, name, ids, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Auth User information
         * @param {AuthUpdateUserRequest} [authUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthUser(authUpdateUserRequest?: AuthUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthUser(authUpdateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Auth User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUser(options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getAuthUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Auth User Accounts
         * @param {AuthApiGetAuthUserAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserAccounts(requestParameters: AuthApiGetAuthUserAccountsRequest, options?: AxiosRequestConfig): AxiosPromise<GetAuthUserAccounts200Response> {
            return localVarFp.getAuthUserAccounts(requestParameters.teamType, requestParameters.name, requestParameters.ids, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Auth User information
         * @param {AuthApiUpdateAuthUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthUser(requestParameters: AuthApiUpdateAuthUserRequest = {}, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.updateAuthUser(requestParameters.authUpdateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAuthUserAccounts operation in AuthApi.
 * @export
 * @interface AuthApiGetAuthUserAccountsRequest
 */
export interface AuthApiGetAuthUserAccountsRequest {
    /**
     * 
     * @type {'PUBLISHER' | 'ADVERTISER' | 'CORPORATION'}
     * @memberof AuthApiGetAuthUserAccounts
     */
    readonly teamType: 'PUBLISHER' | 'ADVERTISER' | 'CORPORATION'

    /**
     * 
     * @type {string}
     * @memberof AuthApiGetAuthUserAccounts
     */
    readonly name?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AuthApiGetAuthUserAccounts
     */
    readonly ids?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AuthApiGetAuthUserAccounts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AuthApiGetAuthUserAccounts
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateAuthUser operation in AuthApi.
 * @export
 * @interface AuthApiUpdateAuthUserRequest
 */
export interface AuthApiUpdateAuthUserRequest {
    /**
     * 
     * @type {AuthUpdateUserRequest}
     * @memberof AuthApiUpdateAuthUser
     */
    readonly authUpdateUserRequest?: AuthUpdateUserRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Get Auth User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Auth User Accounts
     * @param {AuthApiGetAuthUserAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUserAccounts(requestParameters: AuthApiGetAuthUserAccountsRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthUserAccounts(requestParameters.teamType, requestParameters.name, requestParameters.ids, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Auth User information
     * @param {AuthApiUpdateAuthUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateAuthUser(requestParameters: AuthApiUpdateAuthUserRequest = {}, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).updateAuthUser(requestParameters.authUpdateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
