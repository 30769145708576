/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPublisherNote } from '../AfClApiModels';
// @ts-ignore
import { AdminStorePublisherNoteRequest } from '../AfClApiModels';
/**
 * AdminPublisherNotesApi - axios parameter creator
 * @export
 */
export const AdminPublisherNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Publisher Note
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} noteId The ID of the Publisher note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeletePublisherNote: async (publisherId: number, noteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('adminDeletePublisherNote', 'publisherId', publisherId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('adminDeletePublisherNote', 'noteId', noteId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/notes/{noteId}`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Publisher Note
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {AdminStorePublisherNoteRequest} [adminStorePublisherNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherNote: async (publisherId: number, adminStorePublisherNoteRequest?: AdminStorePublisherNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createPublisherNote', 'publisherId', publisherId)
            const localVarPath = `/v1/admin/publishers/{publisherId}/notes`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStorePublisherNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublisherNotesApi - functional programming interface
 * @export
 */
export const AdminPublisherNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublisherNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Publisher Note
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {number} noteId The ID of the Publisher note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeletePublisherNote(publisherId: number, noteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeletePublisherNote(publisherId, noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Publisher Note
         * @param {number} publisherId Numeric ID of the Publisher to find
         * @param {AdminStorePublisherNoteRequest} [adminStorePublisherNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisherNote(publisherId: number, adminStorePublisherNoteRequest?: AdminStorePublisherNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisherNote(publisherId, adminStorePublisherNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublisherNotesApi - factory interface
 * @export
 */
export const AdminPublisherNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublisherNotesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Publisher Note
         * @param {AdminPublisherNotesApiAdminDeletePublisherNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeletePublisherNote(requestParameters: AdminPublisherNotesApiAdminDeletePublisherNoteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminDeletePublisherNote(requestParameters.publisherId, requestParameters.noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Publisher Note
         * @param {AdminPublisherNotesApiCreatePublisherNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherNote(requestParameters: AdminPublisherNotesApiCreatePublisherNoteRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherNote> {
            return localVarFp.createPublisherNote(requestParameters.publisherId, requestParameters.adminStorePublisherNoteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminDeletePublisherNote operation in AdminPublisherNotesApi.
 * @export
 * @interface AdminPublisherNotesApiAdminDeletePublisherNoteRequest
 */
export interface AdminPublisherNotesApiAdminDeletePublisherNoteRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof AdminPublisherNotesApiAdminDeletePublisherNote
     */
    readonly publisherId: number

    /**
     * The ID of the Publisher note
     * @type {number}
     * @memberof AdminPublisherNotesApiAdminDeletePublisherNote
     */
    readonly noteId: number
}

/**
 * Request parameters for createPublisherNote operation in AdminPublisherNotesApi.
 * @export
 * @interface AdminPublisherNotesApiCreatePublisherNoteRequest
 */
export interface AdminPublisherNotesApiCreatePublisherNoteRequest {
    /**
     * Numeric ID of the Publisher to find
     * @type {number}
     * @memberof AdminPublisherNotesApiCreatePublisherNote
     */
    readonly publisherId: number

    /**
     * 
     * @type {AdminStorePublisherNoteRequest}
     * @memberof AdminPublisherNotesApiCreatePublisherNote
     */
    readonly adminStorePublisherNoteRequest?: AdminStorePublisherNoteRequest
}

/**
 * AdminPublisherNotesApi - object-oriented interface
 * @export
 * @class AdminPublisherNotesApi
 * @extends {BaseAPI}
 */
export class AdminPublisherNotesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Publisher Note
     * @param {AdminPublisherNotesApiAdminDeletePublisherNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherNotesApi
     */
    public adminDeletePublisherNote(requestParameters: AdminPublisherNotesApiAdminDeletePublisherNoteRequest, options?: AxiosRequestConfig) {
        return AdminPublisherNotesApiFp(this.configuration).adminDeletePublisherNote(requestParameters.publisherId, requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Publisher Note
     * @param {AdminPublisherNotesApiCreatePublisherNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherNotesApi
     */
    public createPublisherNote(requestParameters: AdminPublisherNotesApiCreatePublisherNoteRequest, options?: AxiosRequestConfig) {
        return AdminPublisherNotesApiFp(this.configuration).createPublisherNote(requestParameters.publisherId, requestParameters.adminStorePublisherNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
