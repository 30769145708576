/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RedirectLink } from '../AfClApiModels';
// @ts-ignore
import { StoreRedirectLinkRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateRedirectLinkRequest } from '../AfClApiModels';
/**
 * RedirectLinkApi - axios parameter creator
 * @export
 */
export const RedirectLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Redirect Link
         * @param {StoreRedirectLinkRequest} [storeRedirectLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedirectLink: async (storeRedirectLinkRequest?: StoreRedirectLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/redirect-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeRedirectLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Redirect Link
         * @param {number} redirectLinkId Numeric ID of the Redirect Link to find
         * @param {UpdateRedirectLinkRequest} [updateRedirectLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedirectLink: async (redirectLinkId: number, updateRedirectLinkRequest?: UpdateRedirectLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectLinkId' is not null or undefined
            assertParamExists('updateRedirectLink', 'redirectLinkId', redirectLinkId)
            const localVarPath = `/v1/redirect-links/{redirectLinkId}`
                .replace(`{${"redirectLinkId"}}`, encodeURIComponent(String(redirectLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRedirectLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RedirectLinkApi - functional programming interface
 * @export
 */
export const RedirectLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RedirectLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Redirect Link
         * @param {StoreRedirectLinkRequest} [storeRedirectLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRedirectLink(storeRedirectLinkRequest?: StoreRedirectLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRedirectLink(storeRedirectLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Redirect Link
         * @param {number} redirectLinkId Numeric ID of the Redirect Link to find
         * @param {UpdateRedirectLinkRequest} [updateRedirectLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRedirectLink(redirectLinkId: number, updateRedirectLinkRequest?: UpdateRedirectLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRedirectLink(redirectLinkId, updateRedirectLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RedirectLinkApi - factory interface
 * @export
 */
export const RedirectLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RedirectLinkApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Redirect Link
         * @param {RedirectLinkApiCreateRedirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedirectLink(requestParameters: RedirectLinkApiCreateRedirectLinkRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RedirectLink> {
            return localVarFp.createRedirectLink(requestParameters.storeRedirectLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Redirect Link
         * @param {RedirectLinkApiUpdateRedirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedirectLink(requestParameters: RedirectLinkApiUpdateRedirectLinkRequest, options?: AxiosRequestConfig): AxiosPromise<RedirectLink> {
            return localVarFp.updateRedirectLink(requestParameters.redirectLinkId, requestParameters.updateRedirectLinkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRedirectLink operation in RedirectLinkApi.
 * @export
 * @interface RedirectLinkApiCreateRedirectLinkRequest
 */
export interface RedirectLinkApiCreateRedirectLinkRequest {
    /**
     * 
     * @type {StoreRedirectLinkRequest}
     * @memberof RedirectLinkApiCreateRedirectLink
     */
    readonly storeRedirectLinkRequest?: StoreRedirectLinkRequest
}

/**
 * Request parameters for updateRedirectLink operation in RedirectLinkApi.
 * @export
 * @interface RedirectLinkApiUpdateRedirectLinkRequest
 */
export interface RedirectLinkApiUpdateRedirectLinkRequest {
    /**
     * Numeric ID of the Redirect Link to find
     * @type {number}
     * @memberof RedirectLinkApiUpdateRedirectLink
     */
    readonly redirectLinkId: number

    /**
     * 
     * @type {UpdateRedirectLinkRequest}
     * @memberof RedirectLinkApiUpdateRedirectLink
     */
    readonly updateRedirectLinkRequest?: UpdateRedirectLinkRequest
}

/**
 * RedirectLinkApi - object-oriented interface
 * @export
 * @class RedirectLinkApi
 * @extends {BaseAPI}
 */
export class RedirectLinkApi extends BaseAPI {
    /**
     * 
     * @summary Create Redirect Link
     * @param {RedirectLinkApiCreateRedirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedirectLinkApi
     */
    public createRedirectLink(requestParameters: RedirectLinkApiCreateRedirectLinkRequest = {}, options?: AxiosRequestConfig) {
        return RedirectLinkApiFp(this.configuration).createRedirectLink(requestParameters.storeRedirectLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Redirect Link
     * @param {RedirectLinkApiUpdateRedirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedirectLinkApi
     */
    public updateRedirectLink(requestParameters: RedirectLinkApiUpdateRedirectLinkRequest, options?: AxiosRequestConfig) {
        return RedirectLinkApiFp(this.configuration).updateRedirectLink(requestParameters.redirectLinkId, requestParameters.updateRedirectLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
