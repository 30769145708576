/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BiGetCorporations200Response } from '../AfClApiModels';
/**
 * HubCorporationsApi - axios parameter creator
 * @export
 */
export const HubCorporationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Corporations for the hub endpoint
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubGetCorporations: async (page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/hub/corporations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HubCorporationsApi - functional programming interface
 * @export
 */
export const HubCorporationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HubCorporationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Corporations for the hub endpoint
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubGetCorporations(page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiGetCorporations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubGetCorporations(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HubCorporationsApi - factory interface
 * @export
 */
export const HubCorporationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HubCorporationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Corporations for the hub endpoint
         * @param {HubCorporationsApiHubGetCorporationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubGetCorporations(requestParameters: HubCorporationsApiHubGetCorporationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BiGetCorporations200Response> {
            return localVarFp.hubGetCorporations(requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hubGetCorporations operation in HubCorporationsApi.
 * @export
 * @interface HubCorporationsApiHubGetCorporationsRequest
 */
export interface HubCorporationsApiHubGetCorporationsRequest {
    /**
     * 
     * @type {number}
     * @memberof HubCorporationsApiHubGetCorporations
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof HubCorporationsApiHubGetCorporations
     */
    readonly perPage?: number
}

/**
 * HubCorporationsApi - object-oriented interface
 * @export
 * @class HubCorporationsApi
 * @extends {BaseAPI}
 */
export class HubCorporationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Corporations for the hub endpoint
     * @param {HubCorporationsApiHubGetCorporationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubCorporationsApi
     */
    public hubGetCorporations(requestParameters: HubCorporationsApiHubGetCorporationsRequest = {}, options?: AxiosRequestConfig) {
        return HubCorporationsApiFp(this.configuration).hubGetCorporations(requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
