/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminProgramLicense } from '../AfClApiModels';
// @ts-ignore
import { GetProgramLicenses200Response } from '../AfClApiModels';
// @ts-ignore
import { StoreProgramLicense } from '../AfClApiModels';
// @ts-ignore
import { UpdateProgramLicenseExpiredAtRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateProgramLicenseRequest } from '../AfClApiModels';
/**
 * AdminProgramLicensesApi - axios parameter creator
 * @export
 */
export const AdminProgramLicensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Program License
         * @param {number} programLicenseId Numeric ID of the program license to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramLicense: async (programLicenseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programLicenseId' is not null or undefined
            assertParamExists('deleteProgramLicense', 'programLicenseId', programLicenseId)
            const localVarPath = `/v1/admin/program-licenses/{programLicenseId}`
                .replace(`{${"programLicenseId"}}`, encodeURIComponent(String(programLicenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Program Licenses
         * @param {number} [complianceId] 
         * @param {number} [licenseId] 
         * @param {string} [complianceName] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {number} [programId] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [complianceIds] 
         * @param {Array<number>} [accountCoordinatorIds] 
         * @param {Array<number>} [accountManagerIds] 
         * @param {number} [expiresIn] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramLicenses: async (complianceId?: number, licenseId?: number, complianceName?: string, status?: 'ACTIVE' | 'INACTIVE', programId?: number, verticalIds?: Array<number>, regionIds?: Array<number>, complianceIds?: Array<number>, accountCoordinatorIds?: Array<number>, accountManagerIds?: Array<number>, expiresIn?: number, sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/program-licenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (complianceId !== undefined) {
                localVarQueryParameter['compliance_id'] = complianceId;
            }

            if (licenseId !== undefined) {
                localVarQueryParameter['license_id'] = licenseId;
            }

            if (complianceName !== undefined) {
                localVarQueryParameter['compliance_name'] = complianceName;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (programId !== undefined) {
                localVarQueryParameter['program_id'] = programId;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (complianceIds) {
                localVarQueryParameter['compliance_ids[]'] = complianceIds;
            }

            if (accountCoordinatorIds) {
                localVarQueryParameter['account_coordinator_ids[]'] = accountCoordinatorIds;
            }

            if (accountManagerIds) {
                localVarQueryParameter['account_manager_ids[]'] = accountManagerIds;
            }

            if (expiresIn !== undefined) {
                localVarQueryParameter['expires_in'] = expiresIn;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Program License
         * @param {StoreProgramLicense} [storeProgramLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeProgramLicense: async (storeProgramLicense?: StoreProgramLicense, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/program-licenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeProgramLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Program License
         * @param {number} programLicenseId Numeric ID of the program license to search
         * @param {UpdateProgramLicenseRequest} [updateProgramLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLicense: async (programLicenseId: number, updateProgramLicenseRequest?: UpdateProgramLicenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programLicenseId' is not null or undefined
            assertParamExists('updateProgramLicense', 'programLicenseId', programLicenseId)
            const localVarPath = `/v1/admin/program-licenses/{programLicenseId}`
                .replace(`{${"programLicenseId"}}`, encodeURIComponent(String(programLicenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProgramLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update expired-at for Program Licenses
         * @param {UpdateProgramLicenseExpiredAtRequest} [updateProgramLicenseExpiredAtRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLicenseExpiredAt: async (updateProgramLicenseExpiredAtRequest?: UpdateProgramLicenseExpiredAtRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/program-licenses/expired-at`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProgramLicenseExpiredAtRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramLicensesApi - functional programming interface
 * @export
 */
export const AdminProgramLicensesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramLicensesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Program License
         * @param {number} programLicenseId Numeric ID of the program license to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgramLicense(programLicenseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProgramLicense(programLicenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Program Licenses
         * @param {number} [complianceId] 
         * @param {number} [licenseId] 
         * @param {string} [complianceName] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {number} [programId] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [complianceIds] 
         * @param {Array<number>} [accountCoordinatorIds] 
         * @param {Array<number>} [accountManagerIds] 
         * @param {number} [expiresIn] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramLicenses(complianceId?: number, licenseId?: number, complianceName?: string, status?: 'ACTIVE' | 'INACTIVE', programId?: number, verticalIds?: Array<number>, regionIds?: Array<number>, complianceIds?: Array<number>, accountCoordinatorIds?: Array<number>, accountManagerIds?: Array<number>, expiresIn?: number, sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramLicenses200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramLicenses(complianceId, licenseId, complianceName, status, programId, verticalIds, regionIds, complianceIds, accountCoordinatorIds, accountManagerIds, expiresIn, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Program License
         * @param {StoreProgramLicense} [storeProgramLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeProgramLicense(storeProgramLicense?: StoreProgramLicense, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeProgramLicense(storeProgramLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Program License
         * @param {number} programLicenseId Numeric ID of the program license to search
         * @param {UpdateProgramLicenseRequest} [updateProgramLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramLicense(programLicenseId: number, updateProgramLicenseRequest?: UpdateProgramLicenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramLicense(programLicenseId, updateProgramLicenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update expired-at for Program Licenses
         * @param {UpdateProgramLicenseExpiredAtRequest} [updateProgramLicenseExpiredAtRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramLicenseExpiredAt(updateProgramLicenseExpiredAtRequest?: UpdateProgramLicenseExpiredAtRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminProgramLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramLicenseExpiredAt(updateProgramLicenseExpiredAtRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramLicensesApi - factory interface
 * @export
 */
export const AdminProgramLicensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramLicensesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Program License
         * @param {AdminProgramLicensesApiDeleteProgramLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgramLicense(requestParameters: AdminProgramLicensesApiDeleteProgramLicenseRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProgramLicense(requestParameters.programLicenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Program Licenses
         * @param {AdminProgramLicensesApiGetProgramLicensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramLicenses(requestParameters: AdminProgramLicensesApiGetProgramLicensesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetProgramLicenses200Response> {
            return localVarFp.getProgramLicenses(requestParameters.complianceId, requestParameters.licenseId, requestParameters.complianceName, requestParameters.status, requestParameters.programId, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.complianceIds, requestParameters.accountCoordinatorIds, requestParameters.accountManagerIds, requestParameters.expiresIn, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Program License
         * @param {AdminProgramLicensesApiStoreProgramLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeProgramLicense(requestParameters: AdminProgramLicensesApiStoreProgramLicenseRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminProgramLicense> {
            return localVarFp.storeProgramLicense(requestParameters.storeProgramLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Program License
         * @param {AdminProgramLicensesApiUpdateProgramLicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLicense(requestParameters: AdminProgramLicensesApiUpdateProgramLicenseRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramLicense> {
            return localVarFp.updateProgramLicense(requestParameters.programLicenseId, requestParameters.updateProgramLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update expired-at for Program Licenses
         * @param {AdminProgramLicensesApiUpdateProgramLicenseExpiredAtRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLicenseExpiredAt(requestParameters: AdminProgramLicensesApiUpdateProgramLicenseExpiredAtRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminProgramLicense>> {
            return localVarFp.updateProgramLicenseExpiredAt(requestParameters.updateProgramLicenseExpiredAtRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteProgramLicense operation in AdminProgramLicensesApi.
 * @export
 * @interface AdminProgramLicensesApiDeleteProgramLicenseRequest
 */
export interface AdminProgramLicensesApiDeleteProgramLicenseRequest {
    /**
     * Numeric ID of the program license to delete
     * @type {number}
     * @memberof AdminProgramLicensesApiDeleteProgramLicense
     */
    readonly programLicenseId: number
}

/**
 * Request parameters for getProgramLicenses operation in AdminProgramLicensesApi.
 * @export
 * @interface AdminProgramLicensesApiGetProgramLicensesRequest
 */
export interface AdminProgramLicensesApiGetProgramLicensesRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly complianceId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly licenseId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly complianceName?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE'}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly status?: 'ACTIVE' | 'INACTIVE'

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly programId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly complianceIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly accountCoordinatorIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly accountManagerIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly expiresIn?: number

    /**
     * 
     * @type {string}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminProgramLicensesApiGetProgramLicenses
     */
    readonly perPage?: number
}

/**
 * Request parameters for storeProgramLicense operation in AdminProgramLicensesApi.
 * @export
 * @interface AdminProgramLicensesApiStoreProgramLicenseRequest
 */
export interface AdminProgramLicensesApiStoreProgramLicenseRequest {
    /**
     * 
     * @type {StoreProgramLicense}
     * @memberof AdminProgramLicensesApiStoreProgramLicense
     */
    readonly storeProgramLicense?: StoreProgramLicense
}

/**
 * Request parameters for updateProgramLicense operation in AdminProgramLicensesApi.
 * @export
 * @interface AdminProgramLicensesApiUpdateProgramLicenseRequest
 */
export interface AdminProgramLicensesApiUpdateProgramLicenseRequest {
    /**
     * Numeric ID of the program license to search
     * @type {number}
     * @memberof AdminProgramLicensesApiUpdateProgramLicense
     */
    readonly programLicenseId: number

    /**
     * 
     * @type {UpdateProgramLicenseRequest}
     * @memberof AdminProgramLicensesApiUpdateProgramLicense
     */
    readonly updateProgramLicenseRequest?: UpdateProgramLicenseRequest
}

/**
 * Request parameters for updateProgramLicenseExpiredAt operation in AdminProgramLicensesApi.
 * @export
 * @interface AdminProgramLicensesApiUpdateProgramLicenseExpiredAtRequest
 */
export interface AdminProgramLicensesApiUpdateProgramLicenseExpiredAtRequest {
    /**
     * 
     * @type {UpdateProgramLicenseExpiredAtRequest}
     * @memberof AdminProgramLicensesApiUpdateProgramLicenseExpiredAt
     */
    readonly updateProgramLicenseExpiredAtRequest?: UpdateProgramLicenseExpiredAtRequest
}

/**
 * AdminProgramLicensesApi - object-oriented interface
 * @export
 * @class AdminProgramLicensesApi
 * @extends {BaseAPI}
 */
export class AdminProgramLicensesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Program License
     * @param {AdminProgramLicensesApiDeleteProgramLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLicensesApi
     */
    public deleteProgramLicense(requestParameters: AdminProgramLicensesApiDeleteProgramLicenseRequest, options?: AxiosRequestConfig) {
        return AdminProgramLicensesApiFp(this.configuration).deleteProgramLicense(requestParameters.programLicenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Program Licenses
     * @param {AdminProgramLicensesApiGetProgramLicensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLicensesApi
     */
    public getProgramLicenses(requestParameters: AdminProgramLicensesApiGetProgramLicensesRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramLicensesApiFp(this.configuration).getProgramLicenses(requestParameters.complianceId, requestParameters.licenseId, requestParameters.complianceName, requestParameters.status, requestParameters.programId, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.complianceIds, requestParameters.accountCoordinatorIds, requestParameters.accountManagerIds, requestParameters.expiresIn, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Program License
     * @param {AdminProgramLicensesApiStoreProgramLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLicensesApi
     */
    public storeProgramLicense(requestParameters: AdminProgramLicensesApiStoreProgramLicenseRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramLicensesApiFp(this.configuration).storeProgramLicense(requestParameters.storeProgramLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Program License
     * @param {AdminProgramLicensesApiUpdateProgramLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLicensesApi
     */
    public updateProgramLicense(requestParameters: AdminProgramLicensesApiUpdateProgramLicenseRequest, options?: AxiosRequestConfig) {
        return AdminProgramLicensesApiFp(this.configuration).updateProgramLicense(requestParameters.programLicenseId, requestParameters.updateProgramLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update expired-at for Program Licenses
     * @param {AdminProgramLicensesApiUpdateProgramLicenseExpiredAtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramLicensesApi
     */
    public updateProgramLicenseExpiredAt(requestParameters: AdminProgramLicensesApiUpdateProgramLicenseExpiredAtRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramLicensesApiFp(this.configuration).updateProgramLicenseExpiredAt(requestParameters.updateProgramLicenseExpiredAtRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
