/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherRedirectDomainResource } from '../AfClApiModels';
/**
 * PublisherCustomDomainsApi - axios parameter creator
 * @export
 */
export const PublisherCustomDomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the publisher\'s existing custom domains
         * @param {number} publisherId Get the custom domains of the publisher with this Publisher ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomDomains: async (publisherId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('getCustomDomains', 'publisherId', publisherId)
            const localVarPath = `/v1/publishers/{publisherId}/custom-domains`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherCustomDomainsApi - functional programming interface
 * @export
 */
export const PublisherCustomDomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherCustomDomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the publisher\'s existing custom domains
         * @param {number} publisherId Get the custom domains of the publisher with this Publisher ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomDomains(publisherId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublisherRedirectDomainResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomDomains(publisherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherCustomDomainsApi - factory interface
 * @export
 */
export const PublisherCustomDomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherCustomDomainsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the publisher\'s existing custom domains
         * @param {PublisherCustomDomainsApiGetCustomDomainsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomDomains(requestParameters: PublisherCustomDomainsApiGetCustomDomainsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PublisherRedirectDomainResource>> {
            return localVarFp.getCustomDomains(requestParameters.publisherId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCustomDomains operation in PublisherCustomDomainsApi.
 * @export
 * @interface PublisherCustomDomainsApiGetCustomDomainsRequest
 */
export interface PublisherCustomDomainsApiGetCustomDomainsRequest {
    /**
     * Get the custom domains of the publisher with this Publisher ID
     * @type {number}
     * @memberof PublisherCustomDomainsApiGetCustomDomains
     */
    readonly publisherId: number
}

/**
 * PublisherCustomDomainsApi - object-oriented interface
 * @export
 * @class PublisherCustomDomainsApi
 * @extends {BaseAPI}
 */
export class PublisherCustomDomainsApi extends BaseAPI {
    /**
     * 
     * @summary Get the publisher\'s existing custom domains
     * @param {PublisherCustomDomainsApiGetCustomDomainsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherCustomDomainsApi
     */
    public getCustomDomains(requestParameters: PublisherCustomDomainsApiGetCustomDomainsRequest, options?: AxiosRequestConfig) {
        return PublisherCustomDomainsApiFp(this.configuration).getCustomDomains(requestParameters.publisherId, options).then((request) => request(this.axios, this.basePath));
    }
}
