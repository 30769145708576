/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminComplianceResource } from '../AfClApiModels';
// @ts-ignore
import { AdminProgramLicense } from '../AfClApiModels';
// @ts-ignore
import { GetCompliances200Response } from '../AfClApiModels';
/**
 * AdminCompliancesApi - axios parameter creator
 * @export
 */
export const AdminCompliancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Compliance
         * @param {string} name 
         * @param {string} shortName 
         * @param {string} localName 
         * @param {string} settlement 
         * @param {string} description 
         * @param {Array<number>} verticalIds 
         * @param {Array<number>} regionIds 
         * @param {File} logo File: jpg,jpeg,png
         * @param {File} logoAge File: jpg,jpeg,png
         * @param {boolean} [isInternational] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompliance: async (name: string, shortName: string, localName: string, settlement: string, description: string, verticalIds: Array<number>, regionIds: Array<number>, logo: File, logoAge: File, isInternational?: boolean, text?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createCompliance', 'name', name)
            // verify required parameter 'shortName' is not null or undefined
            assertParamExists('createCompliance', 'shortName', shortName)
            // verify required parameter 'localName' is not null or undefined
            assertParamExists('createCompliance', 'localName', localName)
            // verify required parameter 'settlement' is not null or undefined
            assertParamExists('createCompliance', 'settlement', settlement)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('createCompliance', 'description', description)
            // verify required parameter 'verticalIds' is not null or undefined
            assertParamExists('createCompliance', 'verticalIds', verticalIds)
            // verify required parameter 'regionIds' is not null or undefined
            assertParamExists('createCompliance', 'regionIds', regionIds)
            // verify required parameter 'logo' is not null or undefined
            assertParamExists('createCompliance', 'logo', logo)
            // verify required parameter 'logoAge' is not null or undefined
            assertParamExists('createCompliance', 'logoAge', logoAge)
            const localVarPath = `/v1/admin/compliances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (shortName !== undefined) { 
                localVarFormParams.append('short_name', shortName as any);
            }
    
            if (localName !== undefined) { 
                localVarFormParams.append('local_name', localName as any);
            }
    
            if (settlement !== undefined) { 
                localVarFormParams.append('settlement', settlement as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
                if (verticalIds) {
                localVarFormParams.append('vertical_ids', verticalIds.join(COLLECTION_FORMATS.csv));
            }

                if (regionIds) {
                localVarFormParams.append('region_ids', regionIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (logoAge !== undefined) { 
                localVarFormParams.append('logo_age', logoAge as any);
            }
    
            if (isInternational !== undefined) { 
                localVarFormParams.append('is_international', isInternational as any);
            }
    
            if (text !== undefined) { 
                localVarFormParams.append('text', text as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Compliance
         * @param {number} complianceId Numeric ID of the compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompliance: async (complianceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complianceId' is not null or undefined
            assertParamExists('deleteCompliance', 'complianceId', complianceId)
            const localVarPath = `/v1/admin/compliances/{complianceId}`
                .replace(`{${"complianceId"}}`, encodeURIComponent(String(complianceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show programs by compliance ID.
         * @param {number} complianceId Numeric ID of the program License Compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompliancePrograms: async (complianceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complianceId' is not null or undefined
            assertParamExists('getCompliancePrograms', 'complianceId', complianceId)
            const localVarPath = `/v1/admin/compliances/{complianceId}/programs`
                .replace(`{${"complianceId"}}`, encodeURIComponent(String(complianceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Compliances
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompliances: async (name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/compliances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Compliance
         * @param {number} complianceId Numeric ID of the compliance
         * @param {string} [name] 
         * @param {string} [shortName] 
         * @param {string} [localName] 
         * @param {string} [settlement] 
         * @param {string} [description] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {File} [logo] File: jpg,jpeg,png
         * @param {File} [logoAge] File: jpg,jpeg,png
         * @param {boolean} [isInternational] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompliance: async (complianceId: number, name?: string, shortName?: string, localName?: string, settlement?: string, description?: string, verticalIds?: Array<number>, regionIds?: Array<number>, logo?: File, logoAge?: File, isInternational?: boolean, text?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complianceId' is not null or undefined
            assertParamExists('updateCompliance', 'complianceId', complianceId)
            const localVarPath = `/v1/admin/compliances/{complianceId}`
                .replace(`{${"complianceId"}}`, encodeURIComponent(String(complianceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (shortName !== undefined) { 
                localVarFormParams.append('short_name', shortName as any);
            }
    
            if (localName !== undefined) { 
                localVarFormParams.append('local_name', localName as any);
            }
    
            if (settlement !== undefined) { 
                localVarFormParams.append('settlement', settlement as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
                if (verticalIds) {
                localVarFormParams.append('vertical_ids', verticalIds.join(COLLECTION_FORMATS.csv));
            }

                if (regionIds) {
                localVarFormParams.append('region_ids', regionIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (logoAge !== undefined) { 
                localVarFormParams.append('logo_age', logoAge as any);
            }
    
            if (isInternational !== undefined) { 
                localVarFormParams.append('is_international', isInternational as any);
            }
    
            if (text !== undefined) { 
                localVarFormParams.append('text', text as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCompliancesApi - functional programming interface
 * @export
 */
export const AdminCompliancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCompliancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Compliance
         * @param {string} name 
         * @param {string} shortName 
         * @param {string} localName 
         * @param {string} settlement 
         * @param {string} description 
         * @param {Array<number>} verticalIds 
         * @param {Array<number>} regionIds 
         * @param {File} logo File: jpg,jpeg,png
         * @param {File} logoAge File: jpg,jpeg,png
         * @param {boolean} [isInternational] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompliance(name: string, shortName: string, localName: string, settlement: string, description: string, verticalIds: Array<number>, regionIds: Array<number>, logo: File, logoAge: File, isInternational?: boolean, text?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminComplianceResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompliance(name, shortName, localName, settlement, description, verticalIds, regionIds, logo, logoAge, isInternational, text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Compliance
         * @param {number} complianceId Numeric ID of the compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompliance(complianceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompliance(complianceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show programs by compliance ID.
         * @param {number} complianceId Numeric ID of the program License Compliance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompliancePrograms(complianceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminProgramLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompliancePrograms(complianceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Compliances
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompliances(name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompliances200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompliances(name, regionIds, verticalIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Compliance
         * @param {number} complianceId Numeric ID of the compliance
         * @param {string} [name] 
         * @param {string} [shortName] 
         * @param {string} [localName] 
         * @param {string} [settlement] 
         * @param {string} [description] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [regionIds] 
         * @param {File} [logo] File: jpg,jpeg,png
         * @param {File} [logoAge] File: jpg,jpeg,png
         * @param {boolean} [isInternational] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompliance(complianceId: number, name?: string, shortName?: string, localName?: string, settlement?: string, description?: string, verticalIds?: Array<number>, regionIds?: Array<number>, logo?: File, logoAge?: File, isInternational?: boolean, text?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminComplianceResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompliance(complianceId, name, shortName, localName, settlement, description, verticalIds, regionIds, logo, logoAge, isInternational, text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCompliancesApi - factory interface
 * @export
 */
export const AdminCompliancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCompliancesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Compliance
         * @param {AdminCompliancesApiCreateComplianceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompliance(requestParameters: AdminCompliancesApiCreateComplianceRequest, options?: AxiosRequestConfig): AxiosPromise<AdminComplianceResource> {
            return localVarFp.createCompliance(requestParameters.name, requestParameters.shortName, requestParameters.localName, requestParameters.settlement, requestParameters.description, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.logo, requestParameters.logoAge, requestParameters.isInternational, requestParameters.text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Compliance
         * @param {AdminCompliancesApiDeleteComplianceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompliance(requestParameters: AdminCompliancesApiDeleteComplianceRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCompliance(requestParameters.complianceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show programs by compliance ID.
         * @param {AdminCompliancesApiGetComplianceProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompliancePrograms(requestParameters: AdminCompliancesApiGetComplianceProgramsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AdminProgramLicense>> {
            return localVarFp.getCompliancePrograms(requestParameters.complianceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Compliances
         * @param {AdminCompliancesApiGetCompliancesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompliances(requestParameters: AdminCompliancesApiGetCompliancesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetCompliances200Response> {
            return localVarFp.getCompliances(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Compliance
         * @param {AdminCompliancesApiUpdateComplianceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompliance(requestParameters: AdminCompliancesApiUpdateComplianceRequest, options?: AxiosRequestConfig): AxiosPromise<AdminComplianceResource> {
            return localVarFp.updateCompliance(requestParameters.complianceId, requestParameters.name, requestParameters.shortName, requestParameters.localName, requestParameters.settlement, requestParameters.description, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.logo, requestParameters.logoAge, requestParameters.isInternational, requestParameters.text, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCompliance operation in AdminCompliancesApi.
 * @export
 * @interface AdminCompliancesApiCreateComplianceRequest
 */
export interface AdminCompliancesApiCreateComplianceRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly shortName: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly localName: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly settlement: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly description: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly verticalIds: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly regionIds: Array<number>

    /**
     * File: jpg,jpeg,png
     * @type {File}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly logo: File

    /**
     * File: jpg,jpeg,png
     * @type {File}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly logoAge: File

    /**
     * 
     * @type {boolean}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly isInternational?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiCreateCompliance
     */
    readonly text?: string
}

/**
 * Request parameters for deleteCompliance operation in AdminCompliancesApi.
 * @export
 * @interface AdminCompliancesApiDeleteComplianceRequest
 */
export interface AdminCompliancesApiDeleteComplianceRequest {
    /**
     * Numeric ID of the compliance
     * @type {number}
     * @memberof AdminCompliancesApiDeleteCompliance
     */
    readonly complianceId: number
}

/**
 * Request parameters for getCompliancePrograms operation in AdminCompliancesApi.
 * @export
 * @interface AdminCompliancesApiGetComplianceProgramsRequest
 */
export interface AdminCompliancesApiGetComplianceProgramsRequest {
    /**
     * Numeric ID of the program License Compliance
     * @type {number}
     * @memberof AdminCompliancesApiGetCompliancePrograms
     */
    readonly complianceId: number
}

/**
 * Request parameters for getCompliances operation in AdminCompliancesApi.
 * @export
 * @interface AdminCompliancesApiGetCompliancesRequest
 */
export interface AdminCompliancesApiGetCompliancesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiGetCompliances
     */
    readonly name?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminCompliancesApiGetCompliances
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminCompliancesApiGetCompliances
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminCompliancesApiGetCompliances
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminCompliancesApiGetCompliances
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateCompliance operation in AdminCompliancesApi.
 * @export
 * @interface AdminCompliancesApiUpdateComplianceRequest
 */
export interface AdminCompliancesApiUpdateComplianceRequest {
    /**
     * Numeric ID of the compliance
     * @type {number}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly complianceId: number

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly shortName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly localName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly settlement?: string

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly description?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly regionIds?: Array<number>

    /**
     * File: jpg,jpeg,png
     * @type {File}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly logo?: File

    /**
     * File: jpg,jpeg,png
     * @type {File}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly logoAge?: File

    /**
     * 
     * @type {boolean}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly isInternational?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminCompliancesApiUpdateCompliance
     */
    readonly text?: string
}

/**
 * AdminCompliancesApi - object-oriented interface
 * @export
 * @class AdminCompliancesApi
 * @extends {BaseAPI}
 */
export class AdminCompliancesApi extends BaseAPI {
    /**
     * 
     * @summary Create Compliance
     * @param {AdminCompliancesApiCreateComplianceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompliancesApi
     */
    public createCompliance(requestParameters: AdminCompliancesApiCreateComplianceRequest, options?: AxiosRequestConfig) {
        return AdminCompliancesApiFp(this.configuration).createCompliance(requestParameters.name, requestParameters.shortName, requestParameters.localName, requestParameters.settlement, requestParameters.description, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.logo, requestParameters.logoAge, requestParameters.isInternational, requestParameters.text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Compliance
     * @param {AdminCompliancesApiDeleteComplianceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompliancesApi
     */
    public deleteCompliance(requestParameters: AdminCompliancesApiDeleteComplianceRequest, options?: AxiosRequestConfig) {
        return AdminCompliancesApiFp(this.configuration).deleteCompliance(requestParameters.complianceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show programs by compliance ID.
     * @param {AdminCompliancesApiGetComplianceProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompliancesApi
     */
    public getCompliancePrograms(requestParameters: AdminCompliancesApiGetComplianceProgramsRequest, options?: AxiosRequestConfig) {
        return AdminCompliancesApiFp(this.configuration).getCompliancePrograms(requestParameters.complianceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Compliances
     * @param {AdminCompliancesApiGetCompliancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompliancesApi
     */
    public getCompliances(requestParameters: AdminCompliancesApiGetCompliancesRequest = {}, options?: AxiosRequestConfig) {
        return AdminCompliancesApiFp(this.configuration).getCompliances(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Compliance
     * @param {AdminCompliancesApiUpdateComplianceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompliancesApi
     */
    public updateCompliance(requestParameters: AdminCompliancesApiUpdateComplianceRequest, options?: AxiosRequestConfig) {
        return AdminCompliancesApiFp(this.configuration).updateCompliance(requestParameters.complianceId, requestParameters.name, requestParameters.shortName, requestParameters.localName, requestParameters.settlement, requestParameters.description, requestParameters.verticalIds, requestParameters.regionIds, requestParameters.logo, requestParameters.logoAge, requestParameters.isInternational, requestParameters.text, options).then((request) => request(this.axios, this.basePath));
    }
}
