<template>
    <BaseModal
        v-model:is-opened="opened"
        :title="isEditMode ? 'Update Key' : 'Create key'"
    >
        <template #content>
            <BaseInput
                v-model="form.name"
                label="Name *"
                :vld-errors="v$.name.$errors"
            />
        </template>
        <template #actions>
            <BaseButton
                class="w-full"
                variant="primary"
                :loading="sourceKeyIsUpdating || sourceKeyIsCreating"
                @click="updateSourceKey"
            >
                {{ isEditMode ? 'Update' : 'Create' }}
            </BaseButton>
        </template>
    </BaseModal>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core';
import BaseModal from '@/Components/Core/BaseModal.vue';
import BaseButton from '@/Components/Core/BaseButton.vue';
import { computed, reactive, watch } from 'vue';
import BaseInput from '@/Components/Core/Form/BaseInput.vue';
import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import { SourceKey } from '@/stub';
import { useSourceKeyUpdate } from '@/Services/ApiHooks/Admin/Keys/UseSourcesKeyUpdate';
import { useSourceKeyCreate } from '@/Services/ApiHooks/Admin/Keys/UseSourcesKeyCreate';
import { useValidateMessage } from '@/Utils/ValidateMessageHelper';

const props = defineProps<{
    modelValue: boolean,
    selectedKey: SourceKey | undefined
}>();

const emit = defineEmits(['update:modelValue']);
const { modelValue: opened } = useVModels(props, emit);
const isEditMode = computed(() => !!props.selectedKey);
const toast = useToast();
const { required } = useValidateMessage();

const form = reactive<{
    name: string | null,
}>({
    name: props?.selectedKey?.name ?? null,
});

watch(() => props.selectedKey, (newKey) => {
    if (newKey) {
        const { name } = newKey;
        form.name = name;
    } else {
        form.name = null;
    }
});

const rules = {
    name: { required },
};
const v$ = useVuelidate(rules, form);

const {
    mutate: sourceKeyCreate,
    isLoading: sourceKeyIsCreating
} = useSourceKeyCreate(() => {
    opened.value = false;
    toast.success('Source key successfully created');
});

const {
    mutate: sourceKeyUpdate,
    isLoading: sourceKeyIsUpdating
} = useSourceKeyUpdate(() => {
    opened.value = false;
    toast.success('Source key successfully updated');
});

const updateSourceKey = async () => {
    const isFormValid = await v$.value.$validate();
    if (isFormValid && form.name) {
        isEditMode.value
            ? sourceKeyUpdate({ id: props.selectedKey.id, name: form.name })
            : sourceKeyCreate({ name: form.name });
    }
};
</script>
