/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetSiteGroupsPaginated200Response } from '../AfClApiModels';
/**
 * SiteGroupsApi - axios parameter creator
 * @export
 */
export const SiteGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all available site groups with sites  paginated.
         * @param {number} [page] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [publisherIds] 
         * @param {Array<number>} [corporationIds] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroupsPaginated: async (page?: number, siteIds?: Array<number>, publisherIds?: Array<number>, corporationIds?: Array<number>, perPage?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sites/groups/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (publisherIds) {
                localVarQueryParameter['publisher_ids[]'] = publisherIds;
            }

            if (corporationIds) {
                localVarQueryParameter['corporation_ids[]'] = corporationIds;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteGroupsApi - functional programming interface
 * @export
 */
export const SiteGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all available site groups with sites  paginated.
         * @param {number} [page] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [publisherIds] 
         * @param {Array<number>} [corporationIds] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteGroupsPaginated(page?: number, siteIds?: Array<number>, publisherIds?: Array<number>, corporationIds?: Array<number>, perPage?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteGroupsPaginated200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteGroupsPaginated(page, siteIds, publisherIds, corporationIds, perPage, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteGroupsApi - factory interface
 * @export
 */
export const SiteGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all available site groups with sites  paginated.
         * @param {SiteGroupsApiGetSiteGroupsPaginatedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroupsPaginated(requestParameters: SiteGroupsApiGetSiteGroupsPaginatedRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetSiteGroupsPaginated200Response> {
            return localVarFp.getSiteGroupsPaginated(requestParameters.page, requestParameters.siteIds, requestParameters.publisherIds, requestParameters.corporationIds, requestParameters.perPage, requestParameters.name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSiteGroupsPaginated operation in SiteGroupsApi.
 * @export
 * @interface SiteGroupsApiGetSiteGroupsPaginatedRequest
 */
export interface SiteGroupsApiGetSiteGroupsPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof SiteGroupsApiGetSiteGroupsPaginated
     */
    readonly page?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof SiteGroupsApiGetSiteGroupsPaginated
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof SiteGroupsApiGetSiteGroupsPaginated
     */
    readonly publisherIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof SiteGroupsApiGetSiteGroupsPaginated
     */
    readonly corporationIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof SiteGroupsApiGetSiteGroupsPaginated
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof SiteGroupsApiGetSiteGroupsPaginated
     */
    readonly name?: string
}

/**
 * SiteGroupsApi - object-oriented interface
 * @export
 * @class SiteGroupsApi
 * @extends {BaseAPI}
 */
export class SiteGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Get all available site groups with sites  paginated.
     * @param {SiteGroupsApiGetSiteGroupsPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteGroupsApi
     */
    public getSiteGroupsPaginated(requestParameters: SiteGroupsApiGetSiteGroupsPaginatedRequest = {}, options?: AxiosRequestConfig) {
        return SiteGroupsApiFp(this.configuration).getSiteGroupsPaginated(requestParameters.page, requestParameters.siteIds, requestParameters.publisherIds, requestParameters.corporationIds, requestParameters.perPage, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }
}
